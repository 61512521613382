var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup",
    attrs: {
      "width": "40%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header w-100"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0 d-flex align-items-center"
  }, [_c('a', {
    staticClass: "newBackButton mr-16px",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "arrow-left-circle"
    }
  }), _c('span', {
    staticClass: "newBackButton-textpart"
  }, [_vm._v("Back")])], 1), _vm._v(" Public Holidays ")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('div', [_c('button', {
    staticClass: "newButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CreatePublicHolidayGroup');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Add Group")])], 1)])])]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v("These days are automatically blocked from having Meetings scheduled.")]), _vm.response.groups.length == 1 ? _c('UpdatePublicHolidayGroup', {
    attrs: {
      "groupId": _vm.response.groups[0].id,
      "conditions": {
        delete: false
      }
    }
  }) : _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again showLastTDonHover mt-0",
    attrs: {
      "isListEmpty": _vm.response.groups.length == 0,
      "listLength": _vm.response.groups.length,
      "loading": _vm.api.fetch_groups.send
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.response.groups, function (group, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: group.id,
            on: {
              "click": function ($event) {
                return _vm.$modal.show('UpdatePublicHolidayGroup', {
                  id: group.id
                });
              }
            }
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(group.name))]), _c('td', {
            staticClass: "dialer-has-sort dialer-col-left"
          }, [group.scheduler_numbers && group.scheduler_numbers[0] ? _c('div', {
            staticClass: "d-flex flex-column"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('span', {
            staticClass: "sm-mar-left font-weight-bold min-width-160px"
          }, [_c('span', {
            staticClass: "mr-1"
          }, [_c('img', {
            staticClass: "country-img",
            attrs: {
              "width": "45px",
              "src": _vm._f("flag_icon")(_vm._f("number_formater")(_vm._f("get_property")(group, 'scheduler_numbers[0].scheduler_number')))
            }
          })]), _vm._v(" " + _vm._s(_vm._f("number_formater")(_vm._f("get_property")(group, 'scheduler_numbers[0].scheduler_number'))) + " ")]), group.scheduler_numbers.length > 1 ? _c('div', {
            staticClass: "w-auto dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight dialer-dropDown-p-1 ml-2",
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                _vm.conditions.more = _vm.conditions.more == group.id ? '' : group.id;
              }
            }
          }, [_c('div', {
            staticClass: "px-2 rounded more-numbers-tag"
          }, [_c('small', [_vm._v("+ " + _vm._s(group.scheduler_numbers.length - 1) + " more")])])]) : _vm._e()]), _vm.conditions.more == group.id ? [_vm._l(group.scheduler_numbers.filter(function (item, index) {
            return index > 0;
          }), function (did, index) {
            return [_c('div', {
              directives: [{
                name: "show",
                rawName: "v-show",
                value: index >= start && index <= end,
                expression: "index >= start && index <= end"
              }],
              key: did.scheduler_number,
              staticClass: "mt-2"
            }, [_c('div', {
              staticClass: "d-flex align-items-center sm-mar-left"
            }, [_c('span', {
              staticClass: "mr-2 d-flex align-items-center"
            }, [_c('img', {
              staticClass: "country-img",
              attrs: {
                "width": "45px",
                "src": _vm._f("flag_icon")(_vm._f("number_formater")(did.scheduler_number))
              }
            })]), _vm._v(" " + _vm._s(_vm._f("number_formater")(did.scheduler_number)) + " ")])])];
          })] : _vm._e()], 2) : _c('span', {
            staticClass: "text-center"
          }, [_vm._v(" - ")])]), _c('td', {
            staticClass: "dialer-has-sort dialer-col-right"
          }, [_vm._v(_vm._s(group.holidays || 0))])]);
        });
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "100%"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "100%"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "100%"
            }
          })])]);
        });
      },
      proxy: true
    }])
  }, [!_vm.getIsMobile ? _c('tr', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "dialer-has-sort px-2"
  }, [_c('span', [_vm._v("Group Name")])]), _c('th', {
    staticClass: "dialer-has-sort px-2 dialer-col-left"
  }, [_c('span', [_vm._v("Numbers")])]), _c('th', {
    staticClass: "dialer-has-sort px-2 dialer-col-right"
  }, [_c('span', [_vm._v("Holidays")])])]) : _vm._e()])], 1)], 1), _c('CreatePublicHolidayGroupModal', {
    on: {
      "created": function ($event) {
        return _vm.fetchGroups();
      }
    }
  }), _c('UpdatePublicHolidayGroupModal', {
    on: {
      "updated": function ($event) {
        return _vm.fetchGroups();
      },
      "removed": function ($event) {
        return _vm.fetchGroups();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }