<template>
  <div class="callActivity-Item-v2-container voicemailMessage" :class="call.status === 'Dialed' ? 'Right' : 'Left'">
    <div class="callActivity-Item-v2-inner-top">
      <div class="icon-container">
        <vb-icon v-b-popover.hover.top="'Voicemail'" :icon="'CallActivityV2-audioMessage-icon'" height="55.922" width="55.922"/>
      </div>
      <div class="user-info">
        <small v-if="call.is_shared=='yes'">Shared</small>
        <template v-if="call.caller_name=='-'">
          <div class="user-name">{{ call.caller.replaceAll('"',"") | number_formater }}</div>
          <div class="user-number">{{ call.callee.replaceAll('"',"") | number_formater }}</div>
        </template>
        <template v-else>
          <div class="user-name">{{ call.caller_name.replaceAll('"',"") | number_formater }}</div>
          <div class="user-number">{{ call.caller.replaceAll('"',"") | number_formater }}</div>
        </template>
      </div>
      <div class="middle-section">
        <span class="middle-section-text">
          <div v-if="conditions.recording && conditions.design.design_width != 25 && call.voicemail" class="dialer-audio-playback">
            <vb-audio-player :design="'minimum'" :src="call.voicemail" />
          </div>
        </span>
      </div>
      <div class="callLogIcons-container">
        <template v-if="conditions.action.enable">
          <span v-if="conditions.action.info" class="mr-8px">
            <vb-icon icon="callInfo-callLog-icon" width="35px" height="35px" @click="$emit('info')" v-b-tooltip.hover :title="`Message info`" />
          </span>
        </template>
      </div>
      <div class="last-section">
        <div class="time">
          <span>{{ call.date | filter_date_current(null,{ unix: true, formate: 'h:mm a' }) }}</span>
          <slot v-if="conditions.design.more" name="more">
            <b-dropdown v-if="conditions.action.enable" varient="link" no-caret class="ico-filter" menu-class="w-fit-content-imp">
              <template #button-content>
                <b-icon icon="three-dots" variant="#393939" font-scale="1.4"/>
              </template>
              <b-dropdown-item v-if="conditions.action.info" @click="$emit('info')">
                <vb-icon icon="dialer-callinfo-icon" width="15.7" height="15.701" class="callInfoIcon" />
                <span class="ml-3">Message info</span>
              </b-dropdown-item>
            </b-dropdown>
          </slot>
        </div>
      </div>
    </div>
    <div v-if="conditions.design.recording && call.voicemail" class="callActivity-Item-v2-inner-2nd">
      <div class="dialer-audio-playback">
        <vb-audio-player :src="call.voicemail" />
      </div>
    </div>
    <div v-if="conditions.design.message && call.transcript" class="callActivity-Item-v2-inner-2nd">
      {{call.transcript}}
    </div>
  </div>
</template>

<script>
import { GET_LOCAL_SETTING_DASHBOARD } from '@/store/helper/getters';
import { mapGetters } from 'vuex';
export default {
  name: "VoicemailLog",
  props: {
    call: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    conditions: {
      type: Object,
      default: () => ({})
    },
  },
  computed: {
    ...mapGetters([
      GET_LOCAL_SETTING_DASHBOARD,
    ]),
  },
};
</script>

<style>
</style>