<template>
  <div>
    <modal
      class="dialer_animation right_side_popup addExtension-voicemailModal addRemove_userModal m-w-700 EditOutOfHoursDayModal bigSquareCheckbox min-width-50percent-modal"
      width="40%"
      height="auto"
      :scrollable="true"
      :name="modalName"
      @before-open="onBeforeOpen($event)"
      @before-close="onBeforeClose()"
    >
      <section> 
        <div class="dialer-edit-header d-block">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h2 class="dialer-settings-title newer mb-0">Create Holiday</h2>
            </div>
            <div class="dialer-edit-actions">
              <button class="newDoneButton" type="button" :disabled="api.create_public_holiday.send" @click="createPublicHoliday()">
                <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                <span class="newDoneButton-textPart">Done</span>
              </button>
            </div>
          </div>
          <div class="vm--modal-text">Configure your business hours settings for a specific day.</div>
        </div>
        <div class="latestGreyBox-9-9-2023">
          <div class="whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionLeftAligned">
            <label>Name</label>
            <input :disabled="api.create_public_holiday.send" class="w-100" type="text" v-model="forms.create_public_holiday.name" :maxlength="$v.forms.create_public_holiday.name.$params.maxLength.max" />
            <p v-if="$v.forms.create_public_holiday.name.$error || api.create_public_holiday.validation_errors.holiday_name" class="text text-danger animated bounceIntop">
              <span v-if="!$v.forms.create_public_holiday.name.required">* Name is required</span>
              <span v-else-if="!$v.forms.create_public_holiday.name.minLength">* Name is should be minimum {{$v.forms.create_public_holiday.name.$params.minLength.min}} character</span>
              <span v-else-if="!$v.forms.create_public_holiday.name.maxLength">* Name is can be maximum {{$v.forms.create_public_holiday.name.$params.maxLength.max}} character</span>
              <span v-else-if="!$v.forms.create_public_holiday.name.valid">* Name can be number and alphabet</span>
              <span v-for="(em,i) in api.create_public_holiday.validation_errors.holiday_name" :key="i">* {{ em }}</span>
            </p>
          </div>
          <div class="whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionLeftAligned mt-20px">
            <label>Select date</label>
            <vue-ctk-date-time-picker 
              :no-label="true"
              :no-header="true"
              v-model="forms.create_public_holiday.date" 
              v-bind="ctkDateTimePickerOptions" 
              format="DD MMM YYYY" 
              formatted="DD MMM YYYY" 
              outputFormat="DD MMM YYYY" 
              class="date-time-input" 
              onlyDate 
              :disabledDates="conditions.disable_dates"
            />
            <p v-if="$v.forms.create_public_holiday.date.$error || api.create_public_holiday.validation_errors.date" class="text text-danger animated bounceIntop">
              <span v-if="!$v.forms.create_public_holiday.date.required">* Date is required</span>
              <span v-for="(em,i) in api.create_public_holiday.validation_errors.date" :key="i">* {{ em }}</span>
            </p>
          </div>
          <div class="whiteBoxWithBorderInsideGreyBox withOutPadding mt-12px">
            <b-form-group class="mb-0" style="position: relative;pointer-events:auto;">
              <div class="w-100">
                <!-- unavailable -->
                <b-form-radio 
                  class="whiteBoxWithBorderInsideGreyBox-row withHover checkBoxOrRadioInside forHolidayFix position-relative makeCheckBoxesSquare b-b" 
                  :class="{'active': forms.create_public_holiday.type=='unavailable' }"
                  :checked="forms.create_public_holiday.type"
                  value="unavailable"
                  @change="forms.create_public_holiday.type='unavailable'"
                  :disabled="api.create_public_holiday.send"
                >
                  <div class="w-100 dialer-call-option-c withRadio" :class="{'active': forms.create_public_holiday.type=='unavailable' }">
                    <div class="latestGreyBox-heading-main">Unavailable all day</div>
                  </div>
                </b-form-radio>
                <!-- custom -->
                <div class="position-relative">
                  <b-form-radio 
                    class="whiteBoxWithBorderInsideGreyBox-row withHover checkBoxOrRadioInside forHolidayFix position-relative makeCheckBoxesSquare" 
                    :class="{'active': forms.create_public_holiday.type=='custom' }"
                    :checked="forms.create_public_holiday.type"
                    value="custom"
                    @change="forms.create_public_holiday.type='custom'"
                    :disabled="api.create_public_holiday.send"
                  >
                    <div class="w-100 dialer-call-option-c withRadio" :class="{'active': forms.create_public_holiday.type=='custom' }">
                      <div class="latestGreyBox-heading-main">
                        Unavailable between 
                        <span 
                          v-if="forms.create_public_holiday.type=='custom'"
                          class="text-decoration-underline" 
                          @click.stop="$modal.show(`${_uid}-TimeSelectionModal`,{ 
                            start: forms.create_public_holiday.start_time, 
                            end: forms.create_public_holiday.end_time 
                          })"
                        >
                          {{ (forms.create_public_holiday.start_time || '00:00:00') | twentyFourHourToAmPmFormat }} - {{ (forms.create_public_holiday.end_time || '00:00:00') | twentyFourHourToAmPmFormat }}
                        </span>
                      </div>
                    </div>
                  </b-form-radio>
                </div>
              </div>
              <p v-if="$v.forms.create_public_holiday.type.$error || api.create_public_holiday.validation_errors.type" class="text text-danger animated bounceIntop">
                <span v-if="!$v.forms.create_public_holiday.type.required">* Type is required</span>
                <span v-else-if="!$v.forms.create_public_holiday.type.valid">* Type is invalid</span>
                <span v-for="(em,i) in api.create_public_holiday.validation_errors.type" :key="i">* {{ em }}</span>
              </p>
            </b-form-group>
          </div>
          <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start min-height-unset">
              <div class="d-flex align-items-center justify-content-between w-100">
                <div class="d-flex flex-column">
                  <div class="latestGreyBox-heading-main mb-0">What happens to calls after business hours ?</div>
                  <template v-if="forms.create_public_holiday.forward_type=='voicemail'">
                    <div class="latestGreyBox-descriptionText-18px">Ask to leave a voicemail</div>
                    <div class="latestGreyBox-descriptionText-14px">Call will be forward to voicemail</div>
                  </template>
                  <template v-if="forms.create_public_holiday.forward_type=='extension'">
                    <div class="latestGreyBox-descriptionText-18px">Forward to a user, team or IVR menu</div>
                    <div class="latestGreyBox-descriptionText-14px">
                      Call will be forward to
                      <span class="color-primary cursor_pointer text-decoration-underline">{{forms.create_public_holiday.extension_name}}</span>
                    </div>
                  </template>
                  <template v-if="forms.create_public_holiday.forward_type=='number'">
                    <div class="latestGreyBox-descriptionText-18px">Forward to another number</div>
                    <div class="latestGreyBox-descriptionText-14px">
                      Call will be forward to 
                      <span class="color-primary cursor_pointer text-decoration-underline">{{forms.create_public_holiday.forward_to}}</span>
                    </div>
                  </template>
                </div>
                <button 
                  class="newButton" 
                  :disabled="api.create_public_holiday.send"
                  @click.stop="$modal.show(`${_uid}-CallForwardSettingModal`,{ 
                    account: '', 
                    accountcode: '',
                    setting: {
                      forward_note: forms.create_public_holiday.forward_type,
                      forward_number: forms.create_public_holiday.forward_to,
                      extension_name: forms.create_public_holiday.extension_name,
                      number: forms.create_public_holiday.forward_to,
                    }, 
                    is_ringing_show: false, 
                    is_sec_show: false, 
                  })">
                    <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                    <span class="newButton-textPart">Change</span>
                  </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </modal>
    <TimeSelectionModal 
      :modalName="`${_uid}-TimeSelectionModal`" 
      @updated="
        forms.create_public_holiday.start_time=`${$event.start.data.HH}:${$event.start.data.mm}:${'00'}`;
        forms.create_public_holiday.end_time=`${$event.end.data.HH}:${$event.end.data.mm}:${'00'}`;
      " 
    />
    <CallForwardSettingModal class="forHolidayFix"
      :modalName="`${_uid}-CallForwardSettingModal`" 
      @updated="
        forms.create_public_holiday.forward_type=$event.forward_note;
        forms.create_public_holiday.forward_to=$event.forward_number;
        forms.create_public_holiday.extension_name=$event.extension_name;
      " 
    />
  </div>
</template>

<script>
import required from 'vuelidate/lib/validators/required'
import requiredIf from 'vuelidate/lib/validators/requiredIf'
import TimeSelectionModal from "../../TimeSelection.vue";
import CallForwardSettingModal from "../../CallForwardSettingModal.vue";
import { ctkDateTimePicker } from '@/mixin';
import { VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';
import { helpers } from 'vuelidate/lib/validators';
import maxLength from 'vuelidate/lib/validators/maxLength';
import minLength from 'vuelidate/lib/validators/minLength';
export default {
  name: 'CreatePublicHolidayModal',
  mixins: [
    ctkDateTimePicker
  ],
  components: {
    TimeSelectionModal,
    CallForwardSettingModal,
  },
  inject: [
    'appNotify',
  ],
  props: {
    modalName: {
      type: String,
      default: 'CreatePublicHoliday'
    },
  },
  data() {
    return {
      forms: {
        create_public_holiday: this.$helperFunction.formInstance({
          data: {
            name: '',
            date: '',
            type: 'unavailable', // unavailable, custom
            start_time: '',
            end_time: '',
            forward_type: 'voicemail', // voicemail, extension, number
            forward_to: '',
            extension_name: '',
          }
        }),
      },
      api: {
        create_public_holiday: this.$helperFunction.apiInstance({ validation_errors: true, }),
      },
      data: {
        group_id: '',
        conditions: null,
      },
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getVoipUsersAlises',
      'getVoipIVRSAlises',
      'getVoipTeamsAlises'
    ]),
    conditions(){ return {
      disable_dates: this.data?.conditions?.disable_dates ?? [],
    } },
  },
  validations: {
    forms: {
      create_public_holiday: {
        name: { 
          required: required,
          minLength: minLength(3), 
          maxLength: maxLength(20),
          valid: helpers.regex('valid',/^[a-z0-9 ]*$/i),
        },
        date: { 
          required: required,
        },
        type: { 
          required: required,
          valid: (value) => ['unavailable','custom'].includes(value),
        },
        start_time: { 
          required: requiredIf(function (){return this.forms.create_public_holiday.type=='custom'}),
        },
        end_time: { 
          required: requiredIf(function (){return this.forms.create_public_holiday.type=='custom'}),
        },
        forward_type: { 
          required: required,
          valid: (value) => ['voicemail','extension','number'].includes(value),
        },
        forward_to: { 
          required: requiredIf(function (){return ['extension','number'].includes(this.forms.create_public_holiday.type)}),
        },
        extension_name: { 
          required: requiredIf(function (){return ['extension'].includes(this.forms.create_public_holiday.type)}),
        },
      },
    },
  },
  filters: {
    twentyFourHourToAmPmFormat(time){ return moment(`2023-12-12 ${time}`,'YYYY-MM-DD HH:mm:ss').format('hh:mm a') },
  },
  watch: {
    "forms.create_public_holiday.type": {
      immediate: true,
      handler(newValue){
        if(newValue!='custom') {
          this.forms.create_public_holiday.start_time=''
          this.forms.create_public_holiday.end_time=''
        } else if(!this.forms.create_public_holiday.start_time && !this.forms.create_public_holiday.end_time) {
          this.forms.create_public_holiday.start_time='09:00:00'
          this.forms.create_public_holiday.end_time='17:00:00'
        }
        if(this.$refs['custom-radio']) this.$refs['custom-radio'].localChecked=newValue
        if(this.$refs['unavailable-radio']) this.$refs['unavailable-radio'].localChecked=newValue
      }
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.data.group_id=event?.params?.group_id
      this.data.conditions=event?.params?.conditions
    },
    onBeforeClose() {
      this.$v.$reset()
      this.data.group_id=''
      this.data.conditions=null
      this.forms.create_public_holiday.reset()
      this.api.create_public_holiday.reset()
    },
    async createPublicHoliday(){
      this.$v.forms.create_public_holiday.$touch()
      if(this.$v.forms.create_public_holiday.$invalid || this.api.create_public_holiday.send) return;
      try {
        this.api.create_public_holiday.send=true
        this.api.create_public_holiday.validation_errors={}
        let forward_to = this.forms.create_public_holiday.forward_to
        let extension_type = this.forms.create_public_holiday.forward_type
        if(extension_type=='extension') {
          if(this.getVoipUsersAlises[forward_to]) extension_type='user'
          // else if(this.getVoipCallQueuesAlises[forward_acccountcode]) extension_type='user'
          else if(this.getVoipIVRSAlises[forward_to]) extension_type='ivr'
          else if(this.getVoipTeamsAlises[forward_to]) extension_type='ringgroup'
        } else if(extension_type=='voicemail') {
          forward_to=`${this.getCurrentUser.default_accountcode.substr(0,11)}1`
        }
        await VOIP_API.endpoints.holiday_schedule.createRule({
          scheduler_name_id: this.data.group_id,
          accountcode: this.getCurrentUser.default_accountcode,
          forward_to: forward_to,
          holidays: [
            {
              date: this.forms.create_public_holiday.date,
              name: this.forms.create_public_holiday.name,
            }
          ],
          months: null,
          years: null,
          month_days: null,
          week_days: null,
          start_time: this.forms.create_public_holiday.start_time,
          end_time: this.forms.create_public_holiday.end_time,
          schedulable_type: 'accountcode',
          extension_type: extension_type,
          type: this.forms.create_public_holiday.type,
          setting_type: 'custom',
          defaulted_holiday: false,
        })
        this.$emit('created')
        this.appNotify({
          message: 'Successfully Created',
          type: 'success',
        })
        this.$modal.hide(this.modalName)
      } catch (ex) {
        this.api.create_public_holiday.validation_errors=ex.own_errors ?? {}
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.create_public_holiday.send=false
      }
    },
  },
}
</script>

<style>

</style>