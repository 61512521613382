var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Forward source caller ID")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm.forms.forward_source_caller_id.enabled ? _c('span', {
    staticClass: "fixInLineDDInCallForwardingSettingsModal"
  }, [_vm._v(" Incoming calls will display "), _c('span', [_c('vb-select', {
    staticClass: "onlyTextInButton-dropDown ml-0",
    attrs: {
      "options": _vm.getVoipNumbers,
      "valueField": "did",
      "textField": "did"
    },
    on: {
      "change": function ($event) {
        return _vm.updateForwardSourceCallerID();
      }
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function (_ref) {
        var option = _ref.option;
        return [option ? _c('span', [_vm._v(_vm._s(_vm._f("number_formater")(option.did)))]) : _c('span', [_vm._v("Select Number")])];
      }
    }, {
      key: "option",
      fn: function (_ref2) {
        var option = _ref2.option;
        return [_vm._v(" " + _vm._s(_vm._f("number_formater")(option.did)) + " ")];
      }
    }], null, false, 2925492521),
    model: {
      value: _vm.forms.forward_source_caller_id.caller_id,
      callback: function ($$v) {
        _vm.$set(_vm.forms.forward_source_caller_id, "caller_id", $$v);
      },
      expression: "forms.forward_source_caller_id.caller_id"
    }
  })], 1), _vm._v(" caller's ID when forwarded. ")]) : _vm._e(), _vm.$v.forms.forward_source_caller_id.caller_id.$invalid ? _c('p', {
    staticClass: "error-red"
  }, [!_vm.$v.forms.forward_source_caller_id.caller_id.required ? _c('span', [_vm._v("caller id is required")]) : _vm._e()]) : _c('span', [_vm._v("Incoming calls will not display the original caller's ID when forwarded.")])])]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "w-fit-content ml-4",
    class: {
      'for-disable': !_vm.getUserPermissions.forward_source_caller_id
    },
    attrs: {
      "id": "ForwardCLIDButtonLock",
      "title": _vm.getUserPermissions.forward_source_caller_id ? '' : 'Your package does not support this feature'
    }
  }, [_vm.api.user_detail.send ? [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "34px",
      "width": "60px",
      "border-radius": "25px"
    }
  })] : _c('b-form-checkbox', {
    ref: "forward_source_caller_id",
    staticClass: "newerSwitch",
    attrs: {
      "disabled": !_vm.getUserPermissions.forward_source_caller_id || _vm.api.update_forward_source_caller_id.send || _vm.api.user_detail.send,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.updateForwardSourceCallerID();
      }
    },
    model: {
      value: _vm.forms.forward_source_caller_id.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.forms.forward_source_caller_id, "enabled", $$v);
      },
      expression: "forms.forward_source_caller_id.enabled"
    }
  })], 2)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }