var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-500 addUserModalV2",
    attrs: {
      "height": "auto",
      "name": _vm.modalName,
      "scrollable": true
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-user"
  }, [_c('div', {
    staticClass: "dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-form"
  }, [_c('div', {
    staticClass: "dialer-edit-header pt-0 pb-0 d-block"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v(" Create user "), _c('vb-icon', {
    staticClass: "ml-2 cursor_pointer checkSlotsModal-icon",
    attrs: {
      "icon": "checkSlotsModal-icon",
      "height": "22px",
      "width": "22px",
      "disabled": _vm.api.create_user.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CheckedServiceAvailableModal', {
          service: 'user',
          onProceed: _vm.createUser
        });
      }
    }
  })], 1), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)], 1)]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v(" Please provide the new user's details below and configure your account preferences. ")])]), _c('div', {
    staticClass: "md-mar-vertical"
  }, [_c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.createUser();
      }
    }
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.create_user.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.create_user.error_message))]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dialer-input--error md-mar-top"
  }, [_c('label', [_vm._v("Username or Email *")]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.create_user.username,
      expression: "forms.create_user.username"
    }],
    staticClass: "bg-white",
    class: !_vm.isInvited ? 'w-50per-15px' : 'w-100',
    attrs: {
      "disabled": _vm.api.create_user.send,
      "placeholder": "Enter Username or Email",
      "type": "text"
    },
    domProps: {
      "value": _vm.forms.create_user.username
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.create_user, "username", $event.target.value);
      }
    }
  }), !_vm.isInvited ? [_vm._v(" @ "), _c('input', {
    staticClass: "w-50per-15px bg-white",
    attrs: {
      "disabled": true
    },
    domProps: {
      "value": _vm.getCurrentUser.company
    }
  })] : _vm._e()], 2), _vm.$v.forms.create_user.username.$error || (_vm.isInvited ? _vm.api.create_user.validation_errors.email : _vm.api.create_user.validation_errors.name) ? _c('p', {
    staticClass: "text text-small text-danger animated bounceIntop mb-0"
  }, [_vm.isInvited ? [!_vm.$v.forms.create_user.username.required ? _c('span', [_vm._v("* Email is required")]) : !_vm.$v.forms.create_user.email.email ? _c('span', [_vm._v("* Email is not valid")]) : _vm._e(), _vm._l(_vm.api.create_user.validation_errors.email, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })] : [!_vm.$v.forms.create_user.username.required ? _c('span', [_vm._v("* Username is required")]) : !_vm.$v.forms.create_user.username.alphaStart ? _c('span', [_vm._v("* Username should start with an alphabet")]) : !_vm.$v.forms.create_user.username.alphaNum ? _c('span', [_vm._v("* Username can be only alphabet or numeric")]) : !_vm.$v.forms.create_user.username.minLength ? _c('span', [_vm._v("* Username should be minimum " + _vm._s(_vm.$v.forms.create_user.username.$params.minLength.min) + " character")]) : !_vm.$v.forms.create_user.username.maxLength ? _c('span', [_vm._v("* Username can be maximum " + _vm._s(_vm.$v.forms.create_user.username.$params.maxLength.max) + " character")]) : _vm._e(), _vm._l(_vm.api.create_user.validation_errors.name, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })]], 2) : _vm._e()]), _c('div', {
    staticClass: "w-100 d-flex md-mar-top"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dialer-input--error w-50 pr-3"
  }, [_c('label', [_vm._v("First name *")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.create_user.first_name,
      expression: "forms.create_user.first_name"
    }],
    staticClass: "w-100 bg-white",
    attrs: {
      "disabled": _vm.api.create_user.send,
      "maxlength": _vm.$v.forms.create_user.first_name.$params.maxLength.max,
      "onkeypress": "return /^[A-Za-z]+$/.test(event.key)",
      "placeholder": "Enter first name",
      "type": "text"
    },
    domProps: {
      "value": _vm.forms.create_user.first_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.create_user, "first_name", $event.target.value);
      }
    }
  }), _vm.$v.forms.create_user.first_name.$error || _vm.api.create_user.validation_errors.display_name || _vm.api.create_user.validation_errors.firstname ? _c('p', {
    staticClass: "text text-small text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.create_user.first_name.required ? _c('span', [_vm._v("* First name is required")]) : !_vm.$v.forms.create_user.first_name.maxLength ? _c('span', [_vm._v("* First name can be maximum " + _vm._s(_vm.$v.forms.create_user.first_name.$params.maxLength.max) + " character")]) : !_vm.$v.forms.create_user.first_name.alpha ? _c('span', [_vm._v("* First name must be only alphabets")]) : _vm._e(), _vm._l(_vm.api.create_user.validation_errors.display_name, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  }), _vm._l(_vm.api.create_user.validation_errors.firstname, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dialer-input--error w-50 pl-3"
  }, [_c('label', [_vm._v("Last name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.create_user.last_name,
      expression: "forms.create_user.last_name"
    }],
    staticClass: "w-100 bg-white",
    attrs: {
      "disabled": _vm.api.create_user.send,
      "maxlength": _vm.$v.forms.create_user.last_name.$params.maxLength.max,
      "onkeypress": "return /^[A-Za-z]+$/.test(event.key)",
      "placeholder": "Enter last name",
      "type": "text"
    },
    domProps: {
      "value": _vm.forms.create_user.last_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.create_user, "last_name", $event.target.value);
      }
    }
  }), _vm.$v.forms.create_user.last_name.$error || _vm.api.create_user.validation_errors.lastname ? _c('p', {
    staticClass: "text text-small text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.create_user.last_name.maxLength ? _c('span', [_vm._v("* Last name can be maximum " + _vm._s(_vm.$v.forms.create_user.last_name.$params.maxLength.max) + " character")]) : !_vm.$v.forms.create_user.last_name.alpha ? _c('span', [_vm._v("* Last name must be only alphabets")]) : !_vm.$v.forms.create_user.last_name.valid ? _c('span', [_vm._v("* Last name must have a space character")]) : _vm._e(), _vm._l(_vm.api.create_user.validation_errors.lastname, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "dialer-box-header md-mar-top md-mar-bottom"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-form-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "switch": "",
      "disabled": _vm.api.create_user.send,
      "name": "check-button"
    },
    model: {
      value: _vm.forms.create_user.role,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_user, "role", $$v);
      },
      expression: "forms.create_user.role"
    }
  }), _c('span', {
    staticClass: "ml-3 setAsAdmin-text",
    staticStyle: {
      "font-size": "16px",
      "font-weight": "400",
      "color": "#676767"
    }
  }, [_vm._v("Set as admin")])], 1), _vm.$v.forms.create_user.role.$error || _vm.api.create_user.validation_errors.role ? _c('p', {
    staticClass: "text text-small text-danger animated bounceIntop mb-0"
  }, _vm._l(_vm.api.create_user.validation_errors.role, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  }), 0) : _vm._e()]), _c('b-alert', {
    attrs: {
      "show": _vm.isInvited,
      "variant": "info"
    }
  }, [_vm._v("User will be set there own password")]), !_vm.isInvited ? _c('div', {
    staticClass: "dialer-box-header md-mar-bottom md-mar-top"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-form-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "switch": "",
      "disabled": _vm.api.create_user.send,
      "checked": _vm.forms.create_user.is_passwword_manual,
      "name": "check-button"
    },
    on: {
      "change": function ($event) {
        _vm.forms.create_user.is_passwword_manual = !_vm.forms.create_user.is_passwword_manual;
        _vm.forms.create_user.password = '';
      }
    }
  }), _c('span', {
    staticClass: "ml-3 setAsAdmin-text",
    staticStyle: {
      "font-size": "16px",
      "font-weight": "400",
      "color": "#676767"
    }
  }, [_vm._v("Set password")])], 1)]) : _vm._e(), _vm.forms.create_user.is_passwword_manual ? _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dialer-input--error md-mar-top"
  }, [_c('label', [_vm._v("Password")]), _c('div', {
    staticClass: "position-relative b-0 adduser-two-input-icon"
  }, [_c('span', {
    staticClass: "icon ml-2"
  }, [_c('b-icon', {
    attrs: {
      "icon": "lock"
    }
  })], 1), (_vm.conditions.create_user_password_show ? 'text' : 'password') === 'checkbox' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.create_user.password,
      expression: "forms.create_user.password"
    }],
    staticClass: "w-100 bg-white",
    attrs: {
      "placeholder": "Enter password",
      "disabled": _vm.api.create_user.send,
      "readonly": "",
      "onfocus": "this.removeAttribute('readonly');",
      "maxlength": _vm.$v.forms.create_user.password.$params.maxLength.max,
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.forms.create_user.password) ? _vm._i(_vm.forms.create_user.password, null) > -1 : _vm.forms.create_user.password
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.forms.create_user.password,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.forms.create_user, "password", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.forms.create_user, "password", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.forms.create_user, "password", $$c);
        }
      }
    }
  }) : (_vm.conditions.create_user_password_show ? 'text' : 'password') === 'radio' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.create_user.password,
      expression: "forms.create_user.password"
    }],
    staticClass: "w-100 bg-white",
    attrs: {
      "placeholder": "Enter password",
      "disabled": _vm.api.create_user.send,
      "readonly": "",
      "onfocus": "this.removeAttribute('readonly');",
      "maxlength": _vm.$v.forms.create_user.password.$params.maxLength.max,
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.forms.create_user.password, null)
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.forms.create_user, "password", null);
      }
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.create_user.password,
      expression: "forms.create_user.password"
    }],
    staticClass: "w-100 bg-white",
    attrs: {
      "placeholder": "Enter password",
      "disabled": _vm.api.create_user.send,
      "readonly": "",
      "onfocus": "this.removeAttribute('readonly');",
      "maxlength": _vm.$v.forms.create_user.password.$params.maxLength.max,
      "type": _vm.conditions.create_user_password_show ? 'text' : 'password'
    },
    domProps: {
      "value": _vm.forms.create_user.password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.create_user, "password", $event.target.value);
      }
    }
  }), _c('b-icon', {
    staticClass: "dialer-showPassword-icon cursor_pointer",
    attrs: {
      "aria-disabled": _vm.api.create_user.send,
      "icon": _vm.conditions.create_user_password_show ? 'eye-slash' : 'eye'
    },
    on: {
      "click": function ($event) {
        _vm.conditions.create_user_password_show = !_vm.conditions.create_user_password_show;
      }
    }
  })], 1), _vm.$v.forms.create_user.password.$error || _vm.api.create_user.validation_errors.password ? _c('p', {
    staticClass: "text text-small text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.create_user.password.required ? _c('span', [_vm._v("* password is required")]) : !_vm.$v.forms.create_user.password.capitalalphabet ? _c('span', [_vm._v("* password required at least one capital character")]) : !_vm.$v.forms.create_user.password.smallalphabet ? _c('span', [_vm._v("* password required at least one small character")]) : !_vm.$v.forms.create_user.password.numbers ? _c('span', [_vm._v("* password required at least one integer character")]) : !_vm.$v.forms.create_user.password.specialcharacter ? _c('span', [_vm._v("* password required at least one special character")]) : !_vm.$v.forms.create_user.password.minLength ? _c('span', [_vm._v("* password is minimum " + _vm._s(_vm.$v.forms.create_user.password.$params.minLength.min) + " is required")]) : !_vm.$v.forms.create_user.password.maxLength ? _c('span', [_vm._v("* password is maximum " + _vm._s(_vm.$v.forms.create_user.password.$params.maxLength.max) + " character")]) : _vm._e(), _vm._l(_vm.api.create_user.validation_errors.password, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()]) : _vm._e(), !_vm.isInvited ? _c('div', {
    staticClass: "dialer-box-header md-mar-bottom md-mar-top"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-form-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "switch": "",
      "disabled": _vm.api.create_user.send,
      "name": "check-button"
    },
    model: {
      value: _vm.forms.create_user.is_send_email,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_user, "is_send_email", $$v);
      },
      expression: "forms.create_user.is_send_email"
    }
  }), _c('span', {
    staticClass: "ml-3 setAsAdmin-text",
    staticStyle: {
      "font-size": "16px",
      "font-weight": "400",
      "color": "#676767"
    }
  }, [_vm._v("Send password in email upon completion")])], 1)]) : _vm._e(), _vm.forms.create_user.is_send_email ? _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dialer-input--error mb-3 md-mar-top"
  }, [_c('label', [_vm._v("Email the new password to the following recepients *")]), _c('div', {
    staticClass: "position-relative"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.create_user.send_password_email,
      expression: "forms.create_user.send_password_email"
    }],
    staticClass: "w-100 bg-white",
    attrs: {
      "type": "text",
      "placeholder": "Enter email",
      "disabled": _vm.api.create_user.send
    },
    domProps: {
      "value": _vm.forms.create_user.send_password_email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.create_user, "send_password_email", $event.target.value);
      }
    }
  })]), _vm.$v.forms.create_user.send_password_email.$error || _vm.api.create_user.validation_errors.email ? _c('p', {
    staticClass: "text text-small text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.create_user.send_password_email.required ? _c('span', [_vm._v("* email is required")]) : !_vm.$v.forms.create_user.send_password_email.email ? _c('span', [_vm._v("* email is invalid")]) : _vm._e(), _vm._l(_vm.api.create_user.validation_errors.email, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()]) : _vm._e(), !_vm.isFreePlan ? _c('div', {
    staticClass: "dialer-box-header md-mar-bottom"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-form-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "switch": "",
      "disabled": _vm.api.create_user.send,
      "checked": _vm.forms.create_user.add_number,
      "name": "check-button"
    },
    on: {
      "change": function ($event) {
        _vm.forms.create_user.add_number = !_vm.forms.create_user.add_number;
      }
    }
  }), _c('span', {
    staticClass: "ml-3 setAsAdmin-text",
    staticStyle: {
      "font-size": "16px",
      "font-weight": "400",
      "color": "#676767"
    }
  }, [_vm._v("Add Telephone Number")])], 1), _vm.api.create_user.validation_errors.skip_number ? _c('p', {
    staticClass: "text text-small text-danger animated bounceIntop mb-0"
  }, _vm._l(_vm.api.create_user.validation_errors.skip_number, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  }), 0) : _vm._e()]) : _vm._e(), _vm.forms.create_user.add_number ? [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between mb-2 lg-mar-top",
    class: {
      'numberSelected': _vm.forms.create_user.number.number
    }
  }, [[_vm.forms.create_user.number.number ? _c('div', {
    staticClass: "beforeDeleteNumbers-item mb-0"
  }, [_vm._v(" " + _vm._s(_vm._f("number_formater")(_vm.forms.create_user.number.number)) + " ")]) : _vm._e()], _c('button', {
    staticClass: "dialer-button dialer-button-primary w-100",
    attrs: {
      "type": "button",
      "disabled": _vm.api.create_user.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm.modalName}-AddNumbersModal`, {
          add_user: true
        });
      }
    }
  }, [_vm._v(" " + _vm._s(!_vm.forms.create_user.number.number ? 'Select number' : 'Change number') + " ")])], 2), _vm.forms.create_user.submitted && _vm.$v.forms.create_user.number.$invalid ? _c('p', {
    staticClass: "text text-small text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.create_user.number.required ? _c('span', [_vm._v("* Number is required")]) : _vm._e()]) : _vm._e()] : _vm._e(), _c('div', {
    staticClass: "d-flex align-items-center justify-content-end mb-2 lg-mar-top"
  }, [_c('button', {
    staticClass: "dialer-button dialer-button-primary w-100",
    attrs: {
      "type": "submit",
      "disabled": _vm.api.create_user.send
    }
  }, [_vm.api.create_user.send ? _c('vb-spinner') : [_vm._v("Create user")]], 2)])], 2)])])])])]), _c('AddNumbersModal', {
    attrs: {
      "modalName": `${_vm.modalName}-AddNumbersModal`
    },
    on: {
      "select-number": function ($event) {
        _vm.forms.create_user.number = $event;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }