var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [_vm.getIsMobile ? _c('section', {
    staticClass: "dialer-settings-section locationSettings"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-width"
  }, [_c('vb-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "latest-sideBarOpenIconForTodoInMobileView-icon",
      "width": "19.979px",
      "height": "16px"
    }
  }), _c('h2', {
    staticClass: "dialer-settings-title w-100 mb-0 text-center flex-1"
  }, [_vm._v("List of contacts")])], 1), _c('div', [_vm._v(" Automatically dial numbers from your lists, save time of manual dialling and boost productivity. ")])]) : _c('section', {
    staticClass: "dialer-settings-section locationSettings newTasksModule"
  }, [_c('div', [_c('div', {
    staticClass: "settingsFirstSection"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "settings-back-icon cursor_pointer showOnlyOnSmallerScreens mr-12px",
    attrs: {
      "icon": "latest-sideBarOpenIconForTodoInMobileView-icon",
      "width": "19.979px",
      "height": "16px"
    }
  }), _vm._v(" List of contacts ")], 1)]), _c('div', {
    staticClass: "settingsFirstSection-right"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end align-items-center mt-0"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder sm-mar-left sm-mar-right seachBox"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search for contacts"
    }
  })], 1), _c('button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "newButton",
    attrs: {
      "title": _vm.getUserPermissions.power_dialer ? '' : 'Your package does not support this feature'
    },
    on: {
      "click": function ($event) {
        !_vm.getUserPermissions.power_dialer ? '' : _vm.$modal.show(`${_vm._uid}-AddSheet`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart position-relative d-flex justify-content-center"
  }, [[_vm._v("Add list")]], 2)], 1)])])]), _vm._m(0)]), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again IVRTable",
    attrs: {
      "textNoRecord": " ",
      "isListEmpty": _vm.response.sheets.length == 0,
      "listLength": _vm.response.sheets.length,
      "loading": _vm.api.fetch_sheets.send
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [!_vm.getIsMobile ? _c('tr', [_c('th', {
          staticClass: "dialer-has-sort"
        }, [_c('span', {
          staticClass: "dialer-sorted-by ascending"
        }, [_vm._v("List name")])]), _c('th', {
          staticClass: "dialer-has-sort"
        }, [_c('span', [_vm._v("Published By")])]), _c('th', {
          staticClass: "dialer-has-sort"
        }, [_c('span', [_vm._v("Assigned to")])]), _c('th', {
          staticClass: "dialer-has-sort"
        }, [_c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: 'Unanswered calls',
            expression: "'Unanswered calls'",
            modifiers: {
              "hover": true
            }
          }]
        }, [_vm._v("UC")])]), _c('th', {
          staticClass: "dialer-has-sort"
        }, [_c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: 'Dialed calls',
            expression: "'Dialed calls'",
            modifiers: {
              "hover": true
            }
          }]
        }, [_vm._v("DC")])]), _c('th', {
          staticClass: "dialer-col-right pr-4"
        }, [_c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: 'Total contacts',
            expression: "'Total contacts'",
            modifiers: {
              "hover": true
            }
          }]
        }, [_vm._v("TC")])])]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.response.sheets, function (sheet, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: sheet.id,
            on: {
              "click": function ($event) {
                !_vm.getUserPermissions.power_dialer ? _vm.appNotify({
                  message: 'Your package does not support this feature',
                  type: 'danger'
                }) : _vm.$modal.show(`${_vm._uid}-UpdateSheet`, {
                  sheet_id: sheet.id,
                  sheet: sheet
                });
              }
            }
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_c('div', {
            staticClass: "d-flex align-items-center justify-content-between"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('vb-icon', {
            staticClass: "mr-16px",
            attrs: {
              "icon": "PowerDialer-NumberListTD-icon",
              "width": "35px",
              "height": "35px"
            }
          }), _c('span', [_vm._v(_vm._s(sheet.name))])], 1)])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [sheet.publish_by ? _c('Info', {
            staticClass: "w-fit-content",
            attrs: {
              "id": sheet.publish_by,
              "is_suspended_show": ""
            }
          }) : _vm._e()], 1), _c('td', {
            staticClass: "dialer-row-title"
          }, [_c('div', {
            class: `${sheet.assigns.length > 3 ? 'moreThanThree' : sheet.assigns.length == 3 ? 'exactlyThree' : ''}`,
            staticStyle: {
              "width": "50%",
              "min-width": "104px",
              "display": "flex",
              "justify-content": "flex-start",
              "align-items": "center"
            }
          }, [sheet.assigns[0] ? _c('vb-avatar', {
            attrs: {
              "id": sheet.assigns[0].accountcode,
              "showPopover": true,
              "is_blf": false
            }
          }) : _vm._e(), sheet.assigns[1] ? _c('vb-avatar', {
            attrs: {
              "id": sheet.assigns[1].accountcode,
              "showPopover": true,
              "is_blf": false
            }
          }) : _vm._e(), sheet.assigns[2] ? _c('vb-avatar', {
            attrs: {
              "id": sheet.assigns[2].accountcode,
              "showPopover": true,
              "is_blf": false
            }
          }) : _vm._e(), sheet.assigns.length > 3 ? _c('div', {
            staticClass: "countPlus"
          }, [_vm._v("+" + _vm._s(sheet.assigns.length - 3))]) : _vm._e()], 1)]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_c('div', {
            staticClass: "d-flex align-items-center justify-content-between"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('span', [_vm._v(_vm._s(sheet.total_missed_calls))])])])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_c('div', {
            staticClass: "d-flex align-items-center justify-content-between"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('span', [_vm._v(_vm._s(sheet.total_answered_calls))])])])]), _c('td', {
            staticClass: "dialer-col-right pr-4"
          }, [_c('div', {
            staticClass: "d-flex align-items-center justify-content-end"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('span', [_vm._v(_vm._s(sheet.total_records))])])])])]);
        });
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mr-2",
            staticStyle: {
              "height": "35px",
              "width": "35px"
            }
          }), _c('div', {
            staticClass: "d-flex flex-column justify-content-between"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "16px",
              "width": "60px"
            }
          }), _c('div', {
            staticClass: "latestShimmerDesign mt-1",
            staticStyle: {
              "height": "16px",
              "width": "30px"
            }
          })])])]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "50%"
            }
          })]), _c('td', [_c('div', {
            staticClass: "d-flex"
          }, [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "85px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "85px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "85px"
            }
          })]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "48px",
              "width": "48px"
            }
          })])]);
        });
      },
      proxy: true
    }])
  })], 1), _c('AddListModal', {
    attrs: {
      "modalName": `${_vm._uid}-AddSheet`
    },
    on: {
      "created": function ($event) {
        return _vm.fetchSheetsDebounce();
      }
    }
  }), _c('UpdateListModal', {
    attrs: {
      "modalName": `${_vm._uid}-UpdateSheet`
    },
    on: {
      "refreshSheets": _vm.fetchSheetsDebounce,
      "updated": function ($event) {
        return _vm.fetchSheetsDebounce();
      }
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "settingsSecondSection"
  }, [_c('span', {
    staticClass: "dialer-settings-intro newerTextVersion mt-0 mb-0"
  }, [_vm._v(" Automatically dial numbers from your lists, save time of manual dialling and boost productivity. ")])]);

}]

export { render, staticRenderFns }