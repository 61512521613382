<template>
  <div :id="id" class="dialer-box alt position-relative">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex flex-column">
        <div class="dialer-box-header mb-0">{{callQueue?'Maximum wait time settings':`When calls aren't answered...`}}</div>
      </div>
      <div :id="`${randomId}-ForwardSettingsButtonLock`" class="w-fit-content">
        <div v-if="api.route_call.send" class="latestShimmerDesign" style="height:50px;width:100px;"></div>
        <div v-else>
          <button 
            class="newButton"
            @click="$modal.show(`${randomId}-CallForwardSettingModal`,{ 
              account, 
              setting: response.forwarding_setting,
              accountcode: forwardCallerId ? account : '',
              is_voicemail_show: callQueue ? false : true,
            })"
          >
            <vb-icon icon="squared-change-icon" height="38px" width="38px" />
            <span class="newButton-textPart">Change</span>
          </button>
        </div>
      </div>
    </div>
    <div v-if="!callQueue" class="d-flex justify-content-between">
      <div class="dialer-call-options v3 w-75">
        <div class="selectedContainer">
          <template v-if="response.forwarding_setting.forward_note == 'off'">
            <div class="selectedText-big">Keep ringing</div>
            <div class="selectedText-small">Call forwarding is off</div>
          </template>
          <template v-if="response.forwarding_setting.forward_note == 'voicemail'">
            <div class="selectedText-big">Ask to leave a voicemail</div>
            <div class="selectedText-small">If phone rings for <span class="latestBlueColor">{{response.forwarding_setting.timeout}} seconds</span> call will be forward to voicemail</div>
          </template>
          <template v-if="response.forwarding_setting.forward_note == 'extension'">
            <div class="selectedText-big">Forward to a user, team or IVR menu</div>
            <div class="selectedText-small">
              If phone rings for 
              <span class="latestBlueColor">{{response.forwarding_setting.timeout}} seconds</span> 
              call will be forward to 
              <span class="latestBlueColor">{{response.forwarding_setting.extension_name}}</span>
            </div>
          </template>
          <template v-if="response.forwarding_setting.forward_note == 'number'">
            <div class="selectedText-big">Forward to another number</div>
            <div class="selectedText-small">
              If phone rings for 
              <span class="latestBlueColor">{{response.forwarding_setting.timeout}} seconds</span> 
              call will be forward to 
              <span class="latestBlueColor">{{ response.forwarding_setting.forward_number | number_formater }}</span>
            </div>
          </template>
        </div>
      </div>
    </div>
    <ScheduleAssignModal 
      :modalName="`${randomId}-ScheduleAssignModal`" 
      :account="account"
      @interface="updateForwardingSettings({ 
        extension_name: $event.accountname, 
        forward_number: $event.account, 
      })" 
    />
    <DurationPickerModal :modalName="`${randomId}-DurationPickerModal`" @time="updateForwardingSettings({ timeout: $event.seconds })" />
    <CallForwardingNumberInputModal 
      :modalName="`${randomId}-CallForwardingNumberInputModal`" 
      @number="updateForwardingSettings({ forward_number: $event.ber })" 
    />
    <CallForwardSettingModal 
      :modalName="`${randomId}-CallForwardSettingModal`" 
      @updated="updateForwardingSettings($event)" 
    />
  </div>  
</template>

<script>
import { $fn, VOIP_API } from '../../utils';
import ScheduleAssignModal from '../Modals/ScheduleAssignModal.vue'
import DurationPickerModal from '../Modals/DurationPickerModal.vue'
import CallForwardingNumberInputModal from '../Modals/CallForwardingNumberInputModal.vue'
import CallForwardSettingModal from '../Modals/CallForwardSettingModal.vue'
import { mapGetters } from 'vuex';
export default {
  name: "CallForwardingSetting",
  components: {
    ScheduleAssignModal,
    DurationPickerModal,
    CallForwardingNumberInputModal,
    CallForwardSettingModal,
  },
  props: {
    account: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: '',
    },
    forwardCallerId: {
      type: Boolean,
      default: false
    },
  },
  data(){
    return {
      random_id: '',
      api: {
        route_call: this.$helperFunction.apiInstance(),
        update_route_call: this.$helperFunction.apiInstance({ source: true }),
        reset(){
          this.route_call?.reset?.()
          this.update_route_call?.reset?.()
        },
      },
      response: this.$helperFunction.stateInstance({
        data: {
          forwarding_setting: {
            forward_setting: "",
            forward_note: "",
            forward_number: "",
            timeout: 5,
            extension_name: "",
            number: "",
          },
          allow_recording: false,
          outofhours: false,
          recording: false,
        }
      }),
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getVoipCallQueuesAlises'
    ]),
    randomId(){ return this.random_id },
    callQueue(){ return this.getVoipCallQueuesAlises[this.account] },
  },
  methods: {
    fetchcallRouteSetting() {
      let vm = this
      if(vm.api.route_call.send) return;
      vm.api.route_call.send = true;
      VOIP_API.endpoints.users.callroute({
        accountcode: vm.account,
      }).then(({ data }) => {
        vm.response.outofhours = data.schedule_enable;
        vm.response.forwarding_setting = data.forwarding_setting;
        vm.response.recording = data.recording;
        vm.response.allow_recording = data.allow_recording=='1';
      }).finally(() => {
        vm.api.route_call.send = false;
      });
    },
    updateForwardingSettings(forward_setting={}){
      let vm = this;
      vm.api.update_route_call.source?.cancel?.("Requesting Again")
      vm.api.update_route_call.source = require("axios").default.CancelToken.source();
      vm.api.update_route_call.send = true;
      const toaster_value = vm.appNotify({
        message: "Saving...",
        type: "success",
        duration: 6 * 1000,
      });
      VOIP_API.endpoints.users.updateroutecall({
        accountcode: vm.account,
        forward_setting: {
          forward_setting: forward_setting.forward_setting ?? vm.response.forwarding_setting.forward_setting,
          forward_note: forward_setting.forward_note ?? vm.response.forwarding_setting.forward_note,
          forward_number: forward_setting.forward_number ?? vm.response.forwarding_setting.forward_number,
          timeout: forward_setting.timeout ?? vm.response.forwarding_setting.timeout,
          extension_name: forward_setting.extension_name ?? vm.response.forwarding_setting.extension_name,
          number: forward_setting.number ?? vm.response.forwarding_setting.number,
        },
        // call_recording: vm.response.recording,
        // schedule_enable: vm.response.outofhours,
      },vm.api.update_route_call.source.token)
      .then(() => {
        vm.appNotify({
          message: "Successfully Updated!",
          type: "success",
        })
        vm.fetchcallRouteSetting()
        vm.$emit('cf-updated')
      })
      .catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        toaster_value.close();
        vm.api.update_route_call.send = false;
        vm.api.update_route_call.source = null;
      });
    },
  },
  created(){
    this.random_id = $fn.makeid(10)
  },
  mounted(){
    this.fetchcallRouteSetting()
  },
  activated(){
    this.fetchcallRouteSetting()
  },
};
</script>
