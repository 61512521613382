var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "putItOnTop putItOnTopModal",
    attrs: {
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "putItOnTopModal-container"
  }, [_c('b-icon', {
    staticClass: "putItOnTopModal-close",
    attrs: {
      "icon": "x-lg",
      "font-scale": "1.2"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }), _vm.conditions.state == 1 ? [_c('b-icon', {
    staticClass: "putItOnTopModal-icon",
    attrs: {
      "icon": "check-circle-fill",
      "variant": "success"
    }
  }), _c('h5', {
    staticClass: "putItOnTopModal-heading"
  }, [_vm._v("Success")]), _c('p', {
    staticClass: "putItOnTopModal-text"
  }, [_vm._v(_vm._s(_vm.conditions.message))]), _c('button', {
    staticClass: "dialer-button dialer-button-primary d-flex align-items-center",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_vm._v("Done")])] : _vm.conditions.state == 2 ? [_c('b-icon', {
    staticClass: "putItOnTopModal-icon",
    attrs: {
      "icon": "x-circle-fill",
      "variant": "danger"
    }
  }), _c('h5', {
    staticClass: "putItOnTopModal-heading"
  }, [_vm._v("Failed")]), _c('p', {
    staticClass: "putItOnTopModal-text"
  }, [_vm._v(_vm._s(_vm.conditions.message))]), _c('button', {
    staticClass: "dialer-button dialer-button-primary d-flex align-items-center",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_vm._v("Done")])] : [_c('div', {
    staticClass: "putItOnTopModal-loader"
  }), _c('h5', {
    staticClass: "putItOnTopModal-heading"
  }, [_vm._v("Loading...")])]], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }