<template>
  <div>
    <modal
      class="dialer_animation right_side_popup"
      width="40%"
      height="auto"
      :scrollable="true"
      :name="modalName"
      @before-open="onBeforeOpen($event)"
      @before-close="onBeforeClose()"
    >
      <div class="dialer-edit-header w-100 pb-0">
        <h2 class="dialer-settings-title newer mb-0 d-flex align-items-center">
          <a type="button" class="newBackButton mr-16px" @click="$modal.hide(modalName)" >
            <b-icon icon="arrow-left-circle" />
            <span class="newBackButton-textpart">Back</span>
          </a>
          Public Holidays
        </h2>
        <!-- <div class="dialer-edit-actions">
          <div>
            <button class="newDoneButton" type="button" :disabled="api.update_group_name.send || api.remove_group.send" @click="''">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div> -->
      </div>
      <UpdatePublicHolidayGroup v-if="data.group_id" :groupId="data.group_id" @removed="$modal.hide(modalName);$emit('removed');" @updated="$emit('updated');" />
    </modal>
  </div>
</template>

<script>
import UpdatePublicHolidayGroup from './UpdatePublicHolidayGroup.vue'
export default {
  nmae: 'UpdatePublicHolidayGroupModal',
  components: {
    UpdatePublicHolidayGroup,
  },
  inject: [
    'appNotify',
  ],
  props: {
    modalName: {
      type: String,
      default: 'UpdatePublicHolidayGroup'
    },
  },
  data() {
    return {
      data: {
        group_id: ''
      },
    }
  },
  methods: {
    onBeforeOpen(event){
      this.data.group_id=event?.params?.id ?? ''
    },
    onBeforeClose(){
      this.data.group_id=''
    },
  },
}
</script>

<style>

</style>