<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-500 MOHPlaylistFilesModal min-width-50percent-modal" 
      width="50%" 
      height="auto" 
      :scrollable="true" 
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose($event)"
    >
      <div class="dialer-assign-to dialer-edit">
        <div v-if="getIsMobile" class="dialer-edit-header flex-column">
          <div class="d-flex w-100 justify-content-between align-items-center mb-2">
            <h2 class="dialer-edit-title">Playlist files</h2>
            <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
          </div>
        </div>
        <div v-else class="dialer-edit-header d-block">
          <div class="flex-fill d-flex align-items-center justify-content-between">
            <h2 class="dialer-settings-title newer mb-0"> Edit {{playlist?.label}} music in the playlist</h2>
            <div class="dialer-edit-actions">
              <button class="newDoneButton" @click="$modal.hide(modalName)">
                <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                <span class="newDoneButton-textPart">Done</span>
              </button>
            </div>
          </div>
          <div class="vm--modal-text">
            Choose the music audio file you'd like to include in this playlist.
          </div>
        </div>
        <template v-if="api.playlist.send && !playlist">
          <div class="latestGreyBox-9-9-2023 mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox">
              <div class="whiteBoxWithBorderInsideGreyBox-row py-2" v-for="n in 4" :key="n.id">
                <div class="latestShimmerDesign" style="height: 27px;width: calc(25% - 16px);"></div>
                <div class="latestShimmerDesign" style="height: 64px;width: calc(50% - 16px);"></div>
                <div class="latestShimmerDesign" style="height: 50px;width: calc(25% - 16px);"></div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="latestGreyBox-9-9-2023 addSoundFilesInMOHplaylistSection mt-20px">
            <div class="d-flex align-items-center">
              <div class="whiteBGinputWithGreyRoundedBorder w-50">
                <input placeholder="Search" v-model="filter.files.search" class="w-100" type="text" />
              </div>
              <div class="d-flex w-50 justify-content-between align-items-center pl-3">
                <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD withCustomSvgCheck">
                  <b-dropdown no-caret> 
                    <template #button-content>
                      <vb-svg class="filterDD-icon" name="filter-icon" width="19" height="18" viewBox="0 0 19 18" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    </template>
                    <b-dropdown-item @click="filter.files.type='all'" >
                      <b-icon v-if="filter.files.type=='all'" icon="check2" scale="2" />
                      <span>All</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="filter.files.type='system'">
                      <b-icon v-if="filter.files.type=='system'" icon="check2" scale="2" />
                      <span>System</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="filter.files.type='custom'">
                      <b-icon v-if="filter.files.type=='custom'" icon="check2" scale="2" />
                      <span>Custom</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <button 
                  class="newButton mwd-200px" 
                  type="button" 
                  @click="$modal.show(`${_uid}-AddRecordingModal`,{
                    group: 'moh',
                    select_category: false,
                  })"
                >
                  <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                  <span class="newButton-textPart">Upload new music</span>
                </button>
              </div>
            </div>
            <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
              <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4" v-for="file in files" :key="file.id">
                <div class="latestGreyBox-heading-main">{{ file.label | check_empty('None') }}</div>
                <div class="wd-33-imp d-flex align-items-center justify-content-between">
                  <vb-audio-player class="whiteBGaudioWithGreyRoundedBorder-again calc-100-32px-imp" :src="file.sound_file" />
                  <div 
                    class="actLikeSquareRadio checkboxVersion ml-auto"
                    :class="{
                      'checked' : selected.files.includes(`${file.real_id}`)
                    }" 
                    :disabled="api.remove_file.send || api.add_file.send" 
                    @click="selected.files.includes(`${file.real_id}`) ? removeFile(file.id) : addFile(file.id)"
                  />
                </div>
              </div>
              <vb-no-record v-if="files.length==0" :text="'No files'" :design="3" />
            </div>
          </div>





















          <!--<div v-if="false" class="d-flex justify-content-between mt-4">
            <div class="dialer-box justify-content-start grayBox dialer-assigned flex-column widthCalc50-14px mb-0" >
              <div class="d-flex align-items-center justify-content-between mb-3">
                <h3 class="dialer-box-header w-fit-content d-flex mb-0">Selected file</h3>
                <div class="whiteBGinputWithGreyRoundedBorder">
                  <input id="edit-number-label" class="bg-white" placeholder="Search Sound" type="text" />
                </div>
              </div>
              <div class="innerWhiteBoxwithRoundedBorder h-100">
                <Container @drop="changeOrder($event.removedIndex,$event.addedIndex)">
                  <Draggable v-for="(element,index) in selectedFiles" :key="index">
                    <div class="draggable-item">
                      <ul class="list-unstyled dialer-asssignee-list">
                        <li
                          class="mb-3"
                          :id="`${element.id}&&${element.real_id}&&${element.label}&&${element.sound}&&${element.sound_file}`"
                        >
                          <div class="d-flex justify-content-between align-items-center w-100">
                            <span class="dialer-assignee-name font-weight-700 wd-30" style="">{{ element.label | check_empty('None') }}</span>
                            <vb-audio-player class="whiteBGaudioWithGreyRoundedBorder-again calc-100-32px w-50" :src="element.sound_file" />
                            <b-spinner v-if="api.remove_file.send == element.id" label="spinner" variant="primary" class="md-mar-left" />
                            <!~~ <a
                              v-else-if="selectedFiles.length>1"
                              v-b-tooltip.hover
                              title="remove to playlist"
                              @click="removeFile(element.id)"
                              class="md-mar-left"
                            >
                              <vb-icon icon="dialer-delete-icon-filled" class="dialer-delete-icon-filled" height="19.995" width="16" />
                            </a> ~~>
                            <button v-else-if="selectedFiles.length>1" class="dialer-button dialer-button-delete-alt md-mar-left" type="button" 
                              v-b-tooltip.hover
                              title="remove to playlist"
                              @click="removeFile(element.id)"
                            >
                              Remove
                            </button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Draggable>
                </Container>
                <vb-no-record v-if="selectedFiles.length==0" :text="api.playlist.send?'':'There is no playlist'" :design="3">
                  <vb-loading v-if="api.playlist.send" slot="loading" />
                </vb-no-record>
              </div>
            </div>
            <div class="dialer-box justify-content-start grayBox dialer-assigned flex-column widthCalc50-14px" >
              <div class="d-flex align-items-center justify-content-between mb-3">
                <h3 class="dialer-box-header w-fit-content d-flex mb-0">Add to list</h3>
                <div class="whiteBGinputWithGreyRoundedBorder">
                  <input id="edit-number-label" class="bg-white" v-model="filter.available.search" placeholder="Search Sound" type="text" />
                </div>
              </div>
              <div class="innerWhiteBoxwithRoundedBorder">
                <ul class="list-unstyled dialer-asssignee-list">
                  <li class="mb-3" v-for="(item, key) in availableFiles.slice((pagination.available.page*pagination.available.per_page)-pagination.available.per_page,pagination.available.page*pagination.available.per_page)" :key="key">
                    <div class="d-flex justify-content-between align-items-center w-100">
                      <span class="dialer-assignee-name font-weight-700 wd-30">{{ item.label }}</span>
                        <vb-audio-player class="whiteBGaudioWithGreyRoundedBorder-again calc-100-32px w-50" :src="item.sound_file" />
                        <b-spinner v-if="api.add_file.send == item.id" label="spinner" variant="primary" class="md-mar-left" />
                        <button v-else class="dialer-button dialer-button-primary md-mar-left" type="button" v-b-tooltip.hover title="add to playlist" @click="addFile(item.id)">
                          <b-icon icon="plus" font-scale="1.5" class="mr-3" />
                          Add
                        </button>
                    </div>
                  </li>
                  <vb-no-record v-if="isEmpty(availableFiles)" :design="3" :text="api.playlist.send ? '' : 'Sorry, No Data Founding list'">
                    <vb-loading v-if="api.playlist.send" slot="loading" />
                  </vb-no-record>
                </ul>
              </div>
              <vb-pagination 
                :listLength="availableFiles.length" 
                :conditions="{ pagination: { per_page: false } }" 
                :perPage="pagination.available.per_page" 
                @page-changed="pagination.available.page=$event"
                :page="pagination.available.page"
                :isListEmpty="false"  
              />
            </div>
          </div>-->
        </template>
      </div>
    </modal>
    <AddRecordingModal :options="{ recording: false, }" :modalName="`${_uid}-AddRecordingModal`" @update-list="fetchPlaylists()" />
  </div>
</template>

<script>
// import { Container, Draggable } from "vue-smooth-dnd";
import { VOIP_API } from "../../utils";
import { mapGetters } from 'vuex';
import AddRecordingModal from './AddRecordingModal.vue';
import _ from 'lodash';
export default {
  name: "EditPlaylistSoundsModal",
  components: {
    // Container,
    // Draggable,
    AddRecordingModal
  },
  props: {
    modalName: {
      type: String,
      default: 'EditPlaylistSounds',
    },
  },
  inject:['isEmpty','appNotify'],
  data() {
    return {
      api: {
        playlist: this.$helperFunction.apiInstance({  }),
        add_file: this.$helperFunction.apiInstance({  }),
        remove_file: this.$helperFunction.apiInstance({  }),
        change_order: this.$helperFunction.apiInstance({  }),
      },
      response: {
        playlists: {},
      },
      data: {
        playlist_id: '',
        accountcode: '',
      },
      conditions: {
        full_screen: false,
      },
      filter: {
        files: {
          search: '',
          type: 'all',
        },
      },
      selected: {
        files: [],
      },
    };
  },
  computed: {
    ...mapGetters([
      'getIsMobile',
    ]),
    playlist(){ return this.response.playlists?.playlists?.data?.find(i=>i.id==this.data.playlist_id); },
    files(){
      const search = this.filter.files.search.toLowerCase().trim()
      const type = this.filter.files.type
      const files = [...Object.values(this.response.playlists?.available?.data ?? {}),...Object.values(this.response.playlists?.selected?.data ?? {})]
      const filter_files = files.map(i=>({
        id: i.id || '',
        real_id: i.real_id || '',
        label: i.label || '',
        sound: i.sound || '',
        sound_file: i.sound_file || '',
        type: i.type || i.sound_type || '',
      }))
      .filter(i=>(type=='all' ? true : (i.type==type)) && `${i.label}`.toLowerCase().trim().includes(search))
      return _.orderBy(filter_files, ['label'], ['asc'])
    },
  },
  methods: {
    onBeforeOpen(event){
      this.data.playlist_id = event?.params?.id || '';
      this.data.accountcode = event?.params?.account || '';
      this.fetchPlaylists()
    },
    onBeforeClose(){
      this.api.playlist.reset()
      this.api.add_file.reset()
      this.api.remove_file.reset()
      this.api.change_order.reset()
      this.response.playlists={}
      this.data.playlist_id=''
      this.data.accountcode=''
      this.conditions.full_screen=false
      this.filter.files.search=''
    },
    async fetchPlaylists() {
      if(this.api.playlist.send) return;
      try {
        this.api.playlist.send = true;
        const { data } = await VOIP_API.endpoints.music_on_hold.playlistfiles(this.data.playlist_id, {
          account: this.data.accountcode,
        });
        this.response.playlists=data
        this.selected.files=Object.keys(data?.selected?.data ?? {})
      } finally {
        this.api.playlist.send = false;
      }
    },
    async addFile(id) {
      if (!id || this.api.add_file.send) return;
      try {
        this.api.add_file.send=true
        const { data } = await VOIP_API.endpoints.music_on_hold.playlistaddfile({
          playlistId: this.data.playlist_id,
          soundId: id,
          fid: 12,
          account: this.data.accountcode,
          flag: "app",
        })
        this.response.playlists=data
        this.selected.files=Object.keys(data?.selected?.data ?? {})
        this.appNotify({
          message: "Successfully Added",
          type: 'success',
        })
        this.$emit('files-updated')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.add_file.send=false
      }
    },
    async removeFile(id) {
      if (!id || this.api.remove_file.send) return;
      try {
        this.api.remove_file.send=true
        const { data } = await VOIP_API.endpoints.music_on_hold.playlistremovefile({
          playlistId: this.data.playlist_id,
          soundId: id,
          fid: 12,
          account: this.data.accountcode,
          flag: "app",
        })
        this.response.playlists=data
        this.selected.files=Object.keys(data?.selected?.data ?? {})
        this.$emit('files-updated')
        this.appNotify({
          message: "Successfully removed",
          type: 'success',
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.remove_file.send=false
      }
    },
    async changeOrder(removedIndex,addedIndex){
      let vm = this
      if(removedIndex == addedIndex) return false;
      const data = JSON.parse(JSON.stringify(vm.selectedFiles[removedIndex]))
      const playlist = vm.response.playlists?.playlists?.data?.find(i=>i.id==vm.data.playlist_id)
      const test = [...playlist.playlist]
      const item = test.splice(removedIndex,1)[0]
      test.splice(addedIndex, 0, item)
      playlist.playlist=test
      vm.api.change_order.send=true
      VOIP_API.endpoints.music_on_hold.updateplaylistorder({
        playlist: vm.data.playlist_id,
        element: data,
        current: removedIndex,
        future: addedIndex,
      })
      .then(()=>{
        vm.$emit('order-changed')
      })
      .finally(()=>{
        vm.api.change_order.send=false
      })
    },
  }
};
</script>

<style>
.buttons {
  margin-top: 35px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
