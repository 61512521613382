<template>
  <div>
    <modal
      class="dialer_animation right_side_popup m-w-500 AssignNumberTo min-width-50percent-modal"
      width="500px"
      height="auto"
      :scrollable="true"
      :name="modalName"
      @before-open="onBeforeOpen($event)"
      @before-close="onBeforeClose()"
    >
      <div class="dialer-assign-to md-pad-bottom dialer-edit">
        <div v-if="getIsMobile" class="dialer-edit-header flex-column">
          <div class="w-100 d-flex justify-content-between align-items-center">
            <h2 class="dialer-edit-title">Assigned telephone numbers</h2>
            <button class="newDoneButton" @click="$modal.hide(modalName)">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <div v-else class="dialer-edit-header d-block">
          <div class="d-flex justify-content-between align-items-center w-100">
            <h2 class="dialer-settings-title newer mb-0 d-flex align-items-center">
              <button class="newBackButton mr-16px" @click="$modal.hide(modalName)">
                <b-icon icon="arrow-left-circle" />
                <span class="newBackButton-textpart">Back</span>
              </button>
              Assigned telephone numbers
            </h2>
            <div class="dialer-edit-actions d-flex">
              <button class="newDoneButton" @click="$modal.hide(modalName)">
                <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                <span class="newDoneButton-textPart">Done</span>
              </button>
            </div>
          </div>
          <div class="vm--modal-text">Choose a telephone number to link to this user.</div>
        </div>
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <div v-if="api.fetch_numbers.send" class="whiteBoxWithBorderInsideGreyBox mt-12px">
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset" v-for="n in 3" :key="n.id">
              <div class="latestShimmerDesign" style="height: 24px;width: 180px;"></div>
              <div class="latestShimmerDesign" style="height: 40px;width: 40px;border-radius: 50%;"></div>
              <div class="latestShimmerDesign" style="height: 45px;width: 180px;"></div>
            </div>
          </div>
          <template v-else>
            <div class="latestGreyBox-heading-main">Available numbers</div>
            <div class="whiteBoxWithBorderInsideGreyBox mt-12px">
              <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset" v-for="(number, index) in numbers" :key="index">
                <b-form-checkbox 
                  :ref="number.did"
                  :class="selected.numbers[number.did] ? 'active' : ''" 
                  :checked="!!selected.numbers[number.did]" 
                  :disabled="!!api.attach_and_deattach_number.send"
                  @change="attachAndDeattachNumber(number)" 
                  class="dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight p-0 makeCheckBoxesSquare less-padding leastPadding"
                >
                  <div class="d-flex align-items-center">
                    <img class="country-img mr-16px" width="35px" :src="number.did | number_formater | flag_icon" />
                    <span class="latestGreyBox-descriptionText mt-0">{{ number.did | number_formater }}</span>
                  </div>
                </b-form-checkbox>
              </div>
            </div>
            <vb-no-record v-if="numbers.length==0" :text="'No record'" :design="3" />
          </template>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';
export default {
  nmae: 'AssignNumberPublicHolidayModal',
  inject:[
    'getNumbers',
    'appNotify',
  ],
  props: {
    modalName: {
      type: String,
      default: 'AssignNumberPublicHoliday'
    },
  },
  data() {
    return {
      api: {
        fetch_numbers: this.$helperFunction.apiInstance({  }),
        attach_and_deattach_number: this.$helperFunction.apiInstance({ send: '' }),
      },
      selected: {
        numbers: {}
      },
      data: {
        group_id: '',
        numbers: [],
      },
    }
  },
  computed: {
    ...mapGetters([
      'getIsMobile',
      'getCurrentUser',
      'getVoipNumbers',
    ]),
    numbers(){ return [...this.getVoipNumbers].filter(i=>i.object=='TelephoneNumbers')},
  },
  methods: {
    onBeforeOpen(event){
      this.data.group_id=event?.params?.id ?? ''
      this.data.numbers=event?.params?.numbers ?? []
      this.fetchNumbers()
    },
    onBeforeClose(){
      this.api.fetch_numbers.reset()
      this.selected.numbers={}
      this.data.group_id=''
      this.data.numbers=[]
    },
    async fetchNumbers(){
      if(this.api.fetch_numbers.send) return;
      try {
        this.api.fetch_numbers.send=true
        await this.getNumbers()
        await this.$nextTick()
        for (const number of this.numbers) {
          const is_selected = !!this.data.numbers.find(i=>i.scheduler_number==number.did)
          if(is_selected) this.$set(this.selected.numbers,number.did,number.did)
        }
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
        
      } finally {
        this.api.fetch_numbers.send = false
      }
    },
    async attachAndDeattachNumber(number) {
      const did = number?.did
      const is_selected = !!this.selected.numbers[did]
      const ref = this.$refs[did]?.[0]
      if(ref) ref.localChecked=is_selected
      if(this.api.attach_and_deattach_number.send || !did) return;
      try {
        this.api.attach_and_deattach_number.send=did
        if(this.selected.numbers[did]){
          await VOIP_API.endpoints.holiday_schedule.deattach(this.data.group_id,{
            accountcode: did.replaceAll('-','')
          })
          this.$delete(this.selected.numbers,did)
        } else {
          await VOIP_API.endpoints.holiday_schedule.attach(this.data.group_id,{
            accountcode: did.replaceAll('-','')
          })
          this.$set(this.selected.numbers,did,did)
        }
        this.$emit('updated')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger'
        })
      } finally {
        this.api.attach_and_deattach_number.send=''
        if(ref) ref.localChecked=is_selected
      }
    },
  },
}
</script>

<style>

</style>