<template>
  <modal 
    class="center-zoomIn-transition v2 SelectUnavailableTime" 
    :scrollable="true" 
    width="100%" 
    height="auto" 
    :clickToClose="false" 
    :name="modalName" 
    @before-close="onBeforeClose($event)" 
    @before-open="onBeforeOpen($event)"
  >
    
    <div class="dialer-edit w-100">
      <div v-if="getIsMobile" class="dialer-edit-header mt-0 flex-column">
        <div class="d-flex w-100 justify-content-between align-items-center mb-2">
          <h2 class="dialer-edit-title">Select available time slots</h2>
        </div>
        <div class="dialer-edit-actions d-flex justify-content-end w-100">
          <button class="newDoneButton" @click="onDone()">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Done</span>
          </button>
        </div>
      </div>
      <div v-else class="dialer-edit-header b-0">
        <h2 class="dialer-edit-title">Select available time slots</h2>
        <div class="dialer-edit-actions d-flex">
          <a class="newCloseButton" type="button" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a>
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023 mt-20px">
        <b-form @submit.prevent="addSlot()">
          <!-- <b-row>
            <b-col cols="12">
              <b-form-group label="Start Time">
                <b-form-timepicker 
                  v-model="forms.add_slot.start" 
                  @input="onValidate()"  
                  locale="en"
                  :state="$v.forms.add_slot.start.$error ? false : null"
                />
                <b-form-invalid-feedback>
                  <template v-if="!$v.forms.add_slot.start.required">Start time is required</template>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="End Time">
                <b-form-timepicker 
                  v-model="forms.add_slot.end" 
                  @input="onValidate()" 
                  locale="en"
                  :state="$v.forms.add_slot.end.$error ? false : null"
                />
                <b-form-invalid-feedback>
                  <template v-if="!$v.forms.add_slot.end.required">End time is required</template>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-button class="w-100" type="submit" variant="success">Add</b-button>
          </b-row> -->
          <div class="d-flex justify-content-between align-items-center w-100">
            <div class="UnavailableTimes-row">
              <div class="UnavailableTimes-startTime">
                <b-form-group>
                  <b-form-timepicker 
                    v-model="forms.add_slot.start" 
                    @input="onValidate()" 
                    locale="en"
                    :state="$v.forms.add_slot.start.$error ? false : null"
                  >
                    <template #button-content>
                      <vb-icon icon="UnavailableTimes-TimesIcon" class="UnavailableTimes-TimesIcon" />
                      <div>
                        <div class="UnavailableTimes-smallHeading">Available</div>
                        <div class="UnavailableTimes-bigHeading">Start time</div>
                      </div>
                    </template>
                  </b-form-timepicker>
                  <b-form-invalid-feedback>
                    <template v-if="!$v.forms.add_slot.start.required">Start time is required</template>
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div class="UnavailableTimes-endTime">
                <b-form-group>
                  <b-form-timepicker 
                    v-model="forms.add_slot.end" 
                    @input="onValidate()" 
                    locale="en"
                    :state="$v.forms.add_slot.end.$error ? false : null"
                  >
                    <template #button-content>
                      <vb-icon icon="UnavailableTimes-TimesIcon-endTime" class="UnavailableTimes-TimesIcon" />
                      <div>
                        <div class="UnavailableTimes-smallHeading">Available</div>
                        <div class="UnavailableTimes-bigHeading">End time</div>
                      </div>
                    </template>
                  </b-form-timepicker>
                  <b-form-invalid-feedback>
                    <template v-if="!$v.forms.add_slot.end.required">End time is required</template>
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
            </div>
            <b-button class="UnavailableTimes-plusBTN" type="submit">
              <!-- Add -->
              <b-icon icon="plus-circle-fill" />
            </b-button>
          </div>
        </b-form>
        <div v-if="time_slots.length > 0" class="whiteBoxWithBorderInsideGreyBox mt-20px px-3">
          <div v-for="(time_slot, index) in time_slots" :key="index" class="whiteBoxWithBorderInsideGreyBox-row py-2 min-height-unset">
            <p class="m-0" style="font-weight: 700;">{{time_slot.start | twentyFourHourToAmPmFormat}} - {{time_slot.end | twentyFourHourToAmPmFormat}}</p>
            <b-button v-if="time_slots.length>1" type="button" variant="link" @click="time_slots.splice(index,1)">
              <b-icon icon="trash-fill" variant="danger" />
            </b-button>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end w-100 mt-20px">
        <button class="fullWidthDoneButton" @click="onDone()">Update</button>
      </div>
    </div>
  </modal>
</template>

<script>
import moment from 'moment-timezone';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import required from 'vuelidate/lib/validators/required';
export default {
  name: "SelectAvailableTimeSlotModal",
  inject: [
    'appNotify',
  ],
  props: {
    modalName: {
      type: String,
      default: "SelectAvailableTimeSlot",
    },
  },
  data() {
    return {
      time_slots: [
        {
          start: '09:00:00',
          end: '17:00:00',
        }
      ],
      forms: {
        add_slot: this.$helperFunction.formInstance({
          data: {
            start: '09:00:00',
            end: '17:00:00',
          }
        }),
      },
    };
  },
  computed: {
    ...mapGetters([
      'getIsMobile'
    ]),
  },
  validations(){
    return {
      forms: {
        add_slot: {
          start: {
            required: required,
          },
          end: {
            required: required,
          },
          
        },
      },
    }
  },
  filters: {
    twentyFourHourToAmPmFormat(time){ return moment(`2023-12-12 ${time}`,'YYYY-MM-DD HH:mm:ss').format('hh:mm a') },
  },
  methods: {
    onBeforeOpen(event) {
      this.time_slots = _.cloneDeep(event?.params?.duration ?? this.time_slots);
    },
    onBeforeClose() {
      this.$v.$reset();
      this.forms.add_slot.reset();
      this.time_slots = [
        {
          start: '09:00:00',
          end: '17:00:00',
        }
      ]
      this.$emit('before-close')
    },
    onDone() {
      this.$emit("updated", {
        slots: this.time_slots
      });
      this.$modal.hide(this.modalName);
    },
    addSlot(){
      this.$v.forms.add_slot.$touch()
      if(this.$v.forms.add_slot.$invalid) return;
      const is_overlapping = this.time_slots.some(slot => (this.forms.add_slot.start < slot.end && this.forms.add_slot.end > slot.start));
      if(is_overlapping) {
        this.appNotify({
          message: 'This time slot is overlap with other',
          type: 'danger'
        })
        return;
      }
      this.time_slots.push({
        start: this.forms.add_slot.start,
        end: this.forms.add_slot.end,
      })
      this.$v.forms.add_slot.$reset()
      this.forms.add_slot.reset()
    },
    onValidate(){
      if(!this.$v.forms.add_slot.start.$invalid || !this.$v.forms.add_slot.end.$invalid) return;
      const start_day = moment(`2023-12-12 00:00:00`,'YYYY-MM-DD HH:mm:ss').startOf('days')
      const start = moment(`2023-12-12 ${this.forms.add_slot.start}`,'YYYY-MM-DD HH:mm:ss')
      const end = moment(`2023-12-12 ${this.forms.add_slot.end}`,'YYYY-MM-DD HH:mm:ss')
      if(end.diff(start_day,'minutes')<start.diff(start_day,'minutes')) this.forms.add_slot.end=this.forms.add_slot.start
    },
  },
};
</script>

<style>
</style>