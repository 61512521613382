import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import store from "./store";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.use(Loading);
Vue.use(require("vue-chartist"));
import VueToast from "vue-toast-notification";
// Import one of available themes
import "vue-toast-notification/dist/theme-default.css";
Vue.use(VueToast,{
  position: 'bottom-left',
  queue: true,
});
// ------------------
import { Plugin } from "vue-fragment";
Vue.use(Plugin);
import("./Jitsi");
import VB from './index'
Vue.use(VB)
import VEmojiPicker from "v-emoji-picker";
Vue.use(VEmojiPicker);
// moment init--------------------------------
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
momentDurationFormatSetup(moment);
// -------------------------------------------
//____________________________
import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);
//____________________________
//__________________________________
import VueGates from 'vue-gates';
Vue.use(VueGates);
//__________________________________
//vue-tour -------------------------
import VueTour from 'vue-tour'
require('vue-tour/dist/vue-tour.css')
Vue.use(VueTour)
//----------------------------------
Vue.config.productionTip = false;
library.add(fas, far, fab);
Vue.component("font-awesome-icon", FontAwesomeIcon);
import ToggleButton from "vue-js-toggle-button";
Vue.use(ToggleButton);
// @hipsjs/flowy-vue-------------------------
// import FlowyPlugin from "@hipsjs/flowy-vue";
// import "@hipsjs/flowy-vue/dist/lib/flowy-vue.css";
// Vue.use(FlowyPlugin);
//----------------------------------
//Install BootstrapVue
Vue.use(BootstrapVue);
//Install Bootstrap icon component plugin (Optional)
Vue.use(IconsPlugin);
import VueOnlineProp from "vue-online-prop";
Vue.use(VueOnlineProp);
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);
// vue js modal plugin
import VModal from "vue-js-modal";
Vue.use(VModal);
import VueHotkey from "v-hotkey";
import 'vue-search-select/dist/VueSearchSelect.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
Vue.use(VueHotkey);
import VueObserveVisibility from 'vue-observe-visibility';
Vue.use(VueObserveVisibility);
import * as Sentry from "@sentry/vue";

Sentry.init({
  Vue,
  dsn: "http://a07f425a4ce299b1be23322d0fc18dd0@sentryprod.0p.network:9000/9",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
new Vue({
  name: 'dialer-app',
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

