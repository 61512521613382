<template>
<div>
  <modal class="ViewContactInfoModal" :name="modalName" @before-close="onBeforeClose()" @before-open="onBeforeOpen($event)">
    <div class="dialer-assign-to md-pad-bottom dialer-edit">
      <div class="dialer-edit-header d-flex w-100 justify-content-between align-items-center">
        <div>
          <h2 class="dialer-settings-title newer mb-0">Contact Info</h2>
        </div>
        <div class="dialer-edit-actions">
          <button class="newDoneButton" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Done</span>
          </button>
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023 mt-20px">
        <template v-if="api.get_info.send && !response.info">
          <div class="whiteBoxWithBorderInsideGreyBox">
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-none py-3" v-for="n in 10" :key="n">
              <div class="latestShimmerDesign w-100" style="height:25px;"></div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="latestGreyBox-heading-main-16px">Tags <b-button variant="link" @click="!getUserPermissions.power_dialer ? appNotify({message:'Your package does not support this feature',type:'danger'}) : $modal.show(`${_uid}-PowerDialerContactUpdateTags`,{ row_no: data.row_no, sheet_id: data.sheet_id, })"><b-icon-pencil-fill variant="primary" /> Edit</b-button></div>
          <div class="whiteBoxWithBorderInsideGreyBox mt-10px">
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-none flex-wrap py-3 justify-content-start">
              <template v-if="tags.length==0">
                <div class="d-flex w-100 flex-column align-items-center">
                  <vb-icon icon="noDataFound-v2" width="57" height="63" />
                  <span>
                    <b>There is no Tags</b>
                  </span>
                </div>
              </template>
              <template v-else>
                <div class="m-1" v-for="tag in tags" :key="tag.id">
                  <div 
                    v-vb-tag-color.text.bg="{
                      color: tag.colour,
                      text_color: tag.text_color
                    }" 
                    :style="`border-radius:4px;height:auto;width:fit-content;padding:0.2rem 0.4rem;font-size: 10.5px;font-weight: 500;line-height: 1.3;text-align: center;vertical-align: initial;white-space: nowrap;`" >
                    {{tag.tag}}
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="latestGreyBox-heading-main-16px mt-20px">Notes <b-button variant="link" @click="!getUserPermissions.power_dialer ? appNotify({message:'Your package does not support this feature',type:'danger'}) : $modal.show(`${_uid}-PowerDialerContactUpdateNote`,{ row_no: data.row_no, sheet_id: data.sheet_id, })"><b-icon-pencil-fill variant="primary" /> Edit</b-button></div>
          <div class="whiteBoxWithBorderInsideGreyBox mt-10px">
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-none flex-wrap py-3 justify-content-start">
              <template v-if="note==''">
                <div class="d-flex w-100 flex-column align-items-center">
                  <vb-icon icon="noDataFound-v2" width="57" height="63" />
                  <span>
                    <b>There is no Note</b>
                  </span>
                </div>
              </template>
              <template v-else>
                <div>{{ note }}</div>
              </template>
            </div>
          </div>
          <div class="latestGreyBox-heading-main-16px mt-20px">Schedule <b-button variant="link" @click="!getUserPermissions.power_dialer ? appNotify({message:'Your package does not support this feature',type:'danger'}) : $modal.show(`${_uid}-PowerDialerContactUpdateSchedule`,{ row_no: data.row_no, sheet_id: data.sheet_id, })"><b-icon-pencil-fill variant="primary" /> Edit</b-button></div>
          <div class="whiteBoxWithBorderInsideGreyBox mt-10px">
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-none flex-wrap py-3 justify-content-start">
              <template v-if="schedule==''">
                <div class="d-flex w-100 flex-column align-items-center">
                  <vb-icon icon="noDataFound-v2" width="57" height="63" />
                  <span>
                    <b>Schedule is not set</b>
                  </span>
                </div>
              </template>
              <template v-else>
                <div>{{ schedule | filter_date_current('YYYY-MM-DD hh:mm:ss',{ formate: 'YYYY-MM-DD hh:mm a' }) }}</div>
              </template>
            </div>
          </div>
          <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
            <div v-for="(value, key) in info" :key="key" class="whiteBoxWithBorderInsideGreyBox-row min-height-none py-3 justify-content-start">
              <b style="min-width:40%;width:40%;text-transform: capitalize;">{{key}}:</b>
              <span>{{value}}</span>
            </div>
          </div>
        </template>
      </div>
    </div>
  </modal>
  <PowerDialerContactUpdateNoteModal :modalName="`${_uid}-PowerDialerContactUpdateNote`" @updated="fetchInfoDebounce()" />
  <PowerDialerContactUpdateTagsModal :modalName="`${_uid}-PowerDialerContactUpdateTags`" @updated="fetchInfoDebounce()" />
  <PowerDialerContactUpdateScheduleModal :modalName="`${_uid}-PowerDialerContactUpdateSchedule`" @updated="fetchInfoDebounce()" />
</div>
</template>

<script>
import { VOIP_API } from '@/utils';
import _ from 'lodash'
import PowerDialerContactUpdateNoteModal from './PowerDialerContactUpdateNoteModal.vue';
import PowerDialerContactUpdateTagsModal from './PowerDialerContactUpdateTagsModal.vue';
import PowerDialerContactUpdateScheduleModal from './PowerDialerContactUpdateScheduleModal.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'PowerDialerContactInfoModal',
  components: {
    PowerDialerContactUpdateNoteModal,
    PowerDialerContactUpdateTagsModal,
    PowerDialerContactUpdateScheduleModal,
  },
  inject: [
    'appNotify'
  ],
  props: {
    modalName: {
      type: String,
      default: 'PowerDialerContactInfo'
    },
  },
  data() {
    return {
      api: {
        get_info: this.$helperFunction.apiInstance({  }), 
      },
      response: {
        info: null,
      },
      data: {
        sheet_id: '',
        row_no: '',
      },
    }
  },
  computed: {
    ...mapGetters([
      'getUserPermissions'
    ]),
    info(){ return this.response.info?.result ?? {} },
    tags(){ return this.response.info?.data?.tags || [] },
    note(){ return this.response.info?.data?.notes || '' },
    schedule(){ return this.response.info?.data?.scheduled_date || '' },
  },
  methods: {
    onBeforeOpen(event){
      this.data.sheet_id=event?.params?.sheet_id || ''
      this.data.row_no=event?.params?.row_no || ''
      this.fetchInfo()
    },
    onBeforeClose(){
      this.api.get_info.reset()
      this.response.info=null
      this.data.sheet_id=''
      this.data.row_no=''
    },
    fetchInfoDebounce: _.debounce(function(){
      this.fetchInfo()
    }, 2 * 1000),
    async fetchInfo(){
      if(this.api.get_info.send) return;
      try {
        this.api.get_info.send=true
        const { data } = await VOIP_API.endpoints.power_dialer.getNextNumber(this.data.sheet_id,{
          row_no: this.data.row_no
        })
        this.response.info=data
      } catch (ex) {
        this.appNotify({
          message: ex.message,
          type: 'danger',
        })
      } finally {
        this.api.get_info.send=false
      }
    },
  },
}
</script>

<style>

</style>