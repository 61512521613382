<template>
  <div>
    <div v-if="api.fetch_group.send && !response.group" class="w-100">
      <!-- Loading... -->
      <div v-for="n in 5" :key="n" class="latestShimmerDesign flex-1 mb-3" style="height:50px;width:100%"></div>
    </div>
    <template v-else>
      <div class="latestGreyBox-9-9-2023 mt-20px">
        <!-- <div class="d-flex justify-content-between align-items-center">
          <p v-if="response.group" class="m-0">
            <span>{{response.group.name}}</span>
            <span class="ml-2">{{response.group.timezone}}</span>
          </p>
          <b-button :disabled="api.update_group_name.send || api.remove_group.send" variant="link" @click="$modal.show(`${_uid}-UpdateName`)" class="p-0">
            <b-icon icon="pencil-fill" variant="primary" />
          </b-button>
        </div> -->
        <div class="whiteBGinputWithGreyRoundedBorder-like w-100">
          <p v-if="response.group" class="m-0">
            <span>{{response.group.name}}</span>
            <span class="ml-2">( {{response.group.timezone}} )</span>
          </p>
          <b-button :disabled="api.update_group_name.send || api.remove_group.send" variant="link" @click="$modal.show(`${_uid}-UpdateName`)" class="p-0">
            <vb-icon icon="UnavailableTimes-pencilIcon-withUnderLine" width="18.79px" height="18.79px" />
          </b-button>
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023 mt-20px">
        <!-- holidays -->
        <div class="d-flex align-items-center justify-content-between mb-20px">
          <div class="latestGreyBox-heading-main-24px darker"> Show {{response.group | get_property('timezone') | countryName}} Public Holiday </div>
          <b-checkbox class="newerSwitch"
            v-model="condition.defaulted_holidays" 
            switch
          />
        </div>
        <button class="newButton ml-auto mb-20px" :disabled="api.remove_group.send" @click="$modal.show(`${_uid}-CreatePublicHoliday`,{ 
          group_id: groupId,
          conditions: {
            disable_dates: disabledDates,
          }, 
        })">
          <vb-icon icon="squared-addHoliday-icon" class="squared-addHoliday-icon" height="38px" width="38px" />
          <span class="newButton-textPart">Add Holiday</span>
        </button>
        <vb-table 
          v-if="condition.defaulted_holidays"
          class="latestTableDesign-withBlackBorders-again showLastTDonHover mt-0"
          :isListEmpty="response.defaulted_holidays.length==0" 
          :listLength="response.defaulted_holidays.length" 
          :loading="api.fetch_default_holidays.send" 
          :conditions="{
            pagination: {
              per_page: false,
            }
          }"
          :perPage="100"
        >
          <tr slot="header" v-if="!getIsMobile">
            <th class="dialer-has-sort px-2 dialer-col-left">
              <span>Name</span>
            </th>
            <th class="dialer-has-sort px-2 dialer-col-left">
              <span>Unavailable Time</span>
            </th>
          </tr>
          <template #body="{ start, end }">
            <tr v-for="(holiday, index) in response.defaulted_holidays" :key="holiday.id" v-show="index >= start && index <= end">
              <td class="dialer-has-sort dialer-col-left">
                <p>{{holiday.holiday_name}}</p>
                <p>{{holiday.holiday_date}}</p>
              </td>
              <td class="dialer-has-sort dialer-col-left">
                <div v-if="holidaysByDateGroup[holiday.holiday_date]" 
                  @click="$modal.show(`${_uid}-UpdatePublicHoliday`,{ 
                    holiday: holidaysByDateGroup[holiday.holiday_date],
                    conditions: {
                      name: false,
                      date: false,
                    },
                  })"
                >
                  <div class="latestGreyBox-descriptionText-18px" v-if="holidaysByDateGroup[holiday.holiday_date].type=='unavailable'">Unavailable all day</div>
                  <div class="latestGreyBox-descriptionText-18px" v-else-if="holidaysByDateGroup[holiday.holiday_date].type=='custom'">Unavailable at {{holidaysByDateGroup[holiday.holiday_date].start_time | twentyFourHourToAmPmFormat}} - {{holidaysByDateGroup[holiday.holiday_date].end_time | twentyFourHourToAmPmFormat}}</div>
                  <div class="latestGreyBox-descriptionText-14px" v-if="holidaysByDateGroup[holiday.holiday_date].extension_type=='voicemail'">Call will be forward to voicemail</div>
                  <div class="latestGreyBox-descriptionText-14px" v-else-if="holidaysByDateGroup[holiday.holiday_date].extension_type=='number'">Call will be forward to {{holidaysByDateGroup[holiday.holiday_date].forward_to | number_formater}}</div>
                  <div class="latestGreyBox-descriptionText-14px" v-else>Call will be forward to <Info :only_name="true" v-if="holidaysByDateGroup[holiday.holiday_date].forward_to" :id="holidaysByDateGroup[holiday.holiday_date].forward_to" /></div>
                </div>
                <div v-else>
                  <b-checkbox class="newerSwitch" :disabled="api.fetch_default_holidays.send" switch @change="addDefaultedHoliday(holiday)" />
                </div>
              </td>
            </tr>
          </template>
        </vb-table>
        <vb-table 
          v-else
          class="latestTableDesign-withBlackBorders-again showLastTDonHover mt-0"
          :isListEmpty="customHolidays.length==0" 
          :listLength="customHolidays.length" 
          :loading="api.fetch_group.send" 
          :conditions="{
            pagination: {
              per_page: false,
            }
          }"
          :perPage="100"
        >
          <tr slot="header" v-if="!getIsMobile">
            <th class="dialer-has-sort px-2 dialer-col-left">
              <span>Name</span>
            </th>
            <th class="dialer-has-sort px-2 dialer-col-left">
              <span>Unavailable Time</span>
            </th>
          </tr>
          <template #body="{ start, end }">
            <tr 
              v-for="(holiday, index) in customHolidays" 
              :key="holiday.id" v-show="index >= start && index <= end" 
              @click="$modal.show(`${_uid}-UpdatePublicHoliday`,{ 
                holiday: holiday,
                conditions: {
                  disable_dates: disabledDates,
                },
              })"
            >
              <td class="dialer-has-sort dialer-col-left">
                <p class="mb-0">{{holiday.holiday_name || ''}}</p>
                <p class="mt-1">{{holiday.holiday_date}}</p>
              </td>
              <td class="dialer-has-sort dialer-col-left">
                <div>
                  <div class="latestGreyBox-descriptionText-18px mt-0" v-if="holiday.type=='unavailable'">Unavailable all day</div>
                  <div class="latestGreyBox-descriptionText-18px mt-0" v-else-if="holiday.type=='custom'">Unavailable at {{holiday.start_time | twentyFourHourToAmPmFormat}} - {{holiday.end_time | twentyFourHourToAmPmFormat}}</div>
                  <div class="latestGreyBox-descriptionText-14px" v-if="holiday.extension_type=='voicemail'">Call will be forward to voicemail</div>
                  <div class="latestGreyBox-descriptionText-14px" v-else-if="holiday.extension_type=='number'">Call will be forward to {{holiday.forward_to | number_formater}}</div>
                  <div class="latestGreyBox-descriptionText-14px" v-else>Call will be forward to <Info :only_name="true" v-if="holiday.forward_to" :id="holiday.forward_to" /></div>
                </div>
              </td>
            </tr>
          </template>
        </vb-table>
      </div>
      <div class="latestGreyBox-9-9-2023 mt-20px">
        <!-- announcement file & telephone numbers-->
        <div class="whiteBoxWithBorderInsideGreyBox">
          <!-- announcement file -->
          <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
            <div class="w-100 d-flex align-items-center justify-content-between">
              <div class="d-flex flex-column">
                <div class="latestGreyBox-heading-main">Announcement File</div>
                <!-- <div class="latestGreyBox-descriptionText">Description</div> -->
              </div>
              <a 
                class="newButton sm-mar-left" 
                @click="$modal.show(`${_uid}-RecordingSelect`, { 
                  group: 'announcement', 
                  selected: forms.update_announcement_file.sound,
                  conditions: {
                    value_field: 'sound'
                  }, 
                })" >
                <vb-icon :icon="`${forms.update_announcement_file.sound ? 'squared-change-icon' : 'squared-add-icon'}`" height="38px" width="38px" />
                <span class="newButton-textPart">{{ forms.update_announcement_file.sound ? 'Change' : 'Add' }}</span>
              </a>
            </div>
            <div v-if="forms.update_announcement_file.file" class="w-50 mt-12px">
              <vb-audio-player :src="forms.update_announcement_file.file" :design="`${getIsMobile ? 'minimum' : ''}`" class="whiteBGaudioWithGreyRoundedBorder-again w-100" />
            </div>
          </div>
          <!-- telephone numbers -->
          <div class="whiteBoxWithBorderInsideGreyBox-row">
            <div class="d-flex flex-column">
              <div class="latestGreyBox-heading-main">Telephone numbers</div>
              <div class="d-flex mt-0 IVR-numbers-container flex-wrap">
                <template v-if="dids.length > 0">
                  <div v-for="(did, key) in dids" :key="key" class="newIVRLayout-number-item">
                    <img class="country-img" width="45px" :src="did.scheduler_number | number_formater | flag_icon" />
                    <span class="newIVRLayout-number-item-text">{{ did.scheduler_number | number_formater }}</span>
                  </div>
                </template>
                <div v-else class="latestGreyBox-descriptionText">No numbers assigned</div>
              </div>
            </div>
            <div class="lg-mar-left">
              <button class="newButton" type="button" @click="$modal.show(`${_uid}-AssignNumberPublicHoliday`, { id: groupId, numbers: dids, })">
                <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                <span class="newButton-textPart">Assign</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- delete -->
      <div v-if="conditions.delete" class="latestGreyBox-9-9-2023 mt-24px mb-20px">
        <div class="whiteBoxWithBorderInsideGreyBox">
          <div class="whiteBoxWithBorderInsideGreyBox-row">
            <div class="d-flex flex-column">
              <div class="latestGreyBox-heading-main">Delete this Group?</div>
              <div class="latestGreyBox-descriptionText">
                This group will be deleted
              </div>
            </div>
            <button class="newDeleteButton" :disabled="api.remove_group.send" @click="removeGroup()">
              <vb-icon icon="squared-delete-icon" height="38px" width="38px" />
              <span class="newDeleteButton-textPart d-flex align-items-center justify-content-center position-relative">
                <vb-spinner v-if="api.remove_group.send" />
                <template v-else>Delete</template>
              </span>
            </button>
          </div>
        </div>
      </div>
    </template>
    <modal :name="`${_uid}-UpdateName`" class="center-zoomIn-transition v2 m-w-500 addCallQueueModal setHeight" @before-open="forms.update_group_name.name=response.group.name" @before-close="$v.forms.update_group_name.$reset();forms.update_group_name.reset();">
      <div class="dialer-edit-user s1">
        <div class="dialer-edit">
          <div class="dialer-form">
            <div class="dialer-edit-header d-block pb-0">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center addCallQueueModal-namePart">
                  <h2 class="dialer-settings-title newer mb-0">Update name</h2>
                </div>
                <div class="dialer-edit-actions d-flex align-items-center">
                  <a class="newCloseButton" @click="$modal.hide(`${_uid}-UpdateName`)">
                    <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                    <span class="newCloseButton-textPart">Close</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="md-mar-vertical">
              <div class="latestGreyBox-9-9-2023 mt-20px">
                <form @submit.prevent="updateGroupName()">
                  <!-- name -->
                  <div class="whiteBGinputWithGreyRoundedBorder">
                    <label>Name</label>
                    <input :disabled="api.update_group_name.send || api.remove_group.send" class="w-100" type="text" v-model="forms.update_group_name.name" :maxlength="$v.forms.update_group_name.name.$params.maxLength.max" />
                    <p v-if="$v.forms.update_group_name.name.$error || api.update_group_name.validation_errors.name" class="text text-danger animated bounceIntop">
                      <span v-if="!$v.forms.update_group_name.name.required">* Name is required</span>
                      <span v-else-if="!$v.forms.update_group_name.name.minLength">* Name is should be minimum {{$v.forms.update_group_name.name.$params.minLength.min}} character</span>
                      <span v-else-if="!$v.forms.update_group_name.name.maxLength">* Name is can be maximum {{$v.forms.update_group_name.name.$params.maxLength.max}} character</span>
                      <span v-else-if="!$v.forms.update_group_name.name.valid">* Name can be number and alphabet</span>
                      <span v-for="(em,i) in api.update_group_name.validation_errors.name" :key="i">* {{ em }}</span>
                    </p>
                  </div>
                  <!-- <div>
                    <b-button type="submit">Submit</b-button>
                  </div> -->
                  <div class="d-flex align-items-center justify-content-end mt-4">
                    <button type="submit" class="fullWidthDoneButton">Update</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <CreatePublicHolidayModal :modalName="`${_uid}-CreatePublicHoliday`" class="forHolidayFix" @created="fetchGroupDebounce()" />
    <UpdatePublicHolidayModal :modalName="`${_uid}-UpdatePublicHoliday`" class="forHolidayFix" @updated="fetchGroupDebounce()" @removed="fetchGroupDebounce()" />
    <!-- <AddCountryPublicHolidayModal :modalName="`${_uid}-AddCountryPublicHoliday`" class="forHolidayFix" :existingHolidays="holidays" @created="fetchGroupDebounce()" /> -->
    <AssignNumberPublicHolidayModal :modalName="`${_uid}-AssignNumberPublicHoliday`" @updated="fetchGroupDebounce()" />
    <RecordingListSelect :modalName="`${_uid}-RecordingSelect`" @selectedRecordings="updateAnnouncementFile($event.data)" :is_single_select="true" />
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { mapGetters } from 'vuex';
import { CountryName2 } from '@/utils/flags';
import _ from 'lodash'
import required from 'vuelidate/lib/validators/required';
import minLength from 'vuelidate/lib/validators/minLength';
import maxLength from 'vuelidate/lib/validators/maxLength';
import { helpers } from 'vuelidate/lib/validators';
import { VOIP_API } from '@/utils';
import CreatePublicHolidayModal from './holidays/CreatePublicHolidayModal.vue'
import UpdatePublicHolidayModal from './holidays/UpdatePublicHolidayModal.vue'
// import AddCountryPublicHolidayModal from './holidays/AddCountryPublicHoliday.vue'
import AssignNumberPublicHolidayModal from './AssignNumberPublicHoliday.vue'
import RecordingListSelect from "../RecordingListSelect.vue";
import Info from '../../Lists/Info.vue';
export default {
  nmae: 'UpdatePublicHolidayGroup',
  components: {
    CreatePublicHolidayModal,
    UpdatePublicHolidayModal,
    AssignNumberPublicHolidayModal,
    // AddCountryPublicHolidayModal,
    RecordingListSelect,
    Info
  },
  inject: [
    'appNotify',
  ],
  props: {
    groupId: {
      type: [String,Number],
      default: ''
    },
    conditions: {
      type: Object,
      default: () =>({
        delete: true,
      })
    },
  },
  data() {
    return {
      forms: {
        update_group_name: this.$helperFunction.formInstance({
          data: {
            name: '',
          }
        }),
        update_announcement_file: this.$helperFunction.formInstance({
          data: {
            file: '',
            sound: '',
          }
        }),
      },
      api: {
        fetch_group: this.$helperFunction.apiInstance({  }),
        fetch_default_holidays: this.$helperFunction.apiInstance({  }),
        add_default_holiday: this.$helperFunction.apiInstance({  }),
        remove_group: this.$helperFunction.apiInstance({  }),
        update_group_name: this.$helperFunction.apiInstance({ validation_errors: true, }),
        announcement_file: this.$helperFunction.apiInstance({  }),
      },
      response: {
        group: null,
        defaulted_holidays: [],
      },
      condition: {
        defaulted_holidays: false,
      },
    }
  },
  computed: {
    ...mapGetters([
      'getIsMobile',
      'getCurrentUser',
    ]),
    country(){ return moment.tz(this.response.group?.timezone ?? '')?._z?.countries?.()?.[0] ?? '' },
    dids(){ return this.response.group?.scheduler_numbers ?? [] },
    holidays(){ return (this.response.group?.rules ?? [])?.map?.(i=>({
      ...i, 
      holiday_date: (i.years=='*'?moment():moment(`${i.years} Jan 01 00:00:00`,'YYYY MMM DD HH:mm:ss')).startOf('year').subtract(1,'day').add(i.year_days || 0 ,'days').format('YYYY-MM-DD')
    })) ?? [] },
    customHolidays(){ return this.holidays.filter(i=>!this.defaultedHolidaysByDateGroup[i.holiday_date]) },
    holidaysByDateGroup(){ return _.keyBy(this.holidays,'holiday_date') },
    defaultedHolidaysByDateGroup(){ return _.keyBy(this.response.defaulted_holidays,'holiday_date') },
    disabledDates(){ return Object.keys(this.holidaysByDateGroup) },
  },
  validations() {
    return {
      forms: {
        update_group_name: {
          name: { 
            required: required,
            minLength: minLength(3), 
            maxLength: maxLength(20),
            valid: helpers.regex('valid',/^[a-z0-9 ]*$/i),
          },
        },
        update_announcement_file: {
          sound: { 
            required: required,
          },
        },
      },
    }
  },
  filters: {
    holiday_date(holiday){
      return (holiday.years=='*'?moment():moment(`${holiday.years} Jan 01 00:00:00`,'YYYY MMM DD HH:mm:ss')).startOf('year').subtract(1,'day').add(holiday.year_days || 0 ,'days').format('DD MMM YYYY')
    },
    twentyFourHourToAmPmFormat(time){ return moment(`2023-12-12 ${time}`,'YYYY-MM-DD HH:mm:ss').format('hh:mm a') },
    countryName(timezone){ return CountryName2[moment.tz(timezone)?._z?.countries?.()?.[0] ?? ''] || '' },
  },
  methods: {
    fetchGroupDebounce: _.debounce(function(){
      this.fetchGroup()
    }, 2*1000),
    async fetchGroup(){
      if(this.api.fetch_group.send) return;
      try {
        this.api.fetch_group.send=true
        const { data } = await VOIP_API.endpoints.holiday_schedule.getByID(this.groupId)
        const group = data ?? {}
        this.response.group = group
        if(group.announcement_file) {
          this.forms.update_announcement_file.file=group.announcement_file_url
          this.forms.update_announcement_file.sound=group.announcement_file.split('/').at(-1)
        }
        this.fetchDefaultedHolidays()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_group.send=false
      }
    },
    async updateGroupName() {
      this.$v.forms.update_group_name.$touch();
      if (this.$v.forms.update_group_name.$invalid || this.api.update_group_name.send || this.api.remove_group.send) return;
      try {
        this.api.update_group_name.send = true;
        this.api.update_group_name.validation_errors = {};
        await VOIP_API.endpoints.holiday_schedule.update(this.groupId,{
          name: this.forms.update_group_name.name,
          timezone: this.response.group.timezone,
        })
        this.$emit('updated')
        this.$modal.hide(`${this._uid}-UpdateName`)
        this.fetchGroupDebounce()
        this.appNotify({
          message: 'Successfully Updated',
          type: 'success'
        })
      } catch (ex) {
        this.api.update_group_name.validation_errors = ex.own_errors;
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.update_group_name.send = false;
      }
    },
    async removeGroup(){
      if(this.api.remove_group.send) return;  /*|| !number*/
      try {
        const { confirm } = await this.$appConfirmation({
          title: "Delete user?",
          message: `Are you sure you want to delete this GRoup.`,
          variant: 'danger',
          alert: {
            variant: 'info',
            title: 'Warning',
            message: `this will also remove all holidays`,
          },
          button: {
            no: "No",
            yes: "Yes",
          },
        })
        if(!confirm) return;
        this.api.remove_group.send=true
        await VOIP_API.endpoints.holiday_schedule.remove(this.groupId)
        this.$emit('removed')
        this.appNotify({
          message: 'Successfully Deleted',
          type: 'success'
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.remove_group.send=false
      }
    },
    async updateAnnouncementFile(event){
      if (this.api.announcement_file.send || _.isEmpty(event)) return;
      try {
        this.api.announcement_file.send=true
        const form_data = new FormData();
        form_data.append("accountcode", this.getCurrentUser?.default_accountcode);
        form_data.append("uid", this.getCurrentUser?.uid);
        form_data.append("id", event.id);
        form_data.append("useraccount", this.getCurrentUser?.account);
        form_data.append("schedule_id", this.groupId);
        form_data.append("is_schedule_name", true);
        form_data.append("flag", "library");
        if(event.type=='system') form_data.append("system", "yes");
        await VOIP_API.endpoints.menu.uploadivrimage(form_data);
        this.forms.update_announcement_file.file = event.sound_file;
        this.forms.update_announcement_file.sound = event.sound;
        this.appNotify({
          message: "Successfully converted",
          type: "success",
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: "danger",
        }) 
      } finally {
        this.api.announcement_file.send=false
      }
    },
    async fetchDefaultedHolidays() {
      if(this.api.fetch_default_holidays.send) return;
      try {
        this.api.fetch_default_holidays.send=true
        const { data } = await VOIP_API.endpoints.holiday_schedule.getCountryPublicHolidays({
          country: moment.tz(this.response.group.timezone)?._z?.countries?.()?.[0],
        })
        this.response.defaulted_holidays=data
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_default_holidays.send=false
      }
    },
    async addDefaultedHoliday(holiday){
      if(this.api.add_default_holiday.send) return;
      try {
        this.api.add_default_holiday.send=true
        await VOIP_API.endpoints.holiday_schedule.createRule({
          holidays: [
            {
              date: holiday.holiday_date,
              name: holiday.holiday_name,
            }
          ],
          scheduler_name_id: this.groupId,
          accountcode: this.getCurrentUser.default_accountcode,
          forward_to: `${this.getCurrentUser.default_accountcode.substr(0,11)}1`,
          year_days: null,
          months: null,
          years: null,
          month_days: null,
          week_days: null,
          start_time: '',
          end_time: '',
          schedulable_type: 'accountcode',
          extension_type: 'voicemail',
          type: 'unavailable',
          setting_type: 'custom',
          defaulted_holiday: true,
        })
        this.appNotify({
          message: 'Successfully Created',
          type: 'success',
        })
        this.fetchGroupDebounce()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.add_default_holiday.send=false
      }

    },
  },
  mounted(){
    this.fetchGroup()
  },
}
</script>

<style>

</style>