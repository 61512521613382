var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-500 AssignNumberTo min-width-50percent-modal",
    attrs: {
      "width": "500px",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to md-pad-bottom dialer-edit"
  }, [_vm.getIsMobile ? _c('div', {
    staticClass: "dialer-edit-header flex-column"
  }, [_c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Assigned telephone numbers")]), _c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]) : _c('div', {
    staticClass: "dialer-edit-header d-block"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0 d-flex align-items-center"
  }, [_c('button', {
    staticClass: "newBackButton mr-16px",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "arrow-left-circle"
    }
  }), _c('span', {
    staticClass: "newBackButton-textpart"
  }, [_vm._v("Back")])], 1), _vm._v(" Assigned telephone numbers ")]), _c('div', {
    staticClass: "dialer-edit-actions d-flex"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v("Choose a telephone number to link to this user.")])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_vm.api.fetch_numbers.send ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-12px"
  }, _vm._l(3, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "24px",
        "width": "180px"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "40px",
        "width": "40px",
        "border-radius": "50%"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "45px",
        "width": "180px"
      }
    })]);
  }), 0) : [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Available numbers")]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-12px"
  }, _vm._l(_vm.numbers, function (number, index) {
    return _c('div', {
      key: index,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset"
    }, [_c('b-form-checkbox', {
      ref: number.did,
      refInFor: true,
      staticClass: "dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight p-0 makeCheckBoxesSquare less-padding leastPadding",
      class: _vm.selected.numbers[number.did] ? 'active' : '',
      attrs: {
        "checked": !!_vm.selected.numbers[number.did],
        "disabled": !!_vm.api.attach_and_deattach_number.send
      },
      on: {
        "change": function ($event) {
          return _vm.attachAndDeattachNumber(number);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('img', {
      staticClass: "country-img mr-16px",
      attrs: {
        "width": "35px",
        "src": _vm._f("flag_icon")(_vm._f("number_formater")(number.did))
      }
    }), _c('span', {
      staticClass: "latestGreyBox-descriptionText mt-0"
    }, [_vm._v(_vm._s(_vm._f("number_formater")(number.did)))])])])], 1);
  }), 0), _vm.numbers.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": 'No record',
      "design": 3
    }
  }) : _vm._e()]], 2)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }