<template>
  <div>
    <modal
      class="dialer_animation right_side_popup"
      width="40%"
      height="auto"
      :scrollable="true"
      :name="modalName"
      @before-open="onBeforeOpen($event)"
      @before-close="onBeforeClose()"
    >
      <div class="dialer-edit-header w-100">
        <h2 class="dialer-settings-title newer mb-0 d-flex align-items-center">
          <a type="button" class="newBackButton mr-16px" @click="$modal.hide(modalName)" >
            <b-icon icon="arrow-left-circle" />
            <span class="newBackButton-textpart">Back</span>
          </a>
          Public Holidays
        </h2>
        <div class="dialer-edit-actions">
          <div>
            <button class="newButton" @click="$modal.show('CreatePublicHolidayGroup')">
              <vb-icon icon="squared-add-icon" height="38px" width="38px" />
              <span class="newButton-textPart">Add Group</span>
            </button>
          </div>
        </div>
      </div>
      <div class="vm--modal-text">These days are automatically blocked from having Meetings scheduled.</div>
      <UpdatePublicHolidayGroup v-if="response.groups.length==1" :groupId="response.groups[0].id" :conditions="{ delete: false, }" />
      <div v-else class="latestGreyBox-9-9-2023 mt-20px">
        <vb-table 
          class="latestTableDesign-withBlackBorders-again showLastTDonHover mt-0"
          :isListEmpty="response.groups.length==0" 
          :listLength="response.groups.length" 
          :loading="api.fetch_groups.send" 
        >
          <tr slot="header" v-if="!getIsMobile">
            <th class="dialer-has-sort px-2">
              <span>Group Name</span>
            </th>
            <th class="dialer-has-sort px-2 dialer-col-left">
              <span>Numbers</span>
            </th>
            <th class="dialer-has-sort px-2 dialer-col-right">
              <span>Holidays</span>
            </th>
          </tr>
          <template #body="{ start, end }">
            <tr v-for="(group, index) in response.groups" :key="group.id" v-show="index >= start && index <= end" @click="$modal.show('UpdatePublicHolidayGroup',{ id: group.id })">
              <td class="dialer-row-title">{{group.name}}</td>
              <td class="dialer-has-sort dialer-col-left">
                <div class="d-flex flex-column" v-if="group.scheduler_numbers && group.scheduler_numbers[0]">
                  <div class="d-flex align-items-center">
                    <span class="sm-mar-left font-weight-bold min-width-160px">
                      <span class="mr-1">
                        <img class="country-img" width="45px" :src="group | get_property('scheduler_numbers[0].scheduler_number') | number_formater | flag_icon" />
                      </span>
                      {{ group | get_property('scheduler_numbers[0].scheduler_number') | number_formater }}
                    </span>
                    <div class="w-auto dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight dialer-dropDown-p-1 ml-2" v-if="group.scheduler_numbers.length>1" @click.stop="conditions.more=conditions.more==group.id?'':group.id">
                      <div class="px-2 rounded more-numbers-tag">
                        <small>+ {{ group.scheduler_numbers.length-1 }} more</small>
                      </div>
                    </div>
                  </div>
                  <template v-if="conditions.more==group.id">
                    <template v-for="(did, index) in group.scheduler_numbers.filter((item,index)=>index>0)" >
                      <div class="mt-2" :key="did.scheduler_number" v-show="index >= start && index <= end" >
                        <div class="d-flex align-items-center sm-mar-left">
                          <span class="mr-2 d-flex align-items-center">
                            <img  class="country-img" width="45px" :src="did.scheduler_number | number_formater | flag_icon" />
                          </span>
                          {{ did.scheduler_number | number_formater }}
                        </div>
                      </div>
                    </template>
                  </template>
                </div>
                <span v-else class="text-center"> - </span>
              </td>
              <td class="dialer-has-sort dialer-col-right">{{group.holidays || 0}}</td>
            </tr>
          </template>
          <template #loading>
          <tr v-for="n in 5" :key="n">
            <td>
              <div class="latestShimmerDesign flex-1" style="height:19px;width:100%"></div>
            </td>
            <td>
              <div class="latestShimmerDesign flex-1" style="height:19px;width:100%"></div>
            </td>
            <td>
              <div class="latestShimmerDesign flex-1" style="height:19px;width:100%"></div>
            </td>
          </tr>
        </template>
        </vb-table>
      </div>
    </modal>
    <CreatePublicHolidayGroupModal @created="fetchGroups()" />
    <UpdatePublicHolidayGroupModal @updated="fetchGroups()" @removed="fetchGroups()" />
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';
import CreatePublicHolidayGroupModal from './CreatePublicHolidayGroupModal.vue';
import UpdatePublicHolidayGroupModal from './UpdatePublicHolidayGroupModal.vue';
import UpdatePublicHolidayGroup from './UpdatePublicHolidayGroup.vue';
import moment from 'moment-timezone';
import { CountryName2 } from '@/utils/flags';
export default {
  name: 'PublicHolidayGroupsModal',
  components: {
    CreatePublicHolidayGroupModal,
    UpdatePublicHolidayGroupModal,
    UpdatePublicHolidayGroup,
  },
  inject: [
    'appNotify',
  ],
  props: {
    modalName: {
      type: String,
      default: 'PublicHolidayGroups',
    },
  },
  data() {
    return {
      api: {
        fetch_groups: this.$helperFunction.apiInstance({  }),
      },
      response: {
        groups: [],
      },
      conditions: {
        more: ''
      }
    }
  },
  computed: {
    ...mapGetters([
      'getIsMobile',
    ]),
  },
  methods: {
    onBeforeOpen(){
      this.fetchGroups()
    },
    onBeforeClose(){
      this.api.fetch_groups.reset()
      this.response.groups=[]
      this.conditions.more=''
    },
    async fetchGroups() {
      if(this.api.fetch_groups.send) return;
      try {
        this.api.fetch_groups.send=true
        const tz = moment().tz()
        const country = moment.tz(tz)._z.countries()?.[0]
        const { data } = await VOIP_API.endpoints.holiday_schedule.list({
          timezone: tz,
          country: CountryName2[country],
        })
        this.response.groups=data ?? []
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_groups.send=false
      }
    },
  },
}
</script>

<style>

</style>