import { VOIP_API } from "../../index"


export const CHAT_UNIVERSAL_AI_API_ENDPOINTS = {
  question(data={}){
    return VOIP_API.axios.universal_ai.v1.request({
      url: `/chat/question`,
      method: 'POST',
      data,
    })
  },
  summary(data={}){
    return VOIP_API.axios.universal_ai.v1.request({
      url: `/chat/summary`,
      method: 'POST',
      data,
    })
  },
  keywords(data={}){
    return VOIP_API.axios.universal_ai.v1.request({
      url: `/chat/keywords`,
      method: 'POST',
      data,
    })
  },
  cancelProcess(task_id='',data={}){
    return VOIP_API.axios.universal_ai.v1.request({
      url: `/chat/cancel-process/${task_id}`,
      method: 'POST',
      data,
    })
  },
}