var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "nowRightSideModal right_side_popup dialer_animation",
    attrs: {
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header pt-0 d-block"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Update List ")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('a', {
    staticClass: "newCloseButton",
    attrs: {
      "id": "CloseModalButton"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)], 1)])]), _c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.updateSheet();
      }
    }
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.update_sheet.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.update_sheet.error_message))]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mb-20px"
  }, [_c('label', [_vm._v("List name")]), _c('b-input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "placeholder": "Enter list name"
    },
    model: {
      value: _vm.forms.update_sheet.name,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update_sheet, "name", $$v);
      },
      expression: "forms.update_sheet.name"
    }
  }), _vm.$v.forms.update_sheet.name.$dirty && _vm.$v.forms.update_sheet.name.$invalid ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_sheet.name.required ? _c('span', [_vm._v("Name is required")]) : !_vm.$v.forms.update_sheet.name.minLength ? _c('span', [_vm._v("* Name is should be minimum " + _vm._s(_vm.$v.forms.update_sheet.name.$params.minLength.min) + " character")]) : !_vm.$v.forms.update_sheet.name.maxLength ? _c('span', [_vm._v("* Name is can be maximum " + _vm._s(_vm.$v.forms.update_sheet.name.$params.maxLength.max) + " character")]) : !_vm.$v.forms.update_sheet.name.valid ? _c('span', [_vm._v("* Name can be number and alphabet")]) : _vm._e()]) : _vm._e()], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mb-20px"
  }, [_c('label', [_vm._v("Description")]), _c('b-textarea', {
    staticClass: "w-100",
    attrs: {
      "placeholder": "Enter description"
    },
    model: {
      value: _vm.forms.update_sheet.desc,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update_sheet, "desc", $$v);
      },
      expression: "forms.update_sheet.desc"
    }
  }), _vm.$v.forms.update_sheet.desc.$dirty && _vm.$v.forms.update_sheet.desc.$invalid ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }) : _vm._e()], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mb-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Assign to")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" " + _vm._s(_vm.forms.update_sheet.assign_to.length == 0 ? 'Select User or Team' : `${_vm.forms.update_sheet.assign_to.length} User or Team`) + " ")]), _vm.$v.forms.update_sheet.assign_to.$dirty && _vm.$v.forms.update_sheet.assign_to.$invalid ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop mt-10px"
  }, [!_vm.$v.forms.update_sheet.assign_to.required ? _c('span', [_vm._v("Assign to is required")]) : _vm._e()]) : _vm._e()]), _c('button', {
    staticClass: "newButton sm-mar-left",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-SelectExtensions`, {
          accountcodes: _vm.forms.update_sheet.assign_to
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Assign")])], 1)])]), _c('button', {
    staticClass: "fullWidthDoneButton mt-20px",
    attrs: {
      "type": "submit"
    }
  }, [[_vm._v("Update")]], 2)])], 1), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Export sheet")]), _c('button', {
    staticClass: "newButton sm-mar-left",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.exportSheet(_vm.importedSheet);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-exportFile-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Export")])], 1)]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Delete this sheet?")]), _c('button', {
    staticClass: "newDeleteButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.deleteSheet(_vm.importedSheet);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-delete-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDeleteButton-textPart"
  }, [_vm._v("Delete")])], 1)])])])]), _c('SelectExtensionsModal', {
    attrs: {
      "modalName": `${_vm._uid}-SelectExtensions`,
      "extensionTypes": ['USER', 'TEAM'],
      "allowMultiSelect": true
    },
    on: {
      "update": function ($event) {
        _vm.forms.update_sheet.assign_to = $event.selected.accountcodes;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }