<template>
  <modal 
    :name="modalName" 
    class="dialer_animation right_side_popup addExtension-voicemailModal addRemove_userModal m-w-700 bigSquareCheckbox min-width-50percent-modal" 
    :scrollable="true" 
    height="auto" 
    @before-open="onBeforeOpen($event)" 
    @before-close="onBeforeClose()" 
  >
    <section> 
      <div class="dialer-edit-header d-block">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h2 class="dialer-settings-title newer mb-0"> Forward call</h2>
          </div>
          <div class="dialer-edit-actions">
            <button class="newDoneButton" type="button" @click="$modal.hide(modalName)">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <div class="vm--modal-text">Choose where unanswered calls should be forwarded.</div>
      </div>
      <div class="latestGreyBox-9-9-2023">
        <div class="whiteBoxWithBorderInsideGreyBox withOutPadding">
          <b-form-group  style="position: relative;pointer-events:auto;">
            <div class="w-100">
              <!-- keep ringing -->
              <template v-if="condition.is_ringing_show">
                <b-form-radio @change="forms.forwarding_setting.forward_note='off';updateForwardingSetting();" class="whiteBoxWithBorderInsideGreyBox-row withHover forHolidayFix checkBoxOrRadioInside position-relative makeCheckBoxesSquare" :class="forms.forwarding_setting.forward_note==='off'?'active':''" v-model="forms.forwarding_setting.forward_note" name="some-radios" value="off">
                  <div class="w-100 dialer-call-option-c withRadio" :class="{'active': forms.forwarding_setting.forward_note === 'off' }">
                    <div class="latestGreyBox-heading-main">{{callQueue ? 'Hangup' : 'Keep ringing'}}</div>
                    <div v-if="forms.forwarding_setting.forward_note === 'off'" class="latestGreyBox-descriptionText">Call forwarding is off</div>
                    <div v-else class="latestGreyBox-descriptionText">Call forwarding is off</div>
                  </div>
                </b-form-radio>
              </template>
              <!-- voicemail -->
              <b-form-radio 
                v-if="condition.is_voicemail_show"
                @change="forms.forwarding_setting.forward_note='voicemail';updateForwardingSetting();" 
                class="whiteBoxWithBorderInsideGreyBox-row withHover checkBoxOrRadioInside forHolidayFix position-relative makeCheckBoxesSquare" 
                :class="forms.forwarding_setting.forward_note==='voicemail'?'active':''" 
                v-model="forms.forwarding_setting.forward_note" 
                name="some-radios" 
                value="voicemail"
              >
                <div class="withRadio" :class="{'active':forms.forwarding_setting.forward_note === 'voicemail'}">
                  <div class="latestGreyBox-heading-main">Ask to leave a voicemail</div>
                  <div v-if="forms.forwarding_setting.forward_note === 'voicemail'" class="latestGreyBox-descriptionText">
                    <template v-if="condition.is_sec_show">
                      If phone rings for 
                      <span class="color-primary text-underline" @click="$modal.show('CallForwardingDurationPickerModal',{ value: forms.forwarding_setting.timeout })">{{forms.forwarding_setting.timeout}} seconds, </span> 
                    </template>
                    call will be forwarded to a voicemail of your choice.
                  </div>
                  <div v-else class="latestGreyBox-descriptionText">
                    <template v-if="condition.is_sec_show">
                      If phone rings for 
                      <span>20 seconds, </span> 
                    </template>
                    call will be forwarded to a voicemail of your choice.
                  </div>
                </div>
              </b-form-radio>
              <!-- extension -->
              <b-form-radio @change="forms.forwarding_setting.forward_note='extension';updateForwardingSetting();" class="whiteBoxWithBorderInsideGreyBox-row forHolidayFix withHover checkBoxOrRadioInside position-relative makeCheckBoxesSquare" :class="forms.forwarding_setting.forward_note==='extension'?'active':''" v-model="forms.forwarding_setting.forward_note" name="some-radios" value="extension">
                <div class="withRadio w-100 dialer-call-option-c" :class="{'active':forms.forwarding_setting.forward_note === 'extension'}">
                  <div class="latestGreyBox-heading-main">Forward to a user, team or IVR menu</div>
                  <div v-if="forms.forwarding_setting.forward_note === 'extension'" class="latestGreyBox-descriptionText">
                    <template v-if="condition.is_sec_show">
                      If phone rings for 
                      <span class="color-primary text-underline" @click="$modal.show('CallForwardingDurationPickerModal',{ value: forms.forwarding_setting.timeout })">{{forms.forwarding_setting.timeout}} seconds, </span> 
                    </template>
                    call will be forwarded to 
                    <!-- <span v-if="forms.forwarding_setting.extension_name" @click="$modal.show('CallForwardingSelectExtensionModal', { account: forms.forwarding_setting.forward_number, type: 'setting', })" class="color-primary text-underline">{{forms.forwarding_setting.extension_name}}</span> -->
                    <span v-if="forms.forwarding_setting.forward_number" @click="$modal.show('CallForwardingSelectExtensionModal', { account: forms.forwarding_setting.forward_number, type: 'setting', })" class="color-primary text-underline">
                      <template v-if="forms.forwarding_setting.extension_name">{{forms.forwarding_setting.extension_name}}</template>
                      <Info v-else :only_name="true" :id="forms.forwarding_setting.forward_number" />
                    </span>
                    <span v-else class="color-primary text-underline cursor_pointer" @click="$modal.show('CallForwardingSelectExtensionModal', { account: forms.forwarding_setting.forward_number, type: 'setting', })">
                      select user
                    </span>
                  </div>
                  <div v-else class="latestGreyBox-descriptionText">
                    <template v-if="condition.is_sec_show">
                      If phone rings for 
                      <span>20 seconds, </span> 
                    </template>
                    call will be forwarded to 
                    <span>a extension</span>
                    of your choice.
                  </div>
                  <p class="error-red" v-if="forms.forwarding_setting.forward_note === 'extension' && forms.forwarding_setting.submitted && $v.forms.forwarding_setting.extension_name.$invalid">
                    <span v-if="!$v.forms.forwarding_setting.extension_name.required">* extension is required</span>
                  </p>
                </div>
              </b-form-radio>
              <!-- number -->
              <div
                v-b-tooltip :title="getUserPermissions.redirecting_to_another_number?'':'Your package does not support this feature'"
                :class="!getUserPermissions.advanced_call_handling ? 'for-disable' : ''"
              >
                <b-form-radio :disabled="!getUserPermissions.redirecting_to_another_number" @change="forms.forwarding_setting.forward_note='number';updateForwardingSetting();" class="whiteBoxWithBorderInsideGreyBox-row withHover checkBoxOrRadioInside forHolidayFix position-relative makeCheckBoxesSquare mb-0" :class="forms.forwarding_setting.forward_note==='number'?'active':''" v-model="forms.forwarding_setting.forward_note" name="some-radios" value="number">
                  <div class="withRadio w-100 dialer-call-option-c" :class="{'active':forms.forwarding_setting.forward_note === 'number'}">
                    <div class="latestGreyBox-heading-main">Forward to another number</div>
                    <div v-if="forms.forwarding_setting.forward_note === 'number'" class="latestGreyBox-descriptionText">
                      <template v-if="condition.is_sec_show">
                        If phone rings for 
                        <span class="color-primary text-underline" @click="$modal.show('CallForwardingDurationPickerModal',{ value: forms.forwarding_setting.timeout })">{{forms.forwarding_setting.timeout}} seconds, </span> 
                      </template>
                      call will be forwarded to 
                      <span 
                        class="color-primary text-underline" 
                        :class="{
                          'text-underline':!forms.forwarding_setting.forward_number
                        }"  
                        @click="$modal.show('CallForwardingCallForwardingNumberInputModal',{
                          number: forms.forwarding_setting.forward_number,
                        })"
                      >{{ (forms.forwarding_setting.forward_number || 'Add number') | number_formater }}</span>
                    </div>
                    <div v-else class="latestGreyBox-descriptionText">
                      <template v-if="condition.is_sec_show">
                        If phone rings for 
                        <span>20 seconds, </span> 
                      </template>
                      call will be forwarded to a number of your choice
                    </div>
                    <p class="error-red" v-if="forms.forwarding_setting.forward_note === 'number' && forms.forwarding_setting.submitted && $v.forms.forwarding_setting.forward_number.$invalid">
                      <span v-if="!$v.forms.forwarding_setting.forward_number.required">* number is required</span>
                    </p>
                  </div>
                </b-form-radio>
              </div>
            </div>
          </b-form-group>
        </div>
        <ForwardSourceCallerID v-if="forms.forwarding_setting.forward_note=='number' && data.accountcode" :accountcode="data.accountcode" />
      </div>
      <ScheduleAssignModal 
        :modalName="`CallForwardingSelectExtensionModal`" 
        :account="data.account"
        @interface="forms.forwarding_setting.extension_name=$event.accountname;forms.forwarding_setting.forward_number=$event.account;updateForwardingSetting();" 
      />
      <DurationPickerModal :modalName="`CallForwardingDurationPickerModal`" @time="forms.forwarding_setting.timeout=$event.seconds;updateForwardingSetting();" />
      <CallForwardingNumberInputModal :modalName="`CallForwardingCallForwardingNumberInputModal`" @number="forms.forwarding_setting.forward_number=$event.ber;updateForwardingSetting();"/>
    </section>
  </modal>
</template>

<script>
import { 
  // minLength, 
  // maxLength, 
  requiredIf 
} from "vuelidate/lib/validators";
import ScheduleAssignModal from "../Modals/ScheduleAssignModal.vue";
import DurationPickerModal from "../Modals/DurationPickerModal.vue";
import CallForwardingNumberInputModal from "../Modals/CallForwardingNumberInputModal.vue";
import ForwardSourceCallerID from "../widgets/ForwardSourceCallerID.vue";
import Info from "../Lists/Info.vue";
import { mapGetters } from 'vuex';
export default {
  name: "CallForwardSettingModal",
  components: {
    ScheduleAssignModal,
    DurationPickerModal,
    CallForwardingNumberInputModal,
    ForwardSourceCallerID,
    Info,
  },
  props: {
    modalName: {
      type: String,
      default: "CallForwardingSettingModal",
    },
  },
  data() {
    return {
      forms: {
        forwarding_setting: this.$helperFunction.formInstance({
          data: {
            forward_setting: "",
            forward_note: "",
            forward_number: "",
            timeout: 0,
            extension_name: "",
            number: "",
          },
        }),
        reset(){
          this.forwarding_setting?.reset?.()
        },
      },
      data: this.$helperFunction.stateInstance({
        data: {
          account: '',
          accountcode: '',
        },
      }),
      condition: this.$helperFunction.stateInstance({
        data: {
          is_ringing_show: true,
          is_voicemail_show: true,
          is_sec_show: true,
        },
      }),
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getVoipNumbers',
      'getVoipCallQueuesAlises'
    ]),
    callQueue(){ return this.getVoipCallQueuesAlises[this.data.account] },
  },
  validations: {
    forms: {
      forwarding_setting: {
        forward_number: {
          required: requiredIf(function(){
            return ['extension','number'].includes(this.forms.forwarding_setting.forward_note)
          }),
          // minLength: minLength(8),
          // maxLength: maxLength(15),
        },
        extension_name: {
          required: requiredIf(function(){
            return ['extension'].includes(this.forms.forwarding_setting.forward_note)
          }),
        }
      },
    },
  },
  watch: {
    "forms.forwarding_setting.forward_note"(new_val,old_val){
      if(old_val){
        this.forms.forwarding_setting.forward_number = '';
        this.forms.forwarding_setting.extension_name = '';
      }
      if(old_val=='off'){
        this.forms.forwarding_setting.timeout = 20;
      }
    },
  },
  methods: {
    onBeforeOpen(event){
      this.data.account=event?.params?.account ?? ''
      this.data.accountcode=event?.params?.accountcode ?? ''
      this.condition.is_ringing_show=event?.params?.is_ringing_show ?? true;
      this.condition.is_voicemail_show=event?.params?.is_voicemail_show ?? true;
      this.condition.is_sec_show=event?.params?.is_sec_show ?? true;
      const setting = event?.params?.setting ?? {}
      this.forms.forwarding_setting.forward_setting = setting?.forward_setting ?? "";
      this.forms.forwarding_setting.forward_note = setting?.forward_note=='none' ? 'off' : setting?.forward_note ?? "";
      this.forms.forwarding_setting.forward_number = setting?.forward_number ?? "";
      this.forms.forwarding_setting.timeout = setting?.timeout ?? 0;
      this.forms.forwarding_setting.extension_name = setting?.extension_name ?? "";
      this.forms.forwarding_setting.number = setting?.number ?? "";
    },
    onBeforeClose(){
      this.forms?.reset?.()
      this.data?.reset?.()
      this.condition?.reset?.()
    },
    updateForwardingSetting() {
      this.forms.forwarding_setting.submitted=true
      this.$v.forms.forwarding_setting.$touch()
      if(this.$v.forms.forwarding_setting.$invalid) return;
      this.$emit('updated',this.forms.forwarding_setting)
      this.forms.forwarding_setting.submitted=false
    },
  },
};
</script>

