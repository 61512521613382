var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: {
      'recording-started': _vm.recorded.audio.is_start,
      'not-hover': _vm.conditions.screen != _vm.screens.select
    }
  }, [_c('input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "upload-input",
    attrs: {
      "type": "file",
      "accept": "audio/mpeg, mp3/wav"
    },
    on: {
      "change": function ($event) {
        _vm.selectFile(_vm.getProperty($event, 'target.files[0]'));
      }
    }
  }), _vm.conditions.screen == _vm.screens.select ? _c('div', [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.localOptions.recording ? _c('b-button', {
    staticClass: "blueRecordingButton mr-12px",
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": function ($event) {
        _vm.recorded.audio.is_start = true;
      }
    }
  }, [_c('vb-icon', {
    staticClass: "recordIcon",
    attrs: {
      "icon": "recordModal-record-icon",
      "width": "38px",
      "height": "38px"
    }
  }), _vm._v(" Record ")], 1) : _vm._e(), _vm.localOptions.sound_library ? _c('b-button', {
    staticClass: "whiteRecordingButton mr-12px",
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-AudioInputComponent`, {
          group: 'announcement'
        });
      }
    }
  }, [_c('vb-icon', {
    staticClass: "uploadIcon",
    attrs: {
      "icon": "recordModal-library-icon",
      "width": "38px",
      "height": "38px"
    }
  }), _vm._v(" Sound library ")], 1) : _vm._e(), _vm.localOptions.text_to_speech ? _c('b-button', {
    staticClass: "whiteRecordingButton mr-12px",
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.text_to_speech;
      }
    }
  }, [_c('vb-icon', {
    staticClass: "uploadIcon",
    attrs: {
      "icon": "recordModal-textToSpeech-icon",
      "width": "38px",
      "height": "38px"
    }
  }), _vm._v(" Text to speech ")], 1) : _vm._e(), _c('b-button', {
    staticClass: "whiteRecordingButton",
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": function ($event) {
        return _vm.$refs['upload-input'].click();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "uploadIcon",
    attrs: {
      "icon": "recordModal-upload-icon",
      "width": "38px",
      "height": "38px"
    }
  }), _vm._v(" Upload ")], 1)], 1), _c('div', {
    staticClass: "RecordingLikeSomething-descriptionText mt-16px"
  }, [_vm.localOptions.recording && _vm.localOptions.show_text ? _c('span', [_vm._v("Maximum length for recording is 60 seconds.")]) : _vm._e(), _vm._v(" Maximum file size 5MB ")])]) : _vm._e(), _vm.conditions.screen == _vm.screens.recording ? _c('div', [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "divLike-blueRecordingButton mr-12px wd-40"
  }, [_c('vb-icon', {
    staticClass: "recordIcon",
    attrs: {
      "icon": "recordModal-stopRecording-icon",
      "width": "38px",
      "height": "38px"
    },
    on: {
      "click": function ($event) {
        _vm.recorded.audio.is_start = false;
      }
    }
  }), _c('div', {
    staticClass: "frequency-wave",
    attrs: {
      "id": `${_vm._uid}-frequency-wave`
    }
  }, _vm._l(_vm.waves, function (data, index) {
    return _c('div', {
      key: index,
      attrs: {
        "id": `${_vm._uid}-frequency-wave-${index}`,
        "data-value": data
      }
    });
  }), 0)], 1), _c('b-button', {
    staticClass: "whiteCancelButton",
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.select;
        _vm.recorded.audio.is_start = false;
      }
    }
  }, [_c('vb-icon', {
    staticClass: "uploadIcon",
    attrs: {
      "icon": "recordModal-cancel-icon",
      "width": "38px",
      "height": "38px"
    }
  }), _vm._v(" Cancel ")], 1)], 1), _vm.localOptions.show_text ? _c('div', {
    staticClass: "RecordingLikeSomething-descriptionText mt-16px"
  }, [_vm._v("Maximum length for recording is 60 seconds. Maximum file size 5MB")]) : _vm._e()]) : _vm._e(), _vm.conditions.screen == _vm.screens.after_select_file ? _c('div', [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-audio-player', {
    staticClass: "mr-12px wd-40",
    attrs: {
      "src": _vm._f("fileToSrc")(_vm.audio.file),
      "design": 'blueAudio'
    }
  }), _c('b-button', {
    staticClass: "GreenSaveButton mr-12px",
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": function ($event) {
        return _vm.save();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "recordIcon",
    attrs: {
      "icon": "recordModal-save-icon",
      "width": "38px",
      "height": "38px"
    }
  }), _vm._v(" Save ")], 1), _c('b-button', {
    staticClass: "whiteCancelButton",
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": function ($event) {
        _vm.audio.file = null;
        _vm.conditions.screen = _vm.screens.select;
      }
    }
  }, [_c('vb-icon', {
    staticClass: "uploadIcon",
    attrs: {
      "icon": "recordModal-cancel-icon",
      "width": "38px",
      "height": "38px"
    }
  }), _vm._v(" Cancel ")], 1)], 1), _vm.localOptions.show_text ? _c('div', {
    staticClass: "RecordingLikeSomething-descriptionText mt-16px"
  }, [_vm._v(" Review your recorded audio ")]) : _vm._e()]) : _vm._e(), _vm.conditions.screen == _vm.screens.text_to_speech ? _c('div', [_c('div', [_c('div', {
    staticClass: "flex-fill wecomeGreetingsTextBoxSection"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100"
  }, [_c('label', [_vm._v("Write your message here")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.text_to_audio.text,
      expression: "forms.text_to_audio.text"
    }],
    staticClass: "w-100",
    attrs: {
      "rows": "4",
      "disabled": _vm.api.text_to_audio_preview.send
    },
    domProps: {
      "value": _vm.forms.text_to_audio.text
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.text_to_audio, "text", $event.target.value);
      }, function ($event) {
        _vm.$emit('update-text', {
          text: _vm.forms.text_to_audio.text
        });
        _vm.response.preview = {};
      }]
    }
  })])]), _c('ul', {
    staticClass: "list-unstyled theSection-buttonsSection d-flex align-items-center pl-0"
  }, [_c('li', [_c('b-button', {
    staticClass: "whiteCancelButton d-flex align-items-center mr-8px",
    attrs: {
      "disabled": _vm.api.text_to_audio_preview.send,
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`SelectVoiceForTextToSpeechModal_${_vm._uid}`);
      }
    }
  }, [_vm.selectedSpeaker ? _c('Info', {
    staticClass: "mr-12px",
    attrs: {
      "hide_popover": true,
      "image": _vm.selectedSpeaker.avatar_url,
      "name": _vm.selectedSpeaker.sudo_name || _vm.forms.text_to_audio.voice
    }
  }) : [_vm._v("Select voice")]], 2)], 1), _c('li', [_vm.response.preview.sound ? _c('vb-audio-player', {
    staticClass: "mr-8px w-auto",
    attrs: {
      "src": _vm.response.preview.sound,
      "design": 'OnlyPreviewAudio'
    }
  }) : _c('b-button', {
    staticClass: "blueRecordingButton mr-12px",
    attrs: {
      "disabled": _vm.api.text_to_audio_preview.send
    },
    on: {
      "click": function ($event) {
        return _vm.preview();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "recordIcon",
    attrs: {
      "icon": "circular-play-icon",
      "width": "38px",
      "height": "38px"
    }
  }), _vm.api.text_to_audio_preview.send ? _c('vb-spinner') : [_vm._v("Generate preview")]], 2)], 1), _c('li', [_vm.response.preview.sound ? _c('b-button', {
    staticClass: "GreenSaveButton mr-8px",
    on: {
      "click": function ($event) {
        return _vm.onConfirmTextToSpeech();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "recordIcon",
    attrs: {
      "icon": "recordModal-save-icon",
      "width": "38px",
      "height": "38px"
    }
  }), _vm._v(" Done ")], 1) : _vm._e()], 1)])])]) : _vm._e(), _vm.conditions.screen == _vm.screens.after_save ? _c('div', [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-audio-player', {
    staticClass: "mr-12px w-auto",
    attrs: {
      "src": _vm._f("fileToSrc")(_vm.audio.file),
      "design": 'blueAudio'
    }
  }), _vm.localOptions.recording ? _c('b-button', {
    staticClass: "redRedoButton mr-12px",
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": function ($event) {
        _vm.recorded.audio.is_start = true;
      }
    }
  }, [_c('vb-icon', {
    staticClass: "recordIcon",
    attrs: {
      "icon": "recordModal-redo-icon",
      "width": "38px",
      "height": "38px"
    }
  }), _vm._v(" redo ")], 1) : _vm._e(), _c('b-button', {
    staticClass: "whiteRecordingButton",
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": function ($event) {
        return _vm.$refs['upload-input'].click();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "uploadIcon",
    attrs: {
      "icon": "recordModal-upload-icon",
      "width": "38px",
      "height": "38px"
    }
  }), _vm._v(" Upload ")], 1)], 1), _vm.localOptions.show_text ? _c('div', {
    staticClass: "RecordingLikeSomething-descriptionText mt-16px"
  }, [_vm._v("Maximum length for recording is 60 seconds. Maximum file size 5MB")]) : _vm._e()]) : _vm._e(), _vm.localOptions.sound_library ? _c('RecordingListSelect', {
    attrs: {
      "modalName": `${_vm._uid}-AudioInputComponent`,
      "is_single_select": true
    },
    on: {
      "selectedRecordings": function ($event) {
        return _vm.onSelectedLibrary($event.data);
      }
    }
  }) : _vm._e(), _c('modal', {
    staticClass: "SelectVoiceForTextToSpeechModal",
    attrs: {
      "name": `SelectVoiceForTextToSpeechModal_${_vm._uid}`
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header position-relative top-0-imp w-100 flex-column align-items-start pt-0 mb-0"
  }, [_c('div', {
    staticClass: "flex-fill d-flex py-0 justify-content-between align-items-center w-100"
  }, [_c('h2', {
    staticClass: "dialer-edit-title newer mb-0"
  }, [_vm._v("Select your perfect voice")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`SelectVoiceForTextToSpeechModal_${_vm._uid}`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v("Personalise your welcome greeting messages with the perfect voice that suits your brand and connects with your callers. ")])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 hasScrollBarInsideIt",
    staticStyle: {
      "--total": "95vh",
      "--minusValue": "110px"
    }
  }, [_c('div', {
    staticClass: "SelectVoiceForTextToSpeechModal-whiteborderbox-container"
  }, [_vm.api.fetch_voices.send ? _vm._l(8, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "SelectVoiceForTextToSpeechModal-whiteborderbox"
    }, [_c('div', {
      staticClass: "voiceImage-container"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "55px",
        "width": "55px",
        "border-radius": "40% 40% 40% 40% / 40% 40% 40% 40%"
      }
    })]), _c('div', {
      staticClass: "latestShimmerDesign w-100",
      staticStyle: {
        "height": "18px",
        "margin-top": "12px"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "20px",
        "width": "40px",
        "margin-top": "10px"
      }
    })]);
  }) : _vm._l(_vm.speakers, function (speaker) {
    return _c('div', {
      key: `${speaker.name}`,
      staticClass: "SelectVoiceForTextToSpeechModal-whiteborderbox"
    }, [_c('div', {
      staticClass: "voiceImage-container"
    }, [_c('vb-audio-player', {
      attrs: {
        "src": speaker.sound,
        "design": 'onlyPlayPause'
      }
    }), _c('vb-avatar', {
      staticClass: "size55px",
      attrs: {
        "image": speaker.avatar_url
      }
    })], 1), _c('div', {
      staticClass: "SelectVoiceForTextToSpeechModal-whiteborderbox-heading"
    }, [_vm._v(_vm._s(speaker.sudo_name))]), _c('img', {
      staticClass: "country-img",
      attrs: {
        "width": "45px",
        "src": _vm._f("flagsByCountryCode")(speaker.flag)
      }
    }), _c('button', {
      staticClass: "newBlackButton w-100 mt-auto",
      class: {
        'selected': speaker.name == _vm.forms.text_to_audio.voice
      },
      attrs: {
        "type": "button"
      },
      on: {
        "click": function ($event) {
          _vm.forms.text_to_audio.voice = speaker.name;
          _vm.response.preview = {};
        }
      }
    }, [_vm._v(" " + _vm._s(speaker.name == _vm.forms.text_to_audio.voice ? 'Selected' : 'Select') + " ")]), speaker.name == _vm.forms.text_to_audio.voice ? _c('b-icon', {
      staticClass: "selectedVoiceIndicator",
      attrs: {
        "icon": "check-circle"
      }
    }) : _vm._e()], 1);
  })], 2)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }