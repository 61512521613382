var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "custom-100vh"
  }, [_vm.loading ? _c('LoaderMain') : [_c('KbInfoModal'), _c('NotificationPermissionsModal'), _c('div', {
    staticClass: "d-flex flex-column dialer-parent dialer-light-mode",
    class: {
      'dialer-dark-mode': !_vm.$store.getters.getIsMobile && _vm.isInCall,
      'nowOutOfCall': !_vm.$store.getters.getIsMobile && !_vm.isInCall,
      'blur': _vm.$store.state.global_conditions.blur_bg,
      'darkModeV2 v2': _vm.GET_LOCAL_SETTING_IS_DARK_MODE && !_vm.$store.getters.getIsMobile
    },
    attrs: {
      "id": "dialer-parent"
    }
  }, [_c('CommonCalls', {
    class: ['dialer-call-notification', _vm.isInCall ? 'incall-true' : ''],
    attrs: {
      "id": "videocomon"
    }
  }), _c('CallFeedbackModal'), [!_vm.$store.getters.isIntegration ? _c('CommonChat', {
    ref: "common_chat"
  }) : _vm._e(), !_vm.$store.getters.isIntegration ? _c('CommonPamarly', {
    ref: "common_pamarly"
  }) : _vm._e(), !_vm.$store.getters.isIntegration ? _c('CommonCRM', {
    ref: "common_crm"
  }) : _vm._e(), !_vm.$store.getters.isIntegration ? _c('KeyboardShortcuts') : _vm._e(), _c('div', {
    class: ['dialer-parent-inner', _vm.isInCall ? 'z-index-0-5' : ''],
    attrs: {
      "id": "dialer-parent-inner"
    }
  }, [_c('div', {
    staticClass: "d-flex h-100"
  }, [_vm.$store.getters.getIsMobile && !['video-call'].includes(_vm.activeTab) || !_vm.$store.getters.getIsMobile ? _c('Sidebar', {
    ref: "leftSideBar",
    attrs: {
      "id": "leftSidebar",
      "activeTab": _vm.activeTab
    },
    on: {
      "selectedTab": function ($event) {
        _vm.$store.state.global_conditions.tab = $event;
      }
    }
  }) : _vm._e(), _c('div', {
    staticClass: "dialer-main-center-area d-flex flex-column h-100",
    class: {
      'applyMargin dialer-screenSize-shrink': _vm.applyMargin === true,
      'ItIsInVideoCall': _vm.activeTab == 'video-call',
      'settingsIsOpened': _vm.activeTab == 'mobile_settings'
    }
  }, [_c('CommonComponent'), _vm.isDormant || _vm.isFreePlanDisabled ? [_c('div', {
    staticClass: "DormantAccountModal-container"
  }, [_c('div', {
    staticClass: "DormantAccountModal"
  }, [_c('div', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, [_c('div', {
    staticClass: "emptyScreenContainer border-0"
  }, [_c('img', {
    staticClass: "mt-50px",
    attrs: {
      "width": "20%",
      "height": "auto",
      "src": require('@/assets/images/emptyScreenImages/dormantAccount.png')
    }
  }), _c('div', {
    staticClass: "emptyScreenContainer-heading mt-5"
  }, [_vm._v(_vm._s(_vm.isFreePlanDisabled ? 'You can not use Free Plan' : 'Plan expired'))]), _c('div', {
    staticClass: "emptyScreenContainer-text w-75",
    staticStyle: {
      "max-width": "650px"
    }
  }, [_vm._v(" " + _vm._s(_vm.isFreePlanDisabled ? `Your plan has expired due to inactivity on your VoIP account for the past three months. To restore your services and continue enjoying our features, please upgrade your plan and renew your subscription.` : 'Your plan has expired due to inactivity on your VoIP account for the past three months. To restore your services and continue enjoying our features, please upgrade your plan and renew your subscription.') + " ")]), _c('button', {
    staticClass: "dialer-button dialer-button-primary mt-20px",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-SubscriptionPlanDetailModal`);
      }
    }
  }, [_vm._v("Upgrade plan")])])])])])] : [_c('keep-alive', [_vm.activeTab == 'homeCopy' ? _c('HomeCopy', {
    ref: "home-copy",
    class: {
      'new-dashboard-height': !_vm.$store.getters.getCurrentUser.is_number_purchased
    },
    attrs: {
      "dialer": true,
      "logs": !_vm.$store.getters.getIsMobile
    }
  }) : _vm._e()], 1), _c('keep-alive', [_vm.activeTab == 'chat' ? _c('Chat', {
    ref: "chat",
    class: {
      'new-dashboard-height': !_vm.$store.getters.getCurrentUser.is_number_purchased
    }
  }) : _vm._e()], 1), _vm.activeTab == 'schedule' ? _c('Scheduling', {
    ref: "schedule",
    class: {
      'new-dashboard-height': !_vm.$store.getters.getCurrentUser.is_number_purchased
    }
  }) : _vm._e(), _c('VideoCall', {
    ref: "video-call",
    style: `display:${_vm.activeTab == 'video-call' ? '' : 'none !important'}`
  }), _vm.activeTab == 'selectContext' ? _c('SelectContext', {
    ref: "selectContext"
  }) : _vm._e(), _vm.activeTab == 'callLogs' ? _c('HomeCopy', {
    ref: "callLogs",
    class: {
      'new-dashboard-height': !_vm.$store.getters.getCurrentUser.is_number_purchased
    },
    attrs: {
      "dialer": false,
      "logs": true
    }
  }) : _vm._e(), _vm.activeTab == 'user_profile' ? _c('UserProfilePage', {
    ref: "user_profile"
  }) : _vm._e(), _vm.activeTab == 'analytics' ? _c('Analytics', {
    ref: "analytics",
    class: {
      'new-dashboard-height': !_vm.$store.getters.getCurrentUser.is_number_purchased
    }
  }) : _vm._e(), _c('keep-alive', [_vm.activeTab == 'callDashboard' ? _c('CallDashboard', {
    ref: "call-dashboard"
  }) : _vm._e()], 1), _c('keep-alive', [_vm.activeTab == 'settings' ? _c('Settings', {
    ref: "settings",
    class: {
      'new-dashboard-height': !_vm.$store.getters.getCurrentUser.is_number_purchased
    }
  }) : _vm._e()], 1), _c('keep-alive', [_vm.activeTab == 'mobile_settings' ? _c('MobileSettings', {
    ref: "mobile_settings",
    class: {
      'new-dashboard-height': !_vm.$store.getters.getCurrentUser.is_number_purchased
    }
  }) : _vm._e()], 1), _vm.activeTab == 'QuickStartPage' ? _c('QuickStart') : _vm._e(), _vm.isHRMTab ? _c('HRM') : _vm._e(), _vm.isPamarlyTab ? _c('Pamarly') : _vm._e(), _vm.isCrmTab ? _c('CRM') : _vm._e(), _c('keep-alive', [_vm.activeTab == 'todo' ? _c('Todo', {
    ref: "todo"
  }) : _vm._e()], 1)]], 2), _vm.$store.getters.getIsMobile && !['mobile_settings', 'video-call'].includes(_vm.activeTab) || !_vm.$store.getters.getIsMobile ? _c('RightSideBar', {
    ref: "rightSideBar",
    attrs: {
      "activeTab": _vm.activeTab
    },
    on: {
      "selectedTab": function ($event) {
        _vm.$store.state.global_conditions.tab = $event;
      }
    }
  }) : _vm._e()], 1)])]], 2), _c('EditTasksModal', {
    attrs: {
      "modalName": 'GlobalEditTask'
    }
  })], _c('AppTour'), _c('CheckedServiceAvailableModal'), _c('SubscriptionPlanDetailModal', {
    attrs: {
      "modalName": `${_vm._uid}-SubscriptionPlanDetailModal`
    }
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }