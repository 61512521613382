<template>
  <div>
    <modal
      class="dialer_animation right_side_popup m-w-500 min-width-50percent-modal"
      width="500px"
      height="auto"
      :scrollable="true"
      name="EditRecordingModal"
      @before-open="beforeOpen"
      @closed="onClosed"
    >
      <section class="ct-user-new dialer-edit">
        <form class="dialer-form lg-mar-bottom" @submit.prevent="edit">
          <div class="dialer-edit-header">
            <div>
              <h2 class="dialer-settings-title newer mb-0">
                <vb-svg @click="$modal.hide('EditRecordingModal')" class="cursor_pointer_no_hover" name="leftLongArrow-icon" width="20" height="12" viewBox="0 0 30 16" stroke-width="1" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                Edit sound file
              </h2>
            </div>
            <div class="dialer-edit-actions">
              <!-- <a class="newCloseButton" @click="$modal.hide('EditRecordingModal')">
                <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                <span class="newCloseButton-textPart">Close</span>
              </a> -->
              <button class="newDoneButton" :disabled="api.edit.send">
                <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                  <vb-spinner v-if="api.edit.send" />
                  <template v-else>Done</template>
                </span>
              </button>
            </div>
          </div>
          <!-- Edit name section -->
          <div class="latestGreyBox-9-9-2023">
            <div class="whiteBGinputWithGreyRoundedBorder w-50">
              <label>Name</label>
              <input placeholder="Enter name" class="w-100" :disabled="api.edit.send || api.delete.send" min="1" type="text" v-model="forms.edit_sound.name" />
              <p v-if="forms.edit_sound.submitted && $v.forms.edit_sound.name.$invalid" class="text text-danger animated bounceIntop">
                <span v-if="!$v.forms.edit_sound.name.required">* name is required</span>
                <span v-else-if="!$v.forms.edit_sound.name.alphaNum">* Name can be only alphabet, number and space</span>
                <span v-else-if="!$v.forms.edit_sound.name.minLength">* Name should be minimum {{$v.forms.edit_sound.name.$params.minLength.min}} character</span>
                <span v-else-if="!$v.forms.edit_sound.name.maxLength">* Name can be maximum {{$v.forms.edit_sound.name.$params.maxLength.max}} character</span>
              </p>
            </div>
          
            <div class="mt-20px">
              <div class="whiteBoxWithBorderInsideGreyBox">
                <div class="whiteBoxWithBorderInsideGreyBox-row">
                  <div class="d-flex flex-column">
                    <div class="latestGreyBox-heading-main">Set as shared</div>
                    <div class="latestGreyBox-descriptionText">This sound file is {{forms.edit_sound_shared.shared ? 'shared' : 'unshared'}}</div>
                  </div>
                  <div v-b-tooltip :title="getUserPermissions.share_sound_file?'':'Your package does not support this feature'" class="w-fit-content">
                    <b-form-checkbox 
                      :disabled="api.edit.send || api.delete.send || api.update_shared_status.send || !getUserPermissions.share_sound_file"
                      ref="shared-sound-file" 
                      class="newerSwitch" 
                      name="check-button" 
                      switch 
                      :checked="forms.edit_sound_shared.shared" 
                      @change="updateSharedStatus($event)" 
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-20px">
              <div class="whiteBoxWithBorderInsideGreyBox">
                <div class="whiteBoxWithBorderInsideGreyBox-row">
                  <div class="d-flex flex-column">
                    <div class="latestGreyBox-heading-main">Delete this sound file?</div>
                  </div>
                  <button class="dialer-button dialer-button-delete-alt" type="button" :disabled="api.delete.send" @click="deleteRecording()">
                    <vb-spinner v-if="api.delete.send" />
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </modal>
  </div>
</template>

<script>
import { helpers, required } from "vuelidate/lib/validators";
import maxLength from 'vuelidate/lib/validators/maxLength';
import minLength from 'vuelidate/lib/validators/minLength';
import { mapGetters } from 'vuex';
import { VOIP_API } from '../../utils';
export default {
  name: "EditRecordingModal",
  data(){
    return {
      forms: {
        edit_sound: {
          name: '',
          reset(){
            this.name=''
            this.submitted = false
          },
          submitted: false,
        },
        edit_sound_shared: {
          shared: false,
          reset(){
            this.shared=false
          },
        },
        reset(){
          this.edit_sound.reset()
          this.edit_sound_shared.reset()
        },
      },
      api: {
        delete: this.$helperFunction.apiInstance(),
        edit: this.$helperFunction.apiInstance(),
        update_shared_status: this.$helperFunction.apiInstance(),
        reset(){
          this.delete?.reset?.()
          this.edit?.reset?.()
          this.update_shared_status?.reset?.()
        },
      },
      data: {
        sound: {},
        reset(){
          this.sound = {}
        },
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters(['getUserPermissions']),
  },
  validations: {
    forms: {
      edit_sound: {
        name: {
          required,
          alphaNum: helpers.regex('alphaNum',/^[a-zA-Z0-9 ]*$/i),
          maxLength: maxLength(20),
          minLength: minLength(3),
        },
      },
    },
  },
  methods: {
    beforeOpen(event){
      const sound = event?.params?.sound
      this.data.sound = sound
      this.forms.edit_sound.name=sound?.label ?? ''
      this.forms.edit_sound_shared.shared=!!sound?.shared
    },
    onClosed(){
      this.forms.reset()
      this.api.reset()
      this.data.reset()
    },
    edit(){
      let vm = this
      vm.forms.edit_sound.submitted=true
      vm.$v.forms.edit_sound.$touch();
      if (vm.$v.forms.edit_sound.$invalid || vm.api.edit.send) return;
      vm.api.edit.send = true
      VOIP_API.endpoints.recording.updateName(vm.data.sound.id,{
        accountcode: vm.$store.getters.getCurrentUser?.account,
        uid: vm.$store.getters.getCurrentUser?.uid,
        name: vm.forms.edit_sound.name,
        flag: 'portal_sound_update'
      }).then(()=>{
        this.appNotify({
          message: 'Successfully updated!',
          type: 'success',
        })
        vm.$emit('update-list')
      }).catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(()=>{
        vm.forms.edit_sound.submitted = false
        vm.api.edit.send = false
      })
    },
    updateSharedStatus(value) {
      let vm = this;
      if (vm.api.update_shared_status.send) return;
      vm.api.update_shared_status.send = true;
      VOIP_API.endpoints.recording.update({
        id: vm.data.sound.id,
      })
      .then(() => {
        vm.forms.edit_sound_shared.shared=value
        vm.$emit('update-list')
        vm.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        vm.$refs['shared-sound-file'].localChecked=!!vm.forms.edit_sound_shared.shared
        vm.api.update_shared_status.send = false;
      });
    },
    deleteRecording() {
      let vm = this;
      if (vm.api.delete.send) return;
      vm.$appConfirmation({
        title: "Warning",
        message: `Are you sure you want to delete this file.`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback({confirm}) {
          if (confirm) {
            vm.api.delete.send=true;
            VOIP_API.endpoints.recording.delete(vm.data.sound.id)
            .then(() => {
              vm.appNotify({
                message: "Successfully Deleted",
                type: "success",
              })
              vm.$emit('update-list')
              vm.$modal.hide('EditRecordingModal')
            })
            .catch((ex) => {
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            })
            .finally(() => {
              vm.api.delete.send=false
            });
          }
        },
      });
    },
  },
};
</script>
