var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-500 addUserModalV2",
    attrs: {
      "height": "auto",
      "name": _vm.modalName,
      "scrollable": true
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-user"
  }, [_c('div', {
    staticClass: "dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-form"
  }, [_c('div', {
    staticClass: "dialer-edit-header pt-0 pb-0 d-block"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Edit Invitation")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)], 1)]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v("Please provide the new user's details below and configure your account preferences.")])]), _c('div', {
    staticClass: "md-mar-vertical"
  }, [_c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.editInvitation();
      }
    }
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.edit_invitation.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.edit_invitation.error_message))]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dialer-input--error md-mar-top"
  }, [_c('label', [_vm._v("Email *")]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.edit_invitation.email,
      expression: "forms.edit_invitation.email"
    }],
    staticClass: "bg-white w-100",
    attrs: {
      "disabled": _vm.api.edit_invitation.send,
      "placeholder": "Enter Email",
      "type": "text"
    },
    domProps: {
      "value": _vm.forms.edit_invitation.email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.edit_invitation, "email", $event.target.value);
      }
    }
  })]), _vm.$v.forms.edit_invitation.email.$error || _vm.api.edit_invitation.validation_errors.to_email ? _c('p', {
    staticClass: "text text-small text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.edit_invitation.email.required ? _c('span', [_vm._v("* Email is required")]) : !_vm.$v.forms.edit_invitation.email.email ? _c('span', [_vm._v("* Email is not valid")]) : _vm._e(), _vm._l(_vm.api.edit_invitation.validation_errors.to_email, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()]), _c('div', {
    staticClass: "w-100 d-flex md-mar-top"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dialer-input--error w-50 pr-3"
  }, [_c('label', [_vm._v("First name *")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.edit_invitation.first_name,
      expression: "forms.edit_invitation.first_name"
    }],
    staticClass: "w-100 bg-white",
    attrs: {
      "disabled": _vm.api.edit_invitation.send,
      "maxlength": _vm.$v.forms.edit_invitation.first_name.$params.maxLength.max,
      "onkeypress": "return /^[A-Za-z]+$/.test(event.key)",
      "placeholder": "Enter first name",
      "type": "text"
    },
    domProps: {
      "value": _vm.forms.edit_invitation.first_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.edit_invitation, "first_name", $event.target.value);
      }
    }
  }), _vm.$v.forms.edit_invitation.first_name.$error || _vm.api.edit_invitation.validation_errors.display_name || _vm.api.edit_invitation.validation_errors.firstname ? _c('p', {
    staticClass: "text text-small text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.edit_invitation.first_name.required ? _c('span', [_vm._v("* First name is required")]) : !_vm.$v.forms.edit_invitation.first_name.maxLength ? _c('span', [_vm._v("* First name can be maximum " + _vm._s(_vm.$v.forms.edit_invitation.first_name.$params.maxLength.max) + " character")]) : !_vm.$v.forms.edit_invitation.first_name.alpha ? _c('span', [_vm._v("* First name must be only alphabets")]) : _vm._e(), _vm._l(_vm.api.edit_invitation.validation_errors.display_name, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  }), _vm._l(_vm.api.edit_invitation.validation_errors.firstname, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dialer-input--error w-50 pl-3"
  }, [_c('label', [_vm._v("Last name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.edit_invitation.last_name,
      expression: "forms.edit_invitation.last_name"
    }],
    staticClass: "w-100 bg-white",
    attrs: {
      "disabled": _vm.api.edit_invitation.send,
      "maxlength": _vm.$v.forms.edit_invitation.last_name.$params.maxLength.max,
      "onkeypress": "return /^[A-Za-z]+$/.test(event.key)",
      "placeholder": "Enter last name",
      "type": "text"
    },
    domProps: {
      "value": _vm.forms.edit_invitation.last_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.edit_invitation, "last_name", $event.target.value);
      }
    }
  }), _vm.$v.forms.edit_invitation.last_name.$error || _vm.api.edit_invitation.validation_errors.lastname ? _c('p', {
    staticClass: "text text-small text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.edit_invitation.last_name.maxLength ? _c('span', [_vm._v("* Last name can be maximum " + _vm._s(_vm.$v.forms.edit_invitation.last_name.$params.maxLength.max) + " character")]) : !_vm.$v.forms.edit_invitation.last_name.alpha ? _c('span', [_vm._v("* Last name must be only alphabets")]) : !_vm.$v.forms.edit_invitation.last_name.valid ? _c('span', [_vm._v("* Last name must have a space character")]) : _vm._e(), _vm._l(_vm.api.edit_invitation.validation_errors.lastname, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-end mb-2 lg-mar-top"
  }, [_c('button', {
    staticClass: "dialer-button dialer-button-primary w-100",
    attrs: {
      "type": "submit",
      "disabled": _vm.api.edit_invitation.send
    }
  }, [_vm.api.edit_invitation.send ? _c('vb-spinner') : [_vm._v("Submit")]], 2)])], 1)])])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }