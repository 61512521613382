var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup CheckSlotsModal",
    attrs: {
      "name": _vm.modalName,
      "clickToClose": false
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "closed": function ($event) {
        return _vm.onClosed();
      }
    }
  }, [_vm.api.check.send ? [_c('div', {
    staticClass: "w-100 p-5"
  }, _vm._l(3, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "d-flex align-items-center mb-4"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "150px",
        "width": "150px"
      }
    }), _c('div', {
      staticClass: "d-flex flex-column flex-fill ml-4"
    }, [_c('div', {
      staticClass: "latestShimmerDesign mb-4",
      staticStyle: {
        "height": "40px",
        "width": "80%"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign mb-3",
      staticStyle: {
        "height": "28px",
        "width": "50%"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "28px",
        "width": "60%"
      }
    })])]);
  }), 0)] : [_vm.slotAvailable ? [_c('div', {
    staticClass: "containerCC"
  }, [_c('div', {
    staticClass: "headerContainer mb-5"
  }, [_c('div', {
    staticClass: "headingText"
  }, [_vm._v("Licences")]), _c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "disabled": _vm.api.check.send
    },
    on: {
      "click": function ($event) {
        return _vm.cancel();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)]), _vm.isFreeNumber ? _c('div', {
    staticClass: "w-100"
  }, [_vm._v(" This is a Free Number ")]) : _vm.isAddon ? _c('div', {
    staticClass: "w-100"
  }, [_c('div', {
    staticClass: "latestTableDesign-withBlackBorders-again dialer-box alt table mb-0"
  }, [_c('table', {
    staticClass: "dialer-table"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Name")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Allowed")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Used")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Remaining")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Price")])]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-right"
  }, [_c('span', [_vm._v("Billing Cycle")])])])]), _c('tbody', [_c('tr', [_c('td', {}, [_c('span', [_vm._v(_vm._s(_vm.data.service))])]), _c('td', {}, [_c('span', [_vm._v(_vm._s(_vm._f("show_positive_value")(_vm._f("get_property")(_vm.response, 'check_service.slots.PlanAddonAllowedProductCount', 0))))])]), _c('td', {}, [_c('span', [_vm._v(_vm._s(_vm._f("show_positive_value")(_vm._f("get_property")(_vm.response, 'check_service.slots.PlanAddonUsedProductCount', 0))))])]), _c('td', {}, [_c('span', [_vm._v(_vm._s(_vm._f("show_positive_value")(_vm._f("get_property")(_vm.response, 'check_service.slots.PlanAddonRemainingProductCount', 0))))])]), _c('td', {}, [_c('span', [_vm._v(_vm._s(_vm._f("to_fixed")(_vm._f("get_property")(_vm.response, 'check_service.price', 0))))])]), _c('td', {
    staticClass: "dialer-col-right"
  }, [_c('span', [_vm._v(_vm._s(_vm._f("get_property")(_vm.response, 'check_service.product_detail.plan.plan_billing_period')))])])])])])])]) : _c('div', {
    staticClass: "w-100"
  }, [_c('div', {
    staticClass: "dialer-box alt table latestTableDesign-withBlackBorders-again"
  }, [_c('table', {
    staticClass: "dialer-table"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Name")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Allowed")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Used")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Remaining")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Price")])])])]), _c('tbody', [_c('tr', [_c('td', {}, [_c('span', [_vm._v(_vm._s(_vm.data.service))])]), _c('td', {}, [_c('span', [_vm._v(_vm._s(_vm._f("show_positive_value")(_vm._f("get_property")(_vm.response, 'check_service.slots.PlanAllowedProductCount', 0))))])]), _c('td', {}, [_c('span', [_vm._v(_vm._s(_vm._f("show_positive_value")(_vm._f("get_property")(_vm.response, 'check_service.slots.PlanUsedProductCount', 0))))])]), _c('td', {}, [_c('span', [_vm._v(_vm._s(_vm._f("show_positive_value")(_vm._f("get_property")(_vm.response, 'check_service.slots.PlanRemainingProductCount', 0))))])]), _c('td', {}, [_c('b-icon', {
    attrs: {
      "id": "freeSlots",
      "icon": "check2-circle",
      "variant": "success",
      "font-scale": "1.5"
    }
  }), _c('b-popover', {
    ref: "popover",
    attrs: {
      "target": "freeSlots",
      "triggers": "hover"
    }
  }, [_c('small', [_vm._v("This resource is available in your current package.")])])], 1)])])])])]), _c('div', {
    staticClass: "proceedButtonContainer"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api.check.send
    },
    on: {
      "click": function ($event) {
        return _vm.proceed();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Proceed")])], 1)])])] : [_c('div', {
    staticClass: "containerCC"
  }, [_c('div', {
    staticClass: "headerContainer"
  }, [_c('div', {
    staticClass: "headingText"
  }, [_vm._v("Check Slots")]), _c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "disabled": _vm.api.check.send
    },
    on: {
      "click": function ($event) {
        return _vm.cancel();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px w-100"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main w-100 text-center mt-20px px-4"
  }, [_vm._v(" You don't have any slot available for adding this service. Kindly upgrade your package to get it. ")]), _c('div', {
    staticClass: "proceedButtonContainer"
  }, [_c('button', {
    staticClass: "dialer-button dialer-button-primary",
    attrs: {
      "disabled": _vm.api.check.send
    },
    on: {
      "click": function ($event) {
        return _vm.ok();
      }
    }
  }, [_vm._v("ok")])])])])]]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }