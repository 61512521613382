<template>
  <div :id="id" class="dialer-box alt position-relative">
    <div class="dialer-outofhours v2">
      <div class="whiteBoxWithBorderInsideGreyBox-row pb-0 EnableBusinessHoursSwitchRow min-height-unset" :class="`${conditions.toggle?'b-0':''}`">
        <div class="d-flex flex-column">
          <div class="dialer-box-header mb-0">Enable business hours</div>
          <div class="dialer-box-text">Select how you will like to manage calls outside office/working hours</div>
        </div>
        <div 
          v-if="conditions.toggle"
          v-b-tooltip :title="getUserPermissions.out_of_hours?'':'Your package does not support this feature'"
          :class="{ 
            'for-disable': !getUserPermissions.out_of_hours 
          }"
        >
          <template v-if="api.get_setting.send">
            <div class="latestShimmerDesign" style="height:34px;width:60px;border-radius: 25px"></div>
          </template>
          <b-form-checkbox 
            v-else
            :disabled="!getUserPermissions.out_of_hours" 
            class="newerSwitch" 
            :checked="response.out_of_hours" 
            @change="updateOutOfHours($event)" 
            switch  
          />
        </div>
      </div>
      <template v-if="api.update_out_of_hours.send">
        <div class="whiteBoxWithBorderInsideGreyBox-row b-0 pb-0 align-items-start">
          <div class="d-flex flex-column flex-fill">
            <div class="latestShimmerDesign" style="height:24px;width:50%;"></div>
            <div class="latestShimmerDesign mt-3" style="height:19px;width:40%;"></div>
            <div class="latestShimmerDesign mt-1" style="height:17px;width:30%;"></div>
          </div>
          <div class="latestShimmerDesign" style="height:50px;width:100px;"></div>
        </div>
        <div class="latestShimmerDesign md-mar-top" style="height:24px;width:50%;"></div>  
        <div class="whiteBoxWithBorderInsideGreyBox withBG-F9F9F9 withOutPadding mt-20px">
          <div class="whiteBoxWithBorderInsideGreyBox-row withHover" v-for="n in 7" :key="n.id" >
            <div class="latestShimmerDesign" style="height:23px;width:150px;"></div>
            <div class="d-flex align-items-center">
              <div class="d-flex flex-column align-items-end text-right mr-2">
                <div class="latestShimmerDesign" style="height:22px;width:150px;"></div>
                <div class="latestShimmerDesign mt-2" style="height:20px;width:160px;"></div>
                <div class="latestShimmerDesign mt-1" style="height:17px;width:180px;"></div>
              </div>
              <b-icon class="iconIconArrow" icon="chevron-right" style="" variant="dark" font-scale="1.5"/>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div v-if="response.out_of_hours && conditions.default" class="whiteBoxWithBorderInsideGreyBox-row b-0 pb-0 align-items-start">
          <div class="d-flex flex-column">
            <div class="latestGreyBox-heading-main mb-0">What happens to calls after business hours ?</div>
            <template v-if="forwardSetting.forward_note == 'none'">
              <div class="latestGreyBox-descriptionText-18px">Keep ringing</div>
              <div class="latestGreyBox-descriptionText-14px">Call forwarding is off</div>
            </template>
            <template v-if="forwardSetting.forward_note == 'voicemail'">
              <div class="latestGreyBox-descriptionText-18px">Ask to leave a voicemail</div>
              <div class="latestGreyBox-descriptionText-14px">Call will be forward to voicemail</div>
            </template>
            <template v-if="forwardSetting.forward_note == 'extension'">
              <div class="latestGreyBox-descriptionText-18px">Forward to a user, team or IVR menu</div>
              <div class="latestGreyBox-descriptionText-14px">
                Call will be forward to
                {{forwardSetting.extension_name || ''}}
              </div>
            </template>
            <template v-if="forwardSetting.forward_note == 'number'">
              <div class="latestGreyBox-descriptionText-18px">Forward to another number</div>
              <div class="latestGreyBox-descriptionText-14px">
                Call will be forward to 
                {{forwardSetting.forward_number || ''}}
              </div>
            </template>
          </div>
          <div class="w-fit-content">
            <div 
              v-b-tooltip :title="getUserPermissions.out_of_hours?'':'Your package does not support this feature'"
              :class="!getUserPermissions.out_of_hours ? 'for-disable' : ''"
            >
              <button 
                :disabled="!getUserPermissions.out_of_hours"
                class="newButton" 
                @click="$modal.show(`${_uid}-CallForwardSettingModal`,{ 
                  account, 
                  accountcode: account, 
                  setting: forwardSetting, 
                  is_ringing_show: false, 
                  is_sec_show: false 
                })"
              >
                <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                <span class="newButton-textPart">Change</span>
              </button>
            </div>
          </div>
        </div>
        <template v-if="response.out_of_hours && conditions.days">
          <div class="dialer-box-header md-mar-top">Set your business hours</div>
          <div class="newBusinessHours">
            <div 
              class="newBusinessHours-row"
              v-for="(day_setting, day) in response.schedules"
              :key="day" 
              v-b-tooltip :title="getUserPermissions.out_of_hours?'':'Your package does not support this feature'"
            >
              <div class="newBusinessHours-row-left">
                <div class="newBusinessHours-row-headingContainer">
                  <div class="newBusinessHours-row-heading">{{day | filterDayText}}</div>
                </div>
              </div>
              <div class="newBusinessHours-row-right">
                <div class="newBusinessHours-row-right-row" @click="!getUserPermissions.out_of_hours ? '' : $modal.show(`${_uid}-EditOutOfHoursDayModal`,{ day: day })">
                  <div class="newBusinessHours-row-right-row-left">
                    <div class="newBusinessHours-row-right-row-left-button">
                      <vb-icon icon="businessHours-editSchedulePencil-icon" width="8.762px" height="8.761px"/>
                      <span>Edit</span>
                    </div>
                  </div>
                  <div class="newBusinessHours-row-right-row-right">
                    <div v-if="!day_setting" :class="`${day}`" class="newBusinessHours-row-right-row-right-heading">Available all day</div>
                    <div v-else-if="day_setting.type=='unavailable'" :class="`${day}`" class="red newBusinessHours-row-right-row-right-heading">Unavailable all day</div>
                    <div v-else :class="`${day}`" class="newBusinessHours-row-right-row-right-heading d-flex flex-column">
                      <span v-for="(slot, index) in day_setting.duration" :key="index">{{ slot.start | twentyFourHourToAmPmFormat }} - {{ slot.end | twentyFourHourToAmPmFormat }}</span>
                    </div>
                    <template v-if="day_setting && day_setting.setting_type=='custom'">
                      <div v-if="day_setting.forward_type=='voicemail'" class="newBusinessHours-row-right-row-right-text">Call will be forward to voicemail</div>
                      <div v-else-if="day_setting.forward_type=='extension'" class="newBusinessHours-row-right-row-right-text">Call will be forward to {{day_setting.forward_name || ''}}</div>
                      <div v-else-if="day_setting.forward_type=='number'" class="newBusinessHours-row-right-row-right-text">Call will be forward to {{day_setting.forward_account || ''}}</div>
                      <div v-else class="newBusinessHours-row-right-row-right-text">Call forwarding is off</div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>
    </div>
    <CallForwardSettingModal 
      :modalName="`${_uid}-CallForwardSettingModal`" 
      @updated="updateDefaultSetting($event)" 
    />
    <EditOutOfHoursDayModal 
      :modalName="`${_uid}-EditOutOfHoursDayModal`" 
      :defaultForwardSetting="forwardSetting"
      :account="account" 
      :schedules="response.schedules" 
      @updated="response.schedules=$event;$emit('ofh-days-updated')" 
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { VOIP_API } from '../../utils'
import EditOutOfHoursDayModal from '../Modals/EditOutOfHoursDayModal.vue'
import CallForwardSettingModal from "../Modals/CallForwardSettingModal.vue";
import moment from 'moment-timezone';
export default {
  name: 'ScheduleSetting',
  components: {
    EditOutOfHoursDayModal,
    CallForwardSettingModal,
  },
  inject: [
    'getProperty','appNotify'
  ],
  props: {
    account: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    condition: {
      type: Object,
    },
  },
  data(){
    return {
      api: {
        route_call: this.$helperFunction.apiInstance(),
        schedules: this.$helperFunction.apiInstance(),
        update_out_of_hours: this.$helperFunction.apiInstance(),
        update_schedule: this.$helperFunction.apiInstance({ source: true }),
        get_setting: this.$helperFunction.apiInstance(),
      },
      response: this.$helperFunction.stateInstance({
        data: {
          schedules: {},
          out_of_hours: false,
          default_setting: {},
        }
      }),
      selected: this.$helperFunction.stateInstance({
        data: {
          day: 'mon',
        }
      }),
    }
  },
  computed: {
    ...mapGetters({
      getUserPermissions: 'getUserPermissions',
      getCurrentUser: 'getCurrentUser',
    }),
    forwardSetting(){
      return {
        extension_name: this.response?.default_setting?.forward_name ?? '',
        forward_note: this.response?.default_setting?.extension_type || 'voicemail' || 'none',
        forward_number: this.response?.default_setting?.forward_to ?? '',
        number: this.response?.default_setting?.forward_to ?? '',
      }
    },
    conditions(){
      return {
        toggle: !!(this.condition?.toggle ?? true),
        days: !!(this.condition?.days ?? true),
        default: !!(this.condition?.default ?? true),
      }
    },
  },
  filters: {
    twentyFourHourToAmPmFormat(time){ return moment(`2023-12-12 ${time}`,'YYYY-MM-DD HH:mm:ss').format('hh:mm a') },
    filterDayText(day){
      switch (day) {
        case 'mon':
          return 'Monday'
        case 'tue':
          return 'Tuesday'
        case 'wed':
          return 'Wednesday'
        case 'thu':
          return 'Thursday'
        case 'fri':
          return 'Friday'
        case 'sat':
          return 'Saturday'
        case 'sun':
          return 'Sunday'
        default:
          return '';
      }
    },
  },
  methods: {
    async fetchcallRouteSetting() {
      if(this.api.route_call.send) return;
      try {
        this.api.route_call.send = true;
        const { data } = await VOIP_API.endpoints.users.callroute({
          accountcode: this.account,
        })
        this.response.out_of_hours = !!data.schedule_enable;
      } finally {
        this.api.route_call.send = false;
      }
    },
    async updateOutOfHours(value){
      if(this.api.update_out_of_hours.send) return;
      try {
        this.api.update_out_of_hours.send=true
        await VOIP_API.endpoints.users.updateoutofhours({
          field: 'schedule',
          uid: this.getCurrentUser?.uid,
          value: value?'1':'0',
          accountcode: this.account
        })
        this.appNotify({
          message: 'Successfully updated',
          type: "success",
        })
        this.fetchcallRouteSetting()
        this.$emit('ofh-toggle-updated')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.update_out_of_hours.send=false
      }
    },
    async fetchSchedule() {
      if(this.api.schedules.send) return;
      try {
        this.api.schedules.send = true;
        const { data } = await VOIP_API.endpoints.users.schedules(this.account, {
          fid: 12,
        })
        this.response.schedules = data ?? {};
      } finally {
        if(!this.selected.day) this.selected.day = "mon";
        this.api.schedules.send = false;
      }
    },
    async fetchDefaultSetting(){
      if(this.api.get_setting.send) return;
      try {
        this.api.get_setting.send = true;
        const { data } = await VOIP_API.endpoints.users.getscheduledefault(this.account)
        this.response.default_setting = data;
      } finally {
        this.api.get_setting.send = false;
      }
    },
    async updateDefaultSetting(setting={}){
      if(this.api.update_schedule.send) return;
      const toaster_value = this.appNotify({
        message: "Schedule setting saving...",
        type: "success",
        duration: 60 * 1000,
      });
      try {
        const forward = setting?.forward_note=='off' ? 'none' : setting?.forward_note ?? this.response.default_setting.extension_type
        this.api.update_schedule.send = true;
        await VOIP_API.endpoints.users.updatescheduledefault({
          account: this.account,
          extn: setting?.forward_note ?? this.response.default_setting.extn,
          forward: forward,
          forward_account: setting?.forward_number ?? this.response.default_setting.forward_account,
          forward_name: setting?.extension_name ?? this.response.default_setting.forward_name,
          ext_type: forward,
          type: setting?.type ?? this.response.default_setting.type,
        })
        this.appNotify({
          message: "Successfully updated!",
          type: "success",
        })
        this.fetchDefaultSetting()
        this.fetchSchedule()
        this.$emit('ofh-default-updated')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        toaster_value.close();
        this.api.update_schedule.send = false;
      }
    },
  },
  mounted(){
    this.fetchSchedule()
    this.fetchcallRouteSetting()
    this.fetchDefaultSetting()
  },
  activated(){
    this.fetchSchedule()
    this.fetchcallRouteSetting()
    this.fetchDefaultSetting()
  },
}
</script>

<style>

</style>