var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "modalForDropdown",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      },
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      }
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-flex align-items-center justify-content-between pt-0"
  }, [_c('div', {
    staticClass: "flex-1 pr-3"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Select extension")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.select();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_vm.api.fetch_extensions.send ? _vm._l(3, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4 justify-content-start"
    }, [_c('div', {
      staticClass: "latestShimmerDesign mx-3",
      staticStyle: {
        "height": "24px",
        "width": "24px"
      }
    }), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "latestShimmerDesign mr-2",
      staticStyle: {
        "height": "45px",
        "width": "45px"
      }
    }), _c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('div', {
      staticClass: "latestShimmerDesign mb-2",
      staticStyle: {
        "height": "21px",
        "width": "120px"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "20px",
        "width": "60px"
      }
    })])])]);
  }) : _vm._l(_vm.response.extensions, function (extension) {
    return _c('div', {
      key: extension.accountcode,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4 justify-content-start",
      on: {
        "click": function ($event) {
          _vm.localConditions.extension_required ? _vm.selected.extension_accountcode = extension.accountcode : _vm.selected.extension_accountcode = _vm.selected.extension_accountcode == extension.accountcode ? '' : extension.accountcode;
        }
      }
    }, [extension.accountcode == _vm.selected.extension_accountcode ? _c('b-icon', {
      attrs: {
        "icon": "check-square-fill",
        "variant": "black",
        "font-scale": "1.5"
      }
    }) : _c('b-icon', {
      attrs: {
        "icon": "square",
        "variant": "black",
        "font-scale": "1.5"
      }
    }), _c('div', {
      staticClass: "latestGreyBox-heading-main ml-4 onlyfirstLetterCapital restLowerCase"
    }, [_vm._v(_vm._s(extension.accountname))])], 1);
  })], 2)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }