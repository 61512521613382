
import { VOIP_API } from "../index"

export const AUTH_API_ENDPOINTS = {
  
  forgotpassword(data={}){
    return VOIP_API.axios.voip_relay_proxy.request({
      url: `forgot/password`,
      method: 'POST',
      data,
    })
  },
  refreshToken(data={}){
    return VOIP_API.axios.voip.request({
      url: `refresh-token`,
      method: 'POST',
      data,
    })
  },
  universalAiToken(data={}){
    return VOIP_API.axios.voip.request({
      url: `universal-ai-token`,
      method: 'POST',
      data,
    })
  },
  userServer(data={}){
    return VOIP_API.axios.voip_relay_proxy.request({
      url: `logins`,
      method: 'POST',
      data,
    })
  },
  getLoginResponseByVBToken(params={}){
    return VOIP_API.axios.voip_relay_proxy.request({
      url: `vb-token-response`,
      method: 'GET',
      params,
    })
  },
  signupServer(params={}){
    return VOIP_API.axios.voip_relay_proxy.request({
      url: `default-relay-server`,
      method: 'GET',
      params,
    })
  },
  settingRegionPlatform(data){
    return VOIP_API.axios.voip_relay_proxy.request({
      url: `authenticate-setting-page`,
      method: 'POST',
      data,
    })
  },
  getPlatforms(params={}){
    return VOIP_API.axios.voip_relay_proxy.request({
      url: `fetch-platforms`,
      method: "GET",
      params,
    })
  },
  getRegions(params={}){
    return VOIP_API.axios.voip_relay_proxy.request({
      url: `fetch-regions`,
      method: "GET",
      params,
    })
  },
}