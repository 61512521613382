<template>
  <div 
    :class="{
      'recording-started': recorded.audio.is_start,
      'not-hover': conditions.screen!=screens.select,
    }"
  >
    <input ref="upload-input" type="file" accept="audio/mpeg, mp3/wav" @change="selectFile(getProperty($event,'target.files[0]'))" v-show="false" />
    <!-- select -->
    <div v-if="conditions.screen==screens.select">
      <div  class="d-flex align-items-center">
        <b-button :disabled="disabled" v-if="localOptions.recording" class="blueRecordingButton mr-12px" @click="recorded.audio.is_start=true;">
          <vb-icon icon="recordModal-record-icon" class="recordIcon" width="38px" height="38px"/>
          Record
        </b-button>
        <b-button :disabled="disabled" v-if="localOptions.sound_library" class="whiteRecordingButton mr-12px" @click="$modal.show(`${_uid}-AudioInputComponent`,{
          group: 'announcement'
        })">
          <vb-icon icon="recordModal-library-icon" class="uploadIcon" width="38px" height="38px"/>
          Sound library
        </b-button>
        <b-button :disabled="disabled" v-if="localOptions.text_to_speech" class="whiteRecordingButton mr-12px" @click="conditions.screen=screens.text_to_speech">
        <vb-icon icon="recordModal-textToSpeech-icon" class="uploadIcon" width="38px" height="38px"/>
          Text to speech
        </b-button>
        <b-button :disabled="disabled" class="whiteRecordingButton" @click="$refs['upload-input'].click()">
          <vb-icon icon="recordModal-upload-icon" class="uploadIcon" width="38px" height="38px"/>
          Upload
        </b-button>
      </div>
      <div class="RecordingLikeSomething-descriptionText mt-16px">
        <span v-if="localOptions.recording && localOptions.show_text">Maximum length for recording is 60 seconds.</span> Maximum file size 5MB
      </div>
    </div>
    <!-- recording -->
    <div v-if="conditions.screen==screens.recording">
      <div class="d-flex align-items-center">
        <div class="divLike-blueRecordingButton mr-12px wd-40">
          <vb-icon icon="recordModal-stopRecording-icon" class="recordIcon" width="38px" height="38px" @click="recorded.audio.is_start=false"/>
          <div :id="`${_uid}-frequency-wave`" class="frequency-wave">
            <div v-for="(data,index) in waves" :key="index" :id="`${_uid}-frequency-wave-${index}`" :data-value="data" />
          </div>
        </div>
        <b-button :disabled="disabled" class="whiteCancelButton" @click="conditions.screen=screens.select;recorded.audio.is_start=false;">
          <vb-icon icon="recordModal-cancel-icon" class="uploadIcon" width="38px" height="38px"/>
          Cancel
        </b-button>
      </div>
      <div v-if="localOptions.show_text" class="RecordingLikeSomething-descriptionText mt-16px">Maximum length for recording is 60 seconds. Maximum file size 5MB</div>
    </div>
    <!-- after select sound library -->
    <div v-if="conditions.screen==screens.after_select_file">
      <div class="d-flex align-items-center">
        <vb-audio-player class="mr-12px wd-40" :src="audio.file | fileToSrc" :design="'blueAudio'" />
        <b-button :disabled="disabled" class="GreenSaveButton mr-12px" @click="save()">
          <vb-icon icon="recordModal-save-icon" class="recordIcon" width="38px" height="38px"/>
          Save
        </b-button>
        <b-button :disabled="disabled" class="whiteCancelButton" @click="audio.file=null;conditions.screen=screens.select;">
          <vb-icon icon="recordModal-cancel-icon" class="uploadIcon" width="38px" height="38px"/>
          Cancel
        </b-button>
      </div>
      <div v-if="localOptions.show_text" class="RecordingLikeSomething-descriptionText mt-16px">
        Review your recorded audio
      </div>
    </div>
    <!-- speech to text -->
    <div v-if="conditions.screen==screens.text_to_speech">
      <div>
        <div class="flex-fill wecomeGreetingsTextBoxSection">
          <div class="whiteBGinputWithGreyRoundedBorder w-100">
            <label>Write your message here</label>
            <textarea rows="4" :disabled="api.text_to_audio_preview.send" class="w-100" v-model="forms.text_to_audio.text" @input="$emit('update-text',{ text: forms.text_to_audio.text });response.preview={};" />
          </div>
        </div>
        <ul class="list-unstyled theSection-buttonsSection d-flex align-items-center pl-0">
          <li>
            <b-button
              :disabled="api.text_to_audio_preview.send"
              class="whiteCancelButton d-flex align-items-center mr-8px" 
              type="button" 
              @click="$modal.show(`SelectVoiceForTextToSpeechModal_${_uid}`)" 
            >
              <Info 
                v-if="selectedSpeaker" 
                :hide_popover="true"
                class="mr-12px" 
                :image="selectedSpeaker.avatar_url" 
                :name="selectedSpeaker.sudo_name || forms.text_to_audio.voice" 
              />
              <template v-else>Select voice</template>
            </b-button>
          </li>
          <li>
            <vb-audio-player v-if="response.preview.sound" class="mr-8px w-auto" :src="response.preview.sound" :design="'OnlyPreviewAudio'" /> 
            <b-button class="blueRecordingButton mr-12px" v-else :disabled="api.text_to_audio_preview.send" @click="preview()">
              <vb-icon icon="circular-play-icon" class="recordIcon" width="38px" height="38px"/>
              <vb-spinner v-if="api.text_to_audio_preview.send" />
              <template v-else>Generate preview</template>
            </b-button>
          </li>
          <li>
            <b-button v-if="response.preview.sound" class="GreenSaveButton mr-8px" @click="onConfirmTextToSpeech()">
              <vb-icon icon="recordModal-save-icon" class="recordIcon" width="38px" height="38px"/>
              Done
            </b-button>
          </li>
        </ul>
      </div>
    </div>
    <!-- after save -->
    <div v-if="conditions.screen==screens.after_save">
      <div class="d-flex align-items-center">
        <vb-audio-player class="mr-12px w-auto" :src="audio.file | fileToSrc" :design="'blueAudio'" />
        <b-button :disabled="disabled" v-if="localOptions.recording" class="redRedoButton mr-12px" @click="recorded.audio.is_start=true;">
          <vb-icon icon="recordModal-redo-icon" class="recordIcon" width="38px" height="38px"/>
          redo
        </b-button>
        <b-button :disabled="disabled" class="whiteRecordingButton" @click="$refs['upload-input'].click();">
          <vb-icon icon="recordModal-upload-icon" class="uploadIcon" width="38px" height="38px"/>
          Upload
        </b-button>
      </div>
      <div v-if="localOptions.show_text" class="RecordingLikeSomething-descriptionText mt-16px">Maximum length for recording is 60 seconds. Maximum file size 5MB</div>
    </div>
    <RecordingListSelect v-if="localOptions.sound_library" :modalName="`${_uid}-AudioInputComponent`" @selectedRecordings="onSelectedLibrary($event.data)" :is_single_select="true" />
    <modal class="SelectVoiceForTextToSpeechModal" :name="`SelectVoiceForTextToSpeechModal_${_uid}`">
      <div class="dialer-edit-header position-relative top-0-imp w-100 flex-column align-items-start pt-0 mb-0">
        <div class="flex-fill d-flex py-0 justify-content-between align-items-center w-100">
          <h2 class="dialer-edit-title newer mb-0 ">Select your perfect voice</h2>
          <div class="dialer-edit-actions">
            <button class="newDoneButton" @click="$modal.hide(`SelectVoiceForTextToSpeechModal_${_uid}`)" type="button" >
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <div class="vm--modal-text">Personalise your welcome greeting messages with the perfect voice that suits your brand and connects with your callers. </div>
      </div>
      <div class="latestGreyBox-9-9-2023 hasScrollBarInsideIt" style="--total: 95vh; --minusValue: 110px;">
        <div class="SelectVoiceForTextToSpeechModal-whiteborderbox-container">
          <template v-if="api.fetch_voices.send">
            <div v-for="n in 8" :key="n.id" class="SelectVoiceForTextToSpeechModal-whiteborderbox">
              <div class="voiceImage-container">
                <div class="latestShimmerDesign" style="height: 55px;width: 55px;border-radius: 40% 40% 40% 40% / 40% 40% 40% 40%;"></div>
              </div>
              <div class="latestShimmerDesign w-100" style="height: 18px;margin-top: 12px;"></div>
              <div class="latestShimmerDesign" style="height: 20px;width: 40px;margin-top: 10px;"></div>
            </div>
          </template>
          <template v-else>
            <div class="SelectVoiceForTextToSpeechModal-whiteborderbox" v-for="speaker in speakers" :key="`${speaker.name}`">
              <div class="voiceImage-container">
                <vb-audio-player :src="speaker.sound" :design="'onlyPlayPause'" /> 
                <vb-avatar :image="speaker.avatar_url" class="size55px" />
              </div>
              <div class="SelectVoiceForTextToSpeechModal-whiteborderbox-heading">{{speaker.sudo_name}}</div>
              <img width="45px" :src="speaker.flag | flagsByCountryCode" class="country-img">
              <button 
                class="newBlackButton w-100 mt-auto"
                :class="{
                  'selected': speaker.name==forms.text_to_audio.voice
                }" 
                type="button"
                @click="forms.text_to_audio.voice=speaker.name;response.preview={};"
              >
                {{speaker.name==forms.text_to_audio.voice?'Selected':'Select'}}
              </button>
              <b-icon class="selectedVoiceIndicator" v-if="speaker.name==forms.text_to_audio.voice" icon="check-circle" />
            </div>
          </template>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { $fn, flagsCountryCode, VOIP_API } from '@/utils';
import Info from '@/components/Lists/Info.vue';
import required from 'vuelidate/lib/validators/required';
import _ from 'lodash';
let audio_recording_interval_id;
const frequency_data_map = [15,13,10,8,5,2,1,0,1,2,5,8,10,13,15];
const screens = {
  after_save: 'after_save',
  after_select_file: 'after_select_file',
  recording: 'recording',
  select: 'select',
  text_to_speech: 'text_to_speech',
}
export default {
  name: 'AudioFileInput',
  components: {
    Info,
    RecordingListSelect: () => import('../Modals/RecordingListSelect.vue'),
  },
  inject: [
    'getProperty','appNotify'
  ],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      recorded: {
        audio: {
          is_start: false,
          time: 0,
          recorder: null,
          audio: {},
          reset(){
            this.is_start=false
            this.time=0
            this.recorder=null
            this.audio={}
          },
        },
      },
      audio: {
        file: null,
        type: 'upload',
      },
      conditions: {
        screen: screens.select,
        drag_and_drop: false,
      },
      api: {
        text_to_audio: this.$helperFunction.apiInstance({
          validation_errors: true,
        }),
        text_to_audio_preview: this.$helperFunction.apiInstance({
          validation_errors: true,
          status: true,
        }),
        fetch_voices: this.$helperFunction.apiInstance(),
      },
      forms: {
        text_to_audio: this.$helperFunction.formInstance({ 
          data: {
            text: "",
            voice: "",
            language: '',
          },
        }),
      },
      response: {
        voices: {},
        preview: {},
      },
    }
  },
  computed: {
    waves(){ return frequency_data_map },
    screens(){ return screens },
    languages(){ return this.response.voices?.lang ?? [] },
    speakers(){ return this.response.voices?.speakers ?? [] },
    selectedSpeaker(){ return this.speakers.find((speaker)=>this.forms.text_to_audio.voice==speaker.name) },
    models(){ return this.response.voices?.models ?? [] },
    localOptions(){
      return {
        recording: !!(this.options?.recording ?? true),
        sound_library: !!(this.options?.sound_library ?? false),
        show_text: !!(this.options?.show_text ?? true),
        text_to_speech: !!(this.options?.text_to_speech ?? false),
      }
    },
  },
  validations: {
    forms: {
      text_to_audio: {
        text: {
          required: required,
        },
        language: {
          required: required,
        },
        voice: {
          required: required,
        },
      },
    },
  },
  watch: {
    async "recorded.audio.is_start"(is_start) {
      let vm = this;
      function visulizing(frequency_array){
        const values = Object.values(frequency_array);
        frequency_data_map.forEach((val,i)=>{
          const el = document.getElementById(`${vm._uid}-frequency-wave-${i}`)
          if(el){
            el.style.height = `calc(${values[val]/4}px - 16px)`;
          }
        })
      }
      if (is_start) {
        this.recorded.audio.recorder = await $fn.recordAudio({
          visulizing,
          blob: {
            type: 'audio/mp3',
            name: 'Recording.mp3',
          },
        });
        this.recorded.audio.time = 0;
        this.recorded.audio.recorder.start();
        audio_recording_interval_id = setInterval(() => {
          if(vm.recorded.audio.time>60){
            vm.recorded.audio.is_start=false
          } else {
            vm.recorded.audio.time = vm.recorded.audio.time + 1;
          }
        }, 1 * 1000);
        this.conditions.screen=screens.recording
      } else if(this.recorded.audio.recorder) {
        const audio = await this.recorded.audio.recorder.stop();
        visulizing({})
        clearInterval(audio_recording_interval_id);
        if(this.conditions.screen!=screens.select){
          this.audio.file = audio.audioBlob
          this.audio.type='recorded'
          this.conditions.screen=screens.after_select_file
        }
      }
    },
    "conditions.screen"(screen){
      if(screen==screens.select) {
        this.$refs['upload-input'].value=''
      }
      if(screen==screens.after_select_file) {
        this.response.preview={}
        this.forms.text_to_audio.reset()
      }
    },
    "localOptions.recording"(){
      this.conditions.screen=screens.select
      this.recorded.audio.is_start=false
      this.audio.file = null
      this.audio.type='upload'
    },
    "localOptions.text_to_speech": {
      immediate: true,
      handler(new_value){
        if(new_value) this.fetchVoices()
        else this.response.voices = {}
      }
    },
  },
  filters: {
    fileToSrc(file){
      if(file.sound_file) return file.sound_file
      else if(file.sound) return file.sound
      else return URL.createObjectURL(file);
    },
    flagsByCountryCode(code){ return flagsCountryCode[code] ?? flagsCountryCode['UN'] },
  },
  methods: {
    save(){
      let vm = this
      this.$emit('change',{
        file: this.audio.file,
        type: this.audio.type,
        complete(){
          vm.conditions.screen=screens.after_save;
        },
        reset(){
          vm.conditions.screen=screens.select;
        },
      });
    },
    onSelectedLibrary(Event){
      this.audio.file=Event
      this.audio.type='library'
      this.conditions.screen=screens.after_select_file
    },
    onConfirmTextToSpeech(){
      this.audio.file=_.cloneDeep(this.response.preview)
      this.audio.type='text_to_speech'
      
      this.conditions.screen=screens.after_select_file
    },
    selectFile(file) {
      const max = 5 * 1024 * 1024; // 5 MB
      const min = 5 * 1024; // 5 kb
      if(!file) return;
      if(!file.type.includes('audio')){
        this.appNotify({
          message: 'File should be audio',
          type: 'danger',
        })
        return;
      } else if(!['audio/mpeg','audio/wav'].includes(file.type)){
        this.appNotify({
          message: 'File can be mpeg or wav',
          type: 'danger',
        })
        return;
      } else if(file?.size > max){
        this.appNotify({
          message: 'File can be maximum 5MB',
          type: 'danger',
        })
        return;
      } else if(file?.size < min){
        this.appNotify({
          message: 'File should be minimum 5kb',
          type: 'danger',
        })
        return;
      }
      this.audio.file=file
      this.audio.type='upload'
      this.conditions.screen=screens.after_select_file
    },
    async preview() {
      this.forms.text_to_audio.submitted = true;
      this.$v.forms.text_to_audio.$touch();
      if (this.api.text_to_audio_preview.send || this.$v.forms.text_to_audio.$invalid) return;
      try {
        this.response.preview={}
        this.api.text_to_audio_preview.send = true;
        this.api.text_to_audio_preview.status = 0;
        this.api.text_to_audio_preview.validation_errors = {};
        const { data } = await VOIP_API.endpoints.menu.convertTextToAudioPreview({
          voice: this.forms.text_to_audio.voice,
          model: 'tts_models/multilingual/multi-dataset/xtts_v2',
          speaker: this.forms.text_to_audio.voice,
          lang: this.forms.text_to_audio.language,
          text: this.forms.text_to_audio.text,
        })
        this.response.preview=data
        this.api.text_to_audio_preview.status = 1;
      } catch (ex) {
        this.api.text_to_audio_preview.status = 2;
        this.api.text_to_audio_preview.validation_errors = ex.own_errors;
        this.appNotify({
          message: ex.own_message,
          type: "danger",
        })
      } finally {
        this.api.text_to_audio_preview.send = false;
        this.forms.text_to_audio.submitted = false;
      }
    },
    async fetchVoices() {
      try {
        this.api.fetch_voices.send = true;
        const { data } = await VOIP_API.endpoints.voicemail.getVoices()
        this.response.voices = data;
        this.forms.text_to_audio.voice = data?.speakers?.[0]?.name || '';
        this.forms.text_to_audio.language = data?.lang?.[0] || '';
        this.forms.text_to_audio.text = '';
      } finally {
        this.api.fetch_voices.send = false;
      }
    },
  },
  beforeDestroy(){
    if(this.recorded.audio.is_start) this.recorded.audio.recorder?.stop?.();
  },
}
</script>

<style>

</style>