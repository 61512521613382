<template>
  <div>
    <modal 
      height="auto" 
      :name="modalName" 
      class="center-zoomIn-transition v2 m-w-500 addUserModalV2" 
      @before-open="onBeforeOpen($event)" 
      :scrollable="true" 
      @before-close="onBeforeClose()"
    >
      <div class="dialer-edit-user">
        <div class="dialer-edit">
          <div class="dialer-form">
            <div class="dialer-edit-header pt-0 pb-0 d-block">
              <div class="d-flex justify-content-between align-items-center">
                <h2 class="dialer-edit-title">Edit Invitation</h2>
                <div class="dialer-edit-actions">
                  <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
                  <a v-else class="newCloseButton" @click="$modal.hide(modalName)">
                    <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                    <span class="newCloseButton-textPart">Close</span>
                  </a>
                </div>
              </div>
              <div class="vm--modal-text">Please provide the new user's details below and configure your account preferences.</div>
            </div>
            <div class="md-mar-vertical">
              <form @submit.prevent="editInvitation()">
                <b-alert :show="!!api.edit_invitation.error_message" variant="danger">{{ api.edit_invitation.error_message }}</b-alert>
                <div class="whiteBGinputWithGreyRoundedBorder dialer-input--error md-mar-top">
                  <label>Email *</label>
                  <div class="d-flex align-items-center justify-content-between">
                    <input 
                      :disabled="api.edit_invitation.send" 
                      v-model="forms.edit_invitation.email" 
                      placeholder="Enter Email" 
                      class="bg-white w-100"
                      type="text" 
                    />
                  </div>
                  <p 
                    v-if="$v.forms.edit_invitation.email.$error || api.edit_invitation.validation_errors.to_email" 
                    class="text text-small text-danger animated bounceIntop mb-0"
                  >
                    <span v-if="!$v.forms.edit_invitation.email.required">* Email is required</span>
                    <span v-else-if="!$v.forms.edit_invitation.email.email">* Email is not valid</span>
                    <span v-for="(em, i) in api.edit_invitation.validation_errors.to_email" :key="i">* {{ em }}</span>
                  </p>
                </div>
                <!-- first and last name -->
                <div class="w-100 d-flex md-mar-top">
                  <!-- first name -->
                  <div class="whiteBGinputWithGreyRoundedBorder dialer-input--error w-50 pr-3">
                    <label>First name *</label>
                    <input 
                      :disabled="api.edit_invitation.send" 
                      v-model="forms.edit_invitation.first_name" 
                      :maxlength="$v.forms.edit_invitation.first_name.$params.maxLength.max" 
                      onkeypress="return /^[A-Za-z]+$/.test(event.key)" 
                      placeholder="Enter first name" 
                      class="w-100 bg-white" 
                      type="text" 
                    />
                    <p 
                      v-if="$v.forms.edit_invitation.first_name.$error || api.edit_invitation.validation_errors.display_name || api.edit_invitation.validation_errors.firstname" 
                      class="text text-small text-danger animated bounceIntop mb-0"
                    >
                      <span v-if="!$v.forms.edit_invitation.first_name.required">* First name is required</span>
                      <span v-else-if="!$v.forms.edit_invitation.first_name.maxLength">* First name can be maximum {{ $v.forms.edit_invitation.first_name.$params.maxLength.max }} character</span>
                      <span v-else-if="!$v.forms.edit_invitation.first_name.alpha">* First name must be only alphabets</span>
                      <span v-for="(em, i) in api.edit_invitation.validation_errors.display_name" :key="i">* {{ em }}</span>
                      <span v-for="(em, i) in api.edit_invitation.validation_errors.firstname" :key="i">* {{ em }}</span>
                    </p>
                  </div>
                  <!-- last mame -->
                  <div class="whiteBGinputWithGreyRoundedBorder dialer-input--error w-50 pl-3">
                    <label>Last name</label>
                    <input 
                      :disabled="api.edit_invitation.send" 
                      v-model="forms.edit_invitation.last_name" 
                      :maxlength="$v.forms.edit_invitation.last_name.$params.maxLength.max" 
                      onkeypress="return /^[A-Za-z]+$/.test(event.key)" 
                      placeholder="Enter last name" 
                      class="w-100 bg-white" 
                      type="text" 
                    />
                    <p 
                      v-if="$v.forms.edit_invitation.last_name.$error || api.edit_invitation.validation_errors.lastname" 
                      class="text text-small text-danger animated bounceIntop mb-0"
                    >
                      <span v-if="!$v.forms.edit_invitation.last_name.maxLength">* Last name can be maximum {{ $v.forms.edit_invitation.last_name.$params.maxLength.max }} character</span>
                      <span v-else-if="!$v.forms.edit_invitation.last_name.alpha">* Last name must be only alphabets</span>
                      <span v-else-if="!$v.forms.edit_invitation.last_name.valid">* Last name must have a space character</span>
                      <span v-for="(em, i) in api.edit_invitation.validation_errors.lastname" :key="i">* {{ em }}</span>
                    </p>
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-end mb-2 lg-mar-top">
                  <button type="submit" class="dialer-button dialer-button-primary w-100" :disabled="api.edit_invitation.send">
                    <vb-spinner v-if="api.edit_invitation.send" />
                    <template v-else>Submit</template>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  required,
  maxLength,
  email,
} from "vuelidate/lib/validators";
import { VOIP_API } from "../../utils";
import { mapGetters } from 'vuex';
import alpha from 'vuelidate/lib/validators/alpha';
import _ from 'lodash';

export default {
  name: "EditInvitationModal",
  inject: [
    'appNotify',
  ],
  props: {
    modalName: {
      type: String,
      default: 'EditInvitation',
    },
  },
  data() {
    return {
      forms: {
        edit_invitation: this.$helperFunction.formInstance({
          data: {
            first_name: "",
            last_name: "",
            email: '',
          }
        }),
      },
      api: {
        edit_invitation: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true }),
      },
      data: {
        invitation: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile',
      'isFreePlan',
    ]),
  },
  validations(){
    return {
      forms: {
        edit_invitation: {
          first_name: {
            required, 
            maxLength: maxLength(16),
            alpha: alpha,
          },
          last_name: {
            maxLength: maxLength(16),
            alpha: alpha,
          },
          email: {
            required: required,
            email,
          },
        },
      },
    }
  },
  methods: {
    onBeforeOpen(event){
      const invitation = _.cloneDeep(event.params.invitation ?? null)
      this.data.invitation = invitation
      this.forms.edit_invitation.email = invitation.invitation.email
      let nameParts = invitation.user.display_name.trim().split(" ");
      this.forms.edit_invitation.first_name = nameParts[0] || ""
      this.forms.edit_invitation.last_name = nameParts.slice(1).join(" ") || ""
    },
    onBeforeClose(){
      this.$v.$reset()
      this.forms.edit_invitation.reset()
      this.api.edit_invitation.reset()
      this.data.invitation=null
    },
    async editInvitation(){
      this.$v.forms.edit_invitation.$touch()
      if(this.api.edit_invitation.send || this.$v.forms.edit_invitation.$invalid) return;
      try {
        this.api.edit_invitation.send=true
        this.api.edit_invitation.error_message=''
        this.api.edit_invitation.validation_errors={}
        await VOIP_API.endpoints.users.editInvitation({
          id: this.data.invitation.invitation.id,
          from_email: this.data.invitation.invitation.email,
          to_email: this.forms.edit_invitation.email,
          lastname: this.forms.edit_invitation.last_name,
          firstname: this.forms.edit_invitation.first_name,
          uid: this.getCurrentUser?.uid,
          accountcode: this.getCurrentUser?.account,
          service: "user",
        })
        this.$emit("updated");
        this.$modal.hide(this.modalName)
      } catch (ex) {
        this.api.edit_invitation.error_message=ex.own_message || ex.message
        this.api.edit_invitation.validation_errors=ex.response_error || {}
        return
      } finally {
        this.api.edit_invitation.send=false
        this.forms.edit_invitation.submitted=false
      }
    },
    async UpdateInvite(){

    },
  },
};
</script>


<style>
.slide-ltr-enter-active {
  animation: slide-ltr 0.5s reverse;
}
.slide-ltr-leave-active {
  animation: slide-ltr 0.5s;
}
@keyframes slide-ltr {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>