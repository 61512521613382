<template>
  <div>
    <modal class=" nowRightSideModal right_side_popup dialer_animation" :name="modalName" @before-open="onBeforeOpen($event)" @before-close="onBeforeClose()">
      <div class="dialer-edit-header pt-0 d-block">
        <div class="d-flex justify-content-between align-items-center w-100">
          <h2 class="dialer-settings-title newer mb-0">Update List </h2>
          <div class="dialer-edit-actions">
            <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName);" />
            <a id="CloseModalButton" v-else class="newCloseButton" @click="$modal.hide(modalName);">
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </a>
          </div>
        </div>
      </div>
      <form @submit.prevent="updateSheet()"> 
        <b-alert :show="!!api.update_sheet.error_message" variant="danger">{{ api.update_sheet.error_message }}</b-alert>
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <!-- name -->
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mb-20px">
            <label>List name</label>
            <b-input type="text" class="w-100" v-model="forms.update_sheet.name" placeholder="Enter list name" />
            <p class="mb-1 text-small text text-danger animated bounceIntop" v-if="$v.forms.update_sheet.name.$dirty && $v.forms.update_sheet.name.$invalid">
              <span v-if="!$v.forms.update_sheet.name.required">Name is required</span>
              <span v-else-if="!$v.forms.update_sheet.name.minLength">* Name is should be minimum {{$v.forms.update_sheet.name.$params.minLength.min}} character</span>
              <span v-else-if="!$v.forms.update_sheet.name.maxLength">* Name is can be maximum {{$v.forms.update_sheet.name.$params.maxLength.max}} character</span>
              <span v-else-if="!$v.forms.update_sheet.name.valid">* Name can be number and alphabet</span>
            </p>
          </div>
          <!-- description -->
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mb-20px">
            <label>Description</label>
            <b-textarea class="w-100" v-model="forms.update_sheet.desc" placeholder="Enter description" />
            <p class="mb-1 text-small text text-danger animated bounceIntop" v-if="$v.forms.update_sheet.desc.$dirty && $v.forms.update_sheet.desc.$invalid">
              
            </p>
          </div>
          <!-- assign to -->
          <div class="whiteBoxWithBorderInsideGreyBox mb-20px">
            <div class="whiteBoxWithBorderInsideGreyBox-row">
              <div>
                <div class="latestGreyBox-heading-main">Assign to</div>
                <div class="latestGreyBox-descriptionText">
                  {{forms.update_sheet.assign_to.length==0?'Select User or Team' : `${forms.update_sheet.assign_to.length} User or Team`}}
                </div>
                <p class="mb-1 text-small text text-danger animated bounceIntop mt-10px" v-if="$v.forms.update_sheet.assign_to.$dirty && $v.forms.update_sheet.assign_to.$invalid">
                  <span v-if="!$v.forms.update_sheet.assign_to.required">Assign to is required</span>
                </p>
              </div>
              <button class="newButton sm-mar-left" 
                type="button" 
                @click="$modal.show(`${_uid}-SelectExtensions`,{
                  accountcodes: forms.update_sheet.assign_to,
                })"
              >
                <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                <span class="newButton-textPart">Assign</span>
              </button>
            </div>
          </div>
          <button type="submit" class="fullWidthDoneButton mt-20px">
            <template>Update</template>
          </button>
        </div>
      </form>
      <div class="latestGreyBox-9-9-2023 mt-20px">
        <div class="whiteBoxWithBorderInsideGreyBox">
          <div class="whiteBoxWithBorderInsideGreyBox-row  min-height-unset">
            <div class="latestGreyBox-heading-main">Export sheet</div>
            <button class="newButton sm-mar-left" type="button" @click="exportSheet(importedSheet)" >
              <vb-icon icon="squared-exportFile-icon" height="38px" width="38px" />
              <span class="newButton-textPart">Export</span>
            </button>
          </div>
          <div class="whiteBoxWithBorderInsideGreyBox-row  min-height-unset">
            <div class="latestGreyBox-heading-main">Delete this sheet?</div>
            <button class="newDeleteButton" type="button" @click="deleteSheet(importedSheet)">
              <vb-icon icon="squared-delete-icon" height="38px" width="38px" />
              <span class="newDeleteButton-textPart">Delete</span>
            </button>
          </div>
        </div>
      </div>
    </modal>
    <SelectExtensionsModal 
      :modalName="`${_uid}-SelectExtensions`" 
      :extensionTypes="['USER','TEAM']"
      :allowMultiSelect="true"
      @update="forms.update_sheet.assign_to=$event.selected.accountcodes" 
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import required from 'vuelidate/lib/validators/required'
import { VOIP_API } from '@/utils'
import SelectExtensionsModal from '../Modals/SelectExtensions.vue'
// import _ from 'lodash'
import moment from 'moment-timezone';
import minLength from 'vuelidate/lib/validators/minLength'
import maxLength from 'vuelidate/lib/validators/maxLength'
import { helpers } from 'vuelidate/lib/validators'

export default {
  name: 'UpdateListModal',
  components: {
    SelectExtensionsModal,
  },
  inject: [
    'getProperty',
    'appNotify',
  ],
  props: {
    modalName: {
      type: String,
      default: 'UpdateListModal'
    },
  },
  data() {
    return {
      forms: {
        update_sheet: this.$helperFunction.formInstance({
          data: {
            name: '',
            desc: '',
            assign_to: [],
          },
        }),
      },
      api: {
        update_sheet: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true }),
        delete_sheet: this.$helperFunction.apiInstance({ send: '' }),
        export_sheet: this.$helperFunction.apiInstance({ send: '' }),
      },
      data: {
        sheet_id: ''
      },

      importedSheet: {},
    }
  },
  validations: {
    forms: {
      update_sheet: {
        name: {
          required: required,
          minLength: minLength(3), 
          maxLength: maxLength(20),
          valid: helpers.regex('valid',/^[a-z0-9 ]*$/i),
        },
        desc: {
          
        },
        assign_to: {
          required: required,
        },
      },
    },
  },
  computed: {
    ...mapGetters([
      'getIsMobile',
    ]),
  },
  methods: {
    onBeforeOpen(event){
      const { id, name, description, assigns } = event?.params?.sheet ?? {}
      this.importedSheet = event?.params?.sheet ?? {}
      this.forms.update_sheet.name=name
      this.forms.update_sheet.desc=description
      this.forms.update_sheet.assign_to=assigns?.map?.(i=>i.accountcode) ?? []
      this.data.sheet_id=id
    },
    onBeforeClose(){
      this.$v.forms.update_sheet.$reset()
      this.forms.update_sheet.reset()
      this.api.update_sheet.reset()
    },
    async updateSheet(){
      this.$v.forms.update_sheet.$touch()
      if(this.api.update_sheet.send || this.$v.forms.update_sheet.$invalid) return;
      try {
        this.api.update_sheet.send=true
        this.api.update_sheet.error_message=''
        this.api.update_sheet.validation_errors={}
        await VOIP_API.endpoints.power_dialer.updateSheet({
          name: this.forms.update_sheet.name,
          description: this.forms.update_sheet.desc,
          assign_to: this.forms.update_sheet.assign_to.join(','),
          sheet_id: this.data.sheet_id,
        })
        this.$emit('updated')
        this.$modal.hide(this.modalName)
        this.appNotify({
          message: 'Succssfully Updated!',
          type: 'success'
        })
      } catch (ex) {
        this.api.update_sheet.error_message=ex?.response?.status==417 ? 'Name is invalid' : (ex.own_message || ex.message)
        this.api.update_sheet.validation_errors=ex.own_errors ?? {}
      } finally {
        this.api.update_sheet.send=false
      }
    },
    async deleteSheet(sheet){
      const sheet_id = sheet?.id
      if(!sheet_id || this.api.delete_sheet.send==sheet_id) return;
      try {
        this.api.delete_sheet.send=sheet_id
        const { confirm } = await this.$appConfirmation({
          title: "Warning",
          message: `Are you sure you want to delete the list`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        });
        if(!confirm) return;
        await VOIP_API.endpoints.power_dialer.deleteSheet(sheet_id)
        this.appNotify({
          message: 'Successfully Deleted',
          type: 'success',
        })
        // this.fetchSheets()
        this.$modal.hide(this.modalName)
        this.$emit("refreshSheets")
      } catch (ex) {
        this.appNotify({
          message: ex.message,
          type: 'danger',
        })
      } finally {
        this.api.delete_sheet.send=''
      }
    },
    async exportSheet(sheet){
      const sheet_id = sheet?.id
      if(!sheet_id || this.api.export_sheet.send==sheet_id) return;
      try {
        this.api.export_sheet.send=sheet_id
        const { data } = await VOIP_API.endpoints.power_dialer.exportSheet(sheet_id)
        const blobUrl = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.target = '_blank';
        link.download = `${sheet.name} - ${moment().format('DD MMM YYYY hh_mm_ss a')}.xlsx`;
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (ex) {
        this.appNotify({
          message: ex.message,
          type: 'danger',
        })
      } finally {
        this.api.export_sheet.send=''
      }
    },
  },
}
</script>

<style>

</style>