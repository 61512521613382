var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-box alt position-relative",
    attrs: {
      "id": _vm.id
    }
  }, [_c('div', {
    staticClass: "dialer-outofhours v2"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row pb-0 EnableBusinessHoursSwitchRow min-height-unset",
    class: `${_vm.conditions.toggle ? 'b-0' : ''}`
  }, [_vm._m(0), _vm.conditions.toggle ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: {
      'for-disable': !_vm.getUserPermissions.out_of_hours
    },
    attrs: {
      "title": _vm.getUserPermissions.out_of_hours ? '' : 'Your package does not support this feature'
    }
  }, [_vm.api.get_setting.send ? [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "34px",
      "width": "60px",
      "border-radius": "25px"
    }
  })] : _c('b-form-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "disabled": !_vm.getUserPermissions.out_of_hours,
      "checked": _vm.response.out_of_hours,
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.updateOutOfHours($event);
      }
    }
  })], 2) : _vm._e()]), _vm.api.update_out_of_hours.send ? [_vm._m(1), _c('div', {
    staticClass: "latestShimmerDesign md-mar-top",
    staticStyle: {
      "height": "24px",
      "width": "50%"
    }
  }), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox withBG-F9F9F9 withOutPadding mt-20px"
  }, _vm._l(7, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row withHover"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "23px",
        "width": "150px"
      }
    }), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_vm._m(2, true), _c('b-icon', {
      staticClass: "iconIconArrow",
      attrs: {
        "icon": "chevron-right",
        "variant": "dark",
        "font-scale": "1.5"
      }
    })], 1)]);
  }), 0)] : [_vm.response.out_of_hours && _vm.conditions.default ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row b-0 pb-0 align-items-start"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main mb-0"
  }, [_vm._v("What happens to calls after business hours ?")]), _vm.forwardSetting.forward_note == 'none' ? [_c('div', {
    staticClass: "latestGreyBox-descriptionText-18px"
  }, [_vm._v("Keep ringing")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText-14px"
  }, [_vm._v("Call forwarding is off")])] : _vm._e(), _vm.forwardSetting.forward_note == 'voicemail' ? [_c('div', {
    staticClass: "latestGreyBox-descriptionText-18px"
  }, [_vm._v("Ask to leave a voicemail")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText-14px"
  }, [_vm._v("Call will be forward to voicemail")])] : _vm._e(), _vm.forwardSetting.forward_note == 'extension' ? [_c('div', {
    staticClass: "latestGreyBox-descriptionText-18px"
  }, [_vm._v("Forward to a user, team or IVR menu")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText-14px"
  }, [_vm._v(" Call will be forward to " + _vm._s(_vm.forwardSetting.extension_name || '') + " ")])] : _vm._e(), _vm.forwardSetting.forward_note == 'number' ? [_c('div', {
    staticClass: "latestGreyBox-descriptionText-18px"
  }, [_vm._v("Forward to another number")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText-14px"
  }, [_vm._v(" Call will be forward to " + _vm._s(_vm.forwardSetting.forward_number || '') + " ")])] : _vm._e()], 2), _c('div', {
    staticClass: "w-fit-content"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: !_vm.getUserPermissions.out_of_hours ? 'for-disable' : '',
    attrs: {
      "title": _vm.getUserPermissions.out_of_hours ? '' : 'Your package does not support this feature'
    }
  }, [_c('button', {
    staticClass: "newButton",
    attrs: {
      "disabled": !_vm.getUserPermissions.out_of_hours
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-CallForwardSettingModal`, {
          account: _vm.account,
          accountcode: _vm.account,
          setting: _vm.forwardSetting,
          is_ringing_show: false,
          is_sec_show: false
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)])])]) : _vm._e(), _vm.response.out_of_hours && _vm.conditions.days ? [_c('div', {
    staticClass: "dialer-box-header md-mar-top"
  }, [_vm._v("Set your business hours")]), _c('div', {
    staticClass: "newBusinessHours"
  }, _vm._l(_vm.response.schedules, function (day_setting, day) {
    return _c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip"
      }],
      key: day,
      staticClass: "newBusinessHours-row",
      attrs: {
        "title": _vm.getUserPermissions.out_of_hours ? '' : 'Your package does not support this feature'
      }
    }, [_c('div', {
      staticClass: "newBusinessHours-row-left"
    }, [_c('div', {
      staticClass: "newBusinessHours-row-headingContainer"
    }, [_c('div', {
      staticClass: "newBusinessHours-row-heading"
    }, [_vm._v(_vm._s(_vm._f("filterDayText")(day)))])])]), _c('div', {
      staticClass: "newBusinessHours-row-right"
    }, [_c('div', {
      staticClass: "newBusinessHours-row-right-row",
      on: {
        "click": function ($event) {
          !_vm.getUserPermissions.out_of_hours ? '' : _vm.$modal.show(`${_vm._uid}-EditOutOfHoursDayModal`, {
            day: day
          });
        }
      }
    }, [_c('div', {
      staticClass: "newBusinessHours-row-right-row-left"
    }, [_c('div', {
      staticClass: "newBusinessHours-row-right-row-left-button"
    }, [_c('vb-icon', {
      attrs: {
        "icon": "businessHours-editSchedulePencil-icon",
        "width": "8.762px",
        "height": "8.761px"
      }
    }), _c('span', [_vm._v("Edit")])], 1)]), _c('div', {
      staticClass: "newBusinessHours-row-right-row-right"
    }, [!day_setting ? _c('div', {
      staticClass: "newBusinessHours-row-right-row-right-heading",
      class: `${day}`
    }, [_vm._v("Available all day")]) : day_setting.type == 'unavailable' ? _c('div', {
      staticClass: "red newBusinessHours-row-right-row-right-heading",
      class: `${day}`
    }, [_vm._v("Unavailable all day")]) : _c('div', {
      staticClass: "newBusinessHours-row-right-row-right-heading d-flex flex-column",
      class: `${day}`
    }, _vm._l(day_setting.duration, function (slot, index) {
      return _c('span', {
        key: index
      }, [_vm._v(_vm._s(_vm._f("twentyFourHourToAmPmFormat")(slot.start)) + " - " + _vm._s(_vm._f("twentyFourHourToAmPmFormat")(slot.end)))]);
    }), 0), day_setting && day_setting.setting_type == 'custom' ? [day_setting.forward_type == 'voicemail' ? _c('div', {
      staticClass: "newBusinessHours-row-right-row-right-text"
    }, [_vm._v("Call will be forward to voicemail")]) : day_setting.forward_type == 'extension' ? _c('div', {
      staticClass: "newBusinessHours-row-right-row-right-text"
    }, [_vm._v("Call will be forward to " + _vm._s(day_setting.forward_name || ''))]) : day_setting.forward_type == 'number' ? _c('div', {
      staticClass: "newBusinessHours-row-right-row-right-text"
    }, [_vm._v("Call will be forward to " + _vm._s(day_setting.forward_account || ''))]) : _c('div', {
      staticClass: "newBusinessHours-row-right-row-right-text"
    }, [_vm._v("Call forwarding is off")])] : _vm._e()], 2)])])]);
  }), 0)] : _vm._e()]], 2), _c('CallForwardSettingModal', {
    attrs: {
      "modalName": `${_vm._uid}-CallForwardSettingModal`
    },
    on: {
      "updated": function ($event) {
        return _vm.updateDefaultSetting($event);
      }
    }
  }), _c('EditOutOfHoursDayModal', {
    attrs: {
      "modalName": `${_vm._uid}-EditOutOfHoursDayModal`,
      "defaultForwardSetting": _vm.forwardSetting,
      "account": _vm.account,
      "schedules": _vm.response.schedules
    },
    on: {
      "updated": function ($event) {
        _vm.response.schedules = $event;
        _vm.$emit('ofh-days-updated');
      }
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "dialer-box-header mb-0"
  }, [_vm._v("Enable business hours")]), _c('div', {
    staticClass: "dialer-box-text"
  }, [_vm._v("Select how you will like to manage calls outside office/working hours")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row b-0 pb-0 align-items-start"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-fill"
  }, [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "24px",
      "width": "50%"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign mt-3",
    staticStyle: {
      "height": "19px",
      "width": "40%"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign mt-1",
    staticStyle: {
      "height": "17px",
      "width": "30%"
    }
  })]), _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "50px",
      "width": "100px"
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column align-items-end text-right mr-2"
  }, [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "22px",
      "width": "150px"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign mt-2",
    staticStyle: {
      "height": "20px",
      "width": "160px"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign mt-1",
    staticStyle: {
      "height": "17px",
      "width": "180px"
    }
  })]);

}]

export { render, staticRenderFns }