<template>
  <div>
    <modal
      class="center-zoomIn-transition v2 SmallAddModal" 
      height="auto"
      :name="modalName"
      @before-open="onBeforeOpen($event)"
      @before-close="onBeforeClose()"
    >
      <section class="ct-user-new dialer-edit s1">
        <form class="dialer-form " @submit.prevent="createGroup()">
          <div class="dialer-edit-header d-block pb-0">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <h2 class="dialer-settings-title newer">Add Group</h2>
              </div>
              <div class="dialer-edit-actions">
                <a class="newCloseButton" @click="$modal.hide(modalName)">
                  <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                  <span class="newCloseButton-textPart">Close</span>
                </a>
              </div>
            </div>
            <div class="vm--modal-text">Enter the name of the new Public holiday Group below.
            </div>
          </div>
          <div class="md-mar-top">
            <b-alert :show="!!api.create_group.error_message" variant="danger">{{ api.create_group.error_message }}</b-alert>
            <div class="latestGreyBox-9-9-2023 mt-20px">
              <!-- country -->
              <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mt-20px">
                <label>Country</label>
                <b-form-select 
                  :disabled="api.create_group.send" 
                  v-model="forms.create_group.country" 
                  @change="forms.create_group.timezone=timezones[0] || '';" 
                  :options="countries"
                >
                  <b-form-select-option disabled value="">Select country</b-form-select-option>
                </b-form-select>
                <p v-if="$v.forms.create_group.country.$error" class="text text-danger animated bounceIntop">
                  <span v-if="!$v.forms.create_group.country.required">* Country is required</span>
                </p>
              </div>
              <!-- timezone -->
              <div v-if="timezones.length>1" class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mt-20px">
                <label>TimeZone</label>
                <select 
                  :disabled="api.create_group.send" 
                  v-model="forms.create_group.timezone" 
                  class="form-control custom-select"
                >
                  <option v-for="timeZone in timezones" :key="timeZone">{{ timeZone }}</option>
                </select>
                <p v-if="$v.forms.create_group.timezone.$error || api.create_group.validation_errors.timezone" class="text text-danger animated bounceIntop">
                  <span v-if="!$v.forms.create_group.timezone.required">* TimeZone is required</span>
                  <span v-for="(em,i) in api.create_group.validation_errors.timezone" :key="i">* {{ em }}</span>
                </p>
              </div>
            </div>
            <div class="w-100 mt-4">
              <button class="fullWidthDoneButton" :disabled="api.create_group.send">
                <vb-spinner v-if="api.create_group.send" />
                <template v-else>Create Group</template>
              </button>
            </div>
          </div>
        </form>
      </section>
    </modal>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
import required from 'vuelidate/lib/validators/required';
import _ from 'lodash'
import moment from 'moment-timezone';
import { CountryName2, flagsCountryCode } from '@/utils/flags';
import { mapGetters } from 'vuex';
export default {
  name: 'CreatePublicHolidayGroupModal',
  inject: [
    'appNotify',
  ],
  props: {
    modalName: {
      type: String,
      default: 'CreatePublicHolidayGroup',
    },
  },
  data() {
    return {
      forms: {
        create_group: this.$helperFunction.formInstance({
          data: {
            name: '',
            country: '',
            timezone: '',
          }
        }),
      },
      api: {
        create_group: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true, }),
      },
      data: {
        did: null
      },
    };
  },
  computed: {
    ...mapGetters([
      'getIsMobile',
    ]),
    countries(){ 
      return _.orderBy(moment.tz.countries().map((i)=>({
        value: i,
        text: CountryName2[i],
        flag: flagsCountryCode[i],
      })) , ['text'], ['asc']);
    },
    timezones(){ return moment.tz.zonesForCountry(this.forms.create_group.country) ?? [] },
  },
  validations: {
    forms: {
      create_group: {
        country: { 
          required: required,
        },
        timezone: { 
          required: required,
        },
      },
    },
  },
  methods: {
    onBeforeOpen(event){
      this.data.did=event?.params?.did ?? null
    },
    onBeforeClose(){
      this.$v.$reset()
      this.forms.create_group.reset()
      this.api.create_group.reset()
    },
    async createGroup() {
      this.$v.forms.create_group.$touch();
      if (this.$v.forms.create_group.$invalid || this.api.create_group.send) return;
      try {
        this.api.create_group.send = true;
        this.api.create_group.validation_errors = {};
        this.api.create_group.error_message = '';
        await VOIP_API.endpoints.holiday_schedule.create({
          name: CountryName2[this.forms.create_group.country],
          timezone: this.forms.create_group.timezone,
          did: this.data.did ?? undefined
        })
        this.$emit('created')
        this.$modal.hide(this.modalName)
        this.appNotify({
          message: 'Successfully Created',
          type: 'success'
        })
      } catch (ex) {
        this.api.create_group.validation_errors = ex.own_errors;
        this.api.create_group.error_message = ex.own_message;
      } finally {
        this.api.create_group.send = false;
      }
    },
  }
}
</script>

<style>

</style>