<template>
  <div class="dialer-settings-main">
    <section v-if="getIsMobile" class="dialer-settings-section locationSettings">
      <div class="d-flex align-items-center mb-3 calc-title-width">
        <vb-icon icon="latest-sideBarOpenIconForTodoInMobileView-icon" width="19.979px" height="16px" class="settings-back-icon cursor_pointer" />
        <h2 class="dialer-settings-title w-100 mb-0 text-center flex-1">List of contacts</h2>
      </div>
      <div>
        Automatically dial numbers from your lists, save time of manual dialling and boost productivity. 
      </div>
    </section>
    <section v-else class="dialer-settings-section locationSettings newTasksModule">
      <div>
        <div class="settingsFirstSection">
          <div>
            <h2 class="dialer-settings-title d-flex align-items-center">
              <vb-icon icon="latest-sideBarOpenIconForTodoInMobileView-icon" width="19.979px" height="16px" class="settings-back-icon cursor_pointer showOnlyOnSmallerScreens mr-12px" />
              List of contacts
            </h2>
          </div>
          <div class="settingsFirstSection-right">
            <div class="d-flex justify-content-end align-items-center mt-0">
              <div class="whiteBGinputWithGreyRoundedBorder sm-mar-left sm-mar-right seachBox">
                <b-input type="text" placeholder="Search for contacts"  />
              </div>
              <button 
                class="newButton" 
                v-b-tooltip :title="getUserPermissions.power_dialer?'':'Your package does not support this feature'"
                @click="!getUserPermissions.power_dialer?'':$modal.show(`${_uid}-AddSheet`)"
              >
                <vb-icon icon="squared-add-icon" height="38px" width="38px"  />
                <span class="newButton-textPart position-relative d-flex justify-content-center">
                  <template>Add list</template>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div class="settingsSecondSection">
          <span class="dialer-settings-intro newerTextVersion mt-0 mb-0">
            Automatically dial numbers from your lists, save time of manual dialling and boost productivity.
          </span>
        </div>
      </div>
      <vb-table
        class="latestTableDesign-withBlackBorders-again IVRTable"
        textNoRecord=" "
        :isListEmpty="response.sheets.length==0"
        :listLength="response.sheets.length"
        :loading="api.fetch_sheets.send"
      >
        <template #header>
          <tr v-if="!getIsMobile">
            <th class="dialer-has-sort">
              <span class="dialer-sorted-by ascending">List name</span>
            </th>
            <th class="dialer-has-sort">
              <span>Published By</span>
            </th>
            <th class="dialer-has-sort">
              <span>Assigned to</span>
            </th>
            <th class="dialer-has-sort">
              <!-- <span>Unanswered calls</span> -->
              <span v-b-tooltip.hover="'Unanswered calls'" >UC</span>
            </th>
            <th class="dialer-has-sort">
              <!-- <span>Dialed calls</span> -->
              <span v-b-tooltip.hover="'Dialed calls'" >DC</span>
            </th>
            <th class="dialer-col-right pr-4">
              <!-- <span>Total contacts</span> -->
              <span v-b-tooltip.hover="'Total contacts'" >TC</span>
            </th>
            <!-- <th class="dialer-has-sort dialer-col-right">
              <span class="for-flex-column">Action</span>
            </th> -->
          </tr>
        </template>
        <template #body="{ start, end }">
          <tr v-for="(sheet, index) in response.sheets" v-show="index >= start && index <= end" :key="sheet.id" @click="!getUserPermissions.power_dialer?appNotify({message:'Your package does not support this feature',type:'danger'}):$modal.show(`${_uid}-UpdateSheet`,{ sheet_id: sheet.id, sheet: sheet })">
          <!-- <tr v-for="(sheet, index) in response.sheets" v-show="index >= start && index <= end" :key="sheet.id"> -->
            <!-- name -->
            <td class="dialer-row-title">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <vb-icon icon="PowerDialer-NumberListTD-icon" class="mr-16px" width="35px" height="35px" />
                  <span>{{sheet.name}}</span>
                </div>
              </div>
            </td>
            <!-- published by -->
            <td class="dialer-row-title">
              <Info v-if="sheet.publish_by" class="w-fit-content" :id="sheet.publish_by" is_suspended_show />
            </td>
            <!-- assigned to -->
            <td class="dialer-row-title">
              <div style="width: 50%;min-width: 104px;display: flex;justify-content: flex-start;align-items: center;"  :class="`${sheet.assigns.length>3?'moreThanThree':sheet.assigns.length==3?'exactlyThree':''}`">
                <vb-avatar v-if="sheet.assigns[0]" :id="sheet.assigns[0].accountcode" :showPopover="true" :is_blf="false"  />
                <vb-avatar v-if="sheet.assigns[1]" :id="sheet.assigns[1].accountcode" :showPopover="true" :is_blf="false"  />
                <vb-avatar v-if="sheet.assigns[2]" :id="sheet.assigns[2].accountcode" :showPopover="true" :is_blf="false"  />
                <div class="countPlus" v-if="sheet.assigns.length>3">+{{sheet.assigns.length-3}}</div>
              </div>
            </td>
            <!-- total unanswered call -->
            <td class="dialer-row-title">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <span>{{sheet.total_missed_calls}}</span>
                </div>
              </div>
            </td>
            <!-- total dialed call -->
            <td class="dialer-row-title">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <span>{{sheet.total_answered_calls}}</span>
                </div>
              </div>
            </td>
            <!-- total contacts -->
            <td class="dialer-col-right pr-4">
              <div class="d-flex align-items-center justify-content-end">
                <div class="d-flex align-items-center">
                  <span>{{sheet.total_records}}</span>
                </div>
              </div>
            </td>
            <!-- action -->
            <!-- <td class="dialer-col-right">
              <div class="d-flex justify-content-end">
                <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD">
                  <b-dropdown v-if="api.delete_sheet.send!=sheet.id" right @click.prevent="''" >
                    <template #button-content>
                      <b-icon icon="three-dots-vertical" scale="1.5"></b-icon>
                    </template>
                    <b-dropdown-item @click="deleteSheet(sheet)">Delete</b-dropdown-item>
                    <b-dropdown-item @click="exportSheet(sheet)">Export</b-dropdown-item>
                    <b-dropdown-item @click="$modal.show(`${_uid}-UpdateSheet`,{ sheet_id: sheet.id, sheet: sheet })">Edit</b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </td> -->
          </tr>
        </template>
        <template #loading>
          <tr v-for="n in 5" :key="n">
            <td>
              <div class="d-flex">
                <div class="latestShimmerDesign mr-2" style="height:35px;width:35px;"></div>
                <div class="d-flex flex-column justify-content-between">
                  <div class="latestShimmerDesign flex-1" style="height:16px;width:60px"></div>
                  <div class="latestShimmerDesign mt-1" style="height:16px;width:30px"></div>
                </div>
              </div>
            </td>
            <td>
              <div class="latestShimmerDesign" style="height:20px;width:50%;"></div>
            </td>
            <td>
              <div class="d-flex">
                <div class="latestShimmerDesign" style="height:20px;width:-webkit-fill-available;"></div>
              </div>
            </td>
            <td>
              <div class="latestShimmerDesign" style="height:20px;width:85px;"></div>
            </td>
            <td>
              <div class="latestShimmerDesign" style="height:20px;width:85px;"></div>
            </td>
            <td>
              <div class="latestShimmerDesign" style="height:20px;width:85px;"></div>
            </td>
            <td class="dialer-col-right">
              <div class="latestShimmerDesign ml-auto" style="height:48px;width:48px;"></div>
            </td>
          </tr>
        </template>
      </vb-table>
    </section>
    <AddListModal :modalName="`${_uid}-AddSheet`" @created="fetchSheetsDebounce();" />
    <UpdateListModal @refreshSheets="fetchSheetsDebounce" :modalName="`${_uid}-UpdateSheet`" @updated="fetchSheetsDebounce();" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GET_LOCAL_SETTING_IS_DARK_MODE } from '@/store/helper/getters';
import Info from "../Lists/Info.vue";
import AddListModal from "../PowerDialer/AddListModal.vue"
import UpdateListModal from "../PowerDialer/UpdateListModal.vue"
import { VOIP_API } from '@/utils';
import _ from 'lodash'
import moment from 'moment-timezone';

export default {
  name: 'PowerDialerFile',
  components:{
    Info,
    AddListModal,
    UpdateListModal,
  },
  inject: [
    'appNotify'
  ],
  data() {
    return {
      api: {
        fetch_sheets: this.$helperFunction.apiInstance({  }),
        delete_sheet: this.$helperFunction.apiInstance({ send: '' }),
        export_sheet: this.$helperFunction.apiInstance({ send: '' }),
      },
      response: {
        sheets: [],
      },
      conditions: {
        show_side_bar: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile',
      'getUserPermissions',
      GET_LOCAL_SETTING_IS_DARK_MODE
    ]),
  },
  methods: {
    fetchSheetsDebounce: _.debounce(function(){
      this.fetchSheets()
    }, 2*1000),
    async fetchSheets() {
      if(this.api.fetch_sheets.send) return;
      try {
        this.api.fetch_sheets.send=true
        const { data } = await VOIP_API.endpoints.power_dialer.getSheets({
          type: 'all'
        })
        this.response.sheets = data
      } catch (ex) {
        this.appNotify({
          message: ex.message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_sheets.send=false
      }
    },
    async deleteSheet(sheet){
      const sheet_id = sheet?.id
      if(!sheet_id || this.api.delete_sheet.send==sheet_id) return;
      try {
        this.api.delete_sheet.send=sheet_id
        const { confirm } = await this.$appConfirmation({
          title: "Warning",
          message: `Are you sure you want to delete the list`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        });
        if(!confirm) return;
        await VOIP_API.endpoints.power_dialer.deleteSheet(sheet_id)
        this.appNotify({
          message: 'Successfully Deleted',
          type: 'success',
        })
        this.fetchSheets()
      } catch (ex) {
        this.appNotify({
          message: ex.message,
          type: 'danger',
        })
      } finally {
        this.api.delete_sheet.send=''
      }
    },
    async exportSheet(sheet){
      const sheet_id = sheet?.id
      if(!sheet_id || this.api.export_sheet.send==sheet_id) return;
      try {
        this.api.export_sheet.send=sheet_id
        const { data } = await VOIP_API.endpoints.power_dialer.exportSheet(sheet_id)
        const blobUrl = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.target = '_blank';
        link.download = `${sheet.name} - ${moment().format('DD MMM YYYY hh_mm_ss a')}.xlsx`;
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (ex) {
        this.appNotify({
          message: ex.message,
          type: 'danger',
        })
      } finally {
        this.api.export_sheet.send=''
      }
    },
  },
  mounted(){
    this.fetchSheets()
  },
}
</script>

<style>

</style>