<template>
  <modal class="modalForDropdown" width="600px" height="auto" :scrollable="true" :name="modalName" @before-close="onBeforeClose()" @before-open="onBeforeOpen($event)">
    <section class="ct-user-new dialer-edit">
      <div class="dialer-edit-header d-flex align-items-center justify-content-between pt-0">
        <div class="flex-1 pr-3">
          <h2 class="dialer-settings-title newer">Select extension</h2>
          <!-- <div class="dialer-settings-intro newerTextVersion w-100 mb-0">
            Calls are distributed among the members handling a queue 
            with one of several strategies.
          </div> -->
        </div>
        <div class="dialer-edit-actions">
          <button class="newDoneButton" @click="select()">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Done</span>
          </button>
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023 mt-20px">
        <div class="whiteBoxWithBorderInsideGreyBox">
          <template v-if="api.fetch_extensions.send">
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4 justify-content-start" v-for="n in 3" :key="n.id">
              <div class="latestShimmerDesign mx-3" style="height: 24px;width: 24px;"></div>
              <div class="d-flex align-items-center">
                <div class="latestShimmerDesign mr-2" style="height: 45px;width: 45px;"></div>
                <div class="d-flex flex-column">
                  <div class="latestShimmerDesign mb-2" style="height: 21px;width: 120px;"></div>
                  <div class="latestShimmerDesign" style="height: 20px;width: 60px;"></div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <!-- <button type="button" @click="select()">Done</button> -->
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4 justify-content-start"
              v-for="extension in response.extensions" 
              :key="extension.accountcode" 
              @click="
                localConditions.extension_required ? 
                  selected.extension_accountcode=extension.accountcode : 
                  selected.extension_accountcode=selected.extension_accountcode==extension.accountcode ? '' : extension.accountcode
              "
            >
              <b-icon v-if="extension.accountcode==selected.extension_accountcode" icon="check-square-fill" variant="black" font-scale="1.5"  />
              <b-icon v-else icon="square" variant="black" font-scale="1.5"  />
              <div class="latestGreyBox-heading-main ml-4 onlyfirstLetterCapital restLowerCase">{{ extension.accountname }}</div>
            </div>
          </template>
        </div>
      </div>
    </section>
  </modal>
</template>

<script>
import { VOIP_API } from '@/utils'
import { requiredIf } from 'vuelidate/lib/validators';
export default {
  name: 'SelectUserExtension',
  inject: [
    'appNotify'
  ],
  props: {
    modalName: {
      type: String,
      default: 'SelectUserExtensionModal'
    },
    conditions: {
      type: Object,
      default: ()=>({}),
    },
  },
  data() {
    return {
      api: {
        fetch_extensions: this.$helperFunction.apiInstance(),
      },
      response: {
        extensions: [],
      },
      data: {
        user_accountcode: '',
        extra_data: {},
        conditions: {},
      },
      selected: {
        extension_accountcode: '',
      },
    }
  },
  computed: {
    localConditions() {
      return {
        extension_required: this.data.conditions?.extension_required ?? this.conditions?.extension_required ?? true,
      } 
    }
  },
  validations: {
    selected: {
      extension_accountcode: {
        required: requiredIf(function (){ return this.localConditions.extension_required })
      },
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.data.user_accountcode=event?.params?.accountcode ?? ''
      this.data.extra_data=event?.params?.extra_data ?? {}
      this.selected.extension_accountcode=event?.params?.selected ?? ''
      if(typeof event?.params?.conditions?.extension_required == 'boolean') this.$set(this.data.conditions,'extension_required',event?.params?.conditions?.extension_required)
      this.fetchUserExtensions()
    },
    onBeforeClose(){
      this.api.fetch_extensions.reset()
      this.data.user_accountcode=''
      this.data.conditions={}
      this.data.extra_data={}
      this.response.extensions=[]
      this.selected.extension_accountcode=''
    },
    async fetchUserExtensions(){
      if(this.api.fetch_extensions.send) return;
      try {
        this.api.fetch_extensions.send=true
        const { data } = await VOIP_API.endpoints.extensions.getExtensionByUserAccountcode({
          user_accountcode: this.data.user_accountcode,
        })
        this.response.extensions=data[this.data.user_accountcode] ?? []
        
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_extensions.send=false
      }
    },
    select(){
      this.$v.selected.extension_accountcode.$touch()
      if(this.$v.selected.extension_accountcode.$invalid) {
        this.appNotify({
          message: 'Please Select One Extension',
          type: 'danger',
        })
        return;
      }
      this.$emit('select',{
        extension_accountcode: this.selected.extension_accountcode,
        extra_data: this.data.extra_data,
      })
      this.$modal.hide(this.modalName)
    },
  },
}
</script>

<style>

</style>