<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-500 EditNumbersModal min-width-50percent-modal" 
      width="50%" 
      height="auto" 
      :scrollable="true" 
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose($event)"
    >
      <section class="dialer-number-edit">
        <div class="dialer-edit">
          <div class="dialer-edit-header flex-column align-items-start">
            <div class="d-flex justify-content-between align-items-center w-100">
              <div>
                <h2 class="dialer-settings-title newer">Manage number: {{ (data.number.didWithCode || data.number.number) | number_formater }}</h2>
              </div>
              <div class="dialer-edit-actions">
                <button 
                  :disabled="api.delete_number.send" 
                  class="newDoneButton" 
                  type="button" 
                  @click="$modal.hide(modalName)"
                >
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart">Done</span>
                </button>
              </div>
            </div>
            <p class="vm--modal-text pb-3">Easily control and customise your phone number settings.</p>
          </div>
          <div class="latestGreyBox-9-9-2023 mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox">
              <div v-if="!isCallerId" class="whiteBoxWithBorderInsideGreyBox-row">
                <div class="d-flex flex-column w-100">
                  <div class="latestGreyBox-heading-main">Incoming calls</div>
                  <div class="latestGreyBox-descriptionText onlyfirstLetterCapital restLowerCase">
                    When {{ (data.number.didWithCode || data.number.number) | number_formater }} 
                    is called it will ring to
                    {{ data.extensionType | filterExtensionType }}
                  </div>
                  <div class="d-flex align-items-center justify-content-between w-100 mt-24px">
                    <template v-if="data.number.ringgroupId==data.assigned">
                      <div class="d-flex IVR-numbers-container flex-wrap">
                        <div v-for="assigned in data.number.ringgroup_members" :key="assigned" class="newIVRLayout-number-item hasUuersInIt">
                          <Info class="AganUpdatedOn25July2023" :id="assigned" :is_blf="false" />
                        </div>
                      </div>
                    </template>
                    <template v-else-if="data.assigned">
                      <div class="d-flex align-items-center">
                        <Info class="AganUpdatedOn25July2023" :id="data.assigned" :is_blf="false">
                          <template #sub-info>
                            <div 
                              v-if="assignedTeam" 
                              class="ViewConfigurationText mr-0 mb-0" 
                              style="font-size: 14px;" 
                              @click="$modal.show('EditNumberAssignMembersTeam', { team: assignedTeam, type: 'AssignNumbersTeam' })"
                            >
                              {{ assignedTeam | get_property('members.length',0) }} members
                            </div>
                          </template>
                        </Info>
                        <b-button v-if="assignedTeam"
                          class="newThisDeviceIndicaterTextBox-25-11-2023 h-fit-content"
                          @click="assignedTeam.status !='active' ? 
                          appNotify({ 
                            type: 'danger', 
                            message: 'Unable to proceed because this team is suspended' 
                          }) 
                          : 
                          $modal.show(`${_uid}-EditTeamModal`, { 
                            team: assignedTeam 
                          })"
                        >
                          Configure
                        </b-button>
                        <b-button 
                          class="newThisDeviceIndicaterTextBox-25-11-2023 h-fit-content"
                          v-else-if="assignedIVR" 
                          @click="assignedIVR.status != '1' ? 
                          appNotify({ 
                            type: 'danger', 
                            message: 'Unable to proceed because this ivr is suspended' 
                          }) 
                          : 
                          $modal.show(`${_uid}-EditMenuModal`, { 
                            menu: assignedIVR 
                          })"
                        >
                          Configure
                        </b-button>
                        <b-button v-else-if="assignedUser"
                          class="newThisDeviceIndicaterTextBox-25-11-2023 h-fit-content"
                          @click="assignedUser.active_status != '1' ? 
                          appNotify({ 
                            type: 'danger', 
                            message: 'Unable to proceed because this user is suspended' 
                          }) 
                          : 
                          $modal.show(`${_uid}-EditUserModal`, { 
                            accountcode: assignedUser.voipaccount 
                          })"
                        >
                          Configure
                        </b-button>
                        <b-button v-else-if="assignedQueue"
                          class="newThisDeviceIndicaterTextBox-25-11-2023 h-fit-content"
                          @click="assignedQueue.status!='1' ? 
                          appNotify({ 
                            type: 'danger', 
                            message: 'Unable to proceed because this call queue is suspended' 
                          }) 
                          : 
                          $modal.show(`${_uid}-EditCallQueueModal`,{ 
                            call_queue: assignedQueue 
                          })"
                        >
                          Configure
                        </b-button>
                      </div>
                    </template>
                    <button 
                      :disabled="api.delete_number.send" 
                      class="newButton ml-auto" 
                      @click="$modal.show(`${_uid}-NumberAssignModal`,{
                        number_id: numberId,
                        assigneds: data.number.ringgroupId==data.assigned ? data.number.ringgroup_members : data.assigned?[data.assigned]:[],
                        user_mode: data.user_mode,
                        extra_data: {
                          ringgroupId: data.number.ringgroupId
                        }
                      })"
                    >
                      <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                      <span class="newButton-textPart">{{ isExtensionAssign ? "Change" : "Assign" }}</span>
                    </button>
                  </div>
                  <div v-if="!isCallerId">
                    <div v-if="assignedTeam">
                      
                    </div>
                    <div v-else-if="assignedIVR">
                      <div class="w-100 newIVRLayout-forBorderBottom mt-20px">
                        <WelcomeGreeting class="newIVRLayout-WelcomeGreetingsSection"
                          @fileChange="$emit('ivr-update')"
                          :accountcode="assignedIVR.voipaccount"
                          :menu="assignedIVR"
                        />
                      </div>
                      <!-- Menu options section -->
                      <MenuOptionsWidget :menu="assignedIVR" @updated="$emit('ivr-update');" />
                    </div>
                    <div v-else-if="assignedUser">
                      
                    </div>
                    <div v-else-if="assignedQueue">
                      
                    </div>
                  </div>
                </div>
              </div>
              <template v-if="!isCallerId && ringgroupAccountcode && (data.extensionType=='USER' || data.number.ringgroup_members.length==1)">
                <div class="whiteBoxWithBorderInsideGreyBox-row">
                  <div class="d-flex flex-column w-75">
                    <div class="latestGreyBox-heading-main">Dedicate this number to a user</div>
                    <div class="latestGreyBox-descriptionText">
                      This option assigns a telephone number to a user, allowing custom call routing, forwarding, and voicemail settings in the user profile section.
                    </div>
                  </div>
                  <div class="md-mar-left">
                    <div v-if="api.update_user_mode.send" class="latestShimmerDesign" style="height:34px;width:60px;border-radius: 25px"></div>
                    <b-form-checkbox 
                      v-else
                      v-b-tooltip 
                      :title="data.extensionType=='USER' || data.number.ringgroup_members.length==1?'':'Please select only one user to switch this toggle button'"
                      :disabled="api.update_user_mode.send || !(data.extensionType=='USER' || data.number.ringgroup_members.length==1)" 
                      class="newerSwitch" 
                      :checked="data.user_mode" 
                      @change="updateUserMode()"
                      ref="user-mode"
                      switch 
                    />
                  </div>
                </div>
              </template>
              <template v-if="!isCallerId && ringgroupAccountcode && !data.user_mode && data.number.ringgroupId==data.assigned">
                <div class="whiteBoxWithBorderInsideGreyBox-row somethingInside">
                  <CallForwardingSetting 
                    v-if="!!ringgroupAccountcode" 
                    @cf-updated="$emit('cf-updated')"
                    :forwardCallerId="true"
                    :account="ringgroupAccountcode"
                  />
                </div>
                <div class="whiteBoxWithBorderInsideGreyBox-row somethingInside pt-0 dialer_box_text_my-0">
                  <ScheduleSetting 
                    v-if="!!ringgroupAccountcode" 
                    @ofh-days-updated="$emit('ofh-days-updated')"
                    @ofh-default-updated="$emit('ofh-default-updated')"
                    @ofh-toggle-updated="$emit('ofh-toggle-updated')"
                    :account="ringgroupAccountcode" 
                  />
                </div>
                <div class="whiteBoxWithBorderInsideGreyBox-row somethingInside dialer_box_text_my-0">
                  <MusicOnHoldWidget v-if="ringgroupAccountcode" :accountcode="ringgroupAccountcode"  />
                </div>
              </template>
              <div class="whiteBoxWithBorderInsideGreyBox-row">
                <div class="d-flex flex-column w-100">
                  <div class="latestGreyBox-heading-main">Outgoing calls only</div>
                  <div class="latestGreyBox-descriptionText">
                    The assigned users will only be able to dial outbound call using the "Outbound only number" as caller ID. 
                  </div>
                  <div class="d-flex align-items-center justify-content-between w-100 mt-24px">
                    <div class="d-flex IVR-numbers-container flex-wrap">
                      <div v-for="extension in data.extensions" :key="extension" class="newIVRLayout-number-item hasUuersInIt">
                        <Info class="AganUpdatedOn25July2023" :id="extension | filterExtensionValue" :is_blf="false" />
                      </div>
                    </div>
                    <button 
                      :disabled="api.delete_number.send" 
                      class="newButton md-mar-left" 
                      @click="$modal.show('UserCallerIdShared2', {
                        extensions: data.extensions, 
                        number: data.number, 
                        type: 'UserCallerIdShared'
                      })"
                    >
                      <vb-icon icon="squared-singleUser-icon" height="38px" width="38px" />
                      <span class="newButton-textPart">Add users</span>
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="!isCallerId" class="whiteBoxWithBorderInsideGreyBox-row rowThatHasLabel">
                <div class="d-flex flex-column w-75">
                  <h3 class="latestGreyBox-heading-main mb-0">Label</h3>
                  <p class="latestGreyBox-descriptionText">Personalise your phone number with a custom label for easy identification.</p>
                  <div class="whiteBGinputWithGreyRoundedBorder w-50 position-relative addedLabelInsideEditNumbersModal">
                    <label> {{data.number.label ? `( ${data.number.label} )` : 'No label assigned'}} </label>
                  </div>
                </div>
                <b-button class="newButton" :disabled="api.delete_number.send" @click="$modal.show('ChangeNumberLabel',{ label: data.number.label, })">
                  <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                  <span class="newButton-textPart">Change</span>
                </b-button>
              </div>
            </div>
            <div v-if="!isCallerId" class="mt-20px">
              <div class="latestGreyBox-heading-main">Call recording</div>
              <div class="latestGreyBox-descriptionText">
                Call recordings will be available to review and share in the activity feed on the dashboard and in the phone app.
              </div>
              <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
                <div class="whiteBoxWithBorderInsideGreyBox-row">
                  <div class="d-flex flex-column w-100">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="d-flex flex-column">
                        <div class="latestGreyBox-heading-main">Record incoming calls</div>
                        <div class="latestGreyBox-descriptionText">If enabled, this will start recording <b>incoming calls</b> automatically</div>
                      </div>
                      <div v-if="api.toggle_incoming_call.send || api.fetch_number_settings.send" class="latestShimmerDesign" style="height:34px;width:60px;border-radius: 25px"></div>
                      <b-form-checkbox 
                        v-else
                        v-b-tooltip :title="!getUserPermissions.call_recording?'Your package does not support this feature':''"
                        class="newerSwitch"  
                        name="check-button" 
                        switch 
                        :disabled="!getUserPermissions.call_recording || api.toggle_incoming_call.send || api.fetch_number_settings.send"
                        ref="toggle_incoming_call_ref"
                        :checked="data.incoming_call"
                        @change="toggleIncomingCall($event)"
                      />
                    </div>
                    <div class="innerRedBoxwithRoundedBorder mt-20px">
                      <vb-icon icon="dialer-thunder-icon" height="23.871px" width="11.936px" class="dialer-thunder-icon"/>
                      <p class="innerRedBoxwithRoundedBorder-text mb-0">
                        We recommend you mention that the call will be recorded in the welcome message. If you 
                        wish to delete recorded calls, contact our support team.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="whiteBoxWithBorderInsideGreyBox-row">
                  <div class="d-flex flex-column align-items-start justify-content-center w-100">
                    <div class="d-flex justify-content-between align-items-center w-100">
                      <div class="latestGreyBox-heading-main">Play welcome message</div>
                      <div v-if="api.toggle_welcome_greeting.send || api.fetch_number_settings.send" class="latestShimmerDesign" style="height:34px;width:60px;border-radius: 25px"></div>
                      <b-form-checkbox v-else
                        class="newerSwitch"  
                        name="check-button" 
                        switch 
                        :disabled="api.toggle_welcome_greeting.send || api.fetch_number_settings.send"
                        ref="toggle_welcome_greeting_ref"
                        :checked="data.welcome_greeting"
                        @change="toggleWelcomeGreeting($event)"
                      />
                    </div>
                    <div v-if="!data.welcome_greeting" class="latestGreyBox-descriptionText">Customise the first impression of your business by uploading or recording a personalised welcome greeting.</div>
                    <WelcomeGreeting 
                      v-if="data.welcome_greeting" class="newIVRLayout-WelcomeGreetingsSection "
                      :file="data.welcome_greeting_announcement_url"
                      @fileChange="$emit('interface')"
                      :number="data.number"
                    />
                  </div>
                </div>
                <div class="whiteBoxWithBorderInsideGreyBox-row">
                  <div class="d-flex flex-column">
                    <div class="latestGreyBox-heading-main">Record outgoing calls</div>
                    <div class="latestGreyBox-descriptionText">If enabled, this will start recording <b>outgoing calls</b> automatically</div>
                  </div>
                  <div v-if="api.toggle_outgoing_call.send || api.fetch_number_settings.send" class="latestShimmerDesign" style="height:34px;width:60px;border-radius: 25px"></div>
                  <b-form-checkbox 
                    v-else
                    v-b-tooltip :title="!getUserPermissions.call_recording?'Your package does not support this feature':''"
                    class="newerSwitch"  
                    name="check-button" 
                    switch 
                    :disabled="!getUserPermissions.call_recording || api.toggle_outgoing_call.send || api.fetch_number_settings.send"
                    ref="toggle_outgoing_call_ref"
                    :checked="data.outgoing_call"
                    @change="toggleOutgoingCall($event)"
                  />
                </div>
                <div class="whiteBoxWithBorderInsideGreyBox-row">
                  <div>
                    <div class="latestGreyBox-heading-main">Call recording transcriptions</div>
                    <div class="latestGreyBox-descriptionText">If enabled, this will automatically transcribe the recorded calls.</div>
                  </div>
                  <div v-if="api.toggle_transcriptions.send || api.fetch_number_settings.send" class="latestShimmerDesign" style="height:34px;width:60px;border-radius: 25px"></div>
                  <b-form-checkbox 
                    v-else
                    v-b-tooltip 
                    :title="!getUserPermissions.call_recording_transcriptions?'Your package does not support this feature':!data.incoming_call && !data.outgoing_call?'Firstly enable any one of the incoming or outgoing recording':''"
                    class="newerSwitch"  
                    name="check-button" 
                    switch 
                    :disabled="(!data.incoming_call && !data.outgoing_call) || !getUserPermissions.call_recording_transcriptions || api.toggle_transcriptions.send || api.fetch_number_settings.send"
                    ref="toggle_transcriptions_ref"
                    :checked="data.transcriptions"
                    @change="toggleTranscriptions($event)"
                  />
                </div>
              </div>
            </div>
            <div v-if="!isCallerId" class="mt-20px">
              <div class="latestGreyBox-heading-main mb-20px">Holiday Groups</div>
              <vb-table 
                class="latestTableDesign-withBlackBorders-again showLastTDonHover mt-0 p-0"
                :isListEmpty="response.holiday_groups.length==0" 
                :listLength="response.holiday_groups.length" 
                :loading="api.fetch_holiday_groups.send" 
              >
                <tr slot="header" v-if="!getIsMobile">
                  <th class="dialer-has-sort px-2 dialer-col-left">
                    <span>Name</span>
                  </th>
                  <th></th>
                </tr>
                <template #body="{ start, end }">
                  <tr v-for="(holiday_group, index) in response.holiday_groups" :key="holiday_group.id" v-show="index >= start && index <= end" @click.stop="$modal.show(`${_uid}-UpdatePublicHolidayGroup`,{ id: holiday_group.id })">
                    <td class="dialer-has-sort dialer-col-left">{{holiday_group.name || ''}}</td>
                    <td class="dialer-has-sort dialer-col-right">
                      <b-checkbox class="newerSwitch" switch
                        @click.native.stop="''"
                        :ref="`holiday-group-${holiday_group.id}-checkbox`"
                        :disabled="!!api.attach_deattach_number_holiday_group.send"
                        :checked="selected.holiday_groups.includes(holiday_group.id)" 
                        @change="attachDeattachNumberHolidayGroup(holiday_group)" 
                      />
                    </td>
                  </tr>
                </template>
              </vb-table>
            </div>
            <template v-if="!isCallerId && ringgroupAccountcode && !data.user_mode && data.number.ringgroupId==data.assigned">
              <VoicemailSetting class="VoicemailSetting-insideTeamsModal" v-if="ringgroupAccountcode" :accountcode="ringgroupAccountcode" />
            </template>
            <!-- Emergency services -->
            <EmergenyService v-if="data.number.did" :did="data.number.did" />
            <template v-if="!isCallerId">
              <div v-if="!isExtensionAssign && data.number.is_trial!=1" class="mt-20px">
                <div class="whiteBoxWithBorderInsideGreyBox">
                  <div class="whiteBoxWithBorderInsideGreyBox-row">
                    <div class="latestGreyBox-heading-main">Delete this number?</div>
                    <div class="md-mar-left">
                      <span v-if="data.number.status==4">{{ data.number.actionable_date }}</span>
                      <button v-else class="newDeleteButton" :disabled="api.delete_number.send" @click="deletenumber()">
                        <vb-icon icon="squared-delete-icon" height="38px" width="38px" />
                        <span class="newDeleteButton-textPart">Delete</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else-if="isCallerId">
              <!-- <div class="mt-20px">
                <div class="whiteBoxWithBorderInsideGreyBox">
                  <div class="whiteBoxWithBorderInsideGreyBox-row">
                    <div class="d-flex flex-column w-75">
                      <div class="latestGreyBox-heading-main">Allow add caller ID</div>
                      <div class="latestGreyBox-descriptionText">
                        There is a decription about that
                      </div>
                    </div>
                    <div class="md-mar-left">
                      <div v-if="api.change_allow_add_callerid.send" class="latestShimmerDesign" style="height:34px;width:60px;border-radius: 25px"></div>
                      <b-form-checkbox v-else
                        :disabled="api.change_allow_add_callerid.send" 
                        class="newerSwitch" 
                        :checked="data.allow_add_callerid" 
                        @change="changeAllowAddCallerID()"
                        ref="allow-add-callerid"
                        switch 
                      />
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="mt-20px">
                <div class="whiteBoxWithBorderInsideGreyBox">
                  <div class="whiteBoxWithBorderInsideGreyBox-row">
                    <div class="latestGreyBox-heading-main">Delete this caller id?</div>
                    <div class="md-mar-left">
                      <span v-if="data.number.status==4">{{ data.number.actionable_date }}</span>
                      <button v-else class="newDeleteButton" :disabled="api.delete_number.send" @click="deletecallerid()">
                        <vb-icon icon="squared-delete-icon" height="38px" width="38px" />
                        <span class="newDeleteButton-textPart">Delete</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </section>
    </modal>
    <AddRemoveFromList modalName="UserCallerIdShared2" @update-list="onCallerIDUpdated($event)" />
    <NumberAssignModal :modalName="`${_uid}-NumberAssignModal`" @updated="onUpdateAssign($event);" />
    <AddRemoveFromList modalName="EditNumberAssignMembersTeam" @update-list="getVoipTeams()" />
    <ChangeNumberLabelModal @updated="data.number.label=$event.label;$emit('latest');" :numberId="numberId" />
    <!-- ivr -->
    <EditMenuModal :modalName="`${_uid}-EditMenuModal`" @interface="$emit('ivr-update')" />
    <!-- team -->
    <EditTeamModal :modalName="`${_uid}-EditTeamModal`" @interface="$emit('team-update')" />
    <!-- user -->
    <EditUserModal :modalName="`${_uid}-EditUserModal`" />
    <!-- queue -->
    <EditCallQueueModal :modalName="`${_uid}-EditCallQueueModal`" @list-update="$emit('queue-update')" />
    <UpdatePublicHolidayGroupModal :modalName="`${_uid}-UpdatePublicHolidayGroup`" @updated="fetchHolidayGroups()" @removed="fetchHolidayGroups()" />
  </div>
</template>

<script>
import { VOIP_API } from "../../utils";
import Info from '../Lists/Info.vue';
// import ScheduleAssignModal from "./ScheduleAssignModal.vue";
import { required } from "vuelidate/lib/validators";
import AddRemoveFromList from "../Modals/AddRemoveFromList.vue";
import { mapGetters } from 'vuex';
import EmergenyService from '../widgets/numbers/EmergenyService.vue';


import WelcomeGreeting from "../IVR/WelcomeGreeting.vue";
import ChangeNumberLabelModal from './ChangeNumberLabel.vue';
import NumberAssignModal from './NumberAssignModal.vue';
import MusicOnHoldWidget from '../widgets/MusicOnHold.vue'
import ScheduleSetting from '../Common/ScheduleSetting.vue';
import CallForwardingSetting from '../Common/CallForwardingSetting.vue';
// import MissedCallNotificationWidget from '../widgets/calls/MissedCallNotificationWidget.vue';
import VoicemailSetting from "../Voicemail/Setting.vue";

// ivr
import MenuOptionsWidget from '../widgets/ivr/MenuOptionsWidget.vue';
import EditMenuModal from "../Modals/EditMenuModal.vue";
// team
import EditTeamModal from "../Modals/EditTeamModal.vue";
// user
import EditUserModal from "../Modals/EditUserModal.vue";
// queue
import EditCallQueueModal from '../Modals/EditCallQueueModal.vue'
import UpdatePublicHolidayGroupModal from './public-holidays/UpdatePublicHolidayGroupModal.vue';
import moment from 'moment-timezone';
import { CountryName2 } from '@/utils/flags';

export default {
  name: "EditNumbersModal",
  components: { 
    Info,
    AddRemoveFromList,
    // ScheduleAssignModal,
    EmergenyService,
    NumberAssignModal,
    WelcomeGreeting,
    ChangeNumberLabelModal,
    MusicOnHoldWidget,
    ScheduleSetting,
    CallForwardingSetting,
    // MissedCallNotificationWidget,
    VoicemailSetting,
    // ivr
    MenuOptionsWidget,
    EditMenuModal,
    // team
    EditTeamModal,
    // user
    EditUserModal,
    // queue
    EditCallQueueModal,
    UpdatePublicHolidayGroupModal,
  },
  inject: [
    'getVoipTeams',
    'appNotify'
  ],
  props: {
    modalName: {
      type: String,
      default: 'EditNumbersModal'
    },
  },
  data() {
    return {
      data: this.$helperFunction.stateInstance({
        data: {
          assign_to: {
            label: '',
            extn: '',
          },
          extensions: [],
          // number: {},
          number: {},
          extensionType: '',
          assigned: '',
          allow_add_callerid: false,
          incoming_call: false,
          welcome_greeting: false,
          outgoing_call: false,
          transcriptions: false,
          user_mode: false,
          welcome_greeting_announcement_url: ""
        }
      }),
      api: {
        update_user_mode: this.$helperFunction.apiInstance(),
        delete_number: this.$helperFunction.apiInstance(),
        update_label: this.$helperFunction.apiInstance(),
        update_extension: this.$helperFunction.apiInstance(),
        change_allow_add_callerid: this.$helperFunction.apiInstance(),
        fetch_number_settings: this.$helperFunction.apiInstance(),
        toggle_incoming_call: this.$helperFunction.apiInstance(),
        toggle_welcome_greeting: this.$helperFunction.apiInstance(),
        toggle_outgoing_call: this.$helperFunction.apiInstance(),
        toggle_transcriptions: this.$helperFunction.apiInstance(),
        fetch_holiday_groups: this.$helperFunction.apiInstance(),
        attach_deattach_number_holiday_group: this.$helperFunction.apiInstance(),
      },
      response: {
        holiday_groups: [],
      },
      selected: {
        holiday_groups: [],
      },
      forms: {
        update_label: this.$helperFunction.formInstance({
          data: {
            name: '',
          }
        }),
      },
    };
  },
  computed: {
    ...mapGetters([
      'getIsMobile',
      'getCurrentUser',
      'getVoipTeamsAlises',
      'getVoipUsersAlises',
      'getVoipIVRSAlises',
      'getVoipCallQueuesAlises',
      'getUserPermissions',
    ]),
    isLabelChanged(){ return this.forms.update_label.name!=(this.data.number.label ?? '') },
    isExtensionAssign(){ return this.data.assigned /*this.data.assign_to.extn && this.data.assign_to.label*/ },
    teamMode(){ return this.data.number?.ringgroupId==this.data.assigned },
    ringgroupAccountcode(){ return this.data?.number?.ringgroupId },
    isCallerId(){ return this.data.number?.object=='CallerId' },
    numberId(){ return this.isCallerId ? this.data.number.id : this.data.number.real_id },
    assignedTeam(){ return this.getVoipTeamsAlises[this.data.assigned] },
    assignedIVR(){ return this.getVoipIVRSAlises[this.data.assigned] },
    assignedUser(){ return this.getVoipUsersAlises[this.data.assigned] },
    assignedQueue(){ return this.getVoipCallQueuesAlises[this.data.assigned] },
    assignedTeamMembers(){ return this.assignedTeam?.members?.length ?? 0 },
    recordingwatchProperty(){
      return {
        outgoing_call: this.data.outgoing_call,
        incoming_call: this.data.incoming_call,
        toggle_transcriptions: this.data.transcriptions
      }
    },
    holidayGroups(){ return this.response.holiday_groups },
  },
  validations: {
    forms: {
      update_label: {
        name: {
          required,
        },
      },
    },
  },
  filters: {
    filterExtensionValue(value) {
      const str = value.replaceAll('<span class="label label-primary">','').replaceAll("<span class=\"label label-default\" style=\"color:black\">",'').replaceAll("<span class=\"label label-info\">",'').replaceAll('</span>','')
      return isNaN(parseInt(str.slice(0,4))) ? str.slice(-4) : str.slice(0,4)
    },
    filterExtensionType(value){
      if(value=='BASICIVR') return 'IVR'
      else return value
    },
  },
  watch: {
    recordingwatchProperty: {
      deep: true,
      async handler(){
        await this.$nextTick()
        if(!this.data.outgoing_call && !this.data.incoming_call) {
          this.data.transcriptions=false
        }
      },
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.data.number = event?.params?.number || {};
      this.data.assign_to.extn=this.data.number?.assignedToUsersArr?.extn || ''
      this.data.assign_to.label=this.data.number?.assignedToUsersArr?.label || ''
      this.data.extensions=this.data.number?.extensions || []
      this.forms.update_label.name = this.data.number?.label || ''
      this.data.extensionType = this.data.number?.extnType || ''
      this.data.assigned = this.data.number?.assigned || ''
      this.data.user_mode = this.data.number?.user_mode==1
      this.data.allow_add_callerid = this.data.number?.allow_share==1
      this.getNumberSetting()
      if(!this.isCallerId) {
        this.fetchHolidayGroups()
      }
    },
    onBeforeClose(){
      this.api.delete_number.reset()
      this.api.update_label.reset()
      this.forms.update_label.reset()
      this.data.reset()
      this.response.holiday_groups=[]
    },
    async fetchHolidayGroups(){
      if(this.api.fetch_holiday_groups.send) return;
      try {
        this.api.fetch_holiday_groups.send=true;
        const tz = moment().tz()
        const country = moment.tz(tz)._z.countries()?.[0]
        const { data: groups } = await VOIP_API.endpoints.holiday_schedule.list({
          timezone: tz,
          country: CountryName2[country],
        })
        const { data: selected } = await VOIP_API.endpoints.holiday_schedule.getByNumber({
          did: this.data.number.did,
          uid: this.getCurrentUser.uid,
        })
        this.response.holiday_groups=groups
        this.selected.holiday_groups=selected.map(i=>i.id)
      } catch (ex) {
        this.appNoytify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_holiday_groups.send=false;
      }
    },
    async attachDeattachNumberHolidayGroup(holiday_group){
      const holiday_group_id = holiday_group.id
      const did = this.data.number.did.replaceAll('-','')
      const is_selected = !!this.selected.holiday_groups.includes(holiday_group_id)
      const ref = this.$refs[`holiday-group-${holiday_group_id}-checkbox`]?.[0]
      if(ref) ref.localChecked=is_selected
      if(this.api.attach_deattach_number_holiday_group.send || !holiday_group_id || typeof holiday_group_id != 'number') return;
      try {
        this.api.attach_deattach_number_holiday_group.send=holiday_group_id
        if(is_selected){
          await VOIP_API.endpoints.holiday_schedule.deattach(holiday_group_id,{
            accountcode: did.replaceAll('-','')
          })
          this.selected.holiday_groups.splice(this.selected.holiday_groups.indexOf(holiday_group_id),1)
        } else {
          await VOIP_API.endpoints.holiday_schedule.attach(holiday_group_id,{
            accountcode: did.replaceAll('-','')
          })
          this.selected.holiday_groups.push(holiday_group_id)
        }
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger'
        })
      } finally {
        this.api.attach_deattach_number_holiday_group.send=''
        if(ref) ref.localChecked=is_selected
      }
    },
    async updateUserMode(){
      if(this.api.update_user_mode.send) return;
      try {
        this.api.update_user_mode.send=true
        const { data: { forwardto, memebers=[], user_mode } } = await VOIP_API.endpoints.telephone_number.updateUserMode(this.numberId)
        this.data.user_mode=user_mode==1
        this.data.number.ringgroup_members=memebers
        this.data.assigned=forwardto
        this.data.extensionType=user_mode==1?'USER':''
        this.$emit('latest')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.update_user_mode.send=false
        if(this.$refs['update-role']) this.$refs['update-role'].localChecked = this.data.user_mode
      }
    },
    onUpdateAssign({ extensions, extension_type, extra_data: { ringgroupId } }){
      if(extensions.length==0) {
        this.data.assigned=''
        this.data.extensionType=''
        this.data.number.ringgroup_members=[]
      } else if(extensions.length==1 && (extension_type=='USER' ? this.data.user_mode : true)){
        this.data.assigned=extensions[0] || ''
        this.data.extensionType=extension_type
        this.data.number.ringgroup_members=[]
        if(extension_type!='USER') {
          this.data.user_mode=false
        }
      } else {
        this.data.assigned=ringgroupId
        this.data.extensionType=''
        this.data.number.ringgroup_members=extensions
      }
      this.$emit('latest')
    },
    updateExtension(event) {
      let vm = this
      if(vm.api.update_extension.send) return;
      vm.api.update_extension.send=true
      VOIP_API.endpoints.telephone_number.updateExtn(vm.numberId, {
        extn: event?.account ?? '',
        field: "extn",
      })
      .then(() => {
        vm.$emit("latest");
        vm.data.assign_to.extn = event?.extn || ''
        vm.data.assign_to.label = event?.accountname || ''
        vm.data.assigned = event?.accountcode || ''
        vm.data.extensionType = event?.account_type || ''
        vm.appNotify({
          message: event ? "Successfully Added!" : "Successfully UnAssign!",
          type: "success",
        })
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.update_extension.send=false
      });
    },
    onCallerIDUpdated(Event){
      this.data.extensions=Object.values(Event).map(item=>item.html)
      this.$emit('latest')
    },
    async deletecallerid(){
      if(this.api.delete_number.send) return;
      try {
        const { confirm } = await this.$appConfirmation({
          title: "Delete caller id?",
          message: `Are you sure you want to delete this caller id.`,
          alert: {
            variant: 'danger',
            title: 'Warning',
            message: `This action is irreversible, You will lose all the configurations for this caller id.`,
          },
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          }
        })
        if(!confirm) return;
        this.api.delete_number.send=true
        await VOIP_API.endpoints.telephone_number.deleteCallerID({
          id: this.numberId,
          uid: this.getCurrentUser?.uid,
          accountcode: this.getCurrentUser?.account,
        })
        this.$emit("latest");
        this.$modal.hide(this.modalName);
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.delete_number.send=false
      }
    },
    deletenumber(){
      let vm = this
      if(vm.api.delete_number.send) return;
      vm.$appConfirmation({
        title: "Delete number?",
        message: `Are you sure you want to delete this number.`,
        alert: {
          variant: 'danger',
          title: 'Warning',
          message: `This action is irreversible, You will lose all the configurations for this number.`,
        },
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if(confirm){
            vm.api.delete_number.send=true
            VOIP_API.endpoints.telephone_number.deleteNumber({
              id: vm.numberId,
              uid: vm.getCurrentUser?.uid,
              accountcode: vm.getCurrentUser?.account,
            })
            .then(() => {
              vm.$emit("latest");
              vm.$modal.hide(vm.modalName);
            })
            .catch((ex)=>{
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            })
            .finally(() => {
              vm.api.delete_number.send=false
            });
          }
        }
      })
    },
    updateLabel(){
      let vm = this
      if(!vm.isLabelChanged){
        vm.$modal.hide(vm.modalName)
        return;
      }
      vm.forms.update_label.submitted=true
      vm.$v.forms.update_label.$touch()
      if (vm.$v.forms.update_label.$invalid || vm.api.update_label.send) return;
      vm.api.update_label.send=true
      VOIP_API.endpoints.telephone_number.updateLabel({
        field: "name",
        name: vm.forms.update_label.name,
      },vm.numberId)
      .then(()=>{
        vm.$emit("latest");
        vm.$modal.hide(vm.modalName);
        vm.appNotify({
          message: 'Successfully updated!',
          type: 'success',
        })
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.update_label.send=false
        vm.forms.update_label.submitted=false
      });
    },
    async changeAllowAddCallerID(){
      if(this.api.change_allow_add_callerid.send) return;
      try {
        this.api.change_allow_add_callerid.send=true
        await VOIP_API.endpoints.callerid.changeAllowAddCallerID({
          uid: this.getCurrentUser.uid,
          share_value: !this.data.allow_add_callerid,
          number: this.data.number.number,
        })
        this.$emit("latest");
        this.data.allow_add_callerid=!this.data.allow_add_callerid
        this.appNotify({
          message: 'Successfully Updated!',
          type: 'success'
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        if(this.$refs['allow-add-callerid']) this.$refs['allow-add-callerid'].localChecked = this.data.allow_add_callerid
        this.api.change_allow_add_callerid.send=false
      }
    },
    async getNumberSetting(){
      if(this.api.fetch_number_settings.send) return;
      try {
        this.api.fetch_number_settings.send=true
        const { data } = await VOIP_API.endpoints.telephone_number.getNumberSettings({
          did: this.data.number.did,
        })
        this.data.incoming_call = data?.allow_recording==1
        this.data.welcome_greeting = data?.enable_announcement==1
        this.data.welcome_greeting_announcement_url = data?.announcement_url

        this.data.outgoing_call = data?.allow_outgoing_recording==1
        this.data.transcriptions = data?.recording_transcript==1
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_number_settings.send=false
      }
    },
    async toggleIncomingCall(value){
      if(this.api.toggle_incoming_call.send) return;
      let checked;
      try {
        this.api.toggle_incoming_call.send=true
        await VOIP_API.endpoints.telephone_number.numberSettings({
          uid: this.getCurrentUser.uid,
          field: 'allow_recording',
          value: value ? 1 : 0,
          did: this.data.number.did,
        })
        checked=value
        this.appNotify({
          message: 'Successfully Updated',
          type: 'success',
        })
      } catch (ex) {
        checked=!value
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.data.incoming_call=checked
        if(this.$refs['toggle_incoming_call_ref']) this.$refs['toggle_incoming_call_ref'].localChecked = checked;
        this.api.toggle_incoming_call.send=false
      }
    },
    async toggleWelcomeGreeting(value){
      if(this.api.toggle_welcome_greeting.send) return;
      let checked;
      try {
        this.api.toggle_welcome_greeting.send=true
        await VOIP_API.endpoints.telephone_number.numberSettings({
          uid: this.getCurrentUser.uid,
          field: 'enable_announcement',
          value: value ? 1 : 0,
          did: this.data.number.did,
        })
        checked=value
        this.appNotify({
          message: 'Successfully Updated',
          type: 'success',
        })
      } catch (ex) {
        checked=!value
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.data.welcome_greeting=checked
        if(this.$refs['toggle_welcome_greeting_ref']) this.$refs['toggle_welcome_greeting_ref'].localChecked = checked;
        this.api.toggle_welcome_greeting.send=false
      }
    },
    async toggleOutgoingCall(value){
      if(this.api.toggle_outgoing_call.send) return;
      let checked;
      try {
        this.api.toggle_outgoing_call.send=true
        await VOIP_API.endpoints.telephone_number.numberSettings({
          uid: this.getCurrentUser.uid,
          field: 'allow_outgoing_recording',
          value: value ? 1 : 0,
          did: this.data.number.did,
        })
        checked=value
        this.appNotify({
          message: 'Successfully Updated',
          type: 'success',
        })
      } catch (ex) {
        checked=!value
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.data.outgoing_call=checked
        if(this.$refs['toggle_outgoing_call_ref']) this.$refs['toggle_outgoing_call_ref'].localChecked = checked;
        this.api.toggle_outgoing_call.send=false
      }
    },
    async toggleTranscriptions(value){
      if(this.api.toggle_transcriptions.send) return;
      let checked;
      try {
        this.api.toggle_transcriptions.send=true
        await VOIP_API.endpoints.telephone_number.numberSettings({
          uid: this.getCurrentUser.uid,
          field: 'recording_transcript',
          value: value ? 1 : 0,
          did: this.data.number.did,
        })
        checked=value
        this.appNotify({
          message: 'Successfully Updated',
          type: 'success',
        })
      } catch (ex) {
        checked=!value
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.data.transcriptions=checked
        if(this.$refs['toggle_transcriptions_ref']) this.$refs['toggle_transcriptions_ref'].localChecked = checked;
        this.api.toggle_transcriptions.send=false
      }
    },
  },
};
</script>
