
import { VOIP_API } from "../index"

export const HOLIDAY_SCHEDULE_API_ENDPOINTS = {
  create(data={}) {
    return VOIP_API.axios.voip.request({
      url: `schedule-name`,
      method: "POST",
      data,
    })
  },
  remove(id='',data={}) {
    return VOIP_API.axios.voip.request({
      url: `schedule-name/${id}`,
      method: "DELETE",
      data,
    })
  },
  update(id='',data={}) {
    return VOIP_API.axios.voip.request({
      url: `schedule-name/${id}`,
      method: "PATCH",
      data,
    })
  },
  list(params={}) {
    return VOIP_API.axios.voip.request({
      url: `schedules-names`,
      method: "GET",
      params,
    })
  },
  getByID(id='',params={}) {
    return VOIP_API.axios.voip.request({
      url: `schedules-names/${id}`,
      method: "GET",
      params,
    })
  },
  getByNumber(params={}) {
    return VOIP_API.axios.voip.request({
      url: `number-holiday-groups`,
      method: "GET",
      params,
    })
  },
  attach(id='',data={}){
    return VOIP_API.axios.voip.request({
      url: `schedule-name/attach/${id}`,
      method: "POST",
      data,
    })
  },
  deattach(id='',data={}){
    return VOIP_API.axios.voip.request({
      url: `schedule-name/deattach/${id}`,
      method: "DELETE",
      data,
    })
  },
  createRule(data={}){
    return VOIP_API.axios.voip.request({
      url: `schedule-name/rule`,
      method: "POST",
      data,
    })
  },
  updateRule(rule_id='',data={}){
    return VOIP_API.axios.voip.request({
      url: `schedule-name/rule/${rule_id}`,
      method: "PATCH",
      data,
    })
  },
  listRule(id='',params={}){
    return VOIP_API.axios.voip.request({
      url: `schedule-name/${id}/rule`,
      method: "GET",
      params,
    })
  },
  removeRule(rule_id='',data={}){
    return VOIP_API.axios.voip.request({
      url: `schedule-name/rule/${rule_id}`,
      method: "DELETE",
      data,
    })
  },
  getRulesAgainstNumber(accountcode='',params){
    return VOIP_API.axios.voip.request({
      url: `schedule-name/${accountcode}`,
      method: "GET",
      params,
    })
  },
  getCountryPublicHolidays(data={}){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data: {
        ...data,
        type: 'relay',
        action: 'public_holidays',
      },
    })
  },
}