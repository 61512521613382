<template>
  <modal 
    class="center-zoomIn-transition v2 SelectUnavailableTime" 
    :scrollable="true" 
    width="100%" 
    height="auto" 
    :clickToClose="false" 
    :name="modalName" 
    @before-close="onBeforeClose($event)" 
    @before-open="onBeforeOpen($event)"
    @opened="onOpened($event)"
  >
    
    <div class="dialer-edit w-100">
      <div v-if="getIsMobile" class="dialer-edit-header mt-0 flex-column">
        <div class="d-flex w-100 justify-content-between align-items-center mb-2">
          <h2 class="dialer-edit-title">Select unavailable time</h2>
        </div>
        <div class="dialer-edit-actions d-flex justify-content-end w-100">
          <button class="newDoneButton" @click="onDone()">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Done</span>
          </button>
        </div>
      </div>
      <div v-else class="dialer-edit-header b-0">
        <h2 class="dialer-edit-title">Select unavailable time</h2>
        <div class="dialer-edit-actions d-flex">
          <a class="newCloseButton" type="button" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a>
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023 mt-20px">
        <div class="UnavailableTimes-row">
          <div class="UnavailableTimes-startTime">
            <b-form-timepicker v-model="form.start" @input="onValidate()" locale="en" >
              <template #button-content>
                <vb-icon icon="UnavailableTimes-TimesIcon" class="UnavailableTimes-TimesIcon" />
                <div>
                  <div class="UnavailableTimes-smallHeading">Unavailable</div>
                  <div class="UnavailableTimes-bigHeading">Start time</div>
                </div>
              </template>
            </b-form-timepicker>
          </div>
          <div class="UnavailableTimes-endTime">
            <b-form-timepicker v-model="form.end" @input="onValidate()" locale="en">
              <template #button-content>
                <vb-icon icon="UnavailableTimes-TimesIcon-endTime" class="UnavailableTimes-TimesIcon" />
                <div>
                  <div class="UnavailableTimes-smallHeading">Unavailable</div>
                  <div class="UnavailableTimes-bigHeading">End time</div>
                </div>
              </template>
            </b-form-timepicker>
          </div>
        </div>
        <div class="whiteBoxWithBorderInsideGreyBox withoutBorder mt-20px pb-4">
          <div class="whiteBoxWithBorderInsideGreyBox-row">
            <div class="UnavailableTimes-slider" ref="slider" />
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end w-100 mt-20px">
        <button class="fullWidthDoneButton" @click="onDone()">Update</button>
      </div>
    </div>
  </modal>
</template>

<script>
import moment from 'moment-timezone';
import * as noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';
import { mapGetters } from 'vuex';
export default {
  name: "TimeSelectionModal",
  props: {
    modalName: {
      type: String,
      default: "TimeSelectionModal",
    },
  },
  data() {
    return {
      form: {
        start: '',
        end: '',
      },
      submitted: {
        done: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getIsMobile'
    ]),
  },
  methods: {
    onBeforeOpen(event) {
      this.form.start = event?.params?.start ?? '00:00:00';
      this.form.end = event?.params?.end ?? '00:00:00';
    },
    onOpened(){
      const start_day = moment(`2023-12-12 00:00:00`,'YYYY-MM-DD HH:mm:ss').startOf('days')
      const start = moment(`2023-12-12 ${this.form.start}`,'YYYY-MM-DD HH:mm:ss')
      const end = moment(`2023-12-12 ${this.form.end}`,'YYYY-MM-DD HH:mm:ss')
      noUiSlider.create(this.$refs.slider, {
        start: [
          start.diff(start_day,'minutes'),
          end.diff(start_day,'minutes'),
        ],
        connect: true,
        step: 1,
        tooltips: [ true, true ],
        range: {
          'min': 0,
          'max': 1440
        },
        pips: {
          mode: 'steps',
          filter: (value) => (value % (60 * 4) === 0) ? 1 : (value % 60 === 0) ? 2 : 0,
          format: {
            to: (value) => value % (60 * 4)==0 ? moment().startOf('day').add(value, 'minutes').format('hh:mm a') : '',
            from: (value) => value,
          }
        },
        format: {
          to: (value) => moment().startOf('day').add(value, 'minutes').format('hh:mm a'),
          from: (value) => value,
        }
      });
      this.$refs.slider.noUiSlider.on("change", this.onSliderChange.bind(this));
    },
    onBeforeClose() {
      this.submitted.done = false;
      this.form.start = '';
      this.form.end = '';
      this.$emit('before-close')
    },
    onSliderChange(values,handle,unencoded/*,tap,positions,noUiSlider*/){
      this.form.start=moment(`2023-12-12 00:00:00`,'YYYY-MM-DD HH:mm:ss').startOf('day').add(unencoded[0], 'minutes').format('HH:mm:ss')
      this.form.end=moment(`2023-12-12 00:00:00`,'YYYY-MM-DD HH:mm:ss').startOf('day').add(unencoded[1], 'minutes').format('HH:mm:ss')
    },
    onValidate(){
      const start_day = moment(`2023-12-12 00:00:00`,'YYYY-MM-DD HH:mm:ss').startOf('days')
      const start = moment(`2023-12-12 ${this.form.start}`,'YYYY-MM-DD HH:mm:ss')
      const end = moment(`2023-12-12 ${this.form.end}`,'YYYY-MM-DD HH:mm:ss')
      if(end.diff(start_day,'minutes')<start.diff(start_day,'minutes')) {
        this.form.end=this.form.start
        this.$refs.slider.noUiSlider.set([
          start.diff(start_day,'minutes'),
          start.diff(start_day,'minutes'),
        ])
      } else {
        this.$refs.slider.noUiSlider.set([
          start.diff(start_day,'minutes'),
          end.diff(start_day,'minutes'),
        ])
      }
    },
    onDone() {
      this.submitted.done = true;
      let start = moment(`2023-12-12 ${this.form.start}`,'YYYY-MM-DD HH:mm:ss')
      let end = moment(`2023-12-12 ${this.form.end}`,'YYYY-MM-DD HH:mm:ss')
      const makeFormat = (value) => value.toString().length==1 ? `0${value}` : `${value}`
      this.$emit("updated", {
        start: {
          data: {
            HH: makeFormat(start.get('hour')),
            mm: makeFormat(start.get('minute')),
          },
        },
        end: {
          data: {
            HH: makeFormat(end.get('hour')),
            mm: makeFormat(end.get('minute')),
          },
        },
      });
      this.$modal.hide(this.modalName);
    },
  },
};
</script>

<style>
</style>