import { TokenService } from "@/services/storage.service"
import { $fn } from "@/utils/functions"
import Emitter from "component-emitter"

/* eslint-disable no-undef */
export const MOBILE_APP = {
  interface: null,
  init(){
    try {
      Emitter(this)
      window.mobileMessage = this.onMessage.bind(this)
      if(window?.webkit?.messageHandlers?.iosListener) this.interface=window?.webkit?.messageHandlers?.iosListener
      else this.interface=Android
      that.on('refresh-token-success',(data)=>{
        TokenService.TOKEN.set(data?.access_token)
        TokenService.REFRESH_TOKEN.set(data?.refresh_token)
      })
      that.on('refresh-token-fail',()=>{
        $fn.logout({is_user: true})
      })
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return this
    }
  },
  sendMessage(event,payload={}){
    console.log(event,payload)
    this.interface?.postMessage?.(JSON.stringify({ event, payload }))
  },
  onMessage(message){
    try {
      const { event, payload } = JSON.parse(message)
      this.emit(event,payload)
    } catch (error) {
      alert(`error: ${error}`)
    }
  },
  getRefreshToken(){
    let that = this
    return new Promise((resolve,reject)=>{
      that.sendMessage('get-refresh-token')
      that.once('refresh-token-success',(data)=>{
        TokenService.TOKEN.set(data?.access_token)
        TokenService.REFRESH_TOKEN.set(data?.refresh_token)
        resolve(data)
      })
      that.once('refresh-token-fail',()=>{
        $fn.logout({is_user: true})
        reject(new Error('Failed'))
      })
    })
  },
}