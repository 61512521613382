<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup tagStatus-popup dialer-setting-call-tag" 
      width="50%" 
      height="auto" 
      :scrollable="true" 
      :name="modalName" 
      @before-open="onBeforeOpen($event)"
      @before-close="onBeforeClose()" 
    >
      <div class="dialer-assign-to md-pad-bottom dialer-edit">
        <div class="dialer-edit-header d-block">
          <div class="d-flex justify-content-between align-items-center">
            <h2 class="dialer-edit-title d-flex align-items-center">
              <button class="newBackButton mr-16px" @click="$modal.hide(modalName)">
                <b-icon icon="arrow-left-circle" />
                <span class="newBackButton-textpart">Back</span>
              </button>
              Power Dialer Tags
            </h2>
            <div>
              <button @click="$modal.show(`${_uid}-AddPowerDialerTag`)" class="newButton" variant="success" >
                <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                <span class="newButton-textPart">Add tag</span>
              </button>
            </div>
          </div>
          <div class="vm--modal-text">Call tagging helps organise and analyse call data, enabling easy reporting and customer identification.</div>
        </div>
        <div class="dialer-tabs">
          <vb-table class="latestTableDesign-withBlackBorders-again"
            :isListEmpty="response.tags.length==0"
            :listLength="response.tags.length"
            :perPage="5"
            :loading="api.fetch_tags.send"
            :conditions="{
              pagination: {
                last: true,
                first: true,
                show: true,
              },
              is_no_record: {
                show: true,
              },
              loading: {
                show: true,
              },
            }"
          >
            <tr slot="header" v-if="!getIsMobile">
              <th class="dialer-has-sort">
                <span>Name</span>
              </th>
              <th class="dialer-has-sort">
                <span>Tag color</span>
              </th>
              <th class="dialer-has-sort">
                <span>Sample tag</span>
              </th>
                <th class="dialer-has-sort">
                  <span>Hide</span>
                </th>
              <th class="dialer-has-sort dialer-col-center">
                <span></span>
              </th>
            </tr>
            <template #body="{ start, end }">
              <template v-if="getIsMobile">
                <tr v-for="(tag, index) in response.tags" :key="tag.id" v-show="index >= start && index <= end">
                  <td class="left">
                    <div class="d-flex align-items-center">
                      <div v-vb-tag-color="tag.colour" class="mr-8px" :style="`border-radius:50%;height:25px;width:25px;min-height:25px;min-width:25px;`" />
                      <div>
                        <div class="headingInsideTable">{{ tag.tag }}</div>
                        <span v-if="tag.accountcode!='0'" class="OwnertaggedVersion mb-0">Custom</span>
                        <div 
                          v-vb-tag-color.text.bg="{
                            color: tag.colour,
                            text_color: tag.text_color
                          }" 
                          :style="`border-radius:3px;height:auto;width:fit-content;padding:2px 6px;font-size: 8px;font-weight: 600;line-height: 1.2;text-align: center;vertical-align: initial;white-space: nowrap;`" >
                          {{tag.tag}}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="dialer-col-right">
                    <div class="d-flex justify-content-end align-items-center">
                      <div class="switchWidth">
                        <div v-if="api.toggle_hide_tag.send==tag.id" class="d-flex align-items-center w-fit-content">
                          <div class="latestShimmerDesign" style="height:21px;width:50px;"></div>
                        </div>
                        <b-checkbox class="newerSwitch"
                          v-else
                          :disabled="!!api.toggle_hide_tag.send"
                          :checked="tag.deleted_tags==1 ? true : false"
                          @change="toggleHideTag(tag)"
                          switch
                        />
                      </div>
                      <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD">
                        <div v-if="tag.accountcode!='0'">
                          <b-dropdown right>
                            <template #button-content>
                              <b-icon icon="three-dots-vertical" scale="1.5"></b-icon>
                            </template>
                            <b-dropdown-item v-if="api.remove_tag.send==tag.id"></b-dropdown-item>
                            <b-dropdown-item v-else :disabled="!!api.remove_tag.send" @click="removeTag(tag)">Delete</b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr v-for="(tag, index) in response.tags" :key="tag.id" v-show="index >= start && index <= end">
                  <td class="dialer-row-title">
                    {{ tag.tag }}
                    <span v-if="tag.accountcode!='0'" class="OwnertaggedVersion mb-0 ml-16px">Custom</span>
                  </td>
                  <td class="dialer-row-title">
                    <div class="md-mar-left" v-vb-tag-color="tag.colour" :style="`border-radius:50%;height:16px;width:16px;`" />
                  </td>
                  <td class="dialer-row-title">
                    <div 
                      class="md-mar-left" 
                      v-vb-tag-color.text.bg="{
                        color: tag.colour,
                        text_color: tag.text_color
                      }" 
                      :style="`border-radius:4px;height:auto;width:fit-content;padding:0.2rem 0.4rem;font-size: 10.5px;font-weight: 500;line-height: 1.3;text-align: center;vertical-align: initial;white-space: nowrap;`" >
                      {{tag.tag}}
                    </div>
                  </td>
                  <td class="dialer-row-title">
                    <div class="switchWidth">
                      <div v-if="api.toggle_hide_tag.send==tag.id" class="d-flex align-items-center w-fit-content">
                        <div class="latestShimmerDesign" style="height:21px;width:50px;"></div>
                      </div>
                      <b-checkbox class="newerSwitch"
                        v-else
                        :disabled="!!api.toggle_hide_tag.send"
                        :checked="tag.deleted_tags==1 ? true : false"
                        @change="toggleHideTag(tag)"
                        switch
                      />
                    </div>
                  </td>
                  <td class="dialer-col-right">
                    <div class="d-flex justify-content-end">
                      <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD">
                        <div v-if="tag.accountcode!='0'">
                          <b-dropdown right :disabled="api.remove_tag.send==tag.id">
                            <template #button-content>
                              <b-icon icon="three-dots" scale="1.5"></b-icon>
                            </template>
                            <b-dropdown-item :disabled="!!api.remove_tag.send" @click="removeTag(tag)">Delete</b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </template>
            <template #loading>
              <tr v-for="n in 5" :key="n">
                <td>
                  <div class="d-flex align-items-center w-fit-content">
                    <div class="latestShimmerDesign" style="height:21px;width:80px;"></div>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center w-fit-content">
                    <div class="md-mar-left latestShimmerDesign" style="height:21px;width:21px;"></div>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center w-fit-content">
                    <div class="latestShimmerDesign md-mar-left" style="height:21px;width:21px;"></div>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center w-fit-content">
                    <div class="latestShimmerDesign md-mar-left" style="height:21px;width:50px;"></div>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center justify-sontent-end w-fit-content ml-auto">
                    <div class="latestShimmerDesign" style="height:16px;width:35px;"></div>
                  </div>
                </td>
              </tr>
            </template>
          </vb-table>
        </div>
      </div>
    </modal>
    <modal :name="`${_uid}-AddPowerDialerTag`" @before-close="$v.forms.create_tag.$reset()" class="center-zoomIn-transition v2 m-w-500 addTagsModal-centerVersion onlyTagsModal scrollable">
      <div class="w-100">
        <div class="dialer-edit-header position-relative d-block pb-0">
          <div class="d-flex justify-content-between align-items-center w-100">
            <h2 class="dialer-settings-title newer mb-0">Add tag</h2>
            <div class="dialer-edit-actions">
              <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(`${_uid}-AddPowerDialerTag`)" />
              <a v-else class="newCloseButton" type="button" @click="$modal.hide(`${_uid}-AddPowerDialerTag`)" >
                <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                <span class="newCloseButton-textPart">Close</span>
              </a>
            </div>
          </div>
          <div class="vm--modal-text mb-0">Add tags name and details.</div>
        </div>
        <div class="latestGreyBox-9-9-2023 w-100">
          <b-form @submit.prevent="createTag()" class="dialer-form w-100">
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100">
              <label>Tag name</label>
              <b-input type="text" class="w-100" v-model="forms.create_tag.tag" :disabled="api.create_tag.send" placeholder="Enter name" />
              <p v-if="$v.forms.create_tag.$error" class="mb-1 text-small text text-danger animated bounceIntop">
                <span v-if="!$v.forms.create_tag.tag.required">* Name is required</span>
                <span v-else-if="!$v.forms.create_tag.tag.minLength">* Name is should be minimum {{$v.forms.create_tag.tag.$params.minLength.min}} character</span>
                <span v-else-if="!$v.forms.create_tag.tag.maxLength">* Name is can be maximum {{$v.forms.create_tag.tag.$params.maxLength.max}} character</span>
                <span v-else-if="!$v.forms.create_tag.tag.valid">* Name can be number and alphabet</span>
              </p>
            </div>
            <div class="whiteBGinputWithGreyRoundedBorder mt-20px">
              <label>Tag color</label>
              <div class="createSpace-selectColor-container">
                <template v-if="api.fetch_color_combination.send">
                  <div v-for="n in 30" :key="n.id" class="createSpace-selectColor-item-container">
                    <div class="latestShimmerDesign" style="height: 30px;width: 30px;"></div>
                  </div>
                </template>
                <template v-else>
                  <div v-for="color in response.color_combinations" :key="color.id" class="createSpace-selectColor-item-container">
                    <div 
                      @click="api.create_tag.send ? '' : forms.create_tag.color_combo.id = color.id;forms.create_tag.color_combo.color = color.color;forms.create_tag.color_combo.text_color = color.text_color;" 
                      class="createSpace-selectColor-item"
                      :class="{
                        'selected': forms.create_tag.color_combo.id==color.id,
                        'TransparentSelected': color.color == 'rgba(0,0,0,0)',
                      }"
                      :style="`background-color:${color.color};`">
                      <vb-icon v-if="color.color == 'rgba(0,0,0,0)'" icon="todo-transparentColored-icon" height="28px" width="30px" />
                    </div>
                  </div>
                </template>
              </div>
              <p v-if="$v.forms.create_tag.$error" class="mb-1 text-small text text-danger animated bounceIntop">
                <span v-if="!$v.forms.create_tag.color_combo.id.required">* Tag color is required</span>
              </p>
            </div>
            <div class="mt-4">
              <button class="fullWidthDoneButton" variant="success" :disabled="api.create_tag.send">
                <vb-spinner v-if="api.create_tag.send" />
                <template v-else>Add</template>
              </button>
            </div>
          </b-form>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { VOIP_API } from "@/utils";
import { helpers, required } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';
import minLength from 'vuelidate/lib/validators/minLength';
import maxLength from 'vuelidate/lib/validators/maxLength';

export default {
  name: "PowerDialerTagsModal",
  inject: [
    'isEmpty',
    'appNotify'
  ],
  props: {
    modalName: {
      type: String,
      default: 'PowerDialerTagsModal',
    },
  },
  data() {
    return {
      forms: {
        create_tag: this.$helperFunction.formInstance({
          data: {
            tag: "",
            color_combo: {
              id: '',
              color: '',
              text_color: '',
            },
          }
        }),
      },
      api: {
        remove_tag: this.$helperFunction.apiInstance({ send: '' }),
        toggle_hide_tag: this.$helperFunction.apiInstance({ send: '' }),
        create_tag: this.$helperFunction.apiInstance({  }),
        fetch_tags: this.$helperFunction.apiInstance({  }),
        fetch_color_combination: this.$helperFunction.apiInstance({  }),
      },
      response: {
        color_combinations: [],
        tags: [],
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile',
    ]),
  },
  validations: {
    forms: {
      create_tag: {
        tag: {
          required: required,
          minLength: minLength(3), 
          maxLength: maxLength(20),
          valid: helpers.regex('valid',/^[a-z0-9 ]*$/i),
        },
        color_combo: {
          id: {
            required: required,
          },
          color: {

          },
          text_color: {
            
          },
        },
      },
    },
  },
  methods: {
    onBeforeOpen(){
      this.fetchTags();
      this.fetchColorCombinations()
    },
    onBeforeClose(){
      this.$v.$reset()
      this.forms.create_tag.reset()
      this.api.create_tag.reset()
      this.api.remove_tag.reset()
      this.api.toggle_hide_tag.reset()
      this.api.fetch_tags.reset()
      this.api.fetch_color_combination.reset()
      this.response.color_combinations=[]
      this.response.tags=[]
    },
    async fetchTags() {
      if (this.api.fetch_tags.send) return;
      try {
        this.api.fetch_tags.send = true;
        const { data } = await VOIP_API.endpoints.power_dialer.getTags({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
          type: 'all'
        })
        const tags = data?.data ?? []
        this.response.tags=tags
      } catch(ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_tags.send = false;
      }
    },
    async fetchColorCombinations(){
      if(this.api.fetch_color_combination.send) return;
      try {
        this.api.fetch_color_combination.send=true
        const { data: color_combinations } = await VOIP_API.endpoints.tags.colorCombinations()
        this.response.color_combinations=color_combinations ?? []
      } finally {
        this.api.fetch_color_combination.send=false
      }
    },
    async createTag() {
      this.$v.forms.create_tag.$touch();
      if (this.$v.forms.create_tag.$invalid || this.api.create_tag.send) return;
      try {
        this.api.create_tag.send = true;
        await VOIP_API.endpoints.power_dialer.createTag({
          tag: this.forms.create_tag.tag,
          color_combo_id: this.forms.create_tag.color_combo.id,
          color: this.forms.create_tag.color_combo.color,
          colour: this.forms.create_tag.color_combo.color,
          text_color: this.forms.create_tag.color_combo.text_color,
          uid: this.getCurrentUser?.uid,
          accountcode: this.getCurrentUser?.account,
          section: 'autodialer',
          flag: 'portal_api_create',
        })
        this.$v.forms.create_tag.$reset();
        this.forms.create_tag.reset();
        this.$modal.hide(`${this._uid}-AddPowerDialerTag`)
        this.fetchTags()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.create_tag.send = false;
      }
    },
    async removeTag(tag) {
      const id = tag.id
      if (!id || this.api.remove_tag.send) return;
      try {
        this.api.remove_tag.send = id;
        const { confirm } = await this.$appConfirmation({
          title: "Warning",
          message: `Are you sure you want to delete.`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        })
        if(!confirm) return;
        await VOIP_API.endpoints.power_dialer.deleteTag(id,{
          tag_type: 'custom_delete',
          section: 'autodialer',
          flag: 'portal_api_delete',
        })
        this.appNotify({
          message: `Successfully Deleted`,
          type: 'success',
        })
        this.fetchTags()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message || '',
          type: 'danger',
        })
      } finally {
        this.api.remove_tag.send = "";
      }
    },
    async toggleHideTag(tag){
      const id = tag.id
      if (!id || this.api.toggle_hide_tag.send) return;
      try {
        this.api.toggle_hide_tag.send = id;
        const { confirm } = await this.$appConfirmation({
          title: "Warning",
          message: `Are you sure you want to ${tag.deleted_tags==1?'show':'hide'}.`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        })
        if(!confirm) return;
        await VOIP_API.endpoints.power_dialer.deleteTag(id,{
          action: tag.deleted_tags==1 ? 'unhide' : 'hide',
          section: 'autodialer',
          flag: 'portal_api_delete',
        })
        this.appNotify({
          message: `Successfully ${tag.deleted_tags==1?'show':'hide'}`,
          type: 'success',
        })
        this.fetchTags()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message || '',
          type: 'danger',
        })
      } finally {
        this.api.toggle_hide_tag.send = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons {
  margin-top: 35px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.dialer-table {
  .dialer-col-center {
    span {
      .custom-control {
        padding-left: 0px;
      }
    }
  }
}
</style>
