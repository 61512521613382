<template>
  <modal class="putItOnTop putItOnTopModal" :name="modalName" @before-open="onBeforeOpen($event)" @before-close="onBeforeClose()">
    <div class="putItOnTopModal-container">
      <b-icon icon="x-lg" class="putItOnTopModal-close" font-scale="1.2" @click="$modal.hide(modalName)" />
      <template v-if="conditions.state==1">
        <b-icon class="putItOnTopModal-icon" icon="check-circle-fill" variant="success" />
        <h5 class="putItOnTopModal-heading">Success</h5>
        <p class="putItOnTopModal-text">{{ conditions.message }}</p>
        <button class="dialer-button dialer-button-primary d-flex align-items-center" @click="$modal.hide(modalName)">Done</button>
      </template>
      <template v-else-if="conditions.state==2">
        <b-icon class="putItOnTopModal-icon" icon="x-circle-fill" variant="danger" />
        <h5 class="putItOnTopModal-heading">Failed</h5>
        <p class="putItOnTopModal-text">{{ conditions.message }}</p>
        <button class="dialer-button dialer-button-primary d-flex align-items-center" @click="$modal.hide(modalName)">Done</button>
      </template>
      <template v-else>
        <div class="putItOnTopModal-loader"></div>
        <h5 class="putItOnTopModal-heading">Loading...</h5>
      </template>
    </div>
  </modal>
</template>

<script>
import { AMI_EVENTS, AMI_SOCKET } from '@/ami'
export default {
  name: 'TransactionProcessingModal',
  props: {
    modalName: {
      type: String,
      default: 'TransactionProcessingModal'
    },
  },
  data() {
    return {
      data: {
        service: '', // queue, aql, team, user, call_prefix, call_prefix_2, unlimited_call_plan, ivrs, topup
        onSuccess: null,
        onFail: null,
      },
      conditions: {
        state: 0, // 0=process, 1=success, 2=fail
        message: '',
      },
    }
  },
  watch: {
    "conditions.state"(newValue, oldValue) {
      // let vm = this
      if(newValue!=0 && oldValue==0)
      setTimeout(()=>{
        // if(newValue==1) {
        //   vm.$modal.hide(vm.modalName)
        // }
      },2*1000)
    }
  },
  methods: {
    onBeforeOpen(event){
      this.data.service=event?.params?.service
      this.data.onSuccess=event?.params?.onSuccess
      this.data.onFail=event?.params?.onFail
    },
    onBeforeClose(){
      if(this.conditions.state==1) this.data.onSuccess?.()
      else this.data.onFail?.(new Error(this.conditions.message || 'Transaction Failed'))
      this.data.service=''
      this.data.onSuccess=null
      this.data.onFail=null
      this.conditions.state=0
      this.conditions.message=''
    },
    onNotification(event) {
      // eslint-disable-next-line no-unused-vars
      const { data: { flag, action, section, array, message, type, accountcode, webExtension, }, event: event_string } = event
      if(flag=='transaction_failed') {
        this.conditions.state=2
        this.conditions.message=message
      }
      if(this.data.service=='queue') {
        if(flag=='CALL_QUEUE_ADDED') {
          this.conditions.state=1
          this.conditions.message='Call queue is Added'
        }
      } else if(this.data.service=='aql') {
        if(flag=='NUMBER_ADDED') {
          this.conditions.state=1
          this.conditions.message='Number is Added'
        }
      } else if(this.data.service=='team') {
        if(flag=='TEAM_ADDED') {
          this.conditions.state=1
          this.conditions.message='Team is Added'
        }
      } else if(this.data.service=='user') {
        if(flag=='USER_ADDED') {
          this.conditions.state=1
          this.conditions.message='User is Added'
        }
      } else if(this.data.service=='call_prefix') {
        // TODO - 
      } else if(this.data.service=='call_prefix_2') {
        // TODO - 
      } else if(this.data.service=='unlimited_call_plan') {
        if(flag=='UPDATE_CALL_PLAN') {
          this.conditions.state=1
          this.conditions.message='Unlimited Call Plan is Purchased'
        }
      } else if(this.data.service=='ivrs') {
        if(flag=='IVR_ADDED') {
          this.conditions.state=1
          this.conditions.message='IVR is Added'
        }
      } else if(this.data.service=='topup') {
        if(flag=='topup_added') {
          this.conditions.state=1
          this.conditions.message='Topup successfully is Added'
        }
      }
    },
  },
  mounted(){
    AMI_SOCKET?.on?.(AMI_EVENTS.broadcast,this.onNotification)
  },
  beforeDestroy() {
    AMI_SOCKET?.off?.(AMI_EVENTS.broadcast,this.onNotification)
  },
}
</script>
<style lang="scss">
body{
  .dialer-main-center-area:has(.putItOnTop.vm--container){
    z-index: 10;
    .content-area{
      z-index: 1;
    }
    .putItOnTop.vm--container{
      z-index: 2;
    }
  }
}
</style>