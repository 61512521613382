<template>
  <div>
    <modal 
      height="auto" 
      :name="modalName" 
      class="center-zoomIn-transition v2 m-w-500 addUserModalV2" 
      @before-open="onBeforeOpen($event)" 
      :scrollable="true" 
      @before-close="onBeforeClose()"
    >
      <div class="dialer-edit-user">
        <div class="dialer-edit">
          <div class="dialer-form">
            <div class="dialer-edit-header pt-0 pb-0 d-block">
              <div class="d-flex justify-content-between align-items-center">
                <h2 class="dialer-edit-title">
                  Create user
                  <vb-icon 
                    icon="checkSlotsModal-icon" 
                    class="ml-2 cursor_pointer checkSlotsModal-icon" 
                    height="22px" 
                    width="22px"
                    :disabled="api.create_user.send" 
                    @click="$modal.show('CheckedServiceAvailableModal',{ service: 'user', onProceed: createUser, })"
                  />
                </h2>
                <div class="dialer-edit-actions">
                  <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
                  <a v-else class="newCloseButton" @click="$modal.hide(modalName)">
                    <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                    <span class="newCloseButton-textPart">Close</span>
                  </a>
                </div>
              </div>
              <div class="vm--modal-text">
                Please provide the new user's details below and configure your account preferences.
              </div>
            </div>
            <div class="md-mar-vertical">
              <form @submit.prevent="createUser()">
                <b-alert :show="!!api.create_user.error_message" variant="danger">{{ api.create_user.error_message }}</b-alert>
                <!-- username or email -->
                <div class="whiteBGinputWithGreyRoundedBorder dialer-input--error md-mar-top">
                  <label>Username or Email *</label>
                  <div class="d-flex align-items-center justify-content-between">
                    <input 
                      :disabled="api.create_user.send" 
                      v-model="forms.create_user.username" 
                      placeholder="Enter Username or Email" 
                      class="bg-white"
                      :class="!isInvited?'w-50per-15px':'w-100'"
                      type="text" 
                    />
                    <template v-if="!isInvited">
                      @
                      <input 
                        class="w-50per-15px bg-white" 
                        :disabled="true" 
                        :value="getCurrentUser.company" 
                      />
                    </template>
                  </div>
                  <p 
                    v-if="$v.forms.create_user.username.$error || (isInvited ? api.create_user.validation_errors.email : api.create_user.validation_errors.name)" 
                    class="text text-small text-danger animated bounceIntop mb-0"
                  >
                    <template v-if="isInvited">
                      <span v-if="!$v.forms.create_user.username.required">* Email is required</span>
                      <span v-else-if="!$v.forms.create_user.email.email">* Email is not valid</span>
                      <span v-for="(em, i) in api.create_user.validation_errors.email" :key="i">* {{ em }}</span>
                    </template>
                    <template v-else>
                      <span v-if="!$v.forms.create_user.username.required">* Username is required</span>
                      <span v-else-if="!$v.forms.create_user.username.alphaStart">* Username should start with an alphabet</span>
                      <span v-else-if="!$v.forms.create_user.username.alphaNum">* Username can be only alphabet or numeric</span>
                      <span v-else-if="!$v.forms.create_user.username.minLength">* Username should be minimum {{ $v.forms.create_user.username.$params.minLength.min }} character</span>
                      <span v-else-if="!$v.forms.create_user.username.maxLength">* Username can be maximum {{ $v.forms.create_user.username.$params.maxLength.max }} character</span>
                      <span v-for="(em, i) in api.create_user.validation_errors.name" :key="i">* {{ em }}</span>
                    </template>
                  </p>
                </div>
                <!-- first and last name -->
                <div class="w-100 d-flex md-mar-top">
                  <!-- first name -->
                  <div class="whiteBGinputWithGreyRoundedBorder dialer-input--error w-50 pr-3">
                    <label>First name *</label>
                    <input 
                      :disabled="api.create_user.send" 
                      v-model="forms.create_user.first_name" 
                      :maxlength="$v.forms.create_user.first_name.$params.maxLength.max" 
                      onkeypress="return /^[A-Za-z]+$/.test(event.key)" 
                      placeholder="Enter first name" 
                      class="w-100 bg-white" 
                      type="text" 
                    />
                    <p 
                      v-if="$v.forms.create_user.first_name.$error || api.create_user.validation_errors.display_name || api.create_user.validation_errors.firstname" 
                      class="text text-small text-danger animated bounceIntop mb-0"
                    >
                      <span v-if="!$v.forms.create_user.first_name.required">* First name is required</span>
                      <span v-else-if="!$v.forms.create_user.first_name.maxLength">* First name can be maximum {{ $v.forms.create_user.first_name.$params.maxLength.max }} character</span>
                      <span v-else-if="!$v.forms.create_user.first_name.alpha">* First name must be only alphabets</span>
                      <span v-for="(em, i) in api.create_user.validation_errors.display_name" :key="i">* {{ em }}</span>
                      <span v-for="(em, i) in api.create_user.validation_errors.firstname" :key="i">* {{ em }}</span>
                    </p>
                  </div>
                  <!-- last mame -->
                  <div class="whiteBGinputWithGreyRoundedBorder dialer-input--error w-50 pl-3">
                    <label>Last name</label>
                    <input 
                      :disabled="api.create_user.send" 
                      v-model="forms.create_user.last_name" 
                      :maxlength="$v.forms.create_user.last_name.$params.maxLength.max" 
                      onkeypress="return /^[A-Za-z]+$/.test(event.key)" 
                      placeholder="Enter last name" 
                      class="w-100 bg-white" 
                      type="text" 
                    />
                    <p 
                      v-if="$v.forms.create_user.last_name.$error || api.create_user.validation_errors.lastname" 
                      class="text text-small text-danger animated bounceIntop mb-0"
                    >
                      <span v-if="!$v.forms.create_user.last_name.maxLength">* Last name can be maximum {{ $v.forms.create_user.last_name.$params.maxLength.max }} character</span>
                      <span v-else-if="!$v.forms.create_user.last_name.alpha">* Last name must be only alphabets</span>
                      <span v-else-if="!$v.forms.create_user.last_name.valid">* Last name must have a space character</span>
                      <span v-for="(em, i) in api.create_user.validation_errors.lastname" :key="i">* {{ em }}</span>
                    </p>
                  </div>
                </div>
                <!-- admin -->
                <div class="dialer-box-header md-mar-top md-mar-bottom">
                  <div class="d-flex align-items-center">
                    <b-form-checkbox 
                      switch
                      class="newerSwitch"
                      :disabled="api.create_user.send" 
                      v-model="forms.create_user.role" 
                      name="check-button"
                    />
                    <span class="ml-3 setAsAdmin-text" style="font-size: 16px;font-weight: 400;color: #676767;">Set as admin</span>
                  </div>
                  <p 
                    v-if="$v.forms.create_user.role.$error || api.create_user.validation_errors.role" 
                    class="text text-small text-danger animated bounceIntop mb-0"
                  >
                    <span v-for="(em, i) in api.create_user.validation_errors.role" :key="i">* {{ em }}</span>
                  </p>
                </div>
                <!-- alert in invited user case -->
                <b-alert :show="isInvited" variant="info">User will be set there own password</b-alert>
                <!-- set password -->
                <div v-if="!isInvited" class="dialer-box-header md-mar-bottom md-mar-top">
                  <div class="d-flex align-items-center">
                    <b-form-checkbox 
                      switch
                      class="newerSwitch"
                      :disabled="api.create_user.send" 
                      :checked="forms.create_user.is_passwword_manual" 
                      @change="forms.create_user.is_passwword_manual=!forms.create_user.is_passwword_manual;forms.create_user.password='';"
                      name="check-button"
                    />
                    <span class="ml-3 setAsAdmin-text" style="font-size: 16px;font-weight: 400;color: #676767;">Set password</span>
                  </div>
                </div>
                <!-- password -->
                <div v-if="forms.create_user.is_passwword_manual" class="whiteBGinputWithGreyRoundedBorder dialer-input--error md-mar-top">
                  <label>Password</label>
                  <div class="position-relative b-0 adduser-two-input-icon">
                    <span class="icon ml-2">
                      <b-icon icon="lock" />
                    </span>
                    <input
                      class="w-100 bg-white"
                      :type="conditions.create_user_password_show ? 'text' : 'password'"
                      placeholder="Enter password"
                      :disabled="api.create_user.send"
                      readonly
                      onfocus="this.removeAttribute('readonly');"
                      v-model="forms.create_user.password"
                      :maxlength="$v.forms.create_user.password.$params.maxLength.max"
                    />
                    <b-icon
                      class="dialer-showPassword-icon cursor_pointer"
                      :aria-disabled="api.create_user.send"
                      :icon="conditions.create_user_password_show ? 'eye-slash' : 'eye'"
                      @click="conditions.create_user_password_show = !conditions.create_user_password_show"
                    />
                  </div>
                  <p 
                    v-if="$v.forms.create_user.password.$error || api.create_user.validation_errors.password" 
                    class="text text-small text-danger animated bounceIntop mb-0"
                  >
                    <span v-if="!$v.forms.create_user.password.required">* password is required</span>
                    <span v-else-if="!$v.forms.create_user.password.capitalalphabet">* password required at least one capital character</span>
                    <span v-else-if="!$v.forms.create_user.password.smallalphabet">* password required at least one small character</span>
                    <span v-else-if="!$v.forms.create_user.password.numbers">* password required at least one integer character</span>
                    <span v-else-if="!$v.forms.create_user.password.specialcharacter">* password required at least one special character</span>
                    <span v-else-if="!$v.forms.create_user.password.minLength">* password is minimum {{$v.forms.create_user.password.$params.minLength.min}} is required</span>
                    <span v-else-if="!$v.forms.create_user.password.maxLength">* password is maximum {{$v.forms.create_user.password.$params.maxLength.max}} character</span>
                    <span v-for="(em,i) in api.create_user.validation_errors.password" :key="i">* {{ em }}</span>
                  </p>
                </div>
                <!-- set send password email -->
                <div v-if="!isInvited" class="dialer-box-header md-mar-bottom md-mar-top">
                  <div class="d-flex align-items-center">
                    <b-form-checkbox 
                      switch
                      class="newerSwitch"
                      :disabled="api.create_user.send" 
                      v-model="forms.create_user.is_send_email"
                      name="check-button"
                    />
                    <span class="ml-3 setAsAdmin-text" style="font-size: 16px;font-weight: 400;color: #676767;">Send password in email upon completion</span>
                  </div>
                </div>
                <!-- send password email -->
                <div v-if="forms.create_user.is_send_email" class="whiteBGinputWithGreyRoundedBorder dialer-input--error mb-3 md-mar-top">
                  <label>Email the new password to the following recepients *</label>
                  <div class="position-relative ">
                    <input 
                      class="w-100 bg-white" 
                      type="text" 
                      placeholder="Enter email" 
                      :disabled="api.create_user.send"  
                      v-model="forms.create_user.send_password_email" 
                    />
                  </div>
                  <p 
                    v-if="$v.forms.create_user.send_password_email.$error || api.create_user.validation_errors.email" 
                    class="text text-small text-danger animated bounceIntop mb-0"
                  >
                    <span v-if="!$v.forms.create_user.send_password_email.required">* email is required</span>
                    <span v-else-if="!$v.forms.create_user.send_password_email.email">* email is invalid</span>
                    <span v-for="(em, i) in api.create_user.validation_errors.email" :key="i">* {{ em }}</span>
                  </p>
                </div>
                <!-- add number -->
                <div v-if="!isFreePlan" class="dialer-box-header md-mar-bottom">
                  <div class="d-flex align-items-center">
                    <b-form-checkbox 
                      switch
                      class="newerSwitch"
                      :disabled="api.create_user.send" 
                      :checked="forms.create_user.add_number" 
                      @change="
                        forms.create_user.add_number=!forms.create_user.add_number;
                      "
                      name="check-button"
                    />
                    <span class="ml-3 setAsAdmin-text" style="font-size: 16px;font-weight: 400;color: #676767;">Add Telephone Number</span>
                  </div>
                  <p v-if="api.create_user.validation_errors.skip_number" class="text text-small text-danger animated bounceIntop mb-0">
                    <span v-for="(em, i) in api.create_user.validation_errors.skip_number" :key="i">* {{ em }}</span>
                  </p>
                </div>
                <!-- number -->
                <template v-if="forms.create_user.add_number">
                  <div 
                    class="d-flex align-items-center justify-content-between mb-2 lg-mar-top"
                    :class="{
                      'numberSelected': forms.create_user.number.number
                    }"
                  >
                    <template>
                      <div v-if="forms.create_user.number.number" class="beforeDeleteNumbers-item mb-0">
                        {{ forms.create_user.number.number | number_formater }}
                      </div>
                    </template>
                    <button 
                      type="button" 
                      @click="$modal.show(`${modalName}-AddNumbersModal`,{ add_user: true, })" 
                      class="dialer-button dialer-button-primary w-100" 
                      :disabled="api.create_user.send"
                    >
                      {{!forms.create_user.number.number ? 'Select number' : 'Change number'}}
                    </button>
                  </div>
                  <p 
                    v-if="forms.create_user.submitted && $v.forms.create_user.number.$invalid" 
                    class="text text-small text-danger animated bounceIntop mb-0"
                  >
                    <span v-if="!$v.forms.create_user.number.required">* Number is required</span>
                  </p>
                </template>
                <div class="d-flex align-items-center justify-content-end mb-2 lg-mar-top">
                  <button type="submit" class="dialer-button dialer-button-primary w-100" :disabled="api.create_user.send">
                    <vb-spinner v-if="api.create_user.send" />
                    <template v-else>Create user</template>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <AddNumbersModal :modalName="`${modalName}-AddNumbersModal`" @select-number="forms.create_user.number=$event" />
  </div>
</template>

<script>
import { check_service } from '@/mixin';
import {
  required,
  minLength,
  maxLength,
  requiredIf,
  email,
  alphaNum,
  helpers,
} from "vuelidate/lib/validators";
import AddNumbersModal from "./AddNumbersModal.vue";

import { VOIP_API } from "../../utils";
import { mapGetters } from 'vuex';
import alpha from 'vuelidate/lib/validators/alpha';

export default {
  name: "AddUserModal",
  components: {
    AddNumbersModal,
  },
  mixins: [
    check_service
  ],
  inject: [
    'appNotify',
  ],
  props: {
    modalName: {
      type: String,
      default: 'AddUser',
    },
  },
  data() {
    return {
      forms: {
        create_user: this.$helperFunction.formInstance({
          data: {
            first_name: "",
            last_name: "",
            role: false,
            add_number: false,
            number: {},
            username: '',
            is_passwword_manual: false,
            password: "",
            is_send_email: false,
            send_password_email: '',
          }
        }),
      },
      api: {
        create_user: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true }),
      },
      conditions: {
        create_user_password_show: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile',
      'isFreePlan',
    ]),
    isInvited(){ return !!this.forms.create_user.username && email(this.forms.create_user.username) },
  },
  validations(){
    return {
      forms: {
        create_user: {
          first_name: {
            required, 
            maxLength: maxLength(16),
            alpha: alpha,
          },
          last_name: {
            maxLength: maxLength(16),
            alpha: alpha,
          },
          role: {
            
          },
          add_number: {
            
          },
          number: {
            required: requiredIf(function () { return this.forms.create_user.add_number; }),
          },
          username: {
            required: required,
            ...this.isInvited ? {
              email,
            } : {
              alphaNum,
              minLength: minLength(4), 
              maxLength: maxLength(40),
              alphaStart: helpers.regex('alphaStart',/^[A-Za-z]+/),
            },
          },
          is_passwword_manual: {
            
          },
          password: {
            required: requiredIf(function () { return this.forms.create_user.is_passwword_manual }),
            minLength: minLength(8),
            maxLength: maxLength(32),
            capitalalphabet: function(value){ return !value || /[A-Z]/.test(value) },
            smallalphabet: function(value){ return !value || /[a-z]/.test(value) },
            numbers: function(value){ return !value || /[0-9]/.test(value) },
            specialcharacter: function(value){ return !value || /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/.test(value) },
          },
          is_send_email: {
            
          },
          send_password_email: {
            required: requiredIf(function () { return this.forms.create_user.is_send_email }),
            email,
          },
        },
      },
    }
  },
  watch: {
    "isInvited"(){
      this.forms.create_user.is_passwword_manual = false;
      this.forms.create_user.password = '';
      this.forms.create_user.is_send_email = false;
      this.forms.create_user.send_password_email = '';
      this.conditions.create_user_password_show = false;
    },
  },
  methods: {
    onBeforeOpen(/*event*/){
      // TODO - 
    },
    onBeforeClose(){
      this.$v.$reset()
      this.forms.create_user.reset()
      this.api.create_user.reset()
      this.conditions.create_user_password_show=false
    },
    async createUser(){
      this.$v.forms.create_user.$touch()
      if(this.api.create_user.send || this.$v.forms.create_user.$invalid) return;
      try {
        this.api.create_user.send=true
        this.api.create_user.error_message=''
        this.api.create_user.validation_errors={}
        const payload = await this.checkServicePromise({
          service: 'user',
        })
        if(payload?.finally) return;
        if(this.isInvited) {
          await VOIP_API.endpoints.users.create({
            username: this.forms.create_user.username,
            email: this.forms.create_user.username,
            lastname: this.forms.create_user.last_name,
            firstname: this.forms.create_user.first_name,
            number: this.forms.create_user.number,
            role: this.forms.create_user.role ? 1 : 0,
            accountcode: this.getCurrentUser?.account,
            skip_number: this.forms.create_user.add_number ? '0' : '1',
            service: "user",
            action: 'invite'
          })
        } else {
          await VOIP_API.endpoints.user.addorganizationuser({
            username: this.forms.create_user.username,
            display_name: `${this.forms.create_user.first_name}${this.forms.create_user.last_name ? ` ${this.forms.create_user.last_name}` : ''}`,
            password: this.forms.create_user.is_passwword_manual ? this.forms.create_user.password : null,
            email: this.forms.create_user.is_send_email ? this.forms.create_user.send_password_email : `${this.forms.create_user.username}@${this.getCurrentUser?.company.replace(/ /g, "")}.com`,
            number: this.forms.create_user.number,
            is_passwword_manual: this.forms.create_user.is_passwword_manual,
            is_send_email: this.forms.create_user.is_send_email,
            role: this.forms.create_user.role ? 1 : 0,
            uid: this.getCurrentUser?.uid,
            accountcode: this.getCurrentUser?.account,
            skip_number: this.forms.create_user.add_number ? '0' : '1',
            is_shared_caller_id: '1',
          })
        }
        if(!payload.is_free) {
          await this.transactionProcessingModal({
            service: 'user',
          })
        }
        this.$emit("user-added");
        this.$modal.hide(this.modalName)
      } catch (ex) {
        this.api.create_user.error_message=ex.own_message || ex.message
        this.api.create_user.validation_errors=ex.response_error || {}
        return
      } finally {
        this.api.create_user.send=false
        this.forms.create_user.submitted=false
      }
    },
    async UpdateInvite(){

    },
  },
};
</script>


<style>
.slide-ltr-enter-active {
  animation: slide-ltr 0.5s reverse;
}
.slide-ltr-leave-active {
  animation: slide-ltr 0.5s;
}
@keyframes slide-ltr {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>