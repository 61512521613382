

export const flagsCountryCode = {
  "BB": "https://flagcdn.com/w320/bb.png",
  "IO": "https://flagcdn.com/w320/io.png",
  "CG": "https://flagcdn.com/w320/cg.png",
  "BR": "https://flagcdn.com/w320/br.png",
  "LU": "https://flagcdn.com/w320/lu.png",
  "VI": "https://flagcdn.com/w320/vi.png",
  "KI": "https://flagcdn.com/w320/ki.png",
  "RO": "https://flagcdn.com/w320/ro.png",
  "LA": "https://flagcdn.com/w320/la.png",
  "MT": "https://flagcdn.com/w320/mt.png",
  "TG": "https://flagcdn.com/w320/tg.png",
  "IQ": "https://flagcdn.com/w320/iq.png",
  "KE": "https://flagcdn.com/w320/ke.png",
  "KY": "https://flagcdn.com/w320/ky.png",
  "GN": "https://flagcdn.com/w320/gn.png",
  "US": "https://flagcdn.com/w320/us.png",
  "TN": "https://flagcdn.com/w320/tn.png",
  "BO": "https://flagcdn.com/w320/bo.png",
  "CN": "https://flagcdn.com/w320/cn.png",
  "KR": "https://flagcdn.com/w320/kr.png",
  "KW": "https://flagcdn.com/w320/kw.png",
  "DZ": "https://flagcdn.com/w320/dz.png",
  "AW": "https://flagcdn.com/w320/aw.png",
  "RU": "https://flagcdn.com/w320/ru.png",
  "DK": "https://flagcdn.com/w320/dk.png",
  "LT": "https://flagcdn.com/w320/lt.png",
  "GB": "https://flagcdn.com/w320/gb.png",
  "EE": "https://flagcdn.com/w320/ee.png",
  "TW": "https://flagcdn.com/w320/tw.png",
  "CA": "https://flagcdn.com/w320/ca.png",
  "FJ": "https://flagcdn.com/w320/fj.png",
  "PF": "https://flagcdn.com/w320/pf.png",
  "PE": "https://flagcdn.com/w320/pe.png",
  "AU": "https://flagcdn.com/w320/au.png",
  "CL": "https://flagcdn.com/w320/cl.png",
  "YT": "https://flagcdn.com/w320/yt.png",
  "EG": "https://flagcdn.com/w320/eg.png",
  "PR": "https://flagcdn.com/w320/pr.png",
  "MK": "https://flagcdn.com/w320/mk.png",
  "BD": "https://flagcdn.com/w320/bd.png",
  "MF": "https://flagcdn.com/w320/mf.png",
  "SG": "https://flagcdn.com/w320/sg.png",
  "GA": "https://flagcdn.com/w320/ga.png",
  "PT": "https://flagcdn.com/w320/pt.png",
  "ET": "https://flagcdn.com/w320/et.png",
  "BS": "https://flagcdn.com/w320/bs.png",
  "VA": "https://flagcdn.com/w320/va.png",
  "BF": "https://flagcdn.com/w320/bf.png",
  "TM": "https://flagcdn.com/w320/tm.png",
  "MP": "https://flagcdn.com/w320/mp.png",
  "SX": "https://flagcdn.com/w320/sx.png",
  "ZM": "https://flagcdn.com/w320/zm.png",
  "HN": "https://flagcdn.com/w320/hn.png",
  "MA": "https://flagcdn.com/w320/ma.png",
  "NP": "https://flagcdn.com/w320/np.png",
  "AG": "https://flagcdn.com/w320/ag.png",
  "OM": "https://flagcdn.com/w320/om.png",
  "HK": "https://flagcdn.com/w320/hk.png",
  "GL": "https://flagcdn.com/w320/gl.png",
  "PY": "https://flagcdn.com/w320/py.png",
  "MD": "https://flagcdn.com/w320/md.png",
  "NC": "https://flagcdn.com/w320/nc.png",
  "KG": "https://flagcdn.com/w320/kg.png",
  "MU": "https://flagcdn.com/w320/mu.png",
  "BT": "https://flagcdn.com/w320/bt.png",
  "CF": "https://flagcdn.com/w320/cf.png",
  "BL": "https://flagcdn.com/w320/bl.png",
  "CY": "https://flagcdn.com/w320/cy.png",
  "RE": "https://flagcdn.com/w320/re.png",
  "TD": "https://flagcdn.com/w320/td.png",
  "GR": "https://flagcdn.com/w320/gr.png",
  "MS": "https://flagcdn.com/w320/ms.png",
  "YE": "https://flagcdn.com/w320/ye.png",
  "IM": "https://flagcdn.com/w320/im.png",
  "DO": "https://flagcdn.com/w320/do.png",
  "GG": "https://flagcdn.com/w320/gg.png",
  "LR": "https://flagcdn.com/w320/lr.png",
  "ZW": "https://flagcdn.com/w320/zw.png",
  "GP": "https://flagcdn.com/w320/gp.png",
  "UG": "https://flagcdn.com/w320/ug.png",
  "VU": "https://flagcdn.com/w320/vu.png",
  "UM": "https://flagcdn.com/w320/um.png",
  "PS": "https://flagcdn.com/w320/ps.png",
  "PK": "https://flagcdn.com/w320/pk.png",
  "HM": "https://flagcdn.com/w320/hm.png",
  "IN": "https://flagcdn.com/w320/in.png",
  "BN": "https://flagcdn.com/w320/bn.png",
  "AE": "https://flagcdn.com/w320/ae.png",
  "EC": "https://flagcdn.com/w320/ec.png",
  "GE": "https://flagcdn.com/w320/ge.png",
  "GU": "https://flagcdn.com/w320/gu.png",
  "SC": "https://flagcdn.com/w320/sc.png",
  "FK": "https://flagcdn.com/w320/fk.png",
  "GI": "https://flagcdn.com/w320/gi.png",
  "SB": "https://flagcdn.com/w320/sb.png",
  "UZ": "https://flagcdn.com/w320/uz.png",
  "MN": "https://flagcdn.com/w320/mn.png",
  "GY": "https://flagcdn.com/w320/gy.png",
  "BA": "https://flagcdn.com/w320/ba.png",
  "TO": "https://flagcdn.com/w320/to.png",
  "FR": "https://flagcdn.com/w320/fr.png",
  "SI": "https://flagcdn.com/w320/si.png",
  "RW": "https://flagcdn.com/w320/rw.png",
  "BG": "https://flagcdn.com/w320/bg.png",
  "TL": "https://flagcdn.com/w320/tl.png",
  "NG": "https://flagcdn.com/w320/ng.png",
  "GS": "https://flagcdn.com/w320/gs.png",
  "NR": "https://flagcdn.com/w320/nr.png",
  "FO": "https://flagcdn.com/w320/fo.png",
  "AS": "https://flagcdn.com/w320/as.png",
  "LS": "https://flagcdn.com/w320/ls.png",
  "ME": "https://flagcdn.com/w320/me.png",
  "CV": "https://flagcdn.com/w320/cv.png",
  "CM": "https://flagcdn.com/w320/cm.png",
  "KP": "https://flagcdn.com/w320/kp.png",
  "CU": "https://flagcdn.com/w320/cu.png",
  "SR": "https://flagcdn.com/w320/sr.png",
  "NZ": "https://flagcdn.com/w320/nz.png",
  "MX": "https://flagcdn.com/w320/mx.png",
  "MH": "https://flagcdn.com/w320/mh.png",
  "PH": "https://flagcdn.com/w320/ph.png",
  "SE": "https://flagcdn.com/w320/se.png",
  "BV": "https://flagcdn.com/w320/bv.png",
  "JM": "https://flagcdn.com/w320/jm.png",
  "VE": "https://flagcdn.com/w320/ve.png",
  "JE": "https://flagcdn.com/w320/je.png",
  "AF": "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_the_Taliban.svg/320px-Flag_of_the_Taliban.svg.png",
  "MO": "https://flagcdn.com/w320/mo.png",
  "AX": "https://flagcdn.com/w320/ax.png",
  "SY": "https://flagcdn.com/w320/sy.png",
  "ST": "https://flagcdn.com/w320/st.png",
  "CH": "https://flagcdn.com/w320/ch.png",
  "SM": "https://flagcdn.com/w320/sm.png",
  "SZ": "https://flagcdn.com/w320/sz.png",
  "VC": "https://flagcdn.com/w320/vc.png",
  "MY": "https://flagcdn.com/w320/my.png",
  "DE": "https://flagcdn.com/w320/de.png",
  "PL": "https://flagcdn.com/w320/pl.png",
  "GD": "https://flagcdn.com/w320/gd.png",
  "SS": "https://flagcdn.com/w320/ss.png",
  "BE": "https://flagcdn.com/w320/be.png",
  "KH": "https://flagcdn.com/w320/kh.png",
  "LK": "https://flagcdn.com/w320/lk.png",
  "CI": "https://flagcdn.com/w320/ci.png",
  "GH": "https://flagcdn.com/w320/gh.png",
  "ER": "https://flagcdn.com/w320/er.png",
  "BZ": "https://flagcdn.com/w320/bz.png",
  "CX": "https://flagcdn.com/w320/cx.png",
  "GQ": "https://flagcdn.com/w320/gq.png",
  "TT": "https://flagcdn.com/w320/tt.png",
  "CK": "https://flagcdn.com/w320/ck.png",
  "ML": "https://flagcdn.com/w320/ml.png",
  "SH": "https://flagcdn.com/w320/sh.png",
  "CC": "https://flagcdn.com/w320/cc.png",
  "MZ": "https://flagcdn.com/w320/mz.png",
  "IR": "https://flagcdn.com/w320/ir.png",
  "CD": "https://flagcdn.com/w320/cd.png",
  "PW": "https://flagcdn.com/w320/pw.png",
  "MV": "https://flagcdn.com/w320/mv.png",
  "BJ": "https://flagcdn.com/w320/bj.png",
  "JO": "https://flagcdn.com/w320/jo.png",
  "MC": "https://flagcdn.com/w320/mc.png",
  "MR": "https://flagcdn.com/w320/mr.png",
  "CZ": "https://flagcdn.com/w320/cz.png",
  "BY": "https://flagcdn.com/w320/by.png",
  "AL": "https://flagcdn.com/w320/al.png",
  "MW": "https://flagcdn.com/w320/mw.png",
  "SO": "https://flagcdn.com/w320/so.png",
  "RS": "https://flagcdn.com/w320/rs.png",
  "IS": "https://flagcdn.com/w320/is.png",
  "WS": "https://flagcdn.com/w320/ws.png",
  "MM": "https://flagcdn.com/w320/mm.png",
  "TH": "https://flagcdn.com/w320/th.png",
  "LB": "https://flagcdn.com/w320/lb.png",
  "LV": "https://flagcdn.com/w320/lv.png",
  "KZ": "https://flagcdn.com/w320/kz.png",
  "MQ": "https://flagcdn.com/w320/mq.png",
  "PN": "https://flagcdn.com/w320/pn.png",
  "TV": "https://flagcdn.com/w320/tv.png",
  "HU": "https://flagcdn.com/w320/hu.png",
  "SJ": "https://flagcdn.com/w320/sj.png",
  "KM": "https://flagcdn.com/w320/km.png",
  "IL": "https://flagcdn.com/w320/il.png",
  "SD": "https://flagcdn.com/w320/sd.png",
  "AM": "https://flagcdn.com/w320/am.png",
  "UY": "https://flagcdn.com/w320/uy.png",
  "VG": "https://flagcdn.com/w320/vg.png",
  "SA": "https://flagcdn.com/w320/sa.png",
  "DM": "https://flagcdn.com/w320/dm.png",
  "TK": "https://flagcdn.com/w320/tk.png",
  "BI": "https://flagcdn.com/w320/bi.png",
  "UA": "https://flagcdn.com/w320/ua.png",
  "AT": "https://flagcdn.com/w320/at.png",
  "SL": "https://flagcdn.com/w320/sl.png",
  "BM": "https://flagcdn.com/w320/bm.png",
  "NA": "https://flagcdn.com/w320/na.png",
  "NL": "https://flagcdn.com/w320/nl.png",
  "XK": "https://flagcdn.com/w320/xk.png",
  "HT": "https://flagcdn.com/w320/ht.png",
  "ES": "https://flagcdn.com/w320/es.png",
  "IE": "https://flagcdn.com/w320/ie.png",
  "LY": "https://flagcdn.com/w320/ly.png",
  "PG": "https://flagcdn.com/w320/pg.png",
  "LC": "https://flagcdn.com/w320/lc.png",
  "NF": "https://flagcdn.com/w320/nf.png",
  "AO": "https://flagcdn.com/w320/ao.png",
  "ID": "https://flagcdn.com/w320/id.png",
  "NO": "https://flagcdn.com/w320/no.png",
  "AR": "https://flagcdn.com/w320/ar.png",
  "PA": "https://flagcdn.com/w320/pa.png",
  "JP": "https://flagcdn.com/w320/jp.png",
  "SV": "https://flagcdn.com/w320/sv.png",
  "AD": "https://flagcdn.com/w320/ad.png",
  "NE": "https://flagcdn.com/w320/ne.png",
  "NI": "https://flagcdn.com/w320/ni.png",
  "WF": "https://flagcdn.com/w320/wf.png",
  "IT": "https://flagcdn.com/w320/it.png",
  "PM": "https://flagcdn.com/w320/pm.png",
  "GW": "https://flagcdn.com/w320/gw.png",
  "TZ": "https://flagcdn.com/w320/tz.png",
  "SK": "https://flagcdn.com/w320/sk.png",
  "TF": "https://flagcdn.com/w320/tf.png",
  "FI": "https://flagcdn.com/w320/fi.png",
  "LI": "https://flagcdn.com/w320/li.png",
  "TJ": "https://flagcdn.com/w320/tj.png",
  "GT": "https://flagcdn.com/w320/gt.png",
  "TC": "https://flagcdn.com/w320/tc.png",
  "AQ": "https://flagcdn.com/w320/aq.png",
  "BH": "https://flagcdn.com/w320/bh.png",
  "MG": "https://flagcdn.com/w320/mg.png",
  "EH": "https://flagcdn.com/w320/eh.png",
  "FM": "https://flagcdn.com/w320/fm.png",
  "ZA": "https://flagcdn.com/w320/za.png",
  "BQ": "https://flagcdn.com/w320/bq.png",
  "DJ": "https://flagcdn.com/w320/dj.png",
  "SN": "https://flagcdn.com/w320/sn.png",
  "GM": "https://flagcdn.com/w320/gm.png",
  "BW": "https://flagcdn.com/w320/bw.png",
  "GF": "https://flagcdn.com/w320/gf.png",
  "KN": "https://flagcdn.com/w320/kn.png",
  "TR": "https://flagcdn.com/w320/tr.png",
  "AZ": "https://flagcdn.com/w320/az.png",
  "CR": "https://flagcdn.com/w320/cr.png",
  "CW": "https://flagcdn.com/w320/cw.png",
  "VN": "https://flagcdn.com/w320/vn.png",
  "HR": "https://flagcdn.com/w320/hr.png",
  "NU": "https://flagcdn.com/w320/nu.png",
  "AI": "https://flagcdn.com/w320/ai.png",
  "QA": "https://flagcdn.com/w320/qa.png",
  "CO": "https://flagcdn.com/w320/co.png",
  "UN": require("../assets/images/flags/no_flag_found.svg"),
  "VOIP": require("../assets/images/vb-logo.svg"),
}

export const CountryName2 = {
  "AF": "Afghanistan",
  "AL": "Albania",
  "DZ": "Algeria",
  "AS": "American Samoa",
  "AD": "Andorra",
  "AO": "Angola",
  "AI": "Anguilla",
  "AQ": "Antarctica",
  "AG": "Antigua and Barbuda",
  "AR": "Argentina",
  "AM": "Armenia",
  "AW": "Aruba",
  "AU": "Australia",
  "AT": "Austria",
  "AZ": "Azerbaijan",
  "BS": "Bahamas",
  "BH": "Bahrain",
  "BD": "Bangladesh",
  "BB": "Barbados",
  "BY": "Belarus",
  "BE": "Belgium",
  "BZ": "Belize",
  "BJ": "Benin",
  "BM": "Bermuda",
  "BT": "Bhutan",
  "BO": "Bolivia",
  "BA": "Bosnia and Herzegovina",
  "BW": "Botswana",
  "BR": "Brazil",
  "IO": "British Indian Ocean Territory",
  "VG": "British Virgin Islands",
  "BN": "Brunei",
  "BG": "Bulgaria",
  "BF": "Burkina Faso",
  "BI": "Burundi",
  "KH": "Cambodia",
  "CM": "Cameroon",
  "CA": "Canada",
  "CV": "Cape Verde",
  "KY": "Cayman Islands",
  "CF": "Central African Republic",
  "TD": "Chad",
  "CL": "Chile",
  "CN": "China",
  "CX": "Christmas Island",
  "CC": "Cocos Islands",
  "CO": "Colombia",
  "KM": "Comoros",
  "CK": "Cook Islands",
  "CR": "Costa Rica",
  "HR": "Croatia",
  "CU": "Cuba",
  "CW": "Curacao",
  "CY": "Cyprus",
  "CZ": "Czech Republic",
  "CD": "Democratic Republic of the Congo",
  "DK": "Denmark",
  "DJ": "Djibouti",
  "DM": "Dominica",
  "DO": "Dominican Republic",
  "TL": "East Timor",
  "EC": "Ecuador",
  "EG": "Egypt",
  "SV": "El Salvador",
  "GQ": "Equatorial Guinea",
  "ER": "Eritrea",
  "EE": "Estonia",
  "ET": "Ethiopia",
  "FK": "Falkland Islands",
  "FO": "Faroe Islands",
  "FJ": "Fiji",
  "FI": "Finland",
  "FR": "France",
  "PF": "French Polynesia",
  "GA": "Gabon",
  "GM": "Gambia",
  "GE": "Georgia",
  "DE": "Germany",
  "GH": "Ghana",
  "GI": "Gibraltar",
  "GR": "Greece",
  "GL": "Greenland",
  "GD": "Grenada",
  "GU": "Guam",
  "GT": "Guatemala",
  "GG": "Guernsey",
  "GN": "Guinea",
  "GW": "Guinea-Bissau",
  "GY": "Guyana",
  "HT": "Haiti",
  "HN": "Honduras",
  "HK": "Hong Kong",
  "HU": "Hungary",
  "IS": "Iceland",
  "IN": "India",
  "ID": "Indonesia",
  "IR": "Iran",
  "IQ": "Iraq",
  "IE": "Ireland",
  "IM": "Isle of Man",
  "IL": "Israel",
  "IT": "Italy",
  "CI": "Ivory Coast",
  "JM": "Jamaica",
  "JP": "Japan",
  "JE": "Jersey",
  "JO": "Jordan",
  "KZ": "Kazakhstan",
  "KE": "Kenya",
  "KI": "Kiribati",
  "XK": "Kosovo",
  "KW": "Kuwait",
  "KG": "Kyrgyzstan",
  "LA": "Laos",
  "LV": "Latvia",
  "LB": "Lebanon",
  "LS": "Lesotho",
  "LR": "Liberia",
  "LY": "Libya",
  "LI": "Liechtenstein",
  "LT": "Lithuania",
  "LU": "Luxembourg",
  "MO": "Macau",
  "MK": "Macedonia",
  "MG": "Madagascar",
  "MW": "Malawi",
  "MY": "Malaysia",
  "MV": "Maldives",
  "ML": "Mali",
  "MT": "Malta",
  "MH": "Marshall Islands",
  "MR": "Mauritania",
  "MU": "Mauritius",
  "YT": "Mayotte",
  "MX": "Mexico",
  "FM": "Micronesia",
  "MD": "Moldova",
  "MC": "Monaco",
  "MN": "Mongolia",
  "ME": "Montenegro",
  "MS": "Montserrat",
  "MA": "Morocco",
  "MZ": "Mozambique",
  "MM": "Myanmar",
  "NA": "Namibia",
  "NR": "Nauru",
  "NP": "Nepal",
  "NL": "Netherlands",
  "AN": "Netherlands Antilles",
  "NC": "New Caledonia",
  "NZ": "New Zealand",
  "NI": "Nicaragua",
  "NE": "Niger",
  "NG": "Nigeria",
  "NU": "Niue",
  "KP": "North Korea",
  "MP": "Northern Mariana Islands",
  "NO": "Norway",
  "OM": "Oman",
  "PK": "Pakistan",
  "PW": "Palau",
  "PS": "Palestine",
  "PA": "Panama",
  "PG": "Papua New Guinea",
  "PY": "Paraguay",
  "PE": "Peru",
  "PH": "Philippines",
  "PN": "Pitcairn",
  "PL": "Poland",
  "PT": "Portugal",
  "PR": "Puerto Rico",
  "QA": "Qatar",
  "CG": "Republic of the Congo",
  "RE": "Reunion",
  "RO": "Romania",
  "RU": "Russia",
  "RW": "Rwanda",
  "BL": "Saint Barthelemy",
  "SH": "Saint Helena",
  "KN": "Saint Kitts and Nevis",
  "LC": "Saint Lucia",
  "MF": "Saint Martin",
  "PM": "Saint Pierre and Miquelon",
  "VC": "Saint Vincent and the Grenadines",
  "WS": "Samoa",
  "SM": "San Marino",
  "ST": "Sao Tome and Principe",
  "SA": "Saudi Arabia",
  "SN": "Senegal",
  "RS": "Serbia",
  "SC": "Seychelles",
  "SL": "Sierra Leone",
  "SG": "Singapore",
  "SX": "Sint Maarten",
  "SK": "Slovakia",
  "SI": "Slovenia",
  "SB": "Solomon Islands",
  "SO": "Somalia",
  "ZA": "South Africa",
  "KR": "South Korea",
  "SS": "South Sudan",
  "ES": "Spain",
  "LK": "Sri Lanka",
  "SD": "Sudan",
  "SR": "Suriname",
  "SJ": "Svalbard and Jan Mayen",
  "SZ": "Swaziland",
  "SE": "Sweden",
  "CH": "Switzerland",
  "SY": "Syria",
  "TW": "Taiwan",
  "TJ": "Tajikistan",
  "TZ": "Tanzania",
  "TH": "Thailand",
  "TG": "Togo",
  "TK": "Tokelau",
  "TO": "Tonga",
  "TT": "Trinidad and Tobago",
  "TN": "Tunisia",
  "TR": "Turkey",
  "TM": "Turkmenistan",
  "TC": "Turks and Caicos Islands",
  "TV": "Tuvalu",
  "VI": "U.S. Virgin Islands",
  "UG": "Uganda",
  "UA": "Ukraine",
  "AE": "United Arab Emirates",
  "GB": "United Kingdom",
  "US": "United States",
  "UY": "Uruguay",
  "UZ": "Uzbekistan",
  "VU": "Vanuatu",
  "VA": "Vatican",
  "VE": "Venezuela",
  "VN": "Vietnam",
  "WF": "Wallis and Futuna",
  "EH": "Western Sahara",
  "YE": "Yemen",
  "ZM": "Zambia",
  "ZW": "Zimbabwe",
  
  "AX": "Åland Islands", 
  "BQ": "Bonaire, Sint Eustatius and Saba",
  "GF": "French Guiana",
  "GP": "Guadeloupe",
  "GS": "South Georgia and South Sandwich Islands",
  "UM": "United States Minor Outlying Islands",
  "MQ": "Martinique",
  "NF": "Norfolk Island",
  "TF": "French Southern Territories",
}