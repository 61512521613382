var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.conditions.screen == _vm.screens.login ? _c('div', {
    staticClass: "LoginPage-container"
  }, [_c('div', {
    staticClass: "LoginPage-leftSide"
  }, [_c('div', {
    staticClass: "voipBusiness-whiteLogo-container"
  }, [_c('vb-icon', {
    staticClass: "voipBusiness-whiteLogo",
    attrs: {
      "icon": "voipBusiness-latestLogo-icon"
    }
  })], 1), _c('img', {
    attrs: {
      "src": _vm.randomImage
    }
  })]), _c('div', {
    staticClass: "LoginPage-rightSide"
  }, [_c('div', {
    staticClass: "LoginPage-rightSide-inner"
  }, [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.login();
      }
    }
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.login.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.login.error_message))]), _c('div', {
    staticClass: "mainHeading mb-40px mt-40px"
  }, [_vm._v("Sign in")]), _c('div', {
    staticClass: "mainInput-container with-error mb-32px"
  }, [_c('label', [_vm._v("Email or Username")]), _c('div', {
    class: `mainInput ${_vm.api.login.send ? 'disabled' : ''}`
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Please type your email",
      "disabled": _vm.api.login.send,
      "type": "text"
    },
    model: {
      value: _vm.forms.login.username,
      callback: function ($$v) {
        _vm.$set(_vm.forms.login, "username", $$v);
      },
      expression: "forms.login.username"
    }
  })], 1), _vm.forms.login.submitted && _vm.$v.forms.login.username.$invalid || _vm.api.login.validation_errors && _vm.api.login.validation_errors.username ? [_c('b-icon', {
    staticClass: "danger-info-icon",
    attrs: {
      "icon": "info-circle-fill",
      "variant": "danger"
    }
  }), _c('div', {
    staticClass: "errorTemplate-v2"
  }, [_vm.forms.login.submitted && _vm.$v.forms.login.username.$invalid ? _c('p', {
    staticClass: "animated bounceIntop"
  }, [!_vm.$v.forms.login.username.required ? _c('span', [_vm._v("* Username is required")]) : !_vm.$v.forms.login.username.maxLength ? _c('span', [_vm._v("* Username is maximum 32 character")]) : _vm._e()]) : _vm._e(), _vm.api.login.validation_errors.username ? _c('p', {
    staticClass: "animated bounceIntop"
  }, _vm._l(_vm.api.login.validation_errors.username, function (error_message) {
    return _c('span', {
      key: error_message
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e()])] : _vm._e()], 2), _c('div', {
    staticClass: "mainInput-container with-error mb-10px"
  }, [_c('label', [_vm._v("Password")]), _c('div', {
    class: `mainInput password ${_vm.api.login.send ? 'disabled' : ''}`
  }, [_c('b-form-input', {
    attrs: {
      "disabled": _vm.api.login.send,
      "type": _vm.forms.login.password_show ? 'text' : 'password'
    },
    model: {
      value: _vm.forms.login.password,
      callback: function ($$v) {
        _vm.$set(_vm.forms.login, "password", $$v);
      },
      expression: "forms.login.password"
    }
  }), _c('span', {
    staticClass: "pass-icon"
  }, [_c('b-icon', {
    staticClass: "dialer-showpassword-icon cursor_pointer",
    attrs: {
      "icon": _vm.forms.login.password_show ? 'eye-slash-fill' : 'eye-fill'
    },
    on: {
      "click": function ($event) {
        _vm.forms.login.password_show = !_vm.forms.login.password_show;
      }
    }
  })], 1)], 1), _vm.forms.login.submitted && _vm.$v.forms.login.password.$invalid || _vm.api.login.validation_errors && _vm.api.login.validation_errors.password ? [_c('b-icon', {
    staticClass: "danger-info-icon",
    attrs: {
      "icon": "info-circle-fill",
      "variant": "danger"
    }
  }), _c('div', {
    staticClass: "errorTemplate-v2"
  }, [_vm.forms.login.submitted && _vm.$v.forms.login.password.$invalid ? _c('p', {
    staticClass: "animated bounceIntop"
  }, [!_vm.$v.forms.login.password.required ? _c('span', [_vm._v("* Password is required")]) : !_vm.$v.forms.login.password.minLength ? _c('span', [_vm._v("* Password is minimum 8 is required")]) : !_vm.$v.forms.login.password.maxLength ? _c('span', [_vm._v("* Password is maximum 32 is required")]) : _vm._e()]) : _vm._e(), _vm.api.login.validation_errors.password ? _c('p', {
    staticClass: "animated bounceIntop"
  }, _vm._l(_vm.api.login.validation_errors.password, function (error_message) {
    return _c('span', {
      key: error_message
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e()])] : _vm._e()], 2), _c('div', {
    staticClass: "rememberMeCheckbox-container"
  }, [_c('b-form-checkbox', {
    attrs: {
      "disabled": _vm.api.login.send
    },
    model: {
      value: _vm.forms.login.remember_me,
      callback: function ($$v) {
        _vm.$set(_vm.forms.login, "remember_me", $$v);
      },
      expression: "forms.login.remember_me"
    }
  }, [_vm._v("Remember me")])], 1), _c('b-button', {
    staticClass: "fullBlackBTN mt-16px mb-24px",
    attrs: {
      "disabled": _vm.api.login.send,
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm.api.login.send ? _c('b-spinner') : [_vm._v(" Log in "), _c('b-icon', {
    staticClass: "ml-24px iconi",
    attrs: {
      "icon": "arrow-right-short",
      "scale": "1.5"
    }
  })]], 2), !_vm.$store.getters.isIntegration ? _c('a', {
    staticClass: "dialer-custom-link text-small text-center",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.forget;
      }
    }
  }, [_vm._v("Forgot password?")]) : _vm._e()], 1)], 1)])]) : _vm.conditions.screen == _vm.screens.organization ? _c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "signUpPage-container"
  }, [_c('SignupSlider'), _c('div', {
    staticClass: "signUpPage-rightSide"
  }, [_c('div', {
    staticClass: "signUpPage-rightSide-inner"
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.organization_login.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.organization_login.error_message))]), _vm.api.organization_login.send ? _c('div', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, [_c('vb-spinner', {
    staticClass: "big"
  })], 1) : _c('ul', {
    staticClass: "organizationList-container"
  }, _vm._l(_vm.response.organizations, function (organization) {
    return _c('li', {
      key: organization.id,
      on: {
        "click": function ($event) {
          return _vm.organizationlogin(organization);
        }
      }
    }, [_c('a', {
      staticClass: "d-flex align-items-center justify-content-between"
    }, [_c('span', {
      staticClass: "d-flex align-items-center"
    }, [_c('span', {
      staticClass: "py-1 d-flex align-items-left"
    }, [_c('b', {
      staticClass: "ml-8px"
    }, [_vm._v(_vm._s(`${organization.label}`))])]), _c('span', {
      staticClass: "ml-16px organizationName"
    }, [_vm._v(_vm._s(`${organization.uid}`))])])])]);
  }), 0)], 1), _c('div', {
    staticClass: "bottomSection"
  }, [_vm._v(" Back to "), _c('a', {
    staticClass: "mx-2 font-size-14 color-primary",
    attrs: {
      "disabled": _vm.api.organization_login.send
    },
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.login;
      }
    }
  }, [_vm._v("Log In")])])])], 1)]) : _vm.conditions.screen == _vm.screens.extension_occupied ? _c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "signUpPage-container"
  }, [_c('SignupSlider'), _c('div', {
    staticClass: "signUpPage-rightSide extension_occupied"
  }, [_c('div', {
    staticClass: "signUpPage-rightSide-inner"
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.extension_signout.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.extension_signout.error_message))]), _c('div', {
    staticClass: "currentExtentions-container"
  }, [_c('div', {
    staticClass: "mainHeading w-100 mb-0 mt-40px"
  }, [_vm._v("Sign out of one of the devices")]), _c('div', {
    staticClass: "mainText text-center mb-16px w-100"
  }, [_vm._v("Sign out of one of the devices below to continue")]), _vm._l(_vm.response.extensions, function (extension) {
    return _c('div', {
      key: extension.id,
      staticClass: "currentExtentions-box"
    }, [_c('vb-icon', {
      attrs: {
        "icon": _vm._f("extensionDeviceInfo")(_vm._f("get_property")(_vm._f("jsonParse")(_vm._f("get_property")(extension, 'extra_data')), 'fp_data'), 'icon')
      }
    }), _c('div', {
      staticClass: "currentExtentions-heading"
    }, [_vm._v(_vm._s(_vm._f("extensionDeviceInfo")(_vm._f("get_property")(_vm._f("jsonParse")(_vm._f("get_property")(extension, 'extra_data')), 'fp_data'), 'app-name')))]), _c('div', {
      staticClass: "currentExtentions-text"
    }, [_vm._v("Logged in from " + _vm._s(_vm._f("extensionDeviceInfo")(_vm._f("get_property")(_vm._f("jsonParse")(_vm._f("get_property")(extension, 'extra_data')), 'fp_data'), 'ip')))]), _c('div', {
      staticClass: "currentExtentions-subText"
    }, [_vm._v("Logged in " + _vm._s(_vm._f("filter_date_current")(_vm._f("get_property")(extension, 'created_at'))))]), _c('b-button', {
      staticClass: "fullBlackBTN",
      attrs: {
        "disabled": !!_vm.api.extension_signout.send,
        "type": "submit",
        "variant": "primary"
      },
      on: {
        "click": function ($event) {
          return _vm.extensionsignout(extension.id);
        }
      }
    }, [_vm.api.extension_signout.send == extension.id ? _c('vb-spinner') : [_vm._v("Sign out")]], 2)], 1);
  })], 2)], 1), _c('div', {
    staticClass: "bottomSection"
  }, [_vm._v(" Back to "), _c('a', {
    staticClass: "mx-2 font-size-14 color-primary",
    attrs: {
      "disabled": _vm.api.extension_signout.send
    },
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.login;
      }
    }
  }, [_vm._v("Log In")])])])], 1)]) : _vm.conditions.screen == _vm.screens.confirm_device ? _c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.addconfirmdevice('yes');
      }
    }
  }, [_c('div', {
    staticClass: "signUpPage-container"
  }, [_c('SignupSlider'), _c('div', {
    staticClass: "signUpPage-rightSide"
  }, [_c('div', {
    staticClass: "signUpPage-rightSide-inner"
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.confirm_device.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.confirm_device.error_message))]), _c('div', {
    staticClass: "w-100 d-flex justify-content-center mt-40px"
  }, [_c('vb-icon', {
    attrs: {
      "height": "179.85px",
      "width": "214.99px",
      "icon": "trustedDevicesMain-icon"
    }
  })], 1), _c('div', {
    staticClass: "mainText mt-40px"
  }, [_c('b', [_vm._v("This device is not on your trusted devices. Do you want to add on confirm Devices")])]), _vm.api.confirm_device.send ? _c('vb-loading') : [_c('b-button', {
    staticClass: "fullBlackBTN mt-40px",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v("Yes")]), _c('b-button', {
    staticClass: "BlackOutlineBTN mt-20px",
    attrs: {
      "type": "button",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.addconfirmdevice('no');
      }
    }
  }, [_vm._v("No")])]], 2)])], 1)]) : _vm.conditions.screen == _vm.screens.pin_auth ? _c('div', {
    staticClass: "signUpPage-container"
  }, [_c('SignupSlider'), _c('div', {
    staticClass: "signUpPage-rightSide"
  }, [_c('div', {
    staticClass: "signUpPage-rightSide-inner d-flex align-items-center"
  }, [_c('b-form', {
    staticClass: "w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.pinauth();
      }
    }
  }, [_c('div', {
    staticClass: "m-auto w-100"
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.pin_auth.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.pin_auth.error_message))]), _c('div', {
    staticClass: "mainHeading-smallerVersion mt-40px"
  }, [_vm._v("Enter PIN code")]), _c('div', {
    staticClass: "signupLoginV2-sub-heading text-center color-primary mt-32px"
  }, [_vm._v(" We have just sent you an email with a security PIN code. Please enter the PIN code below. ")]), _c('div', {
    staticClass: "latestPin-container bigger mt-70px with-error"
  }, [_c('div', {
    staticClass: "latestPin-inner pin"
  }, [_c('vb-pin-code', {
    staticClass: "input",
    on: {
      "complete": function ($event) {
        _vm.forms.pin_auth.code = $event;
      }
    }
  })], 1), _vm.forms.pin_auth.submitted && _vm.$v.forms.pin_auth.code.$invalid || _vm.api.pin_auth.validation_errors.code ? [_c('div', {
    staticClass: "errorTemplate-v2"
  }, [_vm.forms.pin_auth.submitted && _vm.$v.forms.pin_auth.pin.$invalid ? _c('p', {
    staticClass: "animated bounceIntop"
  }, [!_vm.$v.forms.pin_auth.code.required ? _c('span', [_vm._v("* pincode is required")]) : !_vm.$v.forms.pin_auth.code.valid ? _c('span', [_vm._v("* pincode is invalid")]) : _vm._e()]) : _vm._e(), _vm.api.pin_auth.validation_errors.code ? _c('p', {
    staticClass: "animated bounceIntop"
  }, _vm._l(_vm.api.pin_auth.validation_errors.code, function (error_message) {
    return _c('span', {
      key: error_message
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e()])] : _vm._e()], 2), _c('b-button', {
    staticClass: "fullBlackBTN mt-40px",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm.api.pin_auth.send ? _c('vb-spinner') : [_vm._v(" Log in "), _c('b-icon', {
    staticClass: "ml-24px iconi",
    attrs: {
      "icon": "arrow-right-short",
      "scale": "1.5"
    }
  })]], 2), _c('b-button', {
    staticClass: "BlackOutlineBTN mt-20px",
    attrs: {
      "variant": "primary",
      "type": "button",
      "disabled": _vm.api.pin_auth.send
    },
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.login;
      }
    }
  }, [_vm._v("Back to Login")])], 1)])], 1)])], 1) : _vm.conditions.screen == _vm.screens.blocked ? _c('div', {
    staticClass: "dialer-login-form notAllowed"
  }, [_c('div', {
    staticClass: "notAllowed-inner"
  }, [_c('div', {
    staticClass: "notAllowed-innerBox"
  }, [_c('vb-icon', {
    staticClass: "notAllowed-innerBox-icon",
    attrs: {
      "icon": "newSignup-blockUser-icon",
      "width": "125.705px",
      "height": "124.001px"
    }
  }), _c('div', {
    staticClass: "notAllowed-innerBox-heading"
  }, [_vm._v("User is Blocked")]), _c('div', {
    staticClass: "notAllowed-innerBox-text"
  }, [_vm._v(" Your account has been blocked. If you believe this is a mistake or need assistance, please contact support. ")]), _c('div', {
    staticClass: "notAllowed-innerBox-text"
  }, [_c('span', {
    staticClass: "makeBold"
  }, [_vm._v("Reason:")]), _vm._v(" " + _vm._s(_vm.response.blocked.message) + " ")]), _c('button', {
    staticClass: "newButton mwd-265px mt-32px",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('supportTicketModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "newSignup-submitTicket-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Submit a ticket")])], 1)], 1), _c('div', {
    staticClass: "notAllowed-rightSideBox"
  }, [_c('vb-icon', {
    staticClass: "newSignup-supportTicketSideBar-icon",
    attrs: {
      "icon": "newSignup-supportTicketSideBar-icon",
      "height": "50px",
      "width": "50px"
    }
  })], 1), _c('div', {
    staticClass: "bottomSection"
  }, [_vm._v(" Back to "), _c('a', {
    staticClass: "mx-2 font-weight-bold font-size-14 color-primary",
    attrs: {
      "disabled": _vm.api.pin_auth.send
    },
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.login;
      }
    }
  }, [_vm._v("Log In")])])]), _c('modal', {
    staticClass: "dialer_animation right_side_popup ticketDetailModal",
    attrs: {
      "name": "supportTicketModal"
    }
  }, [false ? _c('div', {
    staticClass: "w-100"
  }, _vm._l(4, function (n) {
    return _c('div', {
      key: n.id,
      class: `latestShimmerDesign w-100 my-4`,
      staticStyle: {
        "height": "80px"
      }
    });
  }), 0) : _c('div', {
    staticClass: "SupportTicketDetail-container"
  }, [_c('div', {
    staticClass: "dialer-edit-header headerInMobile"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title newer mb-0"
  }, [_vm._v("Ticket #123")]), _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('supportTicketModal');
      }
    }
  })], 1), _c('div', {
    staticClass: "d-flex mt-2 w-100"
  }, [_c('h2', {
    staticClass: "dialer-edit-title newer mb-0"
  }, [_vm._v("Subject:")]), _c('span', {
    staticClass: "ticket-subject ms-2"
  }, [_vm._v(" ticket.subject ")])])]), _c('div', {
    staticClass: "dialer-edit-header align-items-start"
  }, [_c('div', {
    staticClass: "customWidth-parent"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-edit-title newer mb-0"
  }, [_vm._v("Ticket #123")]), _c('div', {
    staticClass: "d-flex mt-2 customWidth"
  }, [_c('h2', {
    staticClass: "dialer-edit-title newer mb-0"
  }, [_vm._v("Subject:")]), _c('span', {
    staticClass: "ticket-subject ms-2"
  }, [_vm._v(" ticket.subject ")])])])]), _c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('supportTicketModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "ticketTopInfoContainer"
  }, [_c('div', {
    staticClass: "ticketTopInfoContainer-row"
  }, [_c('div', {
    staticClass: "ticketTopInfoContainer-column one"
  }, [_vm._v("SUBMITTER")]), _c('div', {
    staticClass: "ticketTopInfoContainer-column two"
  }, [_vm._v("myself")])]), _c('div', {
    staticClass: "ticketTopInfoContainer-row"
  }, [_c('div', {
    staticClass: "ticketTopInfoContainer-column one"
  }, [_vm._v("SUBMITTED")]), _c('div', {
    staticClass: "ticketTopInfoContainer-column two"
  }, [_vm._v("25 days ago")])]), _c('div', {
    staticClass: "ticketTopInfoContainer-row"
  }, [_c('div', {
    staticClass: "ticketTopInfoContainer-column one"
  }, [_vm._v("DEPARTMENT")]), _c('div', {
    staticClass: "ticketTopInfoContainer-column two"
  }, [_vm._v("Development")])]), _c('div', {
    staticClass: "ticketTopInfoContainer-row"
  }, [_c('div', {
    staticClass: "ticketTopInfoContainer-column one"
  }, [_vm._v("STATUS/PRIORITY")]), _c('div', {
    staticClass: "ticketTopInfoContainer-column two"
  }, [_vm._v("Active")])])]), _c('div', {
    staticClass: "ticketConversation-container"
  }, [false ? _c('div', {
    staticClass: "h-100 d-flex d-flex justify-content-center align-items-center my-5"
  }, [_c('div', {
    staticClass: "w-75 text-center mt-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("No message to see here")]), _c('div', {
    staticClass: "latestGreyBox-heading-description mt-10px"
  }, [_vm._v("You can send one by writing a message below")])])]) : _c('div', [_c('div', {
    class: `ticketConversation-item sender`
  }, [_c('div', {
    staticClass: "ticketConversation-item-top"
  }, [_c('div', {
    staticClass: "ticketConversation-item-top-left"
  }, [_vm._v(" Frank Fahad ")]), _c('div', {
    staticClass: "ticketConversation-item-top-right"
  }, [_c('b-icon', {
    attrs: {
      "icon": "clock"
    }
  }), _vm._v(" At 06:28 am , 11 Jul 2024 ")], 1)]), _c('div', {
    staticClass: "ticketConversation-item-bottom"
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" We apologise for the delayed response. Unfortunately, we do not offer SMS services in bulk. Our SMS service is available at a rate of 5 pence per message. Currently, we only provide call packages. If there is anything else i can help you with please contact us ")])])])]), _c('div', {
    staticClass: "ticketConversation-actions"
  }, [true ? [_c('div', {
    staticClass: "ticketActions-heading"
  }, [_vm._v("To update this ticket with more information please enter the details below:-")]), _c('div', {
    staticClass: "ticketActions-textArea"
  }, [_c('textarea', {
    staticClass: "w-100",
    attrs: {
      "rows": "7",
      "placeholder": "Write your message"
    }
  })])] : _vm._e(), _c('div', {
    staticClass: "d-flex mt-16px"
  }, [true ? _c('button', {
    staticClass: "newButton mr-20px"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-updateTicket-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart d-flex justify-content-center position-relative"
  }, [_vm._v(" Update ticket ")])], 1) : _vm._e(), _c('a', {
    staticClass: "newCloseButton"
  }, [true ? [_c('vb-icon', {
    attrs: {
      "icon": "squared-openTicket-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Reopen ticket")])] : [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close ticket")])]], 2)])], 2)])])])])], 1) : _vm.conditions.screen == _vm.screens.forget ? _c('div', {
    staticClass: "signUpPage-container"
  }, [_c('SignupSlider'), _c('div', {
    staticClass: "signUpPage-rightSide"
  }, [_c('div', {
    staticClass: "signUpPage-rightSide-inner d-flex align-items-center"
  }, [_c('b-form', {
    staticClass: "w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.forgotpassword();
      }
    }
  }, [_c('div', {
    staticClass: "m-auto"
  }, [_c('div', {
    staticClass: "mainHeading mt-16px"
  }, [_vm._v("Forgot Password?")]), _c('div', {
    staticClass: "subIndicatorText mt-8px"
  }, [[_vm._v("step 1")]], 2), _c('div', {
    staticClass: "SubHeading text-center mt-12px"
  }, [_vm.forms.forgot.sub_user ? [_vm._v(" Enter the email address linked to your account or enter an admin email address ")] : [_vm._v("Enter your email or username")]], 2), _c('b-alert', {
    attrs: {
      "show": !!_vm.api.forgot.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.forgot.error_message))]), _vm.forms.forgot.sub_user ? _c('div', {
    staticClass: "mainInput-container mb-40px with-error"
  }, [_c('label', [_vm._v("Email")]), _c('div', {
    class: `mainInput ${_vm.api.forgot.send ? 'disabled' : ''}`
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "disabled": _vm.api.forgot.send
    },
    model: {
      value: _vm.forms.forgot.notfication_email,
      callback: function ($$v) {
        _vm.$set(_vm.forms.forgot, "notfication_email", $$v);
      },
      expression: "forms.forgot.notfication_email"
    }
  })], 1), _vm.forms.forgot.submitted && _vm.$v.forms.forgot.notfication_email.$invalid || _vm.api.forgot.validation_errors.notfication_email ? [_c('b-icon', {
    staticClass: "danger-info-icon",
    attrs: {
      "icon": "info-circle-fill",
      "variant": "danger"
    }
  }), _c('div', {
    staticClass: "errorTemplate-v2"
  }, [_vm.forms.forgot.submitted && _vm.$v.forms.forgot.notfication_email.$invalid ? _c('p', {
    staticClass: "mb-1 text-small animated bounceIntop"
  }, [!_vm.$v.forms.forgot.notfication_email.required ? _c('span', [_vm._v("* email is required")]) : !_vm.$v.forms.forgot.notfication_email.email ? _c('span', [_vm._v("* email is invalid")]) : _vm._e()]) : _vm.api.forgot.validation_errors.notfication_email ? _c('p', {
    staticClass: "animated bounceIntop"
  }, _vm._l(_vm.api.forgot.validation_errors.notfication_email, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  }), 0) : _vm._e()])] : _vm._e()], 2) : _c('div', {
    staticClass: "mainInput-container mb-40px with-error"
  }, [_c('label', [_vm._v("Username / Email")]), _c('div', {
    class: `mainInput ${_vm.api.forgot.send ? 'disabled' : ''}`
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "disabled": _vm.api.forgot.send
    },
    model: {
      value: _vm.forms.forgot.email,
      callback: function ($$v) {
        _vm.$set(_vm.forms.forgot, "email", $$v);
      },
      expression: "forms.forgot.email"
    }
  })], 1), _vm.forms.forgot.submitted && _vm.$v.forms.forgot.email.$invalid || _vm.api.forgot.validation_errors.email ? [_c('b-icon', {
    staticClass: "danger-info-icon",
    attrs: {
      "icon": "info-circle-fill",
      "variant": "danger"
    }
  }), _c('div', {
    staticClass: "errorTemplate-v2"
  }, [_vm.forms.forgot.submitted && _vm.$v.forms.forgot.email.$invalid ? _c('p', {
    staticClass: "mb-1 text-small animated bounceIntop"
  }, [!_vm.$v.forms.forgot.email.required ? _c('span', [_vm._v("* email is required")]) : _vm._e()]) : _vm.api.forgot.validation_errors.email ? _c('p', {
    staticClass: "animated bounceIntop"
  }, _vm._l(_vm.api.forgot.validation_errors.email, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  }), 0) : _vm._e()])] : _vm._e()], 2), _c('b-button', {
    staticClass: "fullBlackBTN",
    attrs: {
      "disabled": _vm.api.forgot.send,
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm.api.forgot.send ? _c('vb-spinner') : [_vm._v("Reset Password")]], 2)], 1)])], 1), _c('div', {
    staticClass: "bottomSection"
  }, [_vm._v(" Oh!, I remember my password. Take me back to "), _c('a', {
    staticClass: "mx-2 font-weight-bold font-size-14 color-primary",
    attrs: {
      "disabled": _vm.api.forgot.send
    },
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.login;
      }
    }
  }, [_vm._v("Log In")])])])], 1) : _vm.conditions.screen == _vm.screens.pincode ? _c('div', {
    staticClass: "signUpPage-container"
  }, [_c('SignupSlider'), _c('div', {
    staticClass: "signUpPage-rightSide"
  }, [_c('div', {
    staticClass: "signUpPage-rightSide-inner d-flex align-items-center"
  }, [_c('div', {
    staticClass: "m-auto w-100"
  }, [_c('img', {
    staticClass: "newPasswordScreenImage",
    attrs: {
      "src": require("../assets/images/checkYourEmailIMG.png"),
      "alt": "icon"
    }
  }), _c('div', {
    staticClass: "mainHeading mt-16px"
  }, [_vm._v("Forgot Password?")]), _c('div', {
    staticClass: "subIndicatorText mt-8px"
  }, [_vm._v("step 2")]), _c('div', {
    staticClass: "mainHeading-smallerVersion mt-16px"
  }, [_vm._v("Check your email!")]), _c('div', {
    staticClass: "SubHeading text-center mt-12px"
  }, [_vm._v("A pin has been sent to yours or an admin email please enter it below")]), _c('div', {
    staticClass: "latestPin-container bigger mt-70px with-error"
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": !!_vm.api.pin.error_message
    }
  }, [_vm._v(_vm._s(_vm.api.pin.error_message))]), _c('b-alert', {
    staticClass: "mb-16px",
    attrs: {
      "show": !!_vm.api.resend.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.resend.error_message))]), _c('b-alert', {
    staticClass: "mb-16px",
    attrs: {
      "show": !!_vm.api.resend.success_message,
      "variant": "success"
    }
  }, [_vm._v(_vm._s(_vm.api.resend.success_message))]), _c('div', {
    staticClass: "latestPin-inner pin"
  }, [_c('vb-pin-code', {
    staticClass: "input",
    attrs: {
      "disabled": _vm.api.pin.send || _vm.api.resend.send
    },
    on: {
      "complete": function ($event) {
        _vm.forms.pin.pincode = $event;
        _vm.verifyPincode();
      }
    }
  }), _vm.forms.pin.submitted && _vm.$v.forms.pin.pincode.$invalid || _vm.api.pin.validation_errors.pincode ? [_c('b-icon', {
    staticClass: "danger-info-icon",
    attrs: {
      "icon": "info-circle-fill",
      "variant": "danger"
    }
  }), _c('div', {
    staticClass: "errorTemplate-v2"
  }, [_vm.forms.pin.submitted && _vm.$v.forms.pin.pincode.$invalid ? _c('p', {
    staticClass: "mb-1 text-small animated bounceIntop"
  }, [!_vm.$v.forms.pin.pincode.required ? _c('span', [_vm._v("* pincode is required")]) : _vm._e()]) : _vm.api.pin.validation_errors.pincode ? _c('p', {
    staticClass: "animated bounceIntop"
  }, _vm._l(_vm.api.pin.validation_errors.pincode, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  }), 0) : _vm._e()])] : _vm._e()], 2), _c('div', {
    staticClass: "w-100 d-flex justify-content-center align-items-center mt-20px"
  }, [_c('span', {
    class: `cursor_pointer min-width-185px d-flex ${_vm.api.resend.count_down.seconds != 0 ? 'justify-content-between' : 'justify-content-center'} align-items-center`,
    on: {
      "click": function ($event) {
        _vm.api.resend.count_down.seconds != 0 ? '' : _vm.resend();
      }
    }
  }, [_c('span', {
    attrs: {
      "aria-disabled": _vm.api.resend.count_down.seconds != 0,
      "disabled": _vm.api.resend.count_down.seconds != 0
    }
  }, [_c('b-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "me-2",
    attrs: {
      "icon": "arrow-clockwise",
      "animation": _vm.api.resend.send ? 'spin' : '',
      "title": "Resend Email to Confirm"
    }
  }), _c('span', {
    staticClass: "me-2"
  }, [_vm._v("Resend Email")])], 1), _vm.api.resend.count_down.seconds != 0 ? _c('span', [_vm._v(_vm._s(_vm._f("duration_format")(_vm.api.resend.count_down.seconds, {
    format: 'mm:ss',
    default: '00:00'
  })))]) : _vm._e()])]), _vm.api.pin.count != 0 ? _c('div', {
    staticClass: "attemptesIndicatorText mt-40px"
  }, [_vm._v(_vm._s(3 - _vm.api.pin.count + 1) + " attempts remaining")]) : _vm._e()], 1), _c('b-button', {
    staticClass: "fullBlackBTN mt-40px",
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.forget;
      }
    }
  }, [_vm._v("Back")])], 1)]), _c('div', {
    staticClass: "bottomSection"
  }, [_vm._v(" Oh!, I remember my password. Take me back to "), _c('a', {
    staticClass: "mx-2 font-weight-bold font-size-14 color-primary",
    attrs: {
      "disabled": _vm.api.forgot.send
    },
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.login;
      }
    }
  }, [_vm._v("Log In")])])])], 1) : _vm.conditions.screen == _vm.screens.reset ? _c('div', {
    staticClass: "signUpPage-container"
  }, [_c('SignupSlider'), _c('div', {
    staticClass: "signUpPage-rightSide"
  }, [_c('div', {
    staticClass: "signUpPage-rightSide-inner d-flex align-items-center"
  }, [_c('b-form', {
    staticClass: "w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.reset();
      }
    }
  }, [_c('div', {
    staticClass: "m-auto w-100"
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.reset.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.reset.error_message))]), _c('div', {
    staticClass: "mainHeading mt-16px"
  }, [_vm._v("Forgot Password?")]), _c('div', {
    staticClass: "subIndicatorText mt-8px"
  }, [_vm._v("step 3")]), _c('div', {
    staticClass: "mainHeading-smallerVersion mt-16px"
  }, [_vm._v("Create new password")]), _c('div', {
    staticClass: "SubHeading text-center mt-12px"
  }, [_vm._v("Enter a strong and memorable password")]), _c('div', {
    staticClass: "mainInput-container mt-40px mb-32px with-error"
  }, [_c('label', [_vm._v("New Password")]), _c('div', {
    class: `mainInput password ${_vm.api.reset.send ? 'disabled' : ''}`
  }, [_c('b-form-input', {
    attrs: {
      "type": _vm.forms.reset.password_show ? 'text' : 'password'
    },
    model: {
      value: _vm.forms.reset.password,
      callback: function ($$v) {
        _vm.$set(_vm.forms.reset, "password", $$v);
      },
      expression: "forms.reset.password"
    }
  }), _c('span', {
    staticClass: "pass-icon"
  }, [_c('b-icon', {
    staticClass: "dialer-showpassword-icon cursor_pointer",
    attrs: {
      "icon": _vm.forms.reset.password_show ? 'eye-slash-fill' : 'eye-fill'
    },
    on: {
      "click": function ($event) {
        _vm.forms.reset.password_show = !_vm.forms.reset.password_show;
      }
    }
  })], 1)], 1), _vm.forms.reset.submitted && _vm.$v.forms.reset.password.$invalid || _vm.api.reset.validation_errors.password ? [_c('b-icon', {
    staticClass: "danger-info-icon",
    attrs: {
      "icon": "info-circle-fill",
      "variant": "danger"
    }
  }), _c('div', {
    staticClass: "errorTemplate-v2"
  }, [_vm.forms.reset.submitted && _vm.$v.forms.reset.password.$invalid ? _c('p', {
    staticClass: "animated bounceIntop"
  }, [!_vm.$v.forms.reset.password.required ? _c('span', [_vm._v("* Password is required")]) : _vm._e()]) : _vm._e(), _vm.api.reset.validation_errors.password ? _c('p', {
    staticClass: "animated bounceIntop"
  }, _vm._l(_vm.api.reset.validation_errors.password, function (error_message) {
    return _c('span', {
      key: error_message
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e()])] : _vm._e()], 2), _c('div', {
    staticClass: "mainInput-container mt-40px mb-32px with-error"
  }, [_c('label', [_vm._v("Confirm Password")]), _c('div', {
    class: `mainInput password ${_vm.api.reset.send ? 'disabled' : ''}`
  }, [_c('b-form-input', {
    attrs: {
      "type": _vm.forms.reset.confirm_password_show ? 'text' : 'password'
    },
    model: {
      value: _vm.forms.reset.confirm_password,
      callback: function ($$v) {
        _vm.$set(_vm.forms.reset, "confirm_password", $$v);
      },
      expression: "forms.reset.confirm_password"
    }
  }), _c('span', {
    staticClass: "pass-icon"
  }, [_c('b-icon', {
    staticClass: "dialer-showpassword-icon cursor_pointer",
    attrs: {
      "icon": _vm.forms.reset.confirm_password_show ? 'eye-slash-fill' : 'eye-fill'
    },
    on: {
      "click": function ($event) {
        _vm.forms.reset.confirm_password_show = !_vm.forms.reset.confirm_password_show;
      }
    }
  })], 1)], 1), _vm.forms.reset.submitted && _vm.$v.forms.reset.confirm_password.$invalid ? [_c('b-icon', {
    staticClass: "danger-info-icon",
    attrs: {
      "icon": "info-circle-fill",
      "variant": "danger"
    }
  }), _c('div', {
    staticClass: "errorTemplate-v2"
  }, [_vm.forms.reset.submitted && _vm.$v.forms.reset.confirm_password.$invalid ? _c('p', {
    staticClass: "animated bounceIntop"
  }, [!_vm.$v.forms.reset.confirm_password.required ? _c('span', [_vm._v("* Confirm Password is required")]) : _vm._e()]) : _vm._e()])] : _vm._e()], 2), _c('div', {
    staticClass: "PasswordLength-LineContainer mt-0 mb-16px"
  }, [_c('div', {
    staticClass: "line-wrap"
  }, [_c('div', {
    class: `line-itself ${_vm.passwordStrength / 5 * 100 > 60 ? 'strong' : ''} `,
    style: `width: calc(${_vm.passwordStrength / 5 * 100}% + ${_vm.passwordStrength > 0 ? '2' : '0'}px);`
  })]), _c('small', {
    staticClass: "line-text"
  }, [_vm._v(_vm._s(_vm._f("filterPasswordStrengthText")(_vm.passwordStrength)))])]), _c('b-button', {
    staticClass: "fullBlackBTN mt-40px",
    attrs: {
      "disabled": _vm.api.reset.send,
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm.api.reset.send ? _c('vb-spinner') : [_vm._v("Reset Password")]], 2), _c('b-button', {
    staticClass: "BlackOutlineBTN mt-20px",
    attrs: {
      "variant": "primary",
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.pincode;
      }
    }
  }, [_vm._v("Back")])], 1)])], 1), _c('div', {
    staticClass: "bottomSection"
  }, [_vm._v(" Oh!, I remember my password. Take me back to "), _c('a', {
    staticClass: "mx-2 font-weight-bold font-size-14 color-primary",
    attrs: {
      "disabled": _vm.api.forgot.send
    },
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.login;
      }
    }
  }, [_vm._v("Log In")])])])], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }