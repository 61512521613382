var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.isGlobal && !_vm.team ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(0), _c('div', [_vm.api.change_greeting_type.send || _vm.api.fetch_voicemails.send ? _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "34px",
      "width": "60px",
      "border-radius": "25px"
    }
  }) : _c('div', {
    staticClass: "w-auto"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    attrs: {
      "title": _vm.getUserPermissions.advanced_greetings_in_voice_mail ? '' : 'Your package does not support this feature'
    }
  }, [!_vm.team ? _c('b-form-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "disabled": !_vm.getUserPermissions.advanced_greetings_in_voice_mail,
      "switch": "",
      "checked": _vm.selected.greeting_type,
      "offText": "Basic",
      "onText": "Advanced",
      "sync": ""
    },
    on: {
      "change": function ($event) {
        return _vm.changeGreetingType();
      }
    }
  }) : _vm._e()], 1)])])]) : _vm._e(), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column justify-content-center align-items-start"
  }, [!_vm.isGlobal ? _c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Voicemail greetings message")]) : _vm._e(), !_vm.isGlobal ? _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Create your custom voicemail messages for basic or advanced needs.")]) : _vm._e(), _c('div', {
    class: `VoicemailGreetingsNewDesign-container ${!_vm.isGlobal ? 'mt-20px' : ''}`
  }, _vm._l(_vm.voicemails, function (data) {
    return _c('div', {
      key: data.value
    }, [_c('div', {
      staticClass: "VoicemailGreetingsNewDesign-item"
    }, [_c('div', {
      class: `VoicemailGreetingsNewDesign-item-text ${_vm.voicemailValues[data.value] && _vm.voicemailValues[data.value].file_type != 'system' ? 'itsCustom' : ''}`
    }, [_c('span', {
      staticClass: "VoicemailGreetingsNewDesign-item-text-itself"
    }, [_vm._v(_vm._s(data.text2))]), _vm.voicemailValues[data.value] && _vm.voicemailValues[data.value].file_type != 'system' ? _c('span', {
      staticClass: "newBlueColorTagLike"
    }, [_vm._v("Custom")]) : _vm._e()]), _c('div', {
      staticClass: "VoicemailGreetingsNewDesign-item-rightSide"
    }, [!_vm.api.fetch_voicemails.send ? _c('vb-audio-player', {
      staticClass: "whiteBGaudioWithGreyRoundedBorder-again",
      attrs: {
        "design": `${_vm.$store.getters.getIsMobile ? 'minimum' : ''}`,
        "disabled": _vm.api.change_greeting_type.send,
        "src": _vm._f("get_property")(_vm.voicemailValues, `${data.value}.url`)
      }
    }) : _vm._e(), _c('div', {
      staticClass: "VoicemailGreetingsNewDesign-item-buttonSection"
    }, [_c('vb-icon', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip"
      }],
      staticClass: "cursor_pointer ml-16px latestPencilIcon",
      attrs: {
        "icon": "latestPencilIcon",
        "height": "38px",
        "width": "38px",
        "title": !data.advanced || _vm.getUserPermissions.advanced_greetings_in_voice_mail ? '' : 'Your package does not support this feature'
      },
      on: {
        "click": function ($event) {
          data.advanced && !_vm.getUserPermissions.advanced_greetings_in_voice_mail || _vm.api.upload_audio.send ? '' : _vm.conditions.collapse = _vm.conditions.collapse == data.value ? '' : data.value;
        }
      }
    }), _vm.voicemailValues[data.value] && _vm.voicemailValues[data.value].file_type != 'system' ? _c('vb-icon', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip"
      }],
      staticClass: "cursor_pointer ml-16px",
      attrs: {
        "height": "22px",
        "width": "22px",
        "icon": "trash-IOSIcon",
        "disabled": data.advanced && !_vm.getUserPermissions.advanced_greetings_in_voice_mail,
        "title": !data.advanced || _vm.getUserPermissions.advanced_greetings_in_voice_mail ? '' : 'Your package does not support this feature'
      },
      on: {
        "click": function ($event) {
          return _vm.deleteRecordVoiceMail(data);
        }
      }
    }) : _vm._e()], 1)], 1)]), _vm.conditions.collapse == data.value ? _c('div', {
      staticClass: "mt-12px max-width-600px"
    }, [_c('AudioFileInput', {
      attrs: {
        "disabled": _vm.api.upload_audio.send == data.value,
        "options": {
          show_text: false,
          sound_library: true
        }
      },
      on: {
        "change": function ($event) {
          return _vm.uploadAudio({
            sound_type: data.value,
            file: $event.file,
            type: $event.type,
            complete: $event.complete,
            reset: $event.reset
          });
        }
      }
    })], 1) : _vm._e()]);
  }), 0)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Voicemail greetings")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Your custom voicemail messages – enable or disable the switch for basic or advanced voicemail greetings.")])]);

}]

export { render, staticRenderFns }