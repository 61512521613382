var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "center-zoomIn-transition v2 SelectUnavailableTime",
    attrs: {
      "scrollable": true,
      "width": "100%",
      "height": "auto",
      "clickToClose": false,
      "name": _vm.modalName
    },
    on: {
      "before-close": function ($event) {
        return _vm.onBeforeClose($event);
      },
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "opened": function ($event) {
        return _vm.onOpened($event);
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit w-100"
  }, [_vm.getIsMobile ? _c('div', {
    staticClass: "dialer-edit-header mt-0 flex-column"
  }, [_c('div', {
    staticClass: "d-flex w-100 justify-content-between align-items-center mb-2"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Select unavailable time")])]), _c('div', {
    staticClass: "dialer-edit-actions d-flex justify-content-end w-100"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.onDone();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]) : _c('div', {
    staticClass: "dialer-edit-header b-0"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Select unavailable time")]), _c('div', {
    staticClass: "dialer-edit-actions d-flex"
  }, [_c('a', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "UnavailableTimes-row"
  }, [_c('div', {
    staticClass: "UnavailableTimes-startTime"
  }, [_c('b-form-timepicker', {
    attrs: {
      "locale": "en"
    },
    on: {
      "input": function ($event) {
        return _vm.onValidate();
      }
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-icon', {
          staticClass: "UnavailableTimes-TimesIcon",
          attrs: {
            "icon": "UnavailableTimes-TimesIcon"
          }
        }), _c('div', [_c('div', {
          staticClass: "UnavailableTimes-smallHeading"
        }, [_vm._v("Unavailable")]), _c('div', {
          staticClass: "UnavailableTimes-bigHeading"
        }, [_vm._v("Start time")])])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.start,
      callback: function ($$v) {
        _vm.$set(_vm.form, "start", $$v);
      },
      expression: "form.start"
    }
  })], 1), _c('div', {
    staticClass: "UnavailableTimes-endTime"
  }, [_c('b-form-timepicker', {
    attrs: {
      "locale": "en"
    },
    on: {
      "input": function ($event) {
        return _vm.onValidate();
      }
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-icon', {
          staticClass: "UnavailableTimes-TimesIcon",
          attrs: {
            "icon": "UnavailableTimes-TimesIcon-endTime"
          }
        }), _c('div', [_c('div', {
          staticClass: "UnavailableTimes-smallHeading"
        }, [_vm._v("Unavailable")]), _c('div', {
          staticClass: "UnavailableTimes-bigHeading"
        }, [_vm._v("End time")])])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.end,
      callback: function ($$v) {
        _vm.$set(_vm.form, "end", $$v);
      },
      expression: "form.end"
    }
  })], 1)]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox withoutBorder mt-20px pb-4"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    ref: "slider",
    staticClass: "UnavailableTimes-slider"
  })])])]), _c('div', {
    staticClass: "d-flex justify-content-end w-100 mt-20px"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton",
    on: {
      "click": function ($event) {
        return _vm.onDone();
      }
    }
  }, [_vm._v("Update")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }