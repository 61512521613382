<template>
  <div class="mt-20px">
    <div class="whiteBoxWithBorderInsideGreyBox">
      <div class="whiteBoxWithBorderInsideGreyBox-row">
        <div class="d-flex flex-column">
          <div class="latestGreyBox-heading-main">Forward source caller ID</div>
          <div class="latestGreyBox-descriptionText">
            <span v-if="forms.forward_source_caller_id.enabled" class="fixInLineDDInCallForwardingSettingsModal">
              Incoming calls will display 
              <span>
                <vb-select class="onlyTextInButton-dropDown ml-0"
                  v-model="forms.forward_source_caller_id.caller_id" 
                  :options="getVoipNumbers"  
                  valueField="did" 
                  textField="did" 
                  @change="updateForwardSourceCallerID()"
                >
                  <template #selected-option="{ option }">
                    <span v-if="option">{{ option.did | number_formater }}</span>
                    <span v-else>Select Number</span>
                  </template>
                  <template #option="{ option }">
                    {{ option.did | number_formater }}
                  </template>
                </vb-select> 
              </span>
              caller's ID when forwarded.
            </span>
            <p class="error-red" v-if="$v.forms.forward_source_caller_id.caller_id.$invalid">
              <span v-if="!$v.forms.forward_source_caller_id.caller_id.required">caller id is required</span>
            </p>
            <span v-else>Incoming calls will not display the original caller's ID when forwarded.</span>
          </div>
        </div>
        <div 
          id="ForwardCLIDButtonLock" 
          v-b-tooltip :title="getUserPermissions.forward_source_caller_id?'':'Your package does not support this feature'"
          class="w-fit-content ml-4" 
          :class="{
            'for-disable': !getUserPermissions.forward_source_caller_id
          }"
        >
          <template v-if="api.user_detail.send">
            <div class="latestShimmerDesign" style="height:34px;width:60px;border-radius: 25px"></div>
          </template>
          <b-form-checkbox
            v-else
            ref="forward_source_caller_id"
            class="newerSwitch"
            :disabled="!getUserPermissions.forward_source_caller_id || api.update_forward_source_caller_id.send || api.user_detail.send"
            v-model="forms.forward_source_caller_id.enabled"
            name="check-button"
            switch
            @change="updateForwardSourceCallerID()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
import { mapGetters } from 'vuex'
import _ from 'lodash'
import requiredIf from 'vuelidate/lib/validators/requiredIf';
export default {
  name: 'ForwardSourceCallerID',
  props: {
    accountcode: {
      type: [String,Number],
      default: '',
      require: true,
    },
  },
  data() {
    return {
      api: {
        user_detail: this.$helperFunction.apiInstance(),
        update_forward_source_caller_id: this.$helperFunction.apiInstance(),
      },
      forms: {
        forward_source_caller_id: this.$helperFunction.formInstance({
          data: {
            enabled: false,
            caller_id: "",
          },
        }),
      },
    }
  },
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getVoipNumbers',
    ]),
  },
  validations: {
    forms: {
      forward_source_caller_id: {
        caller_id: {
          required: requiredIf(function(){
            return this.forms.forward_source_caller_id.enabled
          })
        }
      },
    },
  },
  methods: {
    async fetchUserDetail() {
      if (this.api.user_detail.send) return;
      try {
        this.api.user_detail.send = true;
        const { data: detail } = await VOIP_API.endpoints.users.getForwardSourceCallerId(this.accountcode)
        this.forms.forward_source_caller_id.enabled=detail.enable_forward_callerid_remote=='0'
        this.forms.forward_source_caller_id.caller_id=detail.forcecallerid ?? ''
      } finally {
        this.api.user_detail.send = false;
      }
    },
    updateForwardSourceCallerID: _.debounce(async function(){
      this.$v.forms.forward_source_caller_id.$touch();
      if(this.api.update_forward_source_caller_id.send || this.$v.forms.forward_source_caller_id.$invalid) return;
      try {
        await VOIP_API.endpoints.users.updateuserdetail({
          field: `enable_forward_callerid_remote`,
          value: this.forms.forward_source_caller_id.enabled ? '0' : '1',
          caller_id: this.forms.forward_source_caller_id.caller_id,
          accountcode: this.accountcode,
        })
        this.appNotify({
          message: "Successfully Updated!",
          type: "success",
        })
        this.fetchUserDetail()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.update_forward_source_caller_id.send=false;
      }
    }, 1*1000),
  },
  mounted () {
    this.fetchUserDetail()
  },
}
</script>

<style>

</style>