var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "callActivity-Item-v2-container",
    class: [_vm.call.direction == 'outbound' ? 'Right' : 'Left', {
      'missedCall': _vm.call.status == 'Missed',
      'recievedCall': _vm.call.status == 'Received',
      'dialledCall': _vm.call.status == 'Dialed'
    }]
  }, [_c('div', {
    staticClass: "callActivity-Item-v2-inner-top"
  }, [_c('div', {
    staticClass: "icon-container"
  }, [_vm.call.status == 'Missed' ? _c('vb-icon', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.top",
      value: 'Missed Audio Call',
      expression: "'Missed Audio Call'",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    attrs: {
      "icon": "CallActivityV2-missedCall-icon",
      "height": "55.922",
      "width": "55.922"
    }
  }) : _vm.call.status == 'Received' ? _c('vb-icon', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.top",
      value: 'Received Audio Call',
      expression: "'Received Audio Call'",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    attrs: {
      "icon": "CallActivityV2-receivedCall-icon",
      "height": "55.922",
      "width": "55.922"
    }
  }) : _c('vb-icon', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.top",
      value: 'Dialed Audio Call',
      expression: "'Dialed Audio Call'",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    attrs: {
      "icon": "CallActivityV2-dialledCall-icon",
      "height": "55.922",
      "width": "55.922"
    }
  })], 1), _c('div', {
    staticClass: "user-info"
  }, [_vm.call.direction == 'outbound' ? [_vm.call.dst_type == 'Number' ? [_c('div', {
    staticClass: "user-name"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.call.dst_number)))])] : [_c('div', {
    staticClass: "user-name"
  }, [_vm._v(_vm._s(_vm.getVoipUsersAlisesDisplayName[_vm.call.dst_accountcode]))]), _c('div', {
    staticClass: "user-number"
  }, [_vm._v(_vm._s(_vm.call.dst_extn))])]] : [_vm.call.src_type == 'Number' ? [_c('div', {
    staticClass: "user-name"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.call.src_number)))])] : [_c('div', {
    staticClass: "user-name"
  }, [_vm._v(_vm._s(_vm.getVoipUsersAlisesDisplayName[_vm.call.src_accountcode]))]), _c('div', {
    staticClass: "user-number"
  }, [_vm._v(_vm._s(_vm.call.src_extn))])]]], 2), _c('div', {
    staticClass: "middle-section"
  }, [_c('span', {
    staticClass: "middle-section-text"
  }, [_vm._v(" " + _vm._s(_vm.call.state == 'Ringing' ? 'Ringing' : '') + " ")])]), _c('div', {
    staticClass: "callLogIcons-container"
  }, [_vm.call.session ? _c('div', [_c('b-button', {
    attrs: {
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        return _vm.call.session.hangup();
      }
    }
  }, [_c('b-icon-telephone-fill', {
    attrs: {
      "variant": "danger"
    }
  })], 1)], 1) : _vm._e()]), _c('div', {
    staticClass: "last-section"
  }, [_c('div', {
    staticClass: "time"
  }, [_c('span', [_vm._v(_vm._s(_vm._f("filter_date_current")(parseInt(_vm.call.date), null, {
    unix: true,
    formate: 'h:mm a'
  })))])]), _c('div', {
    staticClass: "duration"
  }, [_vm._v(_vm._s(_vm.call.bill_sec))])])]), _c('div', {
    staticClass: "callActivity-Item-v2-inner-2nd"
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }