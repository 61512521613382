var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "departmentContainer",
    staticClass: "departmentContainer"
  }, [!_vm.$store.getters.getIsMobile ? _c('div', {
    staticClass: "dialer-tabs"
  }, [_c('div', {
    staticClass: "dialer-tab-headers"
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('div', {
      key: tab.value,
      class: ['dialer-tab text-capitalize', {
        selected: _vm.selected.tab == tab.value
      }],
      on: {
        "click": function ($event) {
          _vm.selected.tab = tab.value;
        }
      }
    }, [_vm._v(" " + _vm._s(tab.text) + " ")]);
  }), 0)]) : _vm._e(), _vm.selected.tab == _vm.tabs.teams.value ? [!_vm.$store.getters.getIsMobile ? _c('b-form', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mb-3",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('b-input', {
    attrs: {
      "placeholder": "Search here"
    },
    model: {
      value: _vm.filter.teams.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.teams, "search", $$v);
      },
      expression: "filter.teams.search"
    }
  })], 1) : _c('b-form', {
    staticClass: "updatingContactsAgain-contacts-searchBar-container",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "updatingContactsAgain-contacts-searchBar"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "mobile-searchBar-icon-likeAirCall-icon",
      "width": "12.68px",
      "height": "12.67px"
    }
  }), _c('b-input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "placeholder": "Search"
    },
    model: {
      value: _vm.filter.teams.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.teams, "search", $$v);
      },
      expression: "filter.teams.search"
    }
  })], 1)]), _c('div', {
    staticClass: "tab-pane active",
    attrs: {
      "id": "departments_tab"
    }
  }, [_c('div', {
    staticClass: "dialer_contact_list departments_list allow_scroll"
  }, [_vm._l(_vm.filterTeams, function (team) {
    return _c('div', {
      key: team.id,
      staticClass: "dialer_contact_department_box"
    }, [!_vm.$store.getters.getIsMobile ? [_c('div', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: 'accordion-' + team.id,
        expression: "'accordion-' + team.id"
      }],
      staticClass: "dialer_contact_department_box_header justify-content-between show-on-hover",
      attrs: {
        "block": "",
        "variant": "info"
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center",
      staticStyle: {
        "width": "inherit"
      }
    }, [_c('info', {
      attrs: {
        "id": team.real_id
      }
    })], 1), _c('div', {
      staticClass: "dialer_contact_hidden_area hidden-for-scroll mr-4"
    }, [_c('div', {
      staticClass: "dialer_contact_connection_box"
    }, [_c('span', {
      staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute"
    }, [_c('b-dropdown', {
      staticClass: "ico-filter",
      attrs: {
        "varient": "link",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_c('b-icon', {
            attrs: {
              "icon": "three-dots",
              "scale": "1.5"
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.dialTeam(team);
        }
      }
    }, [_c('vb-svg', {
      attrs: {
        "name": "dialer-outlineCall-icon2",
        "width": "16.501",
        "height": "16.501",
        "viewBox": "0 0 16.501 16.501",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Call")])], 1), team.members.findIndex(function (mem) {
      return mem.accountcode == _vm.getCurrentUser.account;
    }) > -1 ? _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.chatTeam(team);
        }
      }
    }, [_c('vb-svg', {
      attrs: {
        "name": "dialer-outlineChat-icon",
        "width": "16.501",
        "height": "16.501",
        "viewBox": "0 0 16.501 16.501",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Chat")])], 1) : _vm._e(), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.toggleFavourite(team);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": team.is_favourite ? 'star-fill' : 'star'
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v(_vm._s(team.is_favourite ? 'Remove from Favourite' : 'Add To Favourite'))])], 1)], 1)], 1)])]), team.is_favourite ? _c('span', {
      staticClass: "mr-4 inTeamsFavouriteIcon"
    }, [_c('b-icon', {
      attrs: {
        "icon": "star-fill",
        "variant": "primary"
      }
    })], 1) : _vm._e(), _c('vb-svg', {
      staticClass: "dialer-downArrow",
      attrs: {
        "name": "dialer-downArrow-icon",
        "width": "14",
        "height": "8.001",
        "viewBox": "0 0 14 8.001",
        "stroke-width": "0",
        "stroke": "",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    })], 1), _c('b-collapse', {
      staticClass: "dialer_contact_department_box_hidden_area",
      attrs: {
        "id": 'accordion-' + team.id,
        "accordion": "my-accordion"
      }
    }, [!_vm.isEmpty(team.members) ? _c('div', {
      staticClass: "dialer_contact_row"
    }, _vm._l(team.members, function (member, key) {
      return _c('div', {
        key: key,
        staticClass: "dialer_contact"
      }, [_c('div', {
        staticClass: "d-flex align-items-center",
        staticStyle: {
          "width": "inherit"
        }
      }, [_c('info', {
        attrs: {
          "id": member.accountcode
        }
      })], 1), _c('div', {
        staticClass: "dialer_contact_hidden_area"
      }, [_c('div', {
        staticClass: "dialer_contact_connection_box"
      }, [_c('span', {
        staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute"
      }, [_vm.getCurrentUser.account != member.accountcode ? _c('b-dropdown', {
        staticClass: "ico-filter",
        attrs: {
          "varient": "link",
          "no-caret": ""
        },
        scopedSlots: _vm._u([{
          key: "button-content",
          fn: function () {
            return [_c('b-icon', {
              attrs: {
                "icon": "three-dots",
                "scale": "1.5"
              }
            })];
          },
          proxy: true
        }], null, true)
      }, [_c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.dial(member);
          }
        }
      }, [_c('vb-svg', {
        staticClass: "mr-2",
        attrs: {
          "name": "dialer-outlineCall-icon2",
          "width": "16.501",
          "height": "16.501",
          "viewBox": "0 0 16.501 16.501",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', [_vm._v("Call")])], 1), _c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.chatUser(member);
          }
        }
      }, [_c('vb-svg', {
        staticClass: "mr-2",
        attrs: {
          "name": "dialer-outlineChat-icon",
          "width": "16.501",
          "height": "16.501",
          "viewBox": "0 0 16.501 16.501",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', [_vm._v("Chat")])], 1), _c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.videoCall(member);
          }
        }
      }, [_c('vb-svg', {
        staticClass: "mr-2",
        attrs: {
          "name": "dialer-outlineVideo-icon",
          "width": "20.6",
          "height": "13.6",
          "viewBox": "0 0 20.6 13.6",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', [_vm._v("Video")])], 1)], 1) : _vm._e()], 1)])])]);
    }), 0) : _vm._e(), _vm.isEmpty(team.members) ? _c('vb-no-record', {
      attrs: {
        "text": 'No members in this team',
        "design": 3
      }
    }) : _vm._e()], 1)] : [_c('div', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: 'accordion-' + team.id,
        expression: "'accordion-' + team.id"
      }],
      staticClass: "updatingContactsAgain-contact-item"
    }, [_c('info', {
      attrs: {
        "id": team.real_id,
        "is_unread_show": false,
        "is_suspended_show": false,
        "is_blf": false
      }
    }), _c('div', {
      staticClass: "showOnlyOnHover"
    }, [_c('vb-svg', {
      staticClass: "dialer-downArrow mr-3",
      attrs: {
        "name": "dialer-downArrow-icon",
        "width": "16px",
        "height": "10px",
        "viewBox": "0 0 14 8.001",
        "stroke-width": "0",
        "stroke": "",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('b-dropdown', {
      staticClass: "ico-filter",
      attrs: {
        "varient": "link",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_c('vb-icon', {
            staticClass: "mobile-topBar-threeDots-likeAirCall-icon",
            attrs: {
              "icon": "mobile-topBar-threeDots-likeAirCall-icon",
              "height": "27.06px",
              "width": "6px"
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.dialTeam(team);
        }
      }
    }, [_c('vb-svg', {
      attrs: {
        "name": "dialer-outlineCall-icon2",
        "width": "16.501",
        "height": "16.501",
        "viewBox": "0 0 16.501 16.501",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Call")])], 1), team.members.findIndex(function (mem) {
      return mem.accountcode == _vm.getCurrentUser.account;
    }) > -1 ? _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.chatTeam(team);
        }
      }
    }, [_c('vb-svg', {
      attrs: {
        "name": "dialer-outlineChat-icon",
        "width": "16.501",
        "height": "16.501",
        "viewBox": "0 0 16.501 16.501",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Chat")])], 1) : _vm._e(), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.toggleFavourite(team);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "star-fill"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v(_vm._s(team.is_favourite ? 'Remove from Favourite' : 'Add To Favourite'))])], 1)], 1)], 1)], 1), _c('b-collapse', {
      attrs: {
        "id": 'accordion-' + team.id,
        "accordion": "my-accordion"
      }
    }, [!_vm.isEmpty(team.members) ? _c('div', {
      staticClass: "dialer_contact_row pr-0 py-0"
    }, _vm._l(team.members, function (member, key) {
      return _c('div', {
        key: key,
        staticClass: "updatingContactsAgain-contact-item"
      }, [_c('info', {
        attrs: {
          "id": member.accountcode,
          "is_unread_show": false,
          "is_suspended_show": false,
          "is_blf": false
        }
      }), _c('b-dropdown', {
        staticClass: "ico-filter",
        attrs: {
          "varient": "link",
          "no-caret": ""
        },
        scopedSlots: _vm._u([{
          key: "button-content",
          fn: function () {
            return [_c('vb-icon', {
              staticClass: "mobile-topBar-threeDots-likeAirCall-icon",
              attrs: {
                "icon": "mobile-topBar-threeDots-likeAirCall-icon",
                "height": "27.06px",
                "width": "6px"
              }
            })];
          },
          proxy: true
        }], null, true)
      }, [_c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.dial(member);
          }
        }
      }, [_c('vb-svg', {
        attrs: {
          "name": "dialer-outlineCall-icon2",
          "width": "16.501",
          "height": "16.501",
          "viewBox": "0 0 16.501 16.501",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', {
        staticClass: "ml-2"
      }, [_vm._v("Call")])], 1), _c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.chatUser(member);
          }
        }
      }, [_c('vb-svg', {
        attrs: {
          "name": "dialer-outlineChat-icon",
          "width": "16.501",
          "height": "16.501",
          "viewBox": "0 0 16.501 16.501",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', {
        staticClass: "ml-2"
      }, [_vm._v("Chat")])], 1), _c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.videoCall(member);
          }
        }
      }, [_c('vb-svg', {
        attrs: {
          "name": "dialer-outlineVideo-icon",
          "width": "20.6",
          "height": "13.6",
          "viewBox": "0 0 20.6 13.6",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', {
        staticClass: "ml-2"
      }, [_vm._v("Video")])], 1)], 1)], 1);
    }), 0) : _vm._e(), _vm.isEmpty(team.members) ? _c('vb-no-record', {
      attrs: {
        "text": 'No members in this team',
        "design": 3
      }
    }) : _vm._e()], 1)]], 2);
  }), _vm.isEmpty(_vm.filterTeams) ? _c('vb-no-record', {
    attrs: {
      "text": 'No teams available',
      "design": 3
    }
  }) : _vm._e()], 2)])] : _vm._e(), _vm.selected.tab == _vm.tabs.call_queue.value ? [_c('b-form', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mb-3",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('b-input', {
    attrs: {
      "placeholder": "Search here"
    },
    model: {
      value: _vm.filter.call_queue.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.call_queue, "search", $$v);
      },
      expression: "filter.call_queue.search"
    }
  })], 1), _c('div', {
    staticClass: "tab-pane active",
    attrs: {
      "id": "departments_tab"
    }
  }, [_c('div', {
    staticClass: "dialer_contact_list departments_list allow_scroll"
  }, [_vm._l(_vm.filterCallQueues, function (call_queue) {
    return _c('div', {
      key: call_queue.voipaccount,
      staticClass: "dialer_contact_department_box"
    }, [_c('div', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: 'accordion-' + call_queue.voipaccount,
        expression: "'accordion-' + call_queue.voipaccount"
      }],
      staticClass: "dialer_contact_department_box_header justify-content-between show-on-hover",
      attrs: {
        "block": "",
        "variant": "info"
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center",
      staticStyle: {
        "width": "inherit"
      }
    }, [_c('info', {
      attrs: {
        "id": call_queue.voipaccount
      }
    })], 1), _c('div', {
      staticClass: "dialer_contact_hidden_area hidden-for-scroll mr-4"
    }, [_c('div', {
      staticClass: "dialer_contact_connection_box"
    }, [_c('span', {
      staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute"
    }, [_c('b-dropdown', {
      staticClass: "ico-filter",
      attrs: {
        "varient": "link",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_c('b-icon', {
            attrs: {
              "icon": "three-dots",
              "scale": "1.5"
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.dialCallQueue(call_queue);
        }
      }
    }, [_c('vb-svg', {
      staticClass: "mr-2",
      attrs: {
        "name": "dialer-outlineCall-icon2",
        "width": "16.501",
        "height": "16.501",
        "viewBox": "0 0 16.501 16.501",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', [_vm._v("Call")])], 1), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.toggleCallQueueFavourite(call_queue);
        }
      }
    }, [_c('b-icon', {
      staticClass: "mr-2",
      attrs: {
        "icon": call_queue.is_favourite ? 'star-fill' : 'star'
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v(_vm._s(call_queue.is_favourite ? 'Remove from Favourite' : 'Add To Favourite'))])], 1)], 1)], 1)])]), call_queue.is_favourite ? _c('span', {
      staticClass: "mr-4 inTeamsFavouriteIcon"
    }, [_c('b-icon', {
      attrs: {
        "icon": "star-fill",
        "variant": "primary"
      }
    })], 1) : _vm._e(), _c('vb-svg', {
      staticClass: "dialer-downArrow",
      attrs: {
        "name": "dialer-downArrow-icon",
        "width": "14",
        "height": "8.001",
        "viewBox": "0 0 14 8.001",
        "stroke-width": "0",
        "stroke": "",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    })], 1), _c('b-collapse', {
      staticClass: "dialer_contact_department_box_hidden_area",
      attrs: {
        "id": 'accordion-' + call_queue.voipaccount,
        "accordion": "my-accordion"
      }
    }, [!_vm.isEmpty(call_queue.members) ? _c('div', {
      staticClass: "dialer_contact_row"
    }, _vm._l(call_queue.members, function (member) {
      return _c('div', {
        key: member.membername,
        staticClass: "dialer_contact"
      }, [_c('div', {
        staticClass: "d-flex align-items-center",
        staticStyle: {
          "width": "inherit"
        }
      }, [_c('info', {
        attrs: {
          "id": member.membername
        }
      })], 1), _c('div', {
        staticClass: "dialer_contact_hidden_area"
      }, [_c('div', {
        staticClass: "dialer_contact_connection_box"
      }, [_c('div', {
        staticClass: "d-flex mr-2"
      }, [_c('b-icon', {
        attrs: {
          "id": `${call_queue.voipaccount}-${member.membername}-tooltip-callQueue`,
          "icon": _vm._f("adminforce_icon")(_vm._f("get_property")(call_queue, `call_queue_status.${member.membername}.adminforce`, 'no')),
          "variant": "info"
        }
      }), call_queue.call_queue_status[member.membername].logged_in ? _c('b-popover', {
        attrs: {
          "target": `${call_queue.voipaccount}-${member.membername}-tooltip-callQueue`,
          "triggers": "hover",
          "placement": "top"
        }
      }, [_c('span', [_vm._v(_vm._s(_vm._f("get_property")(call_queue, `call_queue_status.${member.membername}.accountname`)))])]) : _vm._e()], 1), _c('span', {
        staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute"
      }, [_c('b-dropdown', {
        staticClass: "ico-filter",
        attrs: {
          "varient": "link",
          "no-caret": ""
        },
        scopedSlots: _vm._u([{
          key: "button-content",
          fn: function () {
            return [_c('b-icon', {
              attrs: {
                "icon": "three-dots",
                "scale": "1.5"
              }
            })];
          },
          proxy: true
        }], null, true)
      }, [_vm.getCurrentUser.account != member.membername ? _c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.dial(member);
          }
        }
      }, [_c('vb-svg', {
        staticClass: "mr-2",
        attrs: {
          "name": "dialer-outlineCall-icon2",
          "width": "16.501",
          "height": "16.501",
          "viewBox": "0 0 16.501 16.501",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', [_vm._v("Call")])], 1) : _vm._e(), _vm.getCurrentUser.account != member.membername ? _c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.chatUser(member);
          }
        }
      }, [_c('vb-svg', {
        staticClass: "mr-2",
        attrs: {
          "name": "dialer-outlineChat-icon",
          "width": "16.501",
          "height": "16.501",
          "viewBox": "0 0 16.501 16.501",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', [_vm._v("Chat")])], 1) : _vm._e(), _vm.getCurrentUser.account != member.membername ? _c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.videoCall(member);
          }
        }
      }, [_c('vb-svg', {
        staticClass: "mr-2",
        attrs: {
          "name": "dialer-outlineVideo-icon",
          "width": "20.6",
          "height": "13.6",
          "viewBox": "0 0 20.6 13.6",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', [_vm._v("Video")])], 1) : _vm._e(), (_vm.getCurrentUser.administrator_account || member.membername == _vm.getCurrentUser.account) && call_queue.call_queue_status && call_queue.call_queue_status[member.membername] ? [call_queue.call_queue_status[member.membername].adminforce == 'no' ? [call_queue.call_queue_status[member.membername].logged_in ? _c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            $event.stopPropagation();
            return _vm.callQueueToggleLogin(call_queue.voipaccount, call_queue.call_queue_status[member.membername].accountcode);
          }
        }
      }, [_c('b-icon', {
        staticClass: "mr-2",
        attrs: {
          "icon": "box-arrow-in-left"
        }
      }), _c('span', [_vm._v("Logout")])], 1) : _c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            $event.stopPropagation();
            return _vm.$modal.show(`${_vm._uid}-SelectExtension`, {
              accountcode: member.membername,
              selected: call_queue.call_queue_status[member.membername].accountcode,
              extra_data: {
                voipaccount: call_queue.voipaccount
              }
            });
          }
        }
      }, [_c('b-icon', {
        staticClass: "mr-2",
        attrs: {
          "icon": "box-arrow-in-left"
        }
      }), _c('span', [_vm._v("Login")])], 1)] : _vm._e(), call_queue.call_queue_status[member.membername].logged_in ? _c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            $event.stopPropagation();
            return _vm.callQueueTogglePaused(call_queue.voipaccount, call_queue.call_queue_status[member.membername].accountcode);
          }
        }
      }, [_c('b-icon', {
        staticClass: "mr-2",
        attrs: {
          "icon": "pause-fill"
        }
      }), _c('span', [_vm._v(_vm._s(_vm._f("is_paused")(_vm._f("get_property")(call_queue, `call_queue_status.${member.membername}.is_paused`, false))))])], 1) : _vm._e()] : _vm._e()], 2)], 1)])])]);
    }), 0) : _vm._e(), _vm.isEmpty(call_queue.members) ? _c('vb-no-record', {
      attrs: {
        "text": 'No members in this Group',
        "design": 3
      }
    }) : _vm._e()], 1)], 1);
  }), _vm.isEmpty(_vm.filterCallQueues) ? _c('vb-no-record', {
    attrs: {
      "text": 'No Call Queue available',
      "design": 3
    }
  }) : _vm._e()], 2)])] : _vm._e(), _c('DirectChatModal'), _c('TeamChatModal'), _c('SelectUserExtensionModal', {
    attrs: {
      "modalName": `${_vm._uid}-SelectExtension`
    },
    on: {
      "select": function ($event) {
        return _vm.callQueueToggleLogin($event.extra_data.voipaccount, $event.extension_accountcode);
      }
    }
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }