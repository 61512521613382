import { AMI_LOGGER } from '@/utils/console';
import { io } from "socket.io-client";
import { VOIP_API } from '@/utils/index';
import { TokenService } from '@/services/storage.service';
import _ from 'lodash';

const Emitter = require('component-emitter');
export const AMI_SOCKET = {
  socket: null,
  connected: false,
  connecting: false,
  getTokenAndUrl: _.debounce(async function(){
    let token='', url=''
    const current_user = TokenService.USER.get()
    if(process.env.VUE_APP_AMI_LOCALLY) {
      const { data: { token: ami_token } } = await VOIP_API.endpoints.ami.generateToken({
        extension_accountcode: current_user.sip.user, 
        user_accountcode: current_user.account, 
        default_accountcode: current_user.default_accountcode, 
        uid: current_user.uid, 
        is_admin: current_user.administrator_account ? '1' : '0',
      })
      token=ami_token
      url=`http://ami.server:5022`
    } else {
      const { data: { token: ami_token, url: ami_url } } = await VOIP_API.endpoints.ami.authenticate({
        extension_account: current_user.sip.user, 
      })
      token=ami_token
      url=ami_url
    }
    return {
      token: token,
      url: url
    }
  },3*1000,{
    leading: true,
    trailing: false,
  }),
  init(){
    try {
      AMI_LOGGER.log('Init')
      Emitter(this)
      window.ami_socket = this
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return this
    }
  },
  async connect(){
    if(this.connecting) return;
    try {
      this.connecting=true
      AMI_LOGGER.log('Connect')
      const { token, url } = await this.getTokenAndUrl()
      this.disconnect()
      this.socket = io(url,{
        autoConnect: false,
        closeOnBeforeunload: true,
        auth: {
          token: token
        }
      });
      this.socket.connect()
      this.socket.on('connect',this.onConnect.bind(this))
      this.socket.on('disconnect',this.onDisconnect.bind(this))
      this.socket.on("connect_error", this.onConnectError.bind(this));
      this.socket.onAny(this.onAnyEvent.bind(this));
    } catch (ex) {
      AMI_LOGGER.danger('Connect',ex)
    } finally {
      this.connecting=false
    }
  },
  disconnect(){
    this.socket?.disconnect?.()
  },
  onConnect(){
    console.log('ami check onConnect')
    AMI_LOGGER.connected()
    this.emit('connect')
    this.connected=true
  },
  async onConnectError(err){
    console.log('ami check onConnectError',err,this.socket?.active)
    AMI_LOGGER.danger('onConnectError',err,this.socket?.active)
    if(!this.socket?.active) {
      if (err.message=="un authorized") {
        const { token } = await this.getTokenAndUrl()
        this.socket.auth.token = token;
      }
      this.socket.connect();
    }
  },
  onDisconnect(reason){
    console.log('ami check onDisconnect',reason,this.socket?.active)
    AMI_LOGGER.disconnected(reason,this.socket?.active)
    this.emit('disconnect')
    if (!this.socket?.active) {
      if (reason=='io client disconnect') {
        this.socket=null
      }
    }
    this.connected=false
  },
  onAnyEvent(event,payload){
    AMI_LOGGER.event(event,payload)
    this.emit(event,payload)
  },
  transferCall(message){
    AMI_LOGGER.log('transfer-call',message)
    this.socket?.emit?.('transfer-call',message)
  },
  idleState(message){
    AMI_LOGGER.log('idle-state',message)
    this.socket?.emit?.('idle-state',message)
  },
}