var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup addExtension-voicemailModal addRemove_userModal m-w-700 bigSquareCheckbox min-width-50percent-modal",
    attrs: {
      "name": _vm.modalName,
      "scrollable": true,
      "height": "auto"
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('section', [_c('div', {
    staticClass: "dialer-edit-header d-block"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v(" Forward call")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v("Choose where unanswered calls should be forwarded.")])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox withOutPadding"
  }, [_c('b-form-group', {
    staticStyle: {
      "position": "relative",
      "pointer-events": "auto"
    }
  }, [_c('div', {
    staticClass: "w-100"
  }, [_vm.condition.is_ringing_show ? [_c('b-form-radio', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row withHover forHolidayFix checkBoxOrRadioInside position-relative makeCheckBoxesSquare",
    class: _vm.forms.forwarding_setting.forward_note === 'off' ? 'active' : '',
    attrs: {
      "name": "some-radios",
      "value": "off"
    },
    on: {
      "change": function ($event) {
        _vm.forms.forwarding_setting.forward_note = 'off';
        _vm.updateForwardingSetting();
      }
    },
    model: {
      value: _vm.forms.forwarding_setting.forward_note,
      callback: function ($$v) {
        _vm.$set(_vm.forms.forwarding_setting, "forward_note", $$v);
      },
      expression: "forms.forwarding_setting.forward_note"
    }
  }, [_c('div', {
    staticClass: "w-100 dialer-call-option-c withRadio",
    class: {
      'active': _vm.forms.forwarding_setting.forward_note === 'off'
    }
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v(_vm._s(_vm.callQueue ? 'Hangup' : 'Keep ringing'))]), _vm.forms.forwarding_setting.forward_note === 'off' ? _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Call forwarding is off")]) : _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Call forwarding is off")])])])] : _vm._e(), _vm.condition.is_voicemail_show ? _c('b-form-radio', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row withHover checkBoxOrRadioInside forHolidayFix position-relative makeCheckBoxesSquare",
    class: _vm.forms.forwarding_setting.forward_note === 'voicemail' ? 'active' : '',
    attrs: {
      "name": "some-radios",
      "value": "voicemail"
    },
    on: {
      "change": function ($event) {
        _vm.forms.forwarding_setting.forward_note = 'voicemail';
        _vm.updateForwardingSetting();
      }
    },
    model: {
      value: _vm.forms.forwarding_setting.forward_note,
      callback: function ($$v) {
        _vm.$set(_vm.forms.forwarding_setting, "forward_note", $$v);
      },
      expression: "forms.forwarding_setting.forward_note"
    }
  }, [_c('div', {
    staticClass: "withRadio",
    class: {
      'active': _vm.forms.forwarding_setting.forward_note === 'voicemail'
    }
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Ask to leave a voicemail")]), _vm.forms.forwarding_setting.forward_note === 'voicemail' ? _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm.condition.is_sec_show ? [_vm._v(" If phone rings for "), _c('span', {
    staticClass: "color-primary text-underline",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CallForwardingDurationPickerModal', {
          value: _vm.forms.forwarding_setting.timeout
        });
      }
    }
  }, [_vm._v(_vm._s(_vm.forms.forwarding_setting.timeout) + " seconds, ")])] : _vm._e(), _vm._v(" call will be forwarded to a voicemail of your choice. ")], 2) : _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm.condition.is_sec_show ? [_vm._v(" If phone rings for "), _c('span', [_vm._v("20 seconds, ")])] : _vm._e(), _vm._v(" call will be forwarded to a voicemail of your choice. ")], 2)])]) : _vm._e(), _c('b-form-radio', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row forHolidayFix withHover checkBoxOrRadioInside position-relative makeCheckBoxesSquare",
    class: _vm.forms.forwarding_setting.forward_note === 'extension' ? 'active' : '',
    attrs: {
      "name": "some-radios",
      "value": "extension"
    },
    on: {
      "change": function ($event) {
        _vm.forms.forwarding_setting.forward_note = 'extension';
        _vm.updateForwardingSetting();
      }
    },
    model: {
      value: _vm.forms.forwarding_setting.forward_note,
      callback: function ($$v) {
        _vm.$set(_vm.forms.forwarding_setting, "forward_note", $$v);
      },
      expression: "forms.forwarding_setting.forward_note"
    }
  }, [_c('div', {
    staticClass: "withRadio w-100 dialer-call-option-c",
    class: {
      'active': _vm.forms.forwarding_setting.forward_note === 'extension'
    }
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Forward to a user, team or IVR menu")]), _vm.forms.forwarding_setting.forward_note === 'extension' ? _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm.condition.is_sec_show ? [_vm._v(" If phone rings for "), _c('span', {
    staticClass: "color-primary text-underline",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CallForwardingDurationPickerModal', {
          value: _vm.forms.forwarding_setting.timeout
        });
      }
    }
  }, [_vm._v(_vm._s(_vm.forms.forwarding_setting.timeout) + " seconds, ")])] : _vm._e(), _vm._v(" call will be forwarded to "), _vm.forms.forwarding_setting.forward_number ? _c('span', {
    staticClass: "color-primary text-underline",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CallForwardingSelectExtensionModal', {
          account: _vm.forms.forwarding_setting.forward_number,
          type: 'setting'
        });
      }
    }
  }, [_vm.forms.forwarding_setting.extension_name ? [_vm._v(_vm._s(_vm.forms.forwarding_setting.extension_name))] : _c('Info', {
    attrs: {
      "only_name": true,
      "id": _vm.forms.forwarding_setting.forward_number
    }
  })], 2) : _c('span', {
    staticClass: "color-primary text-underline cursor_pointer",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CallForwardingSelectExtensionModal', {
          account: _vm.forms.forwarding_setting.forward_number,
          type: 'setting'
        });
      }
    }
  }, [_vm._v(" select user ")])], 2) : _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm.condition.is_sec_show ? [_vm._v(" If phone rings for "), _c('span', [_vm._v("20 seconds, ")])] : _vm._e(), _vm._v(" call will be forwarded to "), _c('span', [_vm._v("a extension")]), _vm._v(" of your choice. ")], 2), _vm.forms.forwarding_setting.forward_note === 'extension' && _vm.forms.forwarding_setting.submitted && _vm.$v.forms.forwarding_setting.extension_name.$invalid ? _c('p', {
    staticClass: "error-red"
  }, [!_vm.$v.forms.forwarding_setting.extension_name.required ? _c('span', [_vm._v("* extension is required")]) : _vm._e()]) : _vm._e()])]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: !_vm.getUserPermissions.advanced_call_handling ? 'for-disable' : '',
    attrs: {
      "title": _vm.getUserPermissions.redirecting_to_another_number ? '' : 'Your package does not support this feature'
    }
  }, [_c('b-form-radio', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row withHover checkBoxOrRadioInside forHolidayFix position-relative makeCheckBoxesSquare mb-0",
    class: _vm.forms.forwarding_setting.forward_note === 'number' ? 'active' : '',
    attrs: {
      "disabled": !_vm.getUserPermissions.redirecting_to_another_number,
      "name": "some-radios",
      "value": "number"
    },
    on: {
      "change": function ($event) {
        _vm.forms.forwarding_setting.forward_note = 'number';
        _vm.updateForwardingSetting();
      }
    },
    model: {
      value: _vm.forms.forwarding_setting.forward_note,
      callback: function ($$v) {
        _vm.$set(_vm.forms.forwarding_setting, "forward_note", $$v);
      },
      expression: "forms.forwarding_setting.forward_note"
    }
  }, [_c('div', {
    staticClass: "withRadio w-100 dialer-call-option-c",
    class: {
      'active': _vm.forms.forwarding_setting.forward_note === 'number'
    }
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Forward to another number")]), _vm.forms.forwarding_setting.forward_note === 'number' ? _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm.condition.is_sec_show ? [_vm._v(" If phone rings for "), _c('span', {
    staticClass: "color-primary text-underline",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CallForwardingDurationPickerModal', {
          value: _vm.forms.forwarding_setting.timeout
        });
      }
    }
  }, [_vm._v(_vm._s(_vm.forms.forwarding_setting.timeout) + " seconds, ")])] : _vm._e(), _vm._v(" call will be forwarded to "), _c('span', {
    staticClass: "color-primary text-underline",
    class: {
      'text-underline': !_vm.forms.forwarding_setting.forward_number
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CallForwardingCallForwardingNumberInputModal', {
          number: _vm.forms.forwarding_setting.forward_number
        });
      }
    }
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.forms.forwarding_setting.forward_number || 'Add number')))])], 2) : _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm.condition.is_sec_show ? [_vm._v(" If phone rings for "), _c('span', [_vm._v("20 seconds, ")])] : _vm._e(), _vm._v(" call will be forwarded to a number of your choice ")], 2), _vm.forms.forwarding_setting.forward_note === 'number' && _vm.forms.forwarding_setting.submitted && _vm.$v.forms.forwarding_setting.forward_number.$invalid ? _c('p', {
    staticClass: "error-red"
  }, [!_vm.$v.forms.forwarding_setting.forward_number.required ? _c('span', [_vm._v("* number is required")]) : _vm._e()]) : _vm._e()])])], 1)], 2)])], 1), _vm.forms.forwarding_setting.forward_note == 'number' && _vm.data.accountcode ? _c('ForwardSourceCallerID', {
    attrs: {
      "accountcode": _vm.data.accountcode
    }
  }) : _vm._e()], 1), _c('ScheduleAssignModal', {
    attrs: {
      "modalName": `CallForwardingSelectExtensionModal`,
      "account": _vm.data.account
    },
    on: {
      "interface": function ($event) {
        _vm.forms.forwarding_setting.extension_name = $event.accountname;
        _vm.forms.forwarding_setting.forward_number = $event.account;
        _vm.updateForwardingSetting();
      }
    }
  }), _c('DurationPickerModal', {
    attrs: {
      "modalName": `CallForwardingDurationPickerModal`
    },
    on: {
      "time": function ($event) {
        _vm.forms.forwarding_setting.timeout = $event.seconds;
        _vm.updateForwardingSetting();
      }
    }
  }), _c('CallForwardingNumberInputModal', {
    attrs: {
      "modalName": `CallForwardingCallForwardingNumberInputModal`
    },
    on: {
      "number": function ($event) {
        _vm.forms.forwarding_setting.forward_number = $event.ber;
        _vm.updateForwardingSetting();
      }
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }