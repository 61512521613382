<template>
  <div>
    <section v-if="ivr" class="ct-user-new dialer-edit">
      <div v-if="isModal" class="dialer-edit-header">
        <div class="d-flex align-items-center">
          <div class="editModal-imageInsideModalTitle">
            <img :src="ivr | get_property('avatar.image.image')" @error="$event.target.src=local_filess.images.user_male"/>
          </div>
          <h2 class="dialer-settings-title newer">Edit IVR</h2> 
        </div>
        <div class="dialer-edit-actions">
          <button class="newDoneButton" type="button" :disabled="api.delete_ivr.send" @click="$emit('close-modal')">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Done</span>
          </button>
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023">
        <div class="newIVRLayout-forBorderBottom d-flex align-items-center justify-content-between">
          <div class="whiteBGinputWithGreyRoundedBorder withLeftIconOnly w-50">
            <label>IVR name</label>
            <div class="inputContainer-inside-whiteBGinputWithGreyRoundedBorder d-flex">
              <img :src="ivr | get_property('avatar.image.image')" @error="$event.target.src=local_filess.images.user_male" v-b-popover.hover.top="ivr.extn"  />
              <input class="w-100 bg-white" :disabled="api.delete_ivr.send || api.update_label.send" :maxlength="$v.forms.update_label.label.$params.maxLength.max" type="text" v-model="forms.update_label.label" @input="updateLabel()" />
              <div v-if="!isModal">
                <vb-icon @click="$emit('click-graph')" icon="graph-icon" class="graphIcon position-relative cursor_pointer_no_hover" height="40.501" width="41.374" />
              </div>
            </div>
            <p v-if="$v.forms.update_label.label.$error" class="text text-danger animated bounceIntop">
              <span v-if="!$v.forms.update_label.label.required">IVR name is required</span>
              <span v-else-if="!$v.forms.update_label.label.minLength">IVR name should be minimum {{ $v.forms.update_label.label.$params.minLength.min }} character</span>
              <span v-else-if="!$v.forms.update_label.label.maxLength">IVR name can be maximum {{ $v.forms.update_label.label.$params.maxLength.max }} character</span>
              <span v-else-if="!$v.forms.update_label.label.valid">IVR name can be number and alphabet</span>
            </p>
          </div>
          <div class="profileImg-section-latest-container">
            <div class="profileImg-section-latest" @click="$modal.show('ProfileImageUploader', { type: 'IVR', accountcode: ivr.voipaccount, current_image: getProperty(ivr,'avatar.image.image') })">
              <img :src="ivr | get_property('avatar.image.image')" @error="$event.target.src=local_filess.images.user_male" />
            </div>
          </div>
        </div>
        <!-- IVR Numbers Section -->
        <div class="d-flex align-items-center justify-content-between w-100 newIVRLayout-forBorderBottom mt-20px">
          <div>
            <div class="latestGreyBox-heading-main">Telephone number</div>
            <div class="latestGreyBox-descriptionText" v-if="ivr.userDid.length==0"> No telephone number defined. </div>
            <div class="d-flex mt-12px IVR-numbers-container flex-wrap" v-else>
              <div v-for="(did, index) in ivr.userDid.filter((item,index)=>index<3)" :key="index" class="newIVRLayout-number-item">
                <img class="country-img" width="45px" :src="did.did | number_formater | flag_icon" />
                <span class="newIVRLayout-number-item-text">{{ did.did | number_formater }}</span>
              </div>
              <div v-if="ivr.userDid.length > 3" class="newIVRLayout-number-item">+ {{ivr.userDid.length - 3}}</div>
            </div>
          </div>
          <button
            class="newButton sm-mar-left"
            @click="$modal.show(`${_uid}-AssignNumberModal`, { accountcode: ivr.voipaccount, })"
          >
            <vb-icon icon="squared-change-icon" height="38px" width="38px" />
            <span class="newButton-textPart">Change</span>
          </button>
        </div>
        <!-- Welcom Greetings Section -->
        <div class="w-100 newIVRLayout-forBorderBottom mt-20px">
          <WelcomeGreeting 
            v-if="ivr.voipaccount"
            class="newIVRLayout-WelcomeGreetingsSection"
            @fileChange="$emit('welcome-audio-updated');fetchIvrsDebounce()"
            :accountcode="ivr.voipaccount"
            :menu="ivr"
          />
        </div>
        <!-- Menu options section -->
        <MenuOptionsWidget v-if="ivr.voipaccount" :menu="ivr" @updated="$emit('options-updated');fetchIvrsDebounce()" />
        <!-- advance options section -->
        <div class="mt-20px">
          <AdvanceOptions class="w-100" v-if="ivr.voipaccount" :accountcode="ivr.voipaccount" />
        </div>
        <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
          <div class="whiteBoxWithBorderInsideGreyBox-row somethingInside pt-0">
            <ScheduleSetting v-if="ivr.voipaccount" :account="ivr.voipaccount" @ofh-days-updated="$emit('ofh-days-updated')" @ofh-toggle-updated="$emit('ofh-toggle-updated')" @ofh-default-updated="$emit('ofh-default-updated')" />
          </div>
        </div>
        <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
          <div class="whiteBoxWithBorderInsideGreyBox-row">
            <div class="latestGreyBox-heading-main">Delete this IVR?</div>
            <button class="newDeleteButton" type="button" :disabled="api.delete_ivr.send" @click="deleteIVR()">
              <vb-icon icon="squared-delete-icon" height="38px" width="38px" />
              <span class="newDeleteButton-textPart">Delete</span>
            </button>
          </div>
        </div>
      </div>
      <RecordingListSelect :is_single_select="true" @selectedRecordings="updateWrongIVRInput($event.data)" />
      <AssignNumberModal :modalName="`${_uid}-AssignNumberModal`" @added="$emit('assign-number-updated');fetchIvrsDebounce();" />
      <ProfileImageUploader @image-uploaded="$emit('profile-image-updated');fetchIvrsDebounce();" />
    </section>
  </div>
</template>

<script>
import { required, maxLength, minLength, helpers } from "vuelidate/lib/validators";
import _ from "lodash";
import ScheduleSetting from "../Common/ScheduleSetting.vue";
import { VOIP_API } from "../../utils";
import RecordingListSelect from "../Modals/RecordingListSelect.vue";
import ProfileImageUploader from "../Modals/ProfileImageUploader.vue";
import AssignNumberModal from "../Modals/AssignNumberModal.vue";
import AdvanceOptions from "../IVR/AdvanceOptions.vue";
import WelcomeGreeting from "../IVR/WelcomeGreeting.vue";
import { mapGetters } from 'vuex';
import MenuOptionsWidget from '../widgets/ivr/MenuOptionsWidget.vue';

export default {
  name: "EditMenu",
  components: {
    RecordingListSelect,
    ScheduleSetting,
    AdvanceOptions,
    WelcomeGreeting,
    MenuOptionsWidget,
    AssignNumberModal,
    ProfileImageUploader,
  },
  inject: [
    'local_filess',
    'getProperty',
    'appNotify',
    'getIvrs',
  ],
  props: {
    accountcode: {
      type: String,
      default: '',
    },
    isModal: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      forms: {
        update_label: this.$helperFunction.formInstance({
          data: {
            label: '',
          }
        }),
      },
      api: {
        update_label: this.$helperFunction.apiInstance({}),
        delete_ivr: this.$helperFunction.apiInstance({}),
      },
    };
  },
  computed: {
    ...mapGetters([
      'getVoipIVRS',
      'getCurrentUser',
    ]),
    ivr(){ return this.getVoipIVRS?.[this?.accountcode] ?? null },
  },
  validations: {
    forms: {
      update_label: {
        label: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(20),
          valid: helpers.regex('valid',/^[a-z0-9 ]*$/i),
        },
      },
    },
  },
  methods: {
    fetchIvrsDebounce: _.debounce(function(){
      this.getIvrs()
    }, 2*1000),
    updateLabel: _.debounce(async function(){
      this.$v.forms.update_label.$touch();
      if(this.api.update_label.send || this.$v.forms.update_label.$error) return;
      try {
        this.api.update_label.send=true
        await VOIP_API.endpoints.menu.labelUpdate(this.ivr.voipaccount,{
          label: this.forms.update_label.label
        })
        this.appNotify({
          message: "Successfully updated!",
          type: "success",
        })
        this.$emit('label-updated')
        this.fetchIvrsDebounce()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.update_label.send = false;
      }
    },2*1000),
    async deleteIVR() {
      if(this.api.delete_ivr.send) return;
      try {
        this.api.delete_ivr.send=true
        const { confirm } = await this.$appConfirmation({
          title: "Delete IVR?",
          message: `Are you sure you want to delete this IVR service from your subscription?`,
          variant: 'danger',
          button: {
            no: "Cancel",
            yes: "Delete IVR",
          },
          alert: {
            variant: 'danger',
            title: 'Warning',
            message: `After deletion, the IVR's configurations and functionalities will no longer be available`,
          },
        });
        if(!confirm) return;
        VOIP_API.endpoints.billing.unsubscribe({
          id: this.ivr.voipaccount,
          uid: this.getCurrentUser.uid,
          accountcode: this.ivr.voipaccount,
        })
        this.$emit('close-modal');
        this.$emit("delete-ivr");
        this.fetchIvrsDebounce()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger'
        })
      } finally {
        this.api.delete_ivr.send=false
      }
    },
  },
  mounted() {
    let vm = this
    this.$nextTick(()=>{
      vm.forms.update_label.label=vm.ivr.label
    })
  },
};
</script>


<style lang="scss" scoped>
.dialer-audio-playback {
  @include border-radius($border-radius);
  overflow: hidden;
  margin-top: 5px;
  display: flex;
  align-items: center;
  font-size: 12px;
  flex-grow: 1;
  .dialer-audio-playback-inner {
    width: 100%;
    background-color: $white;
    padding: $sidebarTop;
    audio {
      min-width: 220px;
      max-width: 250px;
      max-height: 30px;

      &::-webkit-media-controls-panel {
        background-color: $gray-300;
      }
    }
    .dialer-audio-message {
      width: 100%;
      svg {
        @include border-radius(0px);
        background-color: transparent;
        color: $body-color;
        margin-right: 8px;
      }
      .dialer-audio-progress {
        position: relative;
        margin: 0px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 24px);
        .progress {
          background-color: $progressBar_bg-color;
          width: calc(100% - 28px);
          .progress-bar {
            transition: width 0.1s ease;
          }
        }
        small {
          &:first-child {
            width: 39px;
            margin-right: 8px;
          }
          &:last-child {
            margin-left: 8px;
          }
        }
        input {
          &[type="range"] {
            width: calc(100% - 84px);
            background-color: transparent;
            -webkit-appearance: none;
            position: absolute;
            left: 42px;
            &::-webkit-slider-runnable-track {
              height: 4px;
              @include border-radius(50px);
              background: transparent;
              cursor: pointer;
            }
            &::-moz-range-track {
              height: 4px;
              @include border-radius(50px);
              background: transparent;
              cursor: pointer;
            }
            &::-ms-track {
              height: 4px;
              @include border-radius(50px);
              background: transparent;
              cursor: pointer;
            }
            &::-webkit-slider-thumb {
              width: 12px;
              height: 12px;
              margin-top: -5px;
              background: $dialer-progressBarThumb-color;
              border: 1px solid $dialer-progressBarThumb-color;
              @include border-radius(50%);
              -webkit-appearance: none;
              cursor: pointer;
            }
            &::-moz-range-thumb {
              width: 12px;
              height: 12px;
              margin-top: -5px;
              background: $dialer-progressBarThumb-color;
              border: 1px solid $dialer-progressBarThumb-color;
              @include border-radius(50%);
              cursor: pointer;
            }
            &::-ms-thumb {
              width: 12px;
              height: 12px;
              background: $dialer-progressBarThumb-color;
              border: 1px solid $dialer-progressBarThumb-color;
              @include border-radius(50%);
              cursor: pointer;
              margin-top: -5px;
            }
            &::-ms-fill-lower {
              background: $black;
              border: 0.2px solid $black;
              @include border-radius(2.6px);
            }
            &::-ms-fill-lower {
              background: $black;
              border: 0.2px solid $black;
              @include border-radius(2.6px);
            }
            &:focus {
              &::-ms-fill-lower {
                background: $black;
              }
              &::-ms-fill-upper {
                background: $black;
              }
            }
          }
        }
      }
    }
    .dialer-play-button {
      outline: none;
      border: none;
      background: none;
      padding: 2px 6px 3px;
    }

    .dialer-playback-time {
      margin: 0 5px;
      min-width: 25px;
      color: $secondary;
    }
  }
}
</style>
