<template>
  <div>
    <div class="latestGreyBox-9-9-2023">
      <ul class="phone-list full-width-call-info-number list-unstyled pr-0 pb-0 overlowX-visible-imp">
        <li class="p-0">
          <!-- call -->
          <CallActivityTestItem class="m-0 w-100" :call="call" :conditions="{ action: {enable: false,}, design:{ more: false, } }">
          </CallActivityTestItem>
        </li>
      </ul>
    </div>
    <div>
      <!-- tabs -->
      <div class="d-flex justify-content-between align-items-center w-100 mt-32px mb-32px">
        <div class="TabsDesignWithIcon-container">
          <div 
            class="TabsDesignWithIcon-item" 
            :class="{
              selected: selected.tab == tabs.history
            }" 
            @click="selected.tab = tabs.history"
          >
            <vb-icon icon="newbilling-tab-history-icon" height="38px" width="38px" />
            <span class="TabsDesignWithIcon-item-textPart">History</span>
          </div>
          <div 
            class="TabsDesignWithIcon-item" 
            :class="{
              selected: selected.tab == tabs.recordings
            }" 
            @click="selected.tab = tabs.recordings" 
          >
            <vb-icon icon="newbilling-tab-brain-icon" height="38px" width="38px" />
            <span class="TabsDesignWithIcon-item-textPart">AI transcript</span>
          </div>
        </div>
        <!-- history filter -->
        <slot v-if="selected.tab == tabs.history" name="history-filter"></slot>
      </div>
      <!-- tab content -->
      <ul 
        class="allow_scroll phone-list full-width-call-info-number dialor-callHistoryList list-unstyled pl-0 pr-2"
        style="overflow-y: unset;height: auto !important;"
      > 
        <!-- recording -->
        <template v-if="selected.tab == tabs.recordings">
          <div class="d-flex justify-content-between align-items-center my-3">
            <h2 class="mb-0">Message</h2>
          </div>
          <AudioTranscriptDummy 
            :soundFile="call.voicemail" 
            :uniqueID="call.id"
            :type="'voicemail'"
          >
            
          </AudioTranscriptDummy>
          <!-- <div class="d-flex justify-content-between align-items-center my-3">
            <h2 class="mb-0">Message</h2>
          </div>
          <div class="latestGreyBox-9-9-2023 h-100">
            <a class="d-flex align-items-start justify-content-between text-dark p-0 mb-0">
              <span class="info-area">
                <div class="call-date" style="justify-content:flex-end;display:flex;height:20px;">
                  <div class="dialpad_activity_box_other">
                    <span class="dialer-dropdown dialpad_activity_box_menu whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute w-100" >
                      <b-dropdown varient="link" no-caret class="ico-filter" right v-if="call.voicemail">
                        <template #button-content>
                          <b-icon icon="three-dots" scale="1" />
                        </template>
                        <b-dropdown-item class="mt-0" @click="download()">
                          <b-icon icon="download" class="mr-2" />
                          <span>Download</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </span>
                  </div>
                </div>
                <span class="small text-capitalize w-100-60 mt-8px">
                  <div class="dialer-audio-playback">
                    <vb-audio-player class="whiteBGaudioWithGreyRoundedBorder-again w-100 mr-16px fullWidth backgroundWhite" :design="'whiteBgBlackBorder'" :src="call.voicemail" />
                  </div>
                </span>
              </span>
            </a>
          </div> -->
        </template>
        <!-- history -->
        <slot v-if="selected.tab == tabs.history" name="history" ></slot>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CallActivityTestItem from '@/components/Home/CallActivity/TestItem/index.vue';
import AudioTranscriptDummy from '../../widgets/AudioTranscriptDummy.vue';
const tabs = {
  history: 'history',
  recordings: 'recordings',
}
export default {
  name: "VoicemailLog",
  components: {
    CallActivityTestItem,
    AudioTranscriptDummy,
  },
  inject:[
    
  ],
  props: {
    log: {
      type: Object,
      default: ()=>({}),
    },
    params: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selected: this.$helperFunction.stateInstance({
        data: {
          tab: tabs.history,
        },
      }),
      data: this.$helperFunction.stateInstance({
        data: {
          call_info: {},
        },
      }),
    };
  },
  computed: {
    ...mapGetters([
      
    ]),
    call(){ return this.data.call_info },
    tabs(){ return tabs },
  },
  methods: {
    async download() {
      try {
        const url = this.call.voicemail
        const response = await fetch(url)
        const blob = await response.blob()
        let blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.target = '_blank';
        link.download = 'download';
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (ex) {
        // TODO - 
      }
    },
  },
  mounted(){
    this.data.call_info = this.log
  },
};
</script>

<style>
</style>