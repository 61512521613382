var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-500 min-width-50percent-modal",
    attrs: {
      "width": "500px",
      "height": "auto",
      "scrollable": true,
      "name": "EditRecordingModal"
    },
    on: {
      "before-open": _vm.beforeOpen,
      "closed": _vm.onClosed
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.edit.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_c('vb-svg', {
    staticClass: "cursor_pointer_no_hover",
    attrs: {
      "name": "leftLongArrow-icon",
      "width": "20",
      "height": "12",
      "viewBox": "0 0 30 16",
      "stroke-width": "1",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('EditRecordingModal');
      }
    }
  }), _vm._v(" Edit sound file ")], 1)]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api.edit.send
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api.edit.send ? _c('vb-spinner') : [_vm._v("Done")]], 2)], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-50"
  }, [_c('label', [_vm._v("Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.edit_sound.name,
      expression: "forms.edit_sound.name"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "Enter name",
      "disabled": _vm.api.edit.send || _vm.api.delete.send,
      "min": "1",
      "type": "text"
    },
    domProps: {
      "value": _vm.forms.edit_sound.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.edit_sound, "name", $event.target.value);
      }
    }
  }), _vm.forms.edit_sound.submitted && _vm.$v.forms.edit_sound.name.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.edit_sound.name.required ? _c('span', [_vm._v("* name is required")]) : !_vm.$v.forms.edit_sound.name.alphaNum ? _c('span', [_vm._v("* Name can be only alphabet, number and space")]) : !_vm.$v.forms.edit_sound.name.minLength ? _c('span', [_vm._v("* Name should be minimum " + _vm._s(_vm.$v.forms.edit_sound.name.$params.minLength.min) + " character")]) : !_vm.$v.forms.edit_sound.name.maxLength ? _c('span', [_vm._v("* Name can be maximum " + _vm._s(_vm.$v.forms.edit_sound.name.$params.maxLength.max) + " character")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Set as shared")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("This sound file is " + _vm._s(_vm.forms.edit_sound_shared.shared ? 'shared' : 'unshared'))])]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "w-fit-content",
    attrs: {
      "title": _vm.getUserPermissions.share_sound_file ? '' : 'Your package does not support this feature'
    }
  }, [_c('b-form-checkbox', {
    ref: "shared-sound-file",
    staticClass: "newerSwitch",
    attrs: {
      "disabled": _vm.api.edit.send || _vm.api.delete.send || _vm.api.update_shared_status.send || !_vm.getUserPermissions.share_sound_file,
      "name": "check-button",
      "switch": "",
      "checked": _vm.forms.edit_sound_shared.shared
    },
    on: {
      "change": function ($event) {
        return _vm.updateSharedStatus($event);
      }
    }
  })], 1)])])]), _c('div', {
    staticClass: "mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Delete this sound file?")])]), _c('button', {
    staticClass: "dialer-button dialer-button-delete-alt",
    attrs: {
      "type": "button",
      "disabled": _vm.api.delete.send
    },
    on: {
      "click": function ($event) {
        return _vm.deleteRecording();
      }
    }
  }, [_vm.api.delete.send ? _c('vb-spinner') : _vm._e(), _vm._v(" Delete ")], 1)])])])])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }