var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.api.fetch_group.send && !_vm.response.group ? _c('div', {
    staticClass: "w-100"
  }, _vm._l(5, function (n) {
    return _c('div', {
      key: n,
      staticClass: "latestShimmerDesign flex-1 mb-3",
      staticStyle: {
        "height": "50px",
        "width": "100%"
      }
    });
  }), 0) : [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder-like w-100"
  }, [_vm.response.group ? _c('p', {
    staticClass: "m-0"
  }, [_c('span', [_vm._v(_vm._s(_vm.response.group.name))]), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("( " + _vm._s(_vm.response.group.timezone) + " )")])]) : _vm._e(), _c('b-button', {
    staticClass: "p-0",
    attrs: {
      "disabled": _vm.api.update_group_name.send || _vm.api.remove_group.send,
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-UpdateName`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "UnavailableTimes-pencilIcon-withUnderLine",
      "width": "18.79px",
      "height": "18.79px"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between mb-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main-24px darker"
  }, [_vm._v(" Show " + _vm._s(_vm._f("countryName")(_vm._f("get_property")(_vm.response.group, 'timezone'))) + " Public Holiday ")]), _c('b-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.condition.defaulted_holidays,
      callback: function ($$v) {
        _vm.$set(_vm.condition, "defaulted_holidays", $$v);
      },
      expression: "condition.defaulted_holidays"
    }
  })], 1), _c('button', {
    staticClass: "newButton ml-auto mb-20px",
    attrs: {
      "disabled": _vm.api.remove_group.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-CreatePublicHoliday`, {
          group_id: _vm.groupId,
          conditions: {
            disable_dates: _vm.disabledDates
          }
        });
      }
    }
  }, [_c('vb-icon', {
    staticClass: "squared-addHoliday-icon",
    attrs: {
      "icon": "squared-addHoliday-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Add Holiday")])], 1), _vm.condition.defaulted_holidays ? _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again showLastTDonHover mt-0",
    attrs: {
      "isListEmpty": _vm.response.defaulted_holidays.length == 0,
      "listLength": _vm.response.defaulted_holidays.length,
      "loading": _vm.api.fetch_default_holidays.send,
      "conditions": {
        pagination: {
          per_page: false
        }
      },
      "perPage": 100
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.response.defaulted_holidays, function (holiday, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: holiday.id
          }, [_c('td', {
            staticClass: "dialer-has-sort dialer-col-left"
          }, [_c('p', [_vm._v(_vm._s(holiday.holiday_name))]), _c('p', [_vm._v(_vm._s(holiday.holiday_date))])]), _c('td', {
            staticClass: "dialer-has-sort dialer-col-left"
          }, [_vm.holidaysByDateGroup[holiday.holiday_date] ? _c('div', {
            on: {
              "click": function ($event) {
                return _vm.$modal.show(`${_vm._uid}-UpdatePublicHoliday`, {
                  holiday: _vm.holidaysByDateGroup[holiday.holiday_date],
                  conditions: {
                    name: false,
                    date: false
                  }
                });
              }
            }
          }, [_vm.holidaysByDateGroup[holiday.holiday_date].type == 'unavailable' ? _c('div', {
            staticClass: "latestGreyBox-descriptionText-18px"
          }, [_vm._v("Unavailable all day")]) : _vm.holidaysByDateGroup[holiday.holiday_date].type == 'custom' ? _c('div', {
            staticClass: "latestGreyBox-descriptionText-18px"
          }, [_vm._v("Unavailable at " + _vm._s(_vm._f("twentyFourHourToAmPmFormat")(_vm.holidaysByDateGroup[holiday.holiday_date].start_time)) + " - " + _vm._s(_vm._f("twentyFourHourToAmPmFormat")(_vm.holidaysByDateGroup[holiday.holiday_date].end_time)))]) : _vm._e(), _vm.holidaysByDateGroup[holiday.holiday_date].extension_type == 'voicemail' ? _c('div', {
            staticClass: "latestGreyBox-descriptionText-14px"
          }, [_vm._v("Call will be forward to voicemail")]) : _vm.holidaysByDateGroup[holiday.holiday_date].extension_type == 'number' ? _c('div', {
            staticClass: "latestGreyBox-descriptionText-14px"
          }, [_vm._v("Call will be forward to " + _vm._s(_vm._f("number_formater")(_vm.holidaysByDateGroup[holiday.holiday_date].forward_to)))]) : _c('div', {
            staticClass: "latestGreyBox-descriptionText-14px"
          }, [_vm._v("Call will be forward to "), _vm.holidaysByDateGroup[holiday.holiday_date].forward_to ? _c('Info', {
            attrs: {
              "only_name": true,
              "id": _vm.holidaysByDateGroup[holiday.holiday_date].forward_to
            }
          }) : _vm._e()], 1)]) : _c('div', [_c('b-checkbox', {
            staticClass: "newerSwitch",
            attrs: {
              "disabled": _vm.api.fetch_default_holidays.send,
              "switch": ""
            },
            on: {
              "change": function ($event) {
                return _vm.addDefaultedHoliday(holiday);
              }
            }
          })], 1)])]);
        });
      }
    }], null, false, 4267796609)
  }, [!_vm.getIsMobile ? _c('tr', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "dialer-has-sort px-2 dialer-col-left"
  }, [_c('span', [_vm._v("Name")])]), _c('th', {
    staticClass: "dialer-has-sort px-2 dialer-col-left"
  }, [_c('span', [_vm._v("Unavailable Time")])])]) : _vm._e()]) : _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again showLastTDonHover mt-0",
    attrs: {
      "isListEmpty": _vm.customHolidays.length == 0,
      "listLength": _vm.customHolidays.length,
      "loading": _vm.api.fetch_group.send,
      "conditions": {
        pagination: {
          per_page: false
        }
      },
      "perPage": 100
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref2) {
        var start = _ref2.start,
          end = _ref2.end;
        return _vm._l(_vm.customHolidays, function (holiday, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: holiday.id,
            on: {
              "click": function ($event) {
                return _vm.$modal.show(`${_vm._uid}-UpdatePublicHoliday`, {
                  holiday: holiday,
                  conditions: {
                    disable_dates: _vm.disabledDates
                  }
                });
              }
            }
          }, [_c('td', {
            staticClass: "dialer-has-sort dialer-col-left"
          }, [_c('p', {
            staticClass: "mb-0"
          }, [_vm._v(_vm._s(holiday.holiday_name || ''))]), _c('p', {
            staticClass: "mt-1"
          }, [_vm._v(_vm._s(holiday.holiday_date))])]), _c('td', {
            staticClass: "dialer-has-sort dialer-col-left"
          }, [_c('div', [holiday.type == 'unavailable' ? _c('div', {
            staticClass: "latestGreyBox-descriptionText-18px mt-0"
          }, [_vm._v("Unavailable all day")]) : holiday.type == 'custom' ? _c('div', {
            staticClass: "latestGreyBox-descriptionText-18px mt-0"
          }, [_vm._v("Unavailable at " + _vm._s(_vm._f("twentyFourHourToAmPmFormat")(holiday.start_time)) + " - " + _vm._s(_vm._f("twentyFourHourToAmPmFormat")(holiday.end_time)))]) : _vm._e(), holiday.extension_type == 'voicemail' ? _c('div', {
            staticClass: "latestGreyBox-descriptionText-14px"
          }, [_vm._v("Call will be forward to voicemail")]) : holiday.extension_type == 'number' ? _c('div', {
            staticClass: "latestGreyBox-descriptionText-14px"
          }, [_vm._v("Call will be forward to " + _vm._s(_vm._f("number_formater")(holiday.forward_to)))]) : _c('div', {
            staticClass: "latestGreyBox-descriptionText-14px"
          }, [_vm._v("Call will be forward to "), holiday.forward_to ? _c('Info', {
            attrs: {
              "only_name": true,
              "id": holiday.forward_to
            }
          }) : _vm._e()], 1)])])]);
        });
      }
    }])
  }, [!_vm.getIsMobile ? _c('tr', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "dialer-has-sort px-2 dialer-col-left"
  }, [_c('span', [_vm._v("Name")])]), _c('th', {
    staticClass: "dialer-has-sort px-2 dialer-col-left"
  }, [_c('span', [_vm._v("Unavailable Time")])])]) : _vm._e()])], 1), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-between"
  }, [_vm._m(0), _c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-RecordingSelect`, {
          group: 'announcement',
          selected: _vm.forms.update_announcement_file.sound,
          conditions: {
            value_field: 'sound'
          }
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": `${_vm.forms.update_announcement_file.sound ? 'squared-change-icon' : 'squared-add-icon'}`,
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v(_vm._s(_vm.forms.update_announcement_file.sound ? 'Change' : 'Add'))])], 1)]), _vm.forms.update_announcement_file.file ? _c('div', {
    staticClass: "w-50 mt-12px"
  }, [_c('vb-audio-player', {
    staticClass: "whiteBGaudioWithGreyRoundedBorder-again w-100",
    attrs: {
      "src": _vm.forms.update_announcement_file.file,
      "design": `${_vm.getIsMobile ? 'minimum' : ''}`
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Telephone numbers")]), _c('div', {
    staticClass: "d-flex mt-0 IVR-numbers-container flex-wrap"
  }, [_vm.dids.length > 0 ? _vm._l(_vm.dids, function (did, key) {
    return _c('div', {
      key: key,
      staticClass: "newIVRLayout-number-item"
    }, [_c('img', {
      staticClass: "country-img",
      attrs: {
        "width": "45px",
        "src": _vm._f("flag_icon")(_vm._f("number_formater")(did.scheduler_number))
      }
    }), _c('span', {
      staticClass: "newIVRLayout-number-item-text"
    }, [_vm._v(_vm._s(_vm._f("number_formater")(did.scheduler_number)))])]);
  }) : _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("No numbers assigned")])], 2)]), _c('div', {
    staticClass: "lg-mar-left"
  }, [_c('button', {
    staticClass: "newButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-AssignNumberPublicHoliday`, {
          id: _vm.groupId,
          numbers: _vm.dids
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Assign")])], 1)])])])]), _vm.conditions.delete ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-24px mb-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(1), _c('button', {
    staticClass: "newDeleteButton",
    attrs: {
      "disabled": _vm.api.remove_group.send
    },
    on: {
      "click": function ($event) {
        return _vm.removeGroup();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-delete-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDeleteButton-textPart d-flex align-items-center justify-content-center position-relative"
  }, [_vm.api.remove_group.send ? _c('vb-spinner') : [_vm._v("Delete")]], 2)], 1)])])]) : _vm._e()], _c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-500 addCallQueueModal setHeight",
    attrs: {
      "name": `${_vm._uid}-UpdateName`
    },
    on: {
      "before-open": function ($event) {
        _vm.forms.update_group_name.name = _vm.response.group.name;
      },
      "before-close": function ($event) {
        _vm.$v.forms.update_group_name.$reset();
        _vm.forms.update_group_name.reset();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-user s1"
  }, [_c('div', {
    staticClass: "dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-form"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-block pb-0"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex align-items-center addCallQueueModal-namePart"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Update name")])]), _c('div', {
    staticClass: "dialer-edit-actions d-flex align-items-center"
  }, [_c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-UpdateName`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])])]), _c('div', {
    staticClass: "md-mar-vertical"
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.updateGroupName();
      }
    }
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', [_vm._v("Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_group_name.name,
      expression: "forms.update_group_name.name"
    }],
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.update_group_name.send || _vm.api.remove_group.send,
      "type": "text",
      "maxlength": _vm.$v.forms.update_group_name.name.$params.maxLength.max
    },
    domProps: {
      "value": _vm.forms.update_group_name.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_group_name, "name", $event.target.value);
      }
    }
  }), _vm.$v.forms.update_group_name.name.$error || _vm.api.update_group_name.validation_errors.name ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_group_name.name.required ? _c('span', [_vm._v("* Name is required")]) : !_vm.$v.forms.update_group_name.name.minLength ? _c('span', [_vm._v("* Name is should be minimum " + _vm._s(_vm.$v.forms.update_group_name.name.$params.minLength.min) + " character")]) : !_vm.$v.forms.update_group_name.name.maxLength ? _c('span', [_vm._v("* Name is can be maximum " + _vm._s(_vm.$v.forms.update_group_name.name.$params.maxLength.max) + " character")]) : !_vm.$v.forms.update_group_name.name.valid ? _c('span', [_vm._v("* Name can be number and alphabet")]) : _vm._e(), _vm._l(_vm.api.update_group_name.validation_errors.name, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-end mt-4"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v("Update")])])])])])])])])]), _c('CreatePublicHolidayModal', {
    staticClass: "forHolidayFix",
    attrs: {
      "modalName": `${_vm._uid}-CreatePublicHoliday`
    },
    on: {
      "created": function ($event) {
        return _vm.fetchGroupDebounce();
      }
    }
  }), _c('UpdatePublicHolidayModal', {
    staticClass: "forHolidayFix",
    attrs: {
      "modalName": `${_vm._uid}-UpdatePublicHoliday`
    },
    on: {
      "updated": function ($event) {
        return _vm.fetchGroupDebounce();
      },
      "removed": function ($event) {
        return _vm.fetchGroupDebounce();
      }
    }
  }), _c('AssignNumberPublicHolidayModal', {
    attrs: {
      "modalName": `${_vm._uid}-AssignNumberPublicHoliday`
    },
    on: {
      "updated": function ($event) {
        return _vm.fetchGroupDebounce();
      }
    }
  }), _c('RecordingListSelect', {
    attrs: {
      "modalName": `${_vm._uid}-RecordingSelect`,
      "is_single_select": true
    },
    on: {
      "selectedRecordings": function ($event) {
        return _vm.updateAnnouncementFile($event.data);
      }
    }
  })], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Announcement File")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Delete this Group?")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" This group will be deleted ")])]);

}]

export { render, staticRenderFns }