<template>
  <div :class="`${activeCall ? activeCall.is_sip ? 'sip-call-active' : 'jitsi-call-active' : ''}`">
    <template v-if="!$store.getters.getIsMobile">
      <div v-if="activeIncomingCall" :class="activeCall?'expand-c':'dagarda'" style="">
        <IncomingCalls v-if="activeIncomingCall.room" :data="activeIncomingCall" @join="joinRoomNow($event)" @removeSipcall="removeSipcallAfterSwitched($event)">
          <b-dropdown v-if="allIncomingCalls.length>1" slot="switchCalls" class="incommingCallDropDown accordingToNewDesign">
            <template #button-content>{{ allIncomingCalls.length }} incoming calls</template>
            <template v-for="incoming_call in allIncomingCalls">
              <SwitchIncomingCall 
                v-if="$store.state.calls.active_incoming_call!=(incoming_call.room || incoming_call.uuid)"
                :key="incoming_call.room || incoming_call.uuid"
                :incoming_call="incoming_call"
                :is_sip="!!incoming_call.uuid" 
                @click="$store.state.calls.active_incoming_call=incoming_call.room || incoming_call.uuid" 
              />
            </template>
          </b-dropdown>
        </IncomingCalls>
        <SipSession v-if="activeIncomingCall.uuid" :session="activeIncomingCall">
          <b-dropdown v-if="allIncomingCalls.length>1" slot="switchCalls" class="incommingCallDropDown accordingToNewDesign">
            <template #button-content>{{ allIncomingCalls.length }} incoming calls</template>
            <template v-for="incoming_call in allIncomingCalls">
              <SwitchIncomingCall 
                v-if="$store.state.calls.active_incoming_call!=(incoming_call.room || incoming_call.uuid)"
                :key="incoming_call.room || incoming_call.uuid"
                :incoming_call="incoming_call"
                :is_sip="!!incoming_call.uuid" 
                @click="$store.state.calls.active_incoming_call=incoming_call.room || incoming_call.uuid" 
              />
            </template>
          </b-dropdown>
        </SipSession>
      </div>
      <div v-if="activeCall">
        <SipSession v-if="activeCall.is_sip" :session="activeCall">
          <SwitchCall :activeCall="activeCall" slot="switchCalls" />
        </SipSession>
        <ActiveCalls v-else-if="!$store.getters.isIntegration" v-show="activeCall.isShown" :videoCall="activeCall">
          <SwitchCall :activeCall="activeCall" slot="switchCalls" />
        </ActiveCalls>
      </div>
    </template>
    <template v-if="!$store.getters.isIntegration">
      <OutgoingCalls v-for="outgoing_call in $store.state.calls.outgoing_calls" :key="`${outgoing_call.room}-outgoing`" :data="outgoing_call" @removeSipcall="removeSipcallAfterSwitched($event)" />
    </template>
    <audio id="outgoing-call-738905" :src="require('@/assets/sounds/outgoing.mp3')" style="display:none;" loop />
    <audio id="incoming-call-738905" :src="require('@/assets/sounds/incoming.mp3')" style="display:none;" loop />
    <audio 
      id="videoringtone" 
      v-show="false" 
      :src="media_devices_setting.selectedIncomingRingtone | get_property('file')" 
      loop 
      media-player="audioPlayer" 
      controls="controls" 
      preload="auto" 
      crossorigin="anonymous" 
    />
    <audio 
      id="ringtone" 
      v-show="false" 
      :src="media_devices_setting.selectedIncomingRingtone | get_property('file')" 
      loop 
      media-player="audioPlayer" 
      controls="controls" 
      preload="auto" 
      crossorigin="anonymous" 
    />
    <audio id="dtmfTone" v-show="false" :src="require('@/assets/sounds/dtmf.mp3')" media-player="audioPlayer" controls="controls" preload="auto" crossorigin="anonymous" />
    <audio 
      id="ringbacktone" 
      v-show="false" 
      :src="require('@/assets/sounds/outgoing.mp3')" 
      loop 
      media-player="audioPlayer" 
      controls="controls" 
      preload="auto" 
      crossorigin="anonymous" 
    />
    <audio id="audioRemote" v-show="false" media-player="audioPlayer" controls="controls" preload="auto" crossorigin="anonymous" />
    <audio id="dail_beep" v-show="false" :src="require('@/assets/sounds/dail_beep.mp3')" />
    <audio id="hangup_sound" v-show="false" :src="require('@/assets/sounds/ringtons/alert/Alert 6.mp3')" />
    <audio id="dial_sound" v-show="false" :src="require('@/assets/sounds/ringtons/alert/Alert 7.mp3')" />
    <div v-show="false">
      <select id="videoSource" v-model="media_devices_setting.camera_id">
        <option v-for="device in media_devices_setting.videoinputs" :key="device.deviceId" :value="device.deviceId">{{ device.label }}</option>
      </select>
      <select id="audioSource"  v-model="media_devices_setting.mic_id">
        <option v-for="device in media_devices_setting.audioinputs" :key="device.deviceId" :value="device.deviceId">{{ device.label }}</option>
      </select>
      <select id="audioOutput"  v-model="media_devices_setting.speaker_id">
        <option v-for="device in media_devices_setting.audiooutputs" :key="device.deviceId" :value="device.deviceId">{{ device.label }}</option>
      </select>
    </div>
  </div>
</template>

<script>
import OutgoingCalls from './OutgoingCalls.vue';
import ActiveCalls from './ActiveCalls.vue';
import IncomingCalls from './IncomingCalls.vue';
import SipSession from './SipSession.vue';
import SwitchCall from './SwitchCall/index.vue';
import SwitchIncomingCall from './SwitchCall/IncomingCall.vue';
import { 
  events, 
  $fn, 
  VOIP_API, 
  LOGGER,
  permissions,
} from '@/utils';
import JwtDecode from "jwt-decode";
// import { centrifugo } from '@/Centrifuge/index';
import { TokenService } from '@/services';
import { phone } from '@/sip/app';
import { electron_events } from '@/electron/events';
import { mapGetters, mapState } from 'vuex';
import moment from 'moment-timezone';
import { call_info } from '@/mixin';
import Timer from "timer.js";
import { number_formater } from '@/filter';
import { GET_LOCAL_SETTING_CALLS } from '@/store/helper/getters';
import { AMI_EVENTS, AMI_SOCKET } from '@/ami';
import { MOBILE_APP } from '@/mobile-app';
import { MOBILE_APP_SEND_EVENTS } from '@/mobile-app/events';
import _ from 'lodash';

export default {
  name: "Calls",
  mixins: [
    call_info
  ],
  components: {
    IncomingCalls,
    OutgoingCalls,
    SipSession,
    ActiveCalls,
    SwitchCall,
    SwitchIncomingCall,
  },
  data(){
    return {
      is_call_process: false,
    }
  },
  inject:[
    'getProperty',
    'setDesign',
    'appNotify',
  ],
  computed: {
    ...mapState({
      media_devices_setting: state=>state.media_devices_setting,
    }),
    ...mapGetters([
      'activeCall',
      'jitsiOutgoingCalls',
      'jitsiIncomingCalls',
      'isCallBar',
      'activeIncomingCall',
      'allIncomingCalls',
      'allRunningCalls',
      'sipOutgoingCalls',
      'sipRunningCalls',
      'jitsiRunningCalls',
      'sipsessions',
      'isSwitchAbleState',
      'getVoipUsersAlises',
      'getVoipUsersAlisesDisplayName',
      'getCurrentUser',
      'getIsMobile',
      'isActiveSessionIncoming',
      'isActiveSession',
      'activeSession',
      'getAddressbooksAlaisesNumbers',
      'getVoipTeamsAlises',
      'getVoipCallQueuesAlises',
      'getVoipIVRSAlises',
      GET_LOCAL_SETTING_CALLS,
    ]),
    storeDevices(){
      return TokenService.DEVICES_SETTING.get()
    },
  },
  watch: {
    "jitsiOutgoingCalls"(outgoing_calls){
      const outgoing_audio = document.getElementById('outgoing-call-738905')
      if(outgoing_calls.length==0){
        if(!outgoing_audio.paused){
          outgoing_audio.pause()
        }
      } else {
        if(outgoing_audio.paused){
          outgoing_audio.play()
        }
      }
    },
    "jitsiIncomingCalls"(incoming_calls){
      const incoming_audio = document.getElementById('incoming-call-738905')
      if(incoming_calls.length==0){
        if(!incoming_audio.paused){
          incoming_audio.pause()
        }
      } else {
        if(incoming_audio.paused){
          incoming_audio.play()
        }
      }
    },
    $online(is_online){
      if(!is_online){
        this.hangUpAllCalls()
      }
    },
    // "isActiveSession"(value){
    //   if(!this.getIsMobile){
    //     if(!value){
    //       this.$store.state.global_conditions.tab='homeCopy'
    //     } else if(!this.isActiveSessionIncoming) {
    //       this.$store.state.global_conditions.tab='video-call'
    //     }
    //   }
    // },
    "$store.state.global_conditions.tab"(value){
      if(value=='video-call') {
        if(!this.isActiveSession) {
          this.$store.state.global_conditions.tab='homeCopy'
        }
        this.$root.$emit(events.fetch_call_recording_setting)
      }
    },
    "activeCall"(call,old_call){
      // console.log('call,old_call')
      if(!call){
        let new_call;
        if(old_call && !!old_call?.extra_data?.is_transfer){
          const activeSession = this.$store.state.sip.phone.activeSession
          new_call = activeSession
        }
        if(!new_call) {
          const active_jitsi_call = this.jitsiRunningCalls[0]
          const active_sip_session = [...this.sipRunningCalls,...this.sipOutgoingCalls][0]
          new_call = active_sip_session || active_jitsi_call
        }
        if(new_call) {
          this.$store.state.calls.active_call=new_call.room || new_call.uuid
        }
      } else {
        if(call && call.is_sip && old_call && old_call.is_sip && call.uuid==old_call.uuid){
          // TODO
        } else {
          if(call) {
            if(call.is_sip) {
              this.$store.state.sip.phone.markSessionActive(call.uuid)
              if(call.held) {
                call.hold()
              }
            } else {
              if(!call.isPlay) {
                call.isPlay=true;
              }
            }
          }
          if(old_call) {
            if(old_call.is_sip) {
              if(!old_call.held) {
                old_call.hold()
              }
            } else {
              if(old_call.isPlay) {
                old_call.isPlay=false;
              }
            }
          }
        }
      }
    },
    // "isActiveSessionIncoming"(value){
    //   if(value){
    //     if(this.$store.state.global_conditions.tab=='video-call' && !this.getIsMobile) {
    //       this.$store.state.global_conditions.tab='homeCopy'
    //     }
    //   }
    // },
    "activeIncomingCall"(){
      setTimeout(()=>{this.setDesign()},0.5 * 1000)
    },
    allIncomingCalls(allIncomingCalls){
      const id = this.$store.state.calls.active_incoming_call
      if(!allIncomingCalls.some(item=>id && `${item.uuid || ''} ${item.room || ''}`.includes(id))){
        if(allIncomingCalls.length>=1){
          this.$store.state.calls.active_incoming_call = allIncomingCalls[0].uuid || allIncomingCalls[0].room
        } else {
          this.$store.state.calls.active_incoming_call = ''
        }
      }
    },
    "media_devices_setting.speaker_id": "mediaDeviceSettingSpeakerId",
    "media_devices_setting.ringing_speaker_id": "mediaDeviceSettingRingingSpeakerId",
    "media_devices_setting.speaker_volume": "mediaDeviceSettingSpeakerVolume",
    "media_devices_setting.ringing_speaker_volume": "mediaDeviceSettingRingingSpeakerVolume",
    "media_devices_setting.incoming_ringtone": "mediaDeviceSettingIncomingRingtone",
    "$store.state.power_dialer.sheet_id": {
      immediate: true,
      handler(sheet_id){
        if(sheet_id) this.dial({ call_type: 'power_dialer', })
      },
    },
  },
  methods: {
    //--own--
    mediaDeviceSettingSpeakerId(speaker_id,old_speaker_id){
      let vm = this
      const audioRemote_audio = document.getElementById("audioRemote"); 
      if(audioRemote_audio){
        const isactive = audioRemote_audio?.srcObject?.active
        if(isactive==false){
          audioRemote_audio.srcObject=null
        }
        audioRemote_audio.setSinkId(speaker_id)
        .catch((/* ex */)=>{
          // console.log(ex,{audioRemote_audio})
          if(old_speaker_id) {
            vm.$store.state.media_devices_setting.speakerId=old_speaker_id
          }
        });
      }
    },
    mediaDeviceSettingRingingSpeakerId(ringing_speaker_id){
      const ringtone_audio = document.getElementById("ringtone"); 
      const ringbacktone_audio = document.getElementById("ringbacktone"); 
      const outgoing_audio = document.getElementById("outgoing-call-738905"); 
      const incoming_audio = document.getElementById("incoming-call-738905"); 
      const videoringtone_audio = document.getElementById("videoringtone"); 
      if(ringtone_audio){
        ringtone_audio.setSinkId(ringing_speaker_id);
      }
      if(outgoing_audio){
        outgoing_audio.setSinkId(ringing_speaker_id);
      }
      if(incoming_audio){
        incoming_audio.setSinkId(ringing_speaker_id);
      }
      if(videoringtone_audio){
        videoringtone_audio.setSinkId(ringing_speaker_id);
      }
      if(ringbacktone_audio){
        ringbacktone_audio.setSinkId(ringing_speaker_id);
      }
    },
    mediaDeviceSettingSpeakerVolume(speaker_volume){
      const audioRemote_audio = document.getElementById("audioRemote"); 
      if(audioRemote_audio){
        audioRemote_audio.volume = speaker_volume / 100;
      }
    },
    mediaDeviceSettingRingingSpeakerVolume(ringing_speaker_volume){
      const ringtone_audio = document.getElementById("ringtone"); 
      const ringbacktone_audio = document.getElementById("ringbacktone"); 
      const outgoing_audio = document.getElementById("outgoing-call-738905"); 
      const incoming_audio = document.getElementById("incoming-call-738905"); 
      const videoringtone_audio = document.getElementById("videoringtone"); 
      
      if(ringtone_audio){
        ringtone_audio.volume = ringing_speaker_volume / 100;
      }
      if(outgoing_audio){
        outgoing_audio.volume = ringing_speaker_volume / 100;
      }
      if(incoming_audio){
        incoming_audio.volume = ringing_speaker_volume / 100;
      }
      if(videoringtone_audio){
        videoringtone_audio.volume = ringing_speaker_volume / 100;
      }
      if(ringbacktone_audio){
        ringbacktone_audio.volume = ringing_speaker_volume / 100;
      }
    },
    mediaDeviceSettingIncomingRingtone(){
      let vm = this
      const ringtone_audio = document.getElementById("ringtone"); 
      const ringbacktone_audio = document.getElementById("ringbacktone"); 
      const videoringtone_audio = document.getElementById("videoringtone"); 
      if(ringtone_audio){
        ringtone_audio.load();
        ringtone_audio.onloadedmetadata = () => {
          if(vm.sipIncomingCalls.length>0){
            ringtone_audio.play()
          }
        }
      }
      if(videoringtone_audio){
        videoringtone_audio.load();
      }
      if(ringbacktone_audio){
        ringbacktone_audio.load();
      }
    },
    mediaDevicesSettingSink(){
      if(this.media_devices_setting?.speaker_id !== undefined){ this.mediaDeviceSettingSpeakerId(this.media_devices_setting.speaker_id) }
      if(this.media_devices_setting?.ringing_speaker_id !== undefined){ this.mediaDeviceSettingRingingSpeakerId(this.media_devices_setting.ringing_speaker_id) }
      if(this.media_devices_setting?.speaker_volume !== undefined){ this.mediaDeviceSettingSpeakerVolume(this.media_devices_setting.speaker_volume) }
      if(this.media_devices_setting?.ringing_speaker_volume !== undefined){ this.mediaDeviceSettingRingingSpeakerVolume(this.media_devices_setting.ringing_speaker_volume) }
    },
    sipLoad(){
      LOGGER.log('this.phones',phone)
      phone.videoElement = document.getElementById("audioRemote");
      phone.audioInputSelect = document.querySelector("select#audioSource");
      phone.audioOutputSelect = document.querySelector("select#audioOutput");
      phone.videoSelect = document.querySelector("select#videoSource");
      phone.selectors = [
        phone.audioInputSelect,
        phone.audioOutputSelect,
        phone.videoSelect,
      ];
      phone.audioOutputSelect.disabled = !("sinkId" in HTMLMediaElement.prototype);
    },
    joinRoomNow(message) {
      const { room, jwt, getToken, data, type, is_group, call_type, is_conference, meeting_id, make_active=true } = message
      this.beforeCallInitialize()
      const videoCallData = {
        is_sip: false,
        room,
        call_type,
        jwt: jwt,
        is_group,
        is_conference,
        meeting_id: is_conference ? meeting_id : '',
        data,
        type,
        outgoing: is_conference ? null : {
          time: 30,
          status: 'calling',
        },
        isCaller: type === "dailer",
        getToken: getToken,
        payload: jwt ? JwtDecode(jwt) : {},
        members: {},
        isPlay: true,
        isHangout: false,
        volume: 10,
        isVideo: true,
        isVideoMute: true,
        isParticipants: false,
        isAudioMute: true,
        isChat: false,
        isMemberOpen: false,
        timestamp: null,
        time: "",
        name: this.$store.getters.getVoipUsersAlisesDisplayName["params" in data ? data.params.data.callee : data.callee],
        images: [],
        isShown: true,
        names: [],
        JitsiConnection: {},
        isConnectionEstablished: false,
        JitsiConference: {},
        localTracks: {},
        resolution: 720,
        error: "",
        effect: {},
        isJoined: false,
        isFullScreen: false,
        isMirror: false,
        interval: null,
        channel_id: '',
        toggleIsMemberOpen(){
          this.isMemberOpen=!this.isMemberOpen
        },
        toggleIsVideo(){
          if(this.isPlay){
            this.isVideo=!this.isVideo
          }
        },
        toggleIsAudioMute(){
          if(this.isPlay){
            this.isAudioMute=!this.isAudioMute
          }
        },
        toggleIsVideoMute(){
          if(this.isPlay){
            this.isVideoMute=!this.isVideoMute
          }
        },
        toggleIsPlay(){
          this.isPlay=!this.isPlay
        },
        toggleIsHangout(){
          this.isHangout=!this.isHangout
        },
        toggleIsChat(){
          this.isChat=!this.isChat
        },
        startTimer(timestamp){
          let that = this
          this.timestamp = timestamp;
          this.interval = setInterval(() => {
            that.time = moment.utc().diff(moment.utc(timestamp), 'seconds')
          }, 1000);
        },
        stopTimer(){
          clearInterval(this.interval)
        },
      }
      this.$store.state.calls.videoCalls.push(videoCallData);
      if(make_active) {
        this.$store.state.calls.active_call=room
        this.$store.state.calls.activeVideoCall = videoCallData;
        this.$store.state.calls.isVideoPopupOpen = true;
        this.$store.state.global_conditions.tab='video-call'
      }
      // if(this.$store.getters.getIsMobile){
      //   this.$store.state.global_conditions.tab='video-call'
      // }
      return;
    },
    beforeCallInitialize(){
      if(['listen', 'whisper', 'barge', 'test'].includes(this.$store.state.sip.phone.activeSession?.extra_data?.call_type ?? '')){
        this.$store.state.sip.phone.activeSession.hangup()
      }
      // this.holdAllVideoCalls()
      // this.holdActiveSession()
    },
    removeSipcallAfterSwitched(event){
      this.$store.state.sip.phone.sessions.find(i=>i.extra_data.bridgeuniqueid==event.sip_id)?.hangup?.()
    },
    //events----------------------------------------------
    //->centrifugo
    CANCEL(message){
      this.$store.state.calls.incoming_calls[message?.data?.room]?.onCancel?.()
    },
    BYE(message){
      this.$store.state.calls.incoming_calls[message?.data?.room]?.remove?.()
    },
    ACCEPTED(message){
      this.$store.state.calls.incoming_calls[message?.data?.room]?.remove?.()
    },
    INVITE(message){
      let vm = this
      const { data } = message
      if(data){
        const { room, restricted_key_callee, call_type, display_name, is_group, caller } = data
        const incomingCall = {
          room: room,
          time: 30,
          timer: null,
          data: data,
          is_incoming: true,
          is_submitted: false,
          async init(cb){
            try {
              let that = this
              vm.$store.commit("pushCallActivity", {
                billSec: "00:00:00",
                callRecorded: false,
                call_date: new Date(),
                call_type: 'video',
                callerIp: "",
                date: moment().unix(),
                destinationCallerId: "",
                destinationExt: is_group ? '' : vm.getVoipUsersAlises[restricted_key_callee]?.extn,
                destinationName: is_group ? display_name : vm.getVoipUsersAlisesDisplayName[restricted_key_callee],
                duration: "00:00:00",
                soundFile: null,
                sourceCallerId: "",
                sourceExt: vm.getCurrentUser?.extn,
                sourceName: vm.getCurrentUser?.display_name,
                src: vm.getCurrentUser?.extn,
                status: "Received",
                uniqueid: $fn.makeid(50),
                dialable: restricted_key_callee,
                is_jitsi: true,
              });
              if(!vm.$store.state.calls.restricted_calls[restricted_key_callee]){
                vm.$set(vm.$store.state.calls.restricted_calls,restricted_key_callee,true)
              }
              vm.getCallInfo(that)
              .then((info)=>{
                vm.appNotify({
                  toaster: false,
                  sound: false,
                  title: `${info.name} ${info.sub_info}`,
                  icon: info.image,
                  message: `Incoming Call`,
                  notify_type: 'alert',
                  position: 'top-right',
                })
                if(!window.document.hasFocus() && process.env.IS_ELECTRON) {
                  window.electron.send(electron_events.INCOMING_CALL,{
                    id: data.room,
                    from: `${info.name} ${info.sub_info}`,
                    to: `${vm.getCurrentUser?.display_name} ${vm.getCurrentUser?.extn}`,
                  })
                }
              })
              that.timer = new Timer({
                tick: 1,
                ontick() {
                  that.time = that.time - 1;
                },
                onend() {
                  if (vm.$store.state.calls.incoming_calls[room]) {
                    that.busy();
                  }
                },
              });
              that.timer.start(30);
              VOIP_API.endpoints.users.publish({
                method: "publish",
                params: {
                  channel: `${caller}-in`,
                  data: {
                    ...data,
                    type: AMI_EVENTS.PROGRESS,
                  },
                },
              });
              vm.joinRoomNow({
                room,
                jwt: data.callee_jwt,
                data: this.data,
                type: "receiver", // is_dailer ? "dailer" : "receiver" // due to multi groups created in updated_group call in jitsi
                is_group,
                call_type,
                make_active: false,
              })
            } finally {
              cb?.()
            }
          },
          async accept(){
            // TODO - accept
            if(!vm.isSwitchAbleState) return;
            // if(vm.$store.state.global_conditions.is_mobile_setting) {
            //   alert(`
            //     permission: ${permissions.mic}
            //     camera_id: ${vm.$store.state.media_devices_setting.camera_id}
            //   `)
            // }
            if(permissions.mic=='denied'){
              vm.appNotify({
                message: 'Allow your microphone permission',
                type: 'danger',
              })
              return;
            }
            vm.beforeCallInitialize()
            if (this.is_submitted) return;
            this.is_submitted = true;
            try {
              await VOIP_API.endpoints.users.publish({
                method: "publish",
                extension_accountcode: vm.getCurrentUser?.sip?.user,
                params: {
                  channel: `${caller}-in`,
                  data: {
                    ...data,
                    type: AMI_EVENTS.ACCEPT,
                  },
                },
              });
              const index = vm.$store.state.calls.videoCalls.findIndex(call=>call.room==room)
              console.log(index,call_type)
              if(index>-1) {
                if (call_type=="audio") {
                  vm.$store.state.calls.videoCalls[index].isAudioMute = false;
                } else {
                  vm.$store.state.calls.videoCalls[index].isVideoMute = false;
                  vm.$store.state.calls.videoCalls[index].isAudioMute = false;
                }
              }
              vm.$store.state.calls.active_call=room
              vm.$store.state.calls.isVideoPopupOpen = true;
              vm.$store.state.global_conditions.tab='video-call'
              // const getToken = async function () {
              //   return jwt
              // }
              // vm.joinRoomNow({
              //   room,
              //   getToken: getToken,
              //   jwt: data.callee_jwt,
              //   data: this.data,
              //   type: "receiver", // is_dailer ? "dailer" : "receiver" // due to multi groups created in updated_group call in jitsi
              //   is_group,
              //   call_type,
              // })
              this.remove(false)
            } finally {
              this.is_submitted = false;
            }
          },
          async reject(){
            // TODO - reject
            if (this.is_submitted) return;
            this.is_submitted = true;
            try {
              await VOIP_API.endpoints.users.publish({
                method: "publish",
                params: {
                  channel: `${caller}-in`,
                  data: {
                    ...data,
                    type: AMI_EVENTS.REJECTED,
                  },
                },
              });
              this.remove()
            } finally {
              this.is_submitted = false;
            }
          },
          async busy(){
            // TODO - busy
            await VOIP_API.endpoints.users.publish({
              method: "publish",
              params: {
                channel: `${caller}-in`,
                data: {
                  ...data,
                  type: AMI_EVENTS.BUSY,
                },
              },
            });
            vm.getCallInfo(this)
            .then((info)=>{
              vm.appNotify({
                title: `${info.name} ${info.sub_info}`,
                icon: info.image,
                message: `missed Call`,
                notify_type: 'alert',
                position: 'top-right',
              })
              MOBILE_APP.sendMessage(MOBILE_APP_SEND_EVENTS,{ room: room })
            })
            this.remove()
          },
          onCancel(){
            vm.getCallInfo(this)
            .then((info)=>{
              vm.appNotify({
                title: `${info.name} ${info.sub_info}`,
                icon: info.image,
                message: `missed Call`,
                notify_type: 'alert',
                position: 'top-right',
              })
            })
            this.remove()
          },
          remove(restricted=true){
            if(process.env.IS_ELECTRON){
              window.electron.send(electron_events.INCOMING_CALL_HIDE,{
                id: room
              })
            }
            this.timer?.stop?.()
            if(restricted) {
              vm.$store.state.calls.videoCalls = vm.$store.state.calls.videoCalls.filter(call=>call.room!==room)
              vm.$delete(vm.$store.state.calls.restricted_calls,restricted_key_callee);
            }
            vm.$delete(vm.$store.state.calls.incoming_calls,room);
          },
        }
        incomingCall.init(()=>{
          this.$set(this.$store.state.calls.incoming_calls,room,incomingCall)
        })
      }
    },
    /*INVITE(message){
      const { data } = message
      if(data){
        const { room, restricted_key_callee, call_type, display_name, is_group } = data
        this.$set(this.$store.state.calls.incoming_calls,room,data)
        this.$store.commit("pushCallActivity", {
          billSec: "00:00:00",
          callRecorded: false,
          call_date: new Date(),
          call_type: call_type,
          callerIp: "",
          date: moment().unix(),
          destinationCallerId: "",
          destinationExt: is_group ? '' : this.$store.getters.getVoipUsersAlises[restricted_key_callee]?.extn, // this.getUserInfo(restricted_key_callee).extn,
          destinationName: is_group ? display_name : this.$store.getters.getVoipUsersAlisesDisplayName[restricted_key_callee],//this.showUserName(restricted_key_callee),
          duration: "00:00:00",
          soundFile: null,
          sourceCallerId: "",
          sourceExt: this.$store.getters.getCurrentUser?.extn,//this.current_user.extn,
          sourceName: this.$store.getters.getCurrentUser?.display_name,//this.current_user.display_name,
          src: this.$store.getters.getCurrentUser?.extn,//this.current_user.extn,
          status: "Received",
          uniqueid: $fn.makeid(50),
          dialable: restricted_key_callee,
          is_jitsi: true,
        });
        if(!this.$store.state.calls.restricted_calls[restricted_key_callee]){
          this.$set(this.$store.state.calls.restricted_calls,restricted_key_callee,true)
        }
      }
    },*/
    CENTRIFUGE_OTHER(){
      //TODO
    },
    ACTIVE_GROUP_CALLS(message){
      const { data: { caller, callees, room, extra_data } } = message
      this.$set(this.$store.state.calls.active_group_calls,room,{
        caller: caller,
        callee: callees,
        room: room,
        restricted_key_caller: room,
        restricted_key_callee: room,
        is_group: true,
        display_name: extra_data.display_name,
        call_type: extra_data.call_type,
        is_upgradeable: false,
        type: AMI_EVENTS.ACCEPT
      })
    },
    NOT_ACTIVE_GROUP_CALLS(message){
      const { data: { room } } = message
      this.$delete(this.$store.state.calls.active_group_calls,room)
    },
    //->own
    holdAllVideoCalls(){
      for (let index = 0; index < this.$store.state.calls.videoCalls.length; index++) {
        this.$store.state.calls.videoCalls[index].isPlay=false;
      }
    },
    /* 
      ---conference = {
        room: {String}
        is_conference: {String}
        meeting_id: {String}
        jwt: {String}
        display_name: {String}
        call_type: {String}
      }
      ---group = {
        room: {String}
        accounts: {Array}
        is_group: {String}
        display_name: {String}
        call_type: {String}
      }
      ---conference = {
        accounts: {Array} {length==1}
        call_type: {String}
      }
    **/
    async call(message){
      LOGGER.log('call message',message);
      const { 
        accounts, 
        room: _room, 
        is_group: prop_is_group, 
        is_conference: props_is_conference,
        is_switch_call,
        sip_id,
        meeting_id, 
        jwt, 
        onComplete, 
        onAbort, 
        is_upgradeable, 
        display_name: _display_name, 
        call_type,
        id: __id,
        image: __image,
      } = message
      const is_conference = props_is_conference || false
      const is_group = is_conference ? [] : prop_is_group || accounts.length>1
      const display_name = _display_name ? _display_name : this.$store.getters.getVoipUsersAlisesDisplayName[accounts[0]]
      let token = ''
      let getToken = null
      const data = {
        caller: this.$store.getters.getCurrentUser?.account ?? '',
        callee: is_group ? accounts : accounts[0] ?? '',
        room: is_group ? _room : $fn.getUniqueID() ?? '',
        restricted_key_caller: is_conference ? meeting_id : is_group ? _room : accounts[0] ?? '',
        restricted_key_callee: is_conference ? meeting_id : is_group ? _room : this.$store.getters.getCurrentUser?.account ?? '',
        is_group: !!is_group,
        is_conference: !!is_conference,
        call_type: call_type ?? '',
        extra_data: {
          is_switch_call: is_switch_call ?? false,
          sip_id: sip_id ?? '',
          display_name: _display_name || '',
          call_type: call_type ?? '',
          is_conference: !!is_conference,
          id: __id,
          image: __image,
        },
        display_name: _display_name || '',
        is_upgradeable: !!is_upgradeable,
        type: AMI_EVENTS.INVITE
      }
      try {
        if(this.is_call_process) {
          throw new Error(`OWN::Already in progress`)
        }
        this.is_call_process=true
        if(permissions.mic=='denied'){
          this.appNotify({
            message: 'Allow your microphone permission',
            type: 'danger',
          })
          throw new Error(`OWN::Don't have permission to access microphone`)
        }
        if(!this.media_devices_setting?.audioinputs?.length && !this.$store.state.global_conditions.is_mobile_setting){
          this.appNotify({
            message: 'There is no audio input device',
            type: 'danger',
          })
          throw new Error(`OWN::There is no Audio input Device`)
        }
        if(this.$store.state.calls.restricted_calls[data.restricted_key_caller]) throw new Error('OWN::This call are restricted');
        this.$set(this.$store.state.calls.restricted_calls,data.restricted_key_caller,true)
        if(is_conference){
          token=jwt
        } else if(is_group){
          if(data.callee.length && data.callee.length===0) throw new Error('Callee length is 0');
          const array = data.callee.filter(account=>account!==this.$store.getters.getCurrentUser?.account)
          const channels = array.map(account=>`${account}-in`)
          getToken = async function () {
            const { data: { jwt } } = await VOIP_API.endpoints.users.groupPublish({
              method: "broadcast",
              params: {
                channels: channels,
                data,
              },
            });
            return jwt
          }
          // const { data: { jwt } } = await VOIP_API.endpoints.users.groupPublish({
          //   method: "broadcast",
          //   params: {
          //     channels: channels,
          //     data,
          //   },
          // });
          // token=jwt
        } else {
          if(data.callee===this.$store.getters.getCurrentUser?.account) throw new Error('Caller cannot call to him self');
          getToken = async function () {
            const { data: { jwt } } = await VOIP_API.endpoints.users.publish({
              method: "publish",
              params: {
                channel: `${data.callee}-in`,
                data,
              },
            });
            return jwt
          }
          // const { data: { jwt } } = await VOIP_API.endpoints.users.publish({
          //   method: "publish",
          //   params: {
          //     channel: `${data.callee}-in`,
          //     data,
          //   },
          // });
          // token=jwt
        }
        if(typeof onComplete == 'function'){
          onComplete()
        }
        if(!is_conference){
          this.$store.commit("pushCallActivity", {
            billSec: "00:00:00",
            callRecorded: false,
            call_date: new Date(),
            call_type: 'video',
            callerIp: "",
            date: moment().unix(),
            destinationCallerId: "",
            destinationExt: is_group ? '' : this.$store.getters.getVoipUsersAlises[data.callee]?.extn,
            destinationName: is_group ? display_name : this.$store.getters.getVoipUsersAlisesDisplayName[data.callee],
            duration: "00:00:00",
            soundFile: null,
            sourceCallerId: "",
            sourceExt: this.$store.getters.getCurrentUser?.extn,
            sourceName: this.$store.getters.getCurrentUser?.display_name,
            src: this.$store.getters.getCurrentUser?.extn,
            status: "Dialed",
            uniqueid: $fn.makeid(50),
            dialable: data.callee,
            is_jitsi: true,
          });
          this.$set(this.$store.state.calls.outgoing_calls,data.room,{
            ...data,
            jwt: token,
            call_type,
          })
        }
        this.joinRoomNow({
          room:data.room,
          getToken: getToken,
          jwt: token,
          data,
          type: is_conference ? "joinner" : "dailer",
          is_group: data.is_group,
          is_conference: data.is_conference,
          meeting_id: is_conference ? meeting_id : '',
          call_type: call_type
        });
      } catch(error) {
        LOGGER.danger('error in dial function',error)
        if(typeof onAbort == 'function'){
          onAbort(error)
        }
        if(!error.message.startsWith('OWN::')){
          this.$delete(this.$store.state.calls.restricted_calls,data.restricted_key_caller)
        }
      } finally {
        this.is_call_process=false
      }
    },
    onCallEnded(message){
      const { data: { data: { caller, restricted_key_callee, restricted_key_caller } } } = message
      if(this.$store.getters.getCurrentUser?.account===caller){
        this.$delete(this.$store.state.calls.restricted_calls,restricted_key_caller)
      } else {
        this.$delete(this.$store.state.calls.restricted_calls,restricted_key_callee)
      }
    },
    async joinCall(message){
      const { restricted_key_callee, caller, call_type, room } = message
      this.$set(this.$store.state.calls.restricted_calls,restricted_key_callee,true)
      const { data: { jwt } } = await VOIP_API.endpoints.users.publish({
        method: "publish",
        extension_accountcode: this.getCurrentUser?.sip?.user,
        params: {
          channel: `${caller}-in`,
          data: {
            ...message,
            type: AMI_EVENTS.ACCEPT,
          },
        },
      });
      this.joinRoomNow({
        room,
        jwt,
        data: message,
        type: "reciever",
        is_group: true,
        call_type,
      });
    },
    async dial(event) {
      try {
        if(!this.$store.state.sip.phone.is_dialable) throw new Error('Please wait previous outbound call in progress')
        let number = event?.number, extraHeaders = [], power_dialer_contact = null
        const caller_id = this.$store.state.sip.caller_id, id = event?.id, call_type = event?.call_type ?? 'normal'
        // if no number
        if(!number && call_type=='normal') throw new Error('Please Dial a Number First')
        // if suspended
        if(this.getCurrentUser.suspended) throw new Error(this.getCurrentUser.administrator_account ? 'You have an outstanding invoice to pay' : 'Contact to Administrator')
        // don't have mic permission
        if(permissions.mic=='denied') throw new Error('Allow your microphone permission')
        // no audio input device
        if(!this.media_devices_setting?.audioinputs?.length) throw new Error(`There is no audio input device`)
        // if call to own self
        if(this.getCurrentUser?.account==number || this.getCurrentUser?.extn==number || caller_id==number || number_formater(caller_id)==number_formater(number)) throw new Error(`you can't call your own self`)
        // you are in call so you can't listen, whishper, barge and test
        if(['listen', 'whisper', 'barge', 'test', 'power_dialer'].includes(call_type) && (this.allRunningCalls.length>0 || this.sipOutgoingCalls.length>0)) throw new Error(`You can not ${call_type} call when you are in a call`)
        // make callee info
        const address_book = this.getAddressbooksAlaisesNumbers[id] || this.getAddressbooksAlaisesNumbers[number]
        const user = this.getVoipUsersAlises[id] || this.getVoipUsersAlises[number]
        const team = this.getVoipTeamsAlises[id] || this.getVoipTeamsAlises[number]
        const call_queue = this.getVoipCallQueuesAlises[id] || this.getVoipCallQueuesAlises[number]
        const ivr = this.getVoipIVRSAlises[id] || this.getVoipIVRSAlises[number]
        const callee_type = address_book ? 'address_book' : user ? 'user' : team ? 'team' : call_queue ? 'call_queue' : ivr ? 'ivr' : ''
        const extn = (callee_type=='user' ? user?.extn : callee_type=='team' ? team?.ringroup?.extn : callee_type=='call_queue' ? call_queue?.extn : callee_type=='ivr' ? ivr?.extn : number) ?? ''
        const callee_id = (callee_type=='address_book' ? address_book?.phone?.uid : callee_type=='user' ? user?.voipaccount : callee_type=='team' ? team?.real_id : callee_type=='call_queue' ? call_queue?.voipaccount : callee_type=='ivr' ? ivr?.voipaccount : event?.id) ?? ''
        // make number
        if(call_type=='normal') this.$store.state.sip.number = address_book?.phone?.number ?? extn ?? number ?? this.$store.state.sip.last_call_number ?? ''
        else if(call_type=='listen') this.$store.state.sip.number = `*150*${extn}`
        else if(call_type=='whisper') this.$store.state.sip.number = `*151*${extn}`
        else if(call_type=='barge') this.$store.state.sip.number = `*152*${extn}`
        else if(call_type=='after_jitsi') this.$store.state.sip.number = `*153*${extn}`
        else if(call_type=='test') this.$store.state.sip.number = `*154*`
        else if(call_type=='blf_call') this.$store.state.sip.number = `**${extn}`
        else if(call_type=='power_dialer') {
          const sheet_id = event?.sheet_id || this.$store.state.power_dialer.sheet_id
          const row_no = event?.row_no
          if(!sheet_id) return;
          const { data } = await VOIP_API.endpoints.power_dialer.getNextNumber(sheet_id,{
            ...row_no ? {
              row_no: row_no
            } : {}
          })
          if(_.isEmpty(data.result)) {
            this.$store.state.power_dialer.sheet_id=''
            this.appNotify({
              message: 'No number available for Auto call',
              type: 'info'
            })
            return;
          }
          power_dialer_contact = {
            ...data,
            is_specific_call: !!row_no
          }
          this.$store.state.sip.number = number_formater(data.data.number)
        }
        if (!/([0-9,*,#,+]\d)/.test(this.$store.state.sip.number)) throw new Error(`Invalid Number`)
        number = this.$store.state.sip.number.trim().replace(/ /g, "");
        if (!number)  throw new Error(`You Should write a number`)
        const active_call = this.sipsessions?.find?.(i=>$fn.getNumberFormated(i.number)==$fn.getNumberFormated(number))
        if(active_call) {
          this.$store.state.sip.number=''
          throw new Error(`You already in call with this number`)
        }
        if(!callee_id && !['listen', 'whisper', 'barge', 'test'].includes(call_type)){
          if($fn.validPhoneNumber(number) || $fn.validPhoneNumber(number, this.getCurrentUser.country)) number = $fn.getNumberFormated(number, this.getCurrentUser.country,'','',true)
          else throw new Error(`Invalid Number`)
        }
        console.log(number)
        if(!caller_id && !(['listen', 'whisper', 'barge', 'test'].includes(call_type) || ['user', 'team', 'call_queue', 'ivr'].includes(callee_type))) throw new Error(`Please Assign CallerID for a call or contact administrator`)
        this.beforeCallInitialize()
        const uid = $fn.makeid(5);
        const uuid = $fn.makeid(5);
        if(event?.extraHeader) extraHeaders.push(event?.extraHeader)
        this.$store.state.sip.phone.dial(number, {
          uid,
          uuid,
          power_dialer_contact,
          callee_id: event.callee_id || callee_id,
          type: event.callee_type || callee_type,
          internal_call: !!callee_id,
          call_type,
          caller_id: caller_id,
          caller_id_label: this.$store.state.common.numbers?.find?.(i=>i.did==caller_id)?.label ?? '', 
        },extraHeaders);
        if(call_type=='normal') this.$store.state.sip.last_call_number=number
        this.$store.state.sip.number = "";
        this.$store.state.calls.active_call=uuid
        this.$store.state.global_conditions.tab='video-call'
      } catch (ex) {
        if(event.call_type=='power_dialer') this.$store.state.power_dialer.sheet_id=''
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger'
        })
      }
    },
    hangUpAllCalls() {
      for (let i = 0; i < this.sipsessions.length; i++) {
        this.sipsessions[i].hangup();
      }
      this.$store.state.calls.videoCalls = [];
    },
    holdActiveSession(){
      if(this.$store.state.sip.phone && this.$store.state.sip.phone.activeSession && typeof this.$store.state.sip.phone.activeSession.hold == 'function'){
        this.$store.state.sip.phone.activeSession.hold()
      }
    },
    smsEvent(message){
      this.$store.state.sip.number=message.dialable || this.$store.state.sip.number
    },
    makeActiveCall(id){
      const video_call = this.$store.state.calls.videoCalls.find(item=>item.room==id)
      const sip_call = this.sipsessions.find(item=>item.uuid==id)
      for (let index = 0; index < this.$store.state.calls.videoCalls.length; index++) {
        this.$store.state.calls.videoCalls[index].isPlay=false;
      }
      if(this.$store.state.sip.phone && this.$store.state.sip.phone.activeSession && typeof this.$store.state.sip.phone.activeSession.hold == 'function'){
        this.$store.state.sip.phone.activeSession.hold()
      }
      if(video_call){
        video_call.isPlay = true;
        video_call.isShown = true;
      }
      if(sip_call){
        this.$store.state.sip.phone.markSessionActive(sip_call.uuid)
      }
    },
    //->sip
    onIncomingSipCall(event){
      let vm = this
      this.getCallInfo(event?.session)
      .then((info)=>{
        vm.appNotify({
          toaster: false,
          sound: false,
          title: `${info.name} ${info.sub_info}`,
          icon: info.image,
          message: `Incoming Call`,
          notify_type: 'alert',
          position: 'top-right',
        })
        if (!window.document.hasFocus() && process.env.IS_ELECTRON) {
          window.electron.send(electron_events.INCOMING_CALL,{
            id: event.session.uuid,
            from: `${info.from_name} ${info.from_number}`,
            to: `${info.to_name} ${info.to_number}`,
          })
        }
      })
    },
    getcallerInfo(number) {
      const info = {
        from_name: '',
        from_number: '',
        to_name: '',
        to_number: '',
        image: '',
        call_type: '',
      }
      let extra_data = this.session?.extra_data ?? {}
      info.call_type=extra_data?.call_type ?? ''
      info.from_name = this.$store.getters.getCurrentUser?.display_name
      info.from_number = this.$store.getters.getCurrentUser?.extn
      info.to_name = this.$store.getters.getVoipUsersAlisesDisplayName[number]
      info.to_number = number
      info.image = this.$store.getters.getVoipUsersAlisesImages[number]
      return info;
    },
    
    onMissedCall(event){
      // console.log('onMissedCall')
      // let vm = this
      if(process.env.IS_ELECTRON){
        window.electron.send(electron_events.INCOMING_CALL_HIDE,{id:event.session.uuid})
      }
    },
    onRejectCall(event){
      // console.log('onRejectCall')
      if(process.env.IS_ELECTRON){
        window.electron.send(electron_events.INCOMING_CALL_HIDE,{id:event.session.uuid})
      }
    },
    onAcceptedCall(event){
      // console.log('onAcceptedCall')
      if(process.env.IS_ELECTRON){
        window.electron.send(electron_events.INCOMING_CALL_HIDE,{id:event.session.uuid})
      }
    },
    onHangup(){ // { session }
      // let vm = this
      // const hangup_sound = document.getElementById('hangup_sound')
      // hangup_sound.play()
      // if(this.GET_LOCAL_SETTING_CALLS.feedback) {
      //   this.getCallInfo(session)
      //   .then((info)=>{
      //     console.log(info)
      //     // TODO - show feedback modal
      //     vm.$modal.show('CallFeedback',{
      //       info: info
      //     })
      //   })
      // }
    },
    onSessionTerminate(session){
      let vm = this
      try {
        const hangup_sound = document.getElementById('hangup_sound')
        hangup_sound.play()
      } finally {
        if(this.GET_LOCAL_SETTING_CALLS.feedback) {
          this.getCallInfo(session)
          .then((info)=>{
            // TODO - show feedback modal
            // console.log(':::::::::::info:::::::::::',info)
            if(!info.test_call && !info.call_type) {
              vm.$modal.show('CallFeedback',{
                info: info
              })
            }
          })
        }
      }
    },
    onDial(){
      const dial_sound = document.getElementById('dial_sound')
      dial_sound.play()
    },
    onSipCallRejectedByModal(message){
      LOGGER.log('onSipCallRejectedByModal',message)
      const { id } = message || {}
      const call = this.allIncomingCalls.find(i=>id==(i.uuid || id.room))
      if(call){
        if(call.is_sip) {
          call.hangup();
        }
        if(call) {
          call?.reject?.()
        }
      }
    },
    onSipCallAcceptedByModal(message){
      LOGGER.log('onSipCallAcceptedByModal',message)
      const { id } = message || {}
      const call = this.allIncomingCalls.find(i=>id==(i.uuid || id.room))
      if(call){
        if(call.is_sip) {
          if(!this.isSwitchAbleState) return;
          call.accepts();
          this.$store.state.calls.active_call=call.uuid
          this.$store.state.global_conditions.tab='video-call'
        }
        if(call) {
          call?.accept?.()
        }
      }
    },
    onUpdateCallInfo(payload){
      const { bridgeuniqueid, uniqueid, linkedid, channel, number } = payload
      let index = this.$store.state.sip.phone.sessions.findIndex(i=>i.extra_data.link_id==linkedid)
      if(index==-1) {
        index = this.$store.state.sip.phone.sessions.findIndex(i=>number_formater(i.number)==number_formater(number))
      }
      if(index>-1) {
        this.$store.state.sip.phone.sessions[index].extra_data.uniqe_id=uniqueid
        this.$store.state.sip.phone.sessions[index].extra_data.link_id=linkedid
        this.$store.state.sip.phone.sessions[index].extra_data.channel=channel
        this.$store.state.sip.phone.sessions[index].extra_data.bridgeuniqueid=bridgeuniqueid
      }
    },
    onUpdateLogs(payload){
      const index = this.$store.state.sip.phone.sessions.findIndex(i=>i.extra_data.uniqe_id==payload.uniqueid)
      if(index>-1) this.$store.state.sip.phone.sessions[index].log=payload
    },
    onUpdateCallHold(payload){
      const { bridgeuniqueid } = payload
      let index = this.$store.state.sip.phone.sessions.findIndex(i=>i.extra_data.bridgeuniqueid==bridgeuniqueid)
      if(index>-1) {
        this.$store.state.sip.phone.sessions[index].other_person_hold=true
      }
    },
    onNextCallPowerDialer(){
      if(this.$store.state.power_dialer.auto) {
        this.dial({ call_type: 'power_dialer' })
      }
    },
  },
  created(){
    AMI_SOCKET?.on?.(AMI_EVENTS.update_call_info,this.onUpdateCallInfo)
    AMI_SOCKET?.on?.(AMI_EVENTS.update_logs,this.onUpdateLogs)
    AMI_SOCKET?.on?.(AMI_EVENTS.update_call_hold,this.onUpdateCallHold)
    AMI_SOCKET.on(AMI_EVENTS.CANCEL,this.CANCEL)
    AMI_SOCKET.on(AMI_EVENTS.BYE,this.BYE)
    AMI_SOCKET.on(AMI_EVENTS.INVITE,this.INVITE)
    AMI_SOCKET.on(AMI_EVENTS.ACTIVE_GROUP_CALLS,this.ACTIVE_GROUP_CALLS)
    AMI_SOCKET.on(AMI_EVENTS.NOT_ACTIVE_GROUP_CALLS,this.NOT_ACTIVE_GROUP_CALLS)
    AMI_SOCKET.on(AMI_EVENTS.ACCEPTED,this.ACCEPTED)
    MOBILE_APP?.on?.(AMI_EVENTS.CANCEL,this.CANCEL)
    MOBILE_APP?.on?.(AMI_EVENTS.BYE,this.BYE)
    MOBILE_APP?.on?.(AMI_EVENTS.INVITE,this.INVITE)
    MOBILE_APP?.on?.(AMI_EVENTS.ACTIVE_GROUP_CALLS,this.ACTIVE_GROUP_CALLS)
    MOBILE_APP?.on?.(AMI_EVENTS.NOT_ACTIVE_GROUP_CALLS,this.NOT_ACTIVE_GROUP_CALLS)
    MOBILE_APP?.on?.(AMI_EVENTS.ACCEPTED,this.ACCEPTED)
    // centrifugo.calls.on(centrifugo.calls.events.CANCEL,this.CANCEL)
    // centrifugo.calls.on(centrifugo.calls.events.BYE,this.BYE)
    // centrifugo.calls.on(centrifugo.calls.events.INVITE,this.INVITE)
    // centrifugo.calls.on(centrifugo.calls.events.ACTIVE_GROUP_CALLS,this.ACTIVE_GROUP_CALLS)
    // centrifugo.calls.on(centrifugo.calls.events.NOT_ACTIVE_GROUP_CALLS,this.NOT_ACTIVE_GROUP_CALLS)
    if(!this.$store.state.global_conditions.is_mobile_setting) {
      phone.on(phone.events.incoming_call,this.onIncomingSipCall)
      phone.onmissed = this.onMissedCall
      phone.onaccept = this.onAcceptedCall
      phone.onreject = this.onRejectCall
      phone.onhangup = this.onHangup
      phone.ondial = this.onDial
      phone.onsessionterminate = this.onSessionTerminate
      phone.onnextcallpowerdialer = this.onNextCallPowerDialer
    }
    this.$root.$on(events.join_room_now,this.joinRoomNow);
    this.$root.$on(events.video_call,this.call);
    this.$root.$on(events.call_ended,this.onCallEnded);
    this.$root.$on(events.join_call,this.joinCall);
    this.$root.$on(events.audio_call, this.dial);
    this.$root.$on(events.send_sms, this.smsEvent);
    if(process.env.IS_ELECTRON){
      window.electron.receive(electron_events.recieve.CALL_REJECTED,this.onSipCallRejectedByModal)
      window.electron.receive(electron_events.recieve.CALL_ACCEPTED,this.onSipCallAcceptedByModal)
    }
  },
  mounted(){
    this.sipLoad()
    this.mediaDevicesSettingSink()
  },
  beforeDestroy(){
    AMI_SOCKET?.off?.(AMI_EVENTS.update_call_info,this.onUpdateCallInfo)
    AMI_SOCKET?.off?.(AMI_EVENTS.update_logs,this.onUpdateLogs)
    AMI_SOCKET?.off?.(AMI_EVENTS.update_call_hold,this.onUpdateCallHold)
    AMI_SOCKET.off(AMI_EVENTS.CANCEL,this.CANCEL)
    AMI_SOCKET.off(AMI_EVENTS.BYE,this.BYE)
    AMI_SOCKET.off(AMI_EVENTS.INVITE,this.INVITE)
    AMI_SOCKET.off(AMI_EVENTS.ACTIVE_GROUP_CALLS,this.ACTIVE_GROUP_CALLS)
    AMI_SOCKET.off(AMI_EVENTS.NOT_ACTIVE_GROUP_CALLS,this.NOT_ACTIVE_GROUP_CALLS)
    AMI_SOCKET.off(AMI_EVENTS.ACCEPTED,this.ACCEPTED)
    MOBILE_APP?.off?.(AMI_EVENTS.CANCEL,this.CANCEL)
    MOBILE_APP?.off?.(AMI_EVENTS.BYE,this.BYE)
    MOBILE_APP?.off?.(AMI_EVENTS.INVITE,this.INVITE)
    MOBILE_APP?.off?.(AMI_EVENTS.ACTIVE_GROUP_CALLS,this.ACTIVE_GROUP_CALLS)
    MOBILE_APP?.off?.(AMI_EVENTS.NOT_ACTIVE_GROUP_CALLS,this.NOT_ACTIVE_GROUP_CALLS)
    MOBILE_APP?.off?.(AMI_EVENTS.ACCEPTED,this.ACCEPTED)
    // centrifugo.calls.off(centrifugo.calls.events.CANCEL,this.CANCEL)
    // centrifugo.calls.off(centrifugo.calls.events.BYE,this.BYE)
    // centrifugo.calls.off(centrifugo.calls.events.INVITE,this.INVITE)
    // centrifugo.calls.off(centrifugo.calls.events.ACTIVE_GROUP_CALLS,this.ACTIVE_GROUP_CALLS)
    // centrifugo.calls.off(centrifugo.calls.events.NOT_ACTIVE_GROUP_CALLS,this.NOT_ACTIVE_GROUP_CALLS)
    if(!this.$store.state.global_conditions.is_mobile_setting) {
      phone.off(phone.events.incoming_call,this.onIncomingSipCall)
    }
    this.$root.$off(events.join_room_now,this.joinRoomNow);
    this.$root.$off(events.video_call,this.call);
    this.$root.$off(events.call_ended,this.onCallEnded);
    this.$root.$off(events.join_call,this.joinCall);
    this.$root.$off(events.audio_call, this.dial);
    this.$root.$off(events.send_sms, this.smsEvent);
  },
}
</script>

<style>

</style>