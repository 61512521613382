var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "center-zoomIn-transition v2 SmallAddModal",
    attrs: {
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit s1"
  }, [_c('form', {
    staticClass: "dialer-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.createGroup();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header d-block pb-0"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Add Group")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v("Enter the name of the new Public holiday Group below. ")])]), _c('div', {
    staticClass: "md-mar-top"
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.create_group.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.create_group.error_message))]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mt-20px"
  }, [_c('label', [_vm._v("Country")]), _c('b-form-select', {
    attrs: {
      "disabled": _vm.api.create_group.send,
      "options": _vm.countries
    },
    on: {
      "change": function ($event) {
        _vm.forms.create_group.timezone = _vm.timezones[0] || '';
      }
    },
    model: {
      value: _vm.forms.create_group.country,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_group, "country", $$v);
      },
      expression: "forms.create_group.country"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "disabled": "",
      "value": ""
    }
  }, [_vm._v("Select country")])], 1), _vm.$v.forms.create_group.country.$error ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.create_group.country.required ? _c('span', [_vm._v("* Country is required")]) : _vm._e()]) : _vm._e()], 1), _vm.timezones.length > 1 ? _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mt-20px"
  }, [_c('label', [_vm._v("TimeZone")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.create_group.timezone,
      expression: "forms.create_group.timezone"
    }],
    staticClass: "form-control custom-select",
    attrs: {
      "disabled": _vm.api.create_group.send
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.forms.create_group, "timezone", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(_vm.timezones, function (timeZone) {
    return _c('option', {
      key: timeZone
    }, [_vm._v(_vm._s(timeZone))]);
  }), 0), _vm.$v.forms.create_group.timezone.$error || _vm.api.create_group.validation_errors.timezone ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.create_group.timezone.required ? _c('span', [_vm._v("* TimeZone is required")]) : _vm._e(), _vm._l(_vm.api.create_group.validation_errors.timezone, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "w-100 mt-4"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "disabled": _vm.api.create_group.send
    }
  }, [_vm.api.create_group.send ? _c('vb-spinner') : [_vm._v("Create Group")]], 2)])], 1)])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }