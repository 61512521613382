<template>
  <modal 
    :name="modalName" 
    class="dialer_animation right_side_popup addExtension-voicemailModal addRemove_userModal m-w-700 EditOutOfHoursDayModal bigSquareCheckbox min-width-50percent-modal" 
    :scrollable="true" 
    height="auto" 
    @before-open="onBeforeOpen($event)" 
    @before-close="onBeforeClose()" 
  >
    <section> 
      <div class="dialer-edit-header d-block">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h2 v-if="data.day" class="dialer-settings-title newer mb-0">{{data.day | filterDayText}} business hours</h2>
          </div>
          <div class="dialer-edit-actions">
            <button class="newDoneButton" type="button" @click="$modal.hide(modalName)">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <div class="vm--modal-text">Configure your business hours settings for a specific day.</div>
      </div>
      <div class="latestGreyBox-9-9-2023">
        <div v-if="data.day" class="latestGreyBox-heading-main">Select for {{data.day | filterDayText}} if you are...</div>
        <div class="whiteBoxWithBorderInsideGreyBox withOutPadding mt-12px">
          <b-form-group class="mb-0" style="position: relative;pointer-events:auto;">
            <div class="w-100">
              <!-- availble -->
              <b-form-radio 
                @change="updateSchedule({ type: 'availble' })" 
                ref="available_radio"
                class="whiteBoxWithBorderInsideGreyBox-row withHover checkBoxOrRadioInside position-relative makeCheckBoxesSquare" 
                :class="data.type==='availble'?'active':''" 
                :checked="data.type" 
                value="availble"
                :disabled="api.update_schedule.send"
              >
                <div class="w-100 dialer-call-option-c withRadio" :class="{'active': data.type === 'availble' }">
                  <div class="latestGreyBox-heading-main">Available all day</div>
                </div>
              </b-form-radio>
              <!-- custom -->
              <div class="position-relative" @click="$modal.show(`${_uid}-SelectAvailableTimeSlot`,{ duration: data.duration })">
                <b-form-radio 
                  class="whiteBoxWithBorderInsideGreyBox-row withHover checkBoxOrRadioInside position-relative makeCheckBoxesSquare" 
                  ref="custom_radio"
                  :class="data.type==='custom'?'active':''" 
                  :checked="data.type" 
                  value="custom"
                  :disabled="api.update_schedule.send"
                >
                  <div class="w-100 dialer-call-option-c withRadio" :class="{'active': data.type === 'custom' }">
                    <div class="latestGreyBox-heading-main">
                      Available between 
                      <span class="text-decoration-underline d-flex flex-column" v-if="data.duration.length">
                        <span v-for="(slot, index) in data.duration" :key="index">
                          {{ slot.start | twentyFourHourToAmPmFormat}} - {{ slot.end | twentyFourHourToAmPmFormat}}
                        </span>
                      </span>
                    </div>
                  </div>
                </b-form-radio>
              </div>
              <!-- unavailable -->
              <b-form-radio 
                @change="updateSchedule({ type: 'unavailable' })" 
                ref="unavailable_radio"
                class="whiteBoxWithBorderInsideGreyBox-row withHover checkBoxOrRadioInside position-relative makeCheckBoxesSquare b-b" 
                :class="data.type==='unavailable'?'active':''" 
                :checked="data.type" 
                value="unavailable"
                :disabled="api.update_schedule.send"
              >
                <div class="w-100 dialer-call-option-c withRadio" :class="{'active': data.type === 'unavailable' }">
                  <div class="latestGreyBox-heading-main">Unavailable all day</div>
                </div>
              </b-form-radio>
            </div>
          </b-form-group>
        </div>
        <template v-if="['unavailable','custom'].includes(data.type)">
          <div v-if="forwardSetting.is_default" class="d-flex flex-column w-100 mt-20px">
            <div class="latestGreyBox-heading-main mb-0">What happens to calls after business hours ?</div>
            <template v-if="defaultForwardSetting.forward_note == 'none'">
              <div class="latestGreyBox-descriptionText-18px">Keep ringing</div>
              <div class="latestGreyBox-descriptionText-14px">Call forwarding is off</div>
            </template>
            <template v-if="defaultForwardSetting.forward_note == 'voicemail'">
              <div class="latestGreyBox-descriptionText-18px">Ask to leave a voicemail</div>
              <div class="latestGreyBox-descriptionText-14px">Call will be forward to voicemail</div>
            </template>
            <template v-if="defaultForwardSetting.forward_note == 'extension'">
              <div class="latestGreyBox-descriptionText-18px">Forward to a user, team or IVR menu</div>
              <div class="latestGreyBox-descriptionText-14px">
                Call will be forward to
                <span class="color-primary cursor_pointer" :class="{'text-underline': !defaultForwardSetting.extension_name}">{{defaultForwardSetting.extension_name || 'Select extension'}}</span>
              </div>
            </template>
            <template v-if="defaultForwardSetting.forward_note == 'number'">
              <div class="latestGreyBox-descriptionText-18px">Forward to another number</div>
              <div class="latestGreyBox-descriptionText-14px">
                Call will be forward to 
                <span class="color-primary cursor_pointer" :class="{'text-underline': !defaultForwardSetting.forward_number}">{{defaultForwardSetting.forward_number || 'Select number'}}</span>
              </div>
            </template>
          </div>
          <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start min-height-unset">
              <div class="d-flex align-items-center justify-content-between w-100">
                <div v-if="data.day" class="latestGreyBox-heading-main">Customise business hour behavior for {{data.day | filterDayText}}</div>
                <b-form-checkbox 
                  :disabled="api.update_schedule.send" 
                  class="newerSwitch ml-3" 
                  ref="default-toggle" 
                  switch 
                  :checked="!forwardSetting.is_default" 
                  @change="updateSchedule({ default: !$event })"
                />
              </div>
              <div v-if="!forwardSetting.is_default" class="d-flex align-items-center justify-content-between w-100 mt-5">
                <div class="d-flex flex-column">
                  <div class="latestGreyBox-heading-main mb-0">What happens to calls after business hours ?</div>
                  <template v-if="forwardSetting.forward_note == 'none'">
                    <div class="latestGreyBox-descriptionText-18px">Keep ringing</div>
                    <div class="latestGreyBox-descriptionText-14px">Call forwarding is off</div>
                  </template>
                  <template v-if="forwardSetting.forward_note == 'voicemail'">
                    <div class="latestGreyBox-descriptionText-18px">Ask to leave a voicemail</div>
                    <div class="latestGreyBox-descriptionText-14px">Call will be forward to voicemail</div>
                  </template>
                  <template v-if="forwardSetting.forward_note == 'extension'">
                    <div class="latestGreyBox-descriptionText-18px">Forward to a user, team or IVR menu</div>
                    <div class="latestGreyBox-descriptionText-14px">
                      Call will be forward to
                      <span @click="api.update_schedule.send ? '' : $modal.show(`${_uid}-ScheduleAssignModal`, { account: forwardSetting.forward_number, type: 'setting', })" class="color-primary cursor_pointer text-decoration-underline" :class="{'text-underline': !forwardSetting.extension_name}">{{forwardSetting.extension_name || 'Select extension'}}</span>
                    </div>
                  </template>
                  <template v-if="forwardSetting.forward_note == 'number'">
                    <div class="latestGreyBox-descriptionText-18px">Forward to another number</div>
                    <div class="latestGreyBox-descriptionText-14px">
                      Call will be forward to 
                      <span @click="api.update_schedule.send ? '' : $modal.show(`${_uid}-CallForwardingNumberInputModal`)" class="color-primary cursor_pointer text-decoration-underline" :class="{'text-underline': !forwardSetting.forward_number}">{{forwardSetting.forward_number || 'Select number'}}</span>
                    </div>
                  </template>
                </div>
                <button 
                  class="newButton" 
                  @click.stop="api.update_schedule.send ? '' : $modal.show(`${_uid}-CallForwardSettingModal`,{ 
                    account: account,
                    accountcode: account,
                    setting: forwardSetting, 
                    is_ringing_show: selectedSchedule.type=='availble', 
                    is_sec_show: false , 
                    day: data.day 
                  })"
                >
                    <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                    <span class="newButton-textPart">Change</span>
                  </button>
              </div>
            </div>
          </div>
        </template>
      </div>
    </section>
    <SelectAvailableTimeSlot 
      :modalName="`${_uid}-SelectAvailableTimeSlot`" 
      @updated="data.duration=$event.slots;updateSchedule({ duration: $event.slots, type: 'custom' })" 
      @before-close="$refs.available_radio.localChecked=data.type;$refs.unavailable_radio.localChecked=data.type;$refs.custom_radio.localChecked=data.type;"
    />
    <CallForwardSettingModal :modalName="`${_uid}-CallForwardSettingModal`" @updated="updateSchedule($event)" />
    <ScheduleAssignModal 
      :modalName="`${_uid}-ScheduleAssignModal`" 
      :account="account"
      @interface="updateSchedule({ 
        extension_name: $event.accountname, 
        forward_number: $event.account, 
      })" 
    />
    <CallForwardingNumberInputModal :modalName="`${_uid}-CallForwardingNumberInputModal`" @number="updateSchedule({ forward_number: $event.ber })" />
  </modal>
</template>

<script>
import { VOIP_API } from '@/utils';
import SelectAvailableTimeSlot from "./SelectAvailableTimeSlot.vue";
import CallForwardSettingModal from "./CallForwardSettingModal.vue";
import ScheduleAssignModal from "./ScheduleAssignModal.vue";
import CallForwardingNumberInputModal from "./CallForwardingNumberInputModal.vue";
import moment from 'moment-timezone';
export default {
  name: 'EditOutOfHoursDayModal',
  components: {
    SelectAvailableTimeSlot,
    CallForwardSettingModal,
    ScheduleAssignModal,
    CallForwardingNumberInputModal,
  },
  inject: [
    'appNotify',
  ],
  props: {
    modalName: {
      type: String,
      default: "EditOutOfHoursDayModal",
    },
    account: {
      type: String,
      default: '',
    },
    schedules: {
      type: Object,
      default: ()=>({}),
    },
    defaultForwardSetting: {
      type: Object,
      default: ()=>({})
    }
  },
  data() {
    return {
      data: this.$helperFunction.stateInstance({
        data: {
          day: '',
          duration: [],
          type: '',
        }
      }),
      api: {
        update_schedule: this.$helperFunction.apiInstance({ source: true }),
      },
    };
  },
  computed: {
    selectedSchedule(){
      return {
        day: this.data.day,
        forward_name: this.schedules[this.data.day]?.forward_name ?? "",
        forward: this.schedules[this.data.day]?.forward_type ?? "none",
        duration: this.schedules[this.data.day]?.duration ?? [],
        start: this.schedules[this.data.day]?.start_time ?? "",
        end: this.schedules[this.data.day]?.end_time ?? "",
        type: this.schedules[this.data.day]?.type ?? "availble",
        ext_type: "",
        forward_account: this.schedules[this.data.day]?.forward_account ?? "",
        extn: this.schedules[this.data.day]?.extn ?? "",
        setting_type: this.schedules[this.data.day]?.setting_type ?? "",
      }
    },
    forwardSetting(){
      return {
        extension_name: this.selectedSchedule.forward_name ?? '',
        forward_note: this.selectedSchedule.forward ?? 'none',
        forward_number: this.selectedSchedule.forward_account ?? '',
        forward_setting: this.selectedSchedule.forward_account ?? '',
        number: this.selectedSchedule.forward_account ?? '',
        timeout: "0",
        is_default: this.selectedSchedule.setting_type == 'default'
      }
    },
  },
  watch: {
    "forwardSetting.is_default"(value){
      if(this.$refs['default-toggle']) this.$refs['default-toggle'].localChecked=!value
    },
  },
  filters: {
    twentyFourHourToAmPmFormat(time){ return moment(`2023-12-12 ${time}`,'YYYY-MM-DD HH:mm:ss').format('hh:mm a') },
    filterDayText(day){
      switch (day) {
        case 'mon':
          return 'Monday'
        case 'tue':
          return 'Tuesday'
        case 'wed':
          return 'Wednesday'
        case 'thu':
          return 'Thursday'
        case 'fri':
          return 'Friday'
        case 'sat':
          return 'Saturday'
        case 'sun':
          return 'Sunday'
        default:
          return '';
      }
    },
  },
  methods: {
    async onBeforeOpen(event){
      this.data.day=event?.params?.day ?? ''
      this.data.id=event?.params?.id ?? ''
      await this.$nextTick()
      if(this.selectedSchedule.type=='custom') this.data.duration=this.selectedSchedule.duration
      this.data.type = this.selectedSchedule.type
    },
    onBeforeClose(){
      this.data?.reset?.()
      this.api.update_schedule.reset()
    },
    async updateSchedule(setting={}) {
      if(this.api.update_schedule.send) return;
      const toaster_value = this.appNotify({
        message: "Schedule setting Saving...",
        type: "success",
        duration: 60 * 1000,
      });
      const type = setting?.type ?? this.selectedSchedule.type
      const forward = setting?.forward_note=='off' ? 'none' : setting?.forward_note ?? this.selectedSchedule.forward
      const update_forward = ['custom','unavailable'].includes(type) && forward=='none' ? 'voicemail' : ['availble'].includes(setting?.type) && forward!='none' ? 'none' : forward
      const isdefault = ['custom','unavailable'].includes(type) && forward=='none' ? true : setting?.default ?? this.forwardSetting.is_default
      const prev_type = this.data.type
      try {
        this.api.update_schedule.send = true;
        this.data.type = ''
        const { data } = await VOIP_API.endpoints.users.udpateschedule({
          account: this.account,
          day: this.data.day,
          extn: setting?.forward_note ?? this.selectedSchedule.extn,
          forward: update_forward,
          forward_account: setting?.forward_number ?? this.selectedSchedule.forward_account,
          forward_name: setting?.extension_name ?? this.selectedSchedule.forward_name,
          ext_type: update_forward,
          duration: type=='custom' ? setting?.duration ?? this.selectedSchedule.duration : [],
          // start: setting?.start?.data ?? (this.selectedSchedule.start ? {HH:this.selectedSchedule.start?.split?.(':')?.[0] ?? '',mm:this.selectedSchedule.start?.split?.(':')?.[1] ?? ''} : ''),
          // end: setting?.end?.data ?? (this.selectedSchedule.end ? {HH:this.selectedSchedule.end?.split?.(':')?.[0] ?? '',mm:this.selectedSchedule.end?.split?.(':')?.[1] ?? ''} : ''),
          type: type,
          flag: isdefault ? 'default' : 'custom'
        })
        if(['availble','unavailable'].includes(type)) this.data.duration=[]
        this.appNotify({
          message: "Successfully Updated!",
          type: "success",
        })
        this.$emit('updated',data?.schedules);
        this.data.type = type
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
        this.data.type = prev_type
      } finally {
        toaster_value.close();
        this.api.update_schedule.send = false;
        if(this.$refs['default-toggle']) this.$refs['default-toggle'].localChecked=!this.forwardSetting.is_default
      }
    },
  },
}
</script>

<style>

</style>