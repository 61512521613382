<template>
  <div>
    <modal
      class="dialer_animation right_side_popup addExtension-voicemailModal addRemove_userModal m-w-700 EditOutOfHoursDayModal bigSquareCheckbox min-width-50percent-modal"
      width="40%"
      height="auto"
      :scrollable="true"
      :name="modalName"
      @before-open="onBeforeOpen($event)"
      @before-close="onBeforeClose()"
    >
      <section> 
        <div class="dialer-edit-header d-block">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h2 class="dialer-settings-title newer mb-0">Update Holiday</h2>
            </div>
            <div class="dialer-edit-actions">
              <button class="newDoneButton" type="button" :disabled="api.update_public_holiday.send || api.remove_public_holiday.send" @click="updatePublicHoliday()">
                <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                <span class="newDoneButton-textPart">Done</span>
              </button>
            </div>
          </div>
          <div class="vm--modal-text">Configure your business hours settings for a specific day.</div>
        </div>
        <div class="latestGreyBox-9-9-2023">
          <div v-if="conditions.name" class="whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionLeftAligned">
            <label>Name</label>
            <input :disabled="api.update_public_holiday.send" class="w-100" type="text" v-model="forms.update_public_holiday.name" :maxlength="$v.forms.update_public_holiday.name.$params.maxLength.max" />
            <p v-if="$v.forms.update_public_holiday.name.$error || api.update_public_holiday.validation_errors.holiday_name" class="text text-danger animated bounceIntop">
              <span v-if="!$v.forms.update_public_holiday.name.required">* Name is required</span>
              <span v-else-if="!$v.forms.update_public_holiday.name.minLength">* Name is should be minimum {{$v.forms.update_public_holiday.name.$params.minLength.min}} character</span>
              <span v-else-if="!$v.forms.update_public_holiday.name.maxLength">* Name is can be maximum {{$v.forms.update_public_holiday.name.$params.maxLength.max}} character</span>
              <span v-else-if="!$v.forms.update_public_holiday.name.valid">* Name can be number and alphabet</span>
              <span v-for="(em,i) in api.update_public_holiday.validation_errors.holiday_name" :key="i">* {{ em }}</span>
            </p>
          </div>
          <div v-if="conditions.date" class="whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionLeftAligned">
            <label>Select date</label>
            <vue-ctk-date-time-picker 
              :no-label="true"
              :no-header="true"
              v-model="forms.update_public_holiday.date" 
              v-bind="ctkDateTimePickerOptions" 
              format="DD MMM YYYY" 
              formatted="DD MMM YYYY" 
              outputFormat="DD MMM YYYY" 
              class="date-time-input" 
              onlyDate 
              :disabledDates="conditions.disable_dates"
            />
            <p v-if="$v.forms.update_public_holiday.date.$error || api.update_public_holiday.validation_errors.date" class="text text-danger animated bounceIntop">
              <span v-if="!$v.forms.update_public_holiday.date.required">* Date is required</span>
              <span v-for="(em,i) in api.update_public_holiday.validation_errors.date" :key="i">* {{ em }}</span>
            </p>
          </div>
          <div class="whiteBoxWithBorderInsideGreyBox withOutPadding mt-12px">
            <b-form-group class="mb-0" style="position: relative;pointer-events:auto;">
              <div class="w-100">
                <!-- unavailable -->
                <b-form-radio 
                  ref="unavailable-radio"
                  class="whiteBoxWithBorderInsideGreyBox-row withHover checkBoxOrRadioInside position-relative makeCheckBoxesSquare b-b" 
                  :class="{'active': forms.update_public_holiday.type=='unavailable' }"
                  :checked="forms.update_public_holiday.type"
                  value="unavailable"
                  @change="forms.update_public_holiday.type='unavailable'"
                  :disabled="api.update_public_holiday.send || api.remove_public_holiday.send"
                >
                  <div class="w-100 dialer-call-option-c withRadio" :class="{'active': forms.update_public_holiday.type=='unavailable' }">
                    <div class="latestGreyBox-heading-main">Unavailable all day</div>
                  </div>
                </b-form-radio>
                <!-- custom -->
                <div class="position-relative">
                  <b-form-radio 
                    ref="custom-radio"
                    class="whiteBoxWithBorderInsideGreyBox-row withHover checkBoxOrRadioInside position-relative makeCheckBoxesSquare" 
                    :class="{'active': forms.update_public_holiday.type=='custom' }"
                    :checked="forms.update_public_holiday.type"
                    value="custom"
                    @change="forms.update_public_holiday.type='custom'"
                    :disabled="api.update_public_holiday.send || api.remove_public_holiday.send"
                  >
                    <div class="w-100 dialer-call-option-c withRadio" :class="{'active': forms.update_public_holiday.type=='custom' }">
                      <div class="latestGreyBox-heading-main">
                        Unavailable between 
                        <span 
                          v-if="forms.update_public_holiday.type=='custom'"
                          class="text-decoration-underline" 
                          @click.stop="$modal.show(`${_uid}-TimeSelectionModal`,{ 
                            start: forms.update_public_holiday.start_time, 
                            end: forms.update_public_holiday.end_time 
                          })"
                        >
                          {{ (forms.update_public_holiday.start_time || '00:00:00') | twentyFourHourToAmPmFormat }} - {{ (forms.update_public_holiday.end_time || '00:00:00') | twentyFourHourToAmPmFormat }}
                        </span>
                      </div>
                    </div>
                  </b-form-radio>
                </div>
              </div>
              <p v-if="$v.forms.update_public_holiday.type.$error || api.update_public_holiday.validation_errors.type" class="text text-danger animated bounceIntop">
                <span v-if="!$v.forms.update_public_holiday.type.required">* Type is required</span>
                <span v-else-if="!$v.forms.update_public_holiday.type.valid">* Type is invalid</span>
                <span v-for="(em,i) in api.update_public_holiday.validation_errors.type" :key="i">* {{ em }}</span>
              </p>
            </b-form-group>
          </div>
          <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start min-height-unset">
              <div class="d-flex align-items-center justify-content-between w-100">
                <div class="d-flex flex-column">
                  <div class="latestGreyBox-heading-main mb-0">What happens to calls after business hours ?</div>
                  <template v-if="forms.update_public_holiday.forward_type=='voicemail'">
                    <div class="latestGreyBox-descriptionText-18px">Ask to leave a voicemail</div>
                    <div class="latestGreyBox-descriptionText-14px">Call will be forward to voicemail</div>
                  </template>
                  <template v-if="forms.update_public_holiday.forward_type=='extension'">
                    <div class="latestGreyBox-descriptionText-18px">Forward to a user, team or IVR menu</div>
                    <div class="latestGreyBox-descriptionText-14px">
                      Call will be forward to
                      <span v-if="forms.update_public_holiday.extension_name" class="color-primary cursor_pointer text-decoration-underline">{{forms.update_public_holiday.extension_name}}</span>
                      <span v-else-if="forms.update_public_holiday.forward_to" class="color-primary cursor_pointer text-decoration-underline"><Info :only_name="true" :id="forms.update_public_holiday.forward_to" /></span>
                    </div>
                  </template>
                  <template v-if="forms.update_public_holiday.forward_type=='number'">
                    <div class="latestGreyBox-descriptionText-18px">Forward to another number</div>
                    <div class="latestGreyBox-descriptionText-14px">
                      Call will be forward to 
                      <span class="color-primary cursor_pointer text-decoration-underline">{{forms.update_public_holiday.forward_to}}</span>
                    </div>
                  </template>
                </div>
                <button 
                  class="newButton" 
                  :disabled="api.update_public_holiday.send || api.remove_public_holiday.send"
                  @click.stop="$modal.show(`${_uid}-CallForwardSettingModal`,{ 
                    account: '', 
                    accountcode: '',
                    setting: {
                      forward_note: forms.update_public_holiday.forward_type,
                      forward_number: forms.update_public_holiday.forward_to,
                      extension_name: forms.update_public_holiday.extension_name,
                      number: forms.update_public_holiday.forward_to,
                    }, 
                    is_ringing_show: false, 
                    is_sec_show: false, 
                  })">
                    <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                    <span class="newButton-textPart">Change</span>
                  </button>
              </div>
            </div>
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023 mt-24px">
          <div class="whiteBoxWithBorderInsideGreyBox">
            <div class="whiteBoxWithBorderInsideGreyBox-row">
              <div class="d-flex flex-column">
                <div class="latestGreyBox-heading-main">Delete this Holiday?</div>
                <div class="latestGreyBox-descriptionText">
                  This Holiday will be deleted
                </div>
              </div>
              <button class="newDeleteButton" :disabled="api.remove_public_holiday.send" @click="removePublicHoliday()">
                <vb-icon icon="squared-delete-icon" height="38px" width="38px" />
                <span class="newDeleteButton-textPart d-flex align-items-center justify-content-center position-relative">
                  <vb-spinner v-if="api.remove_public_holiday.send" />
                  <template v-else>Delete</template>
                </span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </modal>
    <TimeSelectionModal 
      :modalName="`${_uid}-TimeSelectionModal`" 
      @updated="
        forms.update_public_holiday.start_time=`${$event.start.data.HH}:${$event.start.data.mm}:${'00'}`;
        forms.update_public_holiday.end_time=`${$event.end.data.HH}:${$event.end.data.mm}:${'00'}`;
      " 
    />
    <CallForwardSettingModal 
      :modalName="`${_uid}-CallForwardSettingModal`" 
      @updated="
        forms.update_public_holiday.forward_type=$event.forward_note;
        forms.update_public_holiday.forward_to=$event.forward_number;
        forms.update_public_holiday.extension_name=$event.extension_name;
      " 
    />
  </div>
</template>

<script>
import required from 'vuelidate/lib/validators/required'
import requiredIf from 'vuelidate/lib/validators/requiredIf'
import TimeSelectionModal from "../../TimeSelection.vue";
import CallForwardSettingModal from "../../CallForwardSettingModal.vue";
import Info from "../../../Lists/Info.vue";
import { ctkDateTimePicker } from '@/mixin';
import { VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';
import maxLength from 'vuelidate/lib/validators/maxLength';
import minLength from 'vuelidate/lib/validators/minLength';
import { helpers } from 'vuelidate/lib/validators';
import moment from 'moment-timezone';
export default {
  name: 'UpdatePublicHolidayModal',
  mixins: [
    ctkDateTimePicker
  ],
  components: {
    TimeSelectionModal,
    CallForwardSettingModal,
    Info,
  },
  inject: [
    'appNotify',
  ],
  props: {
    modalName: {
      type: String,
      default: 'UpdatePublicHoliday'
    },
  },
  data() {
    return {
      forms: {
        update_public_holiday: this.$helperFunction.formInstance({
          data: {
            name: '',
            date: '',
            type: 'unavailable', // unavailable, custom
            start_time: '',
            end_time: '',
            forward_type: 'voicemail', // voicemail, extension, number
            forward_to: '',
            extension_name: '',
          }
        }),
      },
      api: {
        update_public_holiday: this.$helperFunction.apiInstance({ validation_errors: true, }),
        remove_public_holiday: this.$helperFunction.apiInstance({  }),
      },
      data: {
        holiday: null,
        conditions: null,
      },
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getVoipUsersAlises',
      'getVoipIVRSAlises',
      'getVoipTeamsAlises'
    ]),
    conditions(){ return {
      name: !!this.data?.conditions?.name,
      date: !!this.data?.conditions?.date,
      disable_dates: this.data?.conditions?.disable_dates ?? [],
    } },
  },
  filters: {
    twentyFourHourToAmPmFormat(time){ return moment(`2023-12-12 ${time}`,'YYYY-MM-DD HH:mm:ss').format('hh:mm a') },
  },
  watch: {
    "forms.update_public_holiday.type": {
      immediate: true,
      handler(newValue){
        if(newValue!='custom') {
          this.forms.update_public_holiday.start_time=''
          this.forms.update_public_holiday.end_time=''
        } else if(!this.forms.update_public_holiday.start_time && !this.forms.update_public_holiday.end_time) {
          this.forms.update_public_holiday.start_time='09:00:00'
          this.forms.update_public_holiday.end_time='17:00:00'
        }
        if(this.$refs['custom-radio']) this.$refs['custom-radio'].localChecked=newValue
        if(this.$refs['unavailable-radio']) this.$refs['unavailable-radio'].localChecked=newValue
      }
    },
  },
  validations: {
    forms: {
      update_public_holiday: {
        name: { 
          required: required,
          minLength: minLength(3), 
          maxLength: maxLength(20),
          valid: helpers.regex('valid',/^[a-z0-9 ]*$/i),
        },
        date: { 
          required: required,
        },
        type: { 
          required: required,
          valid: (value) => ['unavailable','custom'].includes(value),
        },
        start_time: { 
          required: requiredIf(function (){return this.forms.update_public_holiday.type=='custom'}),
        },
        end_time: { 
          required: requiredIf(function (){return this.forms.update_public_holiday.type=='custom'}),
        },
        forward_type: { 
          required: required,
          valid: (value) => ['voicemail','extension','number'].includes(value),
        },
        forward_to: { 
          required: requiredIf(function (){return ['extension','number'].includes(this.forms.update_public_holiday.type)}),
        },
        extension_name: { 
          required: requiredIf(function (){return ['extension'].includes(this.forms.update_public_holiday.type)}),
        },
      },
    },
  },
  methods: {
    async onBeforeOpen(event) {
      this.data.holiday=event?.params?.holiday
      this.data.conditions=event?.params?.conditions
      await this.$nextTick()
      this.forms.update_public_holiday.name=this.data.holiday.holiday_name || ''
      this.forms.update_public_holiday.date=(this.data.holiday.years=='*'?moment():moment(`${this.data.holiday.years} Jan 01 00:00:00`,'YYYY MMM DD HH:mm:ss')).startOf('year').subtract(1,'day').add(this.data.holiday.year_days || 0 ,'days').format('DD MMM YYYY')
      this.forms.update_public_holiday.start_time=this.data.holiday.start_time || ''
      this.forms.update_public_holiday.end_time=this.data.holiday.end_time || ''
      this.forms.update_public_holiday.type=this.data.holiday.type || 'unavailable'
      this.forms.update_public_holiday.forward_type=['number','voicemail'].includes(this.data.holiday.extension_type) ? this.data.holiday.extension_type : 'extension', // voicemail, extension, number
      this.forms.update_public_holiday.forward_to=this.data.holiday.forward_to
      this.forms.update_public_holiday.extension_name='';
    },
    onBeforeClose() {
      this.$v.$reset()
      this.data.holiday=null
      this.data.conditions=null
      this.forms.update_public_holiday.reset()
      this.api.update_public_holiday.reset()
      this.api.remove_public_holiday.reset()
    },
    async updatePublicHoliday(){
      this.$v.forms.update_public_holiday.$touch()
      if(this.$v.forms.update_public_holiday.$invalid || this.api.update_public_holiday.send || this.api.remove_public_holiday.send) return;
      try {
        this.api.update_public_holiday.send=true
        this.api.update_public_holiday.validation_errors={}
        let forward_to = this.forms.update_public_holiday.forward_to
        let extension_type = this.forms.update_public_holiday.forward_type
        if(extension_type=='extension') {
          if(this.getVoipUsersAlises[forward_to]) extension_type='user'
          // else if(this.getVoipCallQueuesAlises[forward_acccountcode]) extension_type='user'
          else if(this.getVoipIVRSAlises[forward_to]) extension_type='ivr'
          else if(this.getVoipTeamsAlises[forward_to]) extension_type='ringgroup'
        } else if(extension_type=='voicemail') {
          forward_to=`${this.getCurrentUser.default_accountcode.substr(0,11)}1`
        }
        await VOIP_API.endpoints.holiday_schedule.updateRule(this.data.holiday.id,{
          scheduler_name_id: this.data.holiday.scheduler_name_id,
          accountcode: this.getCurrentUser.default_accountcode,
          forward_to: forward_to,
          year_days: this.forms.update_public_holiday.date,
          holiday_name: this.forms.update_public_holiday.name,
          months: null,
          years: null,
          month_days: null,
          week_days: null,
          start_time: this.forms.update_public_holiday.start_time,
          end_time: this.forms.update_public_holiday.end_time,
          schedulable_type: 'accountcode',
          extension_type: extension_type,
          type: this.forms.update_public_holiday.type,
          setting_type: 'custom',
        })
        this.$emit('updated')
        this.appNotify({
          message: 'Successfully Updated',
          type: 'success',
        })
        this.$modal.hide(this.modalName)
      } catch (ex) {
        this.api.update_public_holiday.validation_errors=ex.own_errors ?? {}
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.update_public_holiday.send=false
      }
    },
    async removePublicHoliday(){
      if(this.api.remove_public_holiday.send) return;
      try {
        const { confirm } = await this.$appConfirmation({
          title: "Delete Holiday?",
          message: `Are you sure you want to delete this Holiday.`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        })
        if(!confirm) return;
        this.api.remove_public_holiday.send=true
        await VOIP_API.endpoints.holiday_schedule.removeRule(this.data.holiday.id)
        this.appNotify({
          message: 'Successfully Deleted',
          type: 'success'
        })
        this.$emit('removed')
        this.$modal.hide(this.modalName)
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.remove_public_holiday.send=false
      }
    },
  },
}
</script>

<style>

</style>