<template>
  <div class="dialer-settings-main">
    <section class="dialer-settings-section GlobalSettings v2">
      <!-- <template v-if="getIsMobile">
        <div class="d-flex align-items-center mb-3 calc-title-width">
          <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" style="" @click="$emit('back')" />
          <h2 class="dialer-settings-title w-100 mb-0 text-center">Global settings</h2>
        </div>
        <p class="dialer-settings-intro text-center">
          <span>Global configurations affecting users and devices across the organization</span>
        </p>
      </template>
      <template v-else>
        <h2 class="dialer-settings-title newer"> 
          Global settings
          <b-icon icon="info-circle-fill" @click="$modal.show('KbInfoModal',{ kb: 'globalSettings' })" class="cursor_pointer infoKbIcon md-mar-left" />  
        </h2>
        <p class="dialer-settings-intro newerTextVersion">
          <span>Global configurations affecting users and devices across the organization</span>
        </p>
      </template> -->

      <div class="settingsFirstSection">
        <template v-if="getIsMobile">
          <div class="d-flex align-items-center mb-3 calc-title-width-button w-100">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" style="" @click="$emit('back')" />
            <h2 class="dialer-settings-title w-100 mb-0 text-center">Global settings</h2>
          </div>
        </template>
        <template v-else>
          <h2 class="dialer-settings-title mb-0 settingsFirstSection-left newer">
            Global settings
            <b-icon icon="info-circle-fill" @click="$modal.show('KbInfoModal',{ kb: 'globalSettings' })" class="cursor_pointer infoKbIcon md-mar-left" /> 
          </h2>
        </template>
      </div>
      <div class="settingsSecondSection">
        <span class="dialer-settings-intro newerTextVersion mt-0 mb-0">Global configurations affecting users and devices across the organization</span>
      </div>
      
      <div class="latestGreyBox-9-9-2023 mt-20px">

        <!-- company profile image section -->
        <div class="whiteBoxWithBorderInsideGreyBox GlobalSetingsSection-1">
          <div class="latestGreyBox-9-9-2023 mt-24px mb-32px">
            <!-- time and date -->
            <div class="d-flex align-items-center justify-content-end w-100 GlobalSetingsSection-1-1">
              <div class="verySmallBlackBorderedWhiteBox">
                <span class="d-flex align-items-center">
                  <vb-icon icon="latest-clockinsideGlobalSettings-icon" width="19.292px" height="19.292px" class="sm-mar-right" />
                  {{ current_date.time }}
                </span>
                <span class="d-flex align-items-center ml-60px">
                  <vb-icon icon="latest-calenderInsideGlobalSettings-icon" width="18.006px" height="17.617px" class="sm-mar-right" />
                  {{ current_date.date }}
                </span>
              </div>
            </div>
            <!-- image & timezone -->
            <div class="d-flex align-items-center juystify-content-between GlobalSetingsSection-1-2">
              <!-- image -->
              <div class="d-flex align-items-center flex-wrap w-100 mb-60px GlobalSetingsSection-1-2-1">
                <form class="dialer-form mr-32px" @submit.prevent="update">
                  <div class="profileImg-section-latest-container">
                    <div 
                      class="profileImg-section-latest" 
                      @click="$modal.show('GlobalSettingProfileImageUploader', { 
                        type: 'COMPANY', 
                        accountcode: getCurrentUser.default_accountcode, 
                        current_image: getCurrentUser.companyLogo 
                      })"
                    >
                      <img :src="getCurrentUser.companyLogo" @error="$event.target.src=local_filess.images.user_male" :alt="'Company Image'" />
                    </div>
                  </div>
                </form>
                <div class="d-flex flex-column">
                  <div>
                    <h1 class="mb-0">{{ `${getCurrentUser.company}` }}</h1>
                    <h4>{{` (${getCurrentUser.uid})`}}</h4>
                  </div>
                </div>
              </div>
              <!-- country and timezone section -->
              <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 mt-20px GlobalSetingsSection-1-2-2">
                <b-form-select 
                  :disabled="api.fetch_account_detail.send || api.update_time_zone.send" 
                  v-model="forms.update_time_zone.country" 
                  @change="forms.update_time_zone.time_zone=''" 
                  :options="countries"
                />
                <select 
                  :disabled="api.fetch_account_detail.send || api.update_time_zone.send" 
                  v-model="forms.update_time_zone.time_zone" 
                  @change="updateTimeZone()" 
                  class="form-control custom-select mt-20px"
                >
                  <option v-for="timeZone in timezones" :key="timeZone">{{ timeZone }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!-- BLF section -->
        <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
          <!-- blf -->
          <div class="whiteBoxWithBorderInsideGreyBox-row">
            <div class="d-flex flex-column">
              <div class="latestGreyBox-heading-main">Busy lamp field</div>
              <div class="latestGreyBox-descriptionText">Use the BLF button to effectively track a user's live status, allowing you to instantly determine their availability or current call status.</div>
            </div>
            <div
              v-b-tooltip :title="getUserPermissions.blf?'':'Your package does not support this feature'"
              class="w-fit-content"
              :class="{
                'for-disable' : !getUserPermissions.blf
              }"
            >
              <b-form-checkbox
                :disabled="api.fetch_account_detail.send || api.update_blf.send || !getUserPermissions.blf"
                class="newerSwitch ml-4"
                name="check-button"
                switch
                :checked="forms.update_blf.blf"
                @change="updateBLF();"
                ref="blf_switch"
              />
            </div>
          </div>
          <!-- status -->
          <div class="whiteBoxWithBorderInsideGreyBox-row">
            <div class="d-flex flex-column">
              <div class="latestGreyBox-heading-main">Status</div>
              <div class="latestGreyBox-descriptionText">Indicate your availability or current status, such as available, away, or busy.</div>
            </div>
            <div 
              v-b-tooltip :title="getUserPermissions.status?'':'Your package does not support this feature'"
              class="w-fit-content"
              :class="{
                'for-disable' : !getUserPermissions.status
              }"
            >
              <a class="newButton sm-mar-left" @click="!getUserPermissions.status ? '' : $modal.show('StatusesModal')">
                <vb-icon icon="squared-status-icon" height="38px" width="38px" />
                <span class="newButton-textPart">Status list</span>
              </a>
            </div>
          </div>
          <!-- tags -->
          <div class="whiteBoxWithBorderInsideGreyBox-row">
            <div class="d-flex flex-column">
              <div class="latestGreyBox-heading-main">Call tags</div>
              <div class="latestGreyBox-descriptionText">Call tagging helps organise and analyse call data, enabling easy reporting and customer identification.</div>
            </div>
            <div 
              v-b-tooltip :title="getUserPermissions.call_tagging_and_notes?'':'Your package does not support this feature'"
              class="w-fit-content"
              :class="{
                'for-disable' : !getUserPermissions.call_tagging_and_notes
              }"
            >
              <a
                :aria-disabled="!getUserPermissions.call_tagging_and_notes"
                class="newButton sm-mar-left"
                @click="!getUserPermissions.call_tagging_and_notes?'':$modal.show('TagsModal')"
              >
                <!-- <b-icon flip-h icon="tags" rotate="10" font-scale="1.3"></b-icon>
                <span class="sm-mar-left">Set call tags</span> -->
                <vb-icon icon="squared-tags-icon" height="38px" width="38px" />
                <span class="newButton-textPart">Set call tags</span>
              </a>
            </div>
          </div>
          <!-- power dialer tags -->
          <div class="whiteBoxWithBorderInsideGreyBox-row">
            <div class="d-flex flex-column">
              <div class="latestGreyBox-heading-main">Power Dialer tags</div>
              <div class="latestGreyBox-descriptionText">Call tagging helps organise and analyse call data, enabling easy reporting and customer identification.</div>
            </div>
            <div class="w-fit-content">
              <a
                class="newButton sm-mar-left"
                @click="$modal.show(`${_uid}-PowerDialerTags`)"
              >
                <vb-icon icon="squared-tags-icon" height="38px" width="38px" />
                <span class="newButton-textPart">Set power dialer tags</span>
              </a>
            </div>
          </div>
          <!-- pickup groups -->
          <div class="whiteBoxWithBorderInsideGreyBox-row">
            <div>
              <div class="latestGreyBox-heading-main">Pickup groups</div>
              <div class="latestGreyBox-descriptionText">Users within a group can answer calls for each other, ensuring efficient call handling.</div>
            </div>
            <div 
              v-b-tooltip :title="getUserPermissions.call_pickup?'':'Your package does not support this feature'"
              class="w-fit-content"
              :class="{
                'for-disable' : !getUserPermissions.call_pickup
              }"
            >
              <a
                :aria-disabled="!getUserPermissions.call_pickup"
                class="newButton sm-mar-left"
                @click="!getUserPermissions.call_pickup?'':$modal.show('PickupGroupsModal')"
              >
                <vb-icon icon="squared-show-icon" height="38px" width="38px" />
                <span class="newButton-textPart">View</span>
              </a>
            </div>
          </div>
          <!-- all extensions -->
          <!-- <div class="whiteBoxWithBorderInsideGreyBox-row">
            <div>
              <div class="latestGreyBox-heading-main">All extensions</div>
              <div class="latestGreyBox-descriptionText">Choose your status to convey your availability to others.</div>
            </div>
            <div class="w-fit-content">
              <a class="newButton sm-mar-left" @click="$modal.show('AllExtensions')">
                <vb-icon icon="squared-show-icon" height="38px" width="38px" />
                <span class="newButton-textPart">View</span>
              </a>
            </div>
          </div> -->
          <!-- number blocking -->
          <div class="whiteBoxWithBorderInsideGreyBox-row">
            <div>
              <div class="latestGreyBox-heading-main">Number blocking</div>
              <div class="latestGreyBox-descriptionText">Block unwanted telephone numbers by adding them to a block list, preventing calls to or from those numbers.</div>
            </div>
            <div>
              <a class="newButton sm-mar-left" @click="$modal.show('NumberBlocking')">
                <vb-icon icon="squared-show-icon" height="38px" width="38px" />
                <span class="newButton-textPart">View</span>
              </a>
            </div>
          </div>
          <!-- sound library -->
          <div class="whiteBoxWithBorderInsideGreyBox-row">
            <div>
              <div class="latestGreyBox-heading-main">Sound library</div>
              <div class="latestGreyBox-descriptionText">A library of all available sound files for announcements and music on hold, including both our collection and customer uploads.</div>
            </div>
            <div>
              <a class="newButton sm-mar-left" @click="$modal.show('SoundLibrary')">
                <vb-icon icon="squared-show-icon" height="38px" width="38px" />
                <span class="newButton-textPart">View</span>
              </a>
            </div>
          </div>
          <!-- call recordings -->
          <div class="whiteBoxWithBorderInsideGreyBox-row">
            <div>  
              <div class="latestGreyBox-heading-main">Call recording</div>
              <!-- <div class="latestGreyBox-descriptionText">Capture and store audio recordings of telephone conversations.</div> -->
              <div class="latestGreyBox-descriptionText">Record and save audio from phone conversations.</div>
            </div>
            <div>
              <a class="newButton sm-mar-left" @click="$modal.show('CallRecording')">
                <vb-icon icon="squared-show-icon" height="38px" width="38px" />
                <span class="newButton-textPart">View</span>
              </a>
            </div>
          </div>
          <!-- voicemail greetings -->
          <div class="whiteBoxWithBorderInsideGreyBox-row">
            <div>
              <div class="latestGreyBox-heading-main">Voicemail </div>
              <div class="latestGreyBox-descriptionText">Set a voicemail message to be used for all users who haven't personalised their own.</div>
              <!-- <div class="latestGreyBox-descriptionText">Manage your voicemail settings here.</div> -->
            </div>
            <div>
              <a class="newButton sm-mar-left" @click="$modal.show('SomethingVoicemailModal')">
                <vb-icon icon="squared-show-icon" height="38px" width="38px" />
                <span class="newButton-textPart">View</span>
              </a>
            </div>
          </div>
          <!-- Public Holiday -->
          <div class="whiteBoxWithBorderInsideGreyBox-row">
            <div>
              <div class="latestGreyBox-heading-main">Public Holiday  </div>
              <div class="latestGreyBox-descriptionText">These days are automatically blocked from having Meetings scheduled.</div>
            </div>
            <div>
              <a class="newButton sm-mar-left" @click="$modal.show('PublicHolidayGroups')">
                <vb-icon icon="squared-show-icon" height="38px" width="38px" />
                <span class="newButton-textPart">View</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <modal name="SomethingVoicemailModal" class="dialer_animation right_side_popup m-w-800 EditUserModal bigSquareCheckbox vm--container scrollable">
        <div class="dialer-edit-header">
          <div>
            <h2 class="dialer-settings-title newer mb-0">Voicemail greeting settings</h2>
          </div>
          <div class="dialer-edit-actions">
            <button class="newDoneButton" @click="$modal.hide('SomethingVoicemailModal')">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <!-- greeting -->
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <!-- <div class="latestGreyBox-heading-main">Voicemail settings</div>
          <div class="latestGreyBox-descriptionText">Manage your voicemail settings here.</div> -->
          <div class="whiteBoxWithBorderInsideGreyBox">
            <UserRecordedVoicemail :accountcode="getCurrentUser.default_accountcode" class="s2-mobile" />
          </div>
        </div>
      </modal>
      <!-- locations -->
      <!-- <div class="latestGreyBox-9-9-2023 mt-20px mb-32px">
        <div v-b-toggle.locations-collapse class="d-flex justify-content-between align-items-center cursor_pointer_no_hover">
          <div class="latestGreyBox-heading-main m-0">Show more</div>
          <b-icon icon="chevron-down" font-scale="1.5" />
        </div>
        <b-collapse id="locations-collapse" class="w-100 p-0">
          <Location class="w-100 p-0 mt-32px b-0" />
        </b-collapse>
      </div> -->
      <StatusesModal />
      <TagsModal />
      <PowerDialerTagsModal :modalName="`${_uid}-PowerDialerTags`" />
      <PublicHolidayGroupsModal />
      <modal name="PublicHolidayModal" class="dialer_animation right_side_popup m-w-800 EditUserModal bigSquareCheckbox vm--container scrollable">
        <div class="dialer-edit-header pb-0">
          <div>
            <h2 class="dialer-settings-title newer mb-0">Public Holiday Notification</h2>
          </div>
          <div class="dialer-edit-actions">
            <a class="newCloseButton" @click="$modal.hide('PublicHolidayModal')">
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </a>
          </div>
        </div>
        <div class="vm--modal-text">These days are automatically blocked from having Meetings scheduled.</div>
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <vb-table class="latestTableDesign-withBlackBorders-again showLastTDonHover mt-0"
            :isListEmpty="false"
            :listLength="10"
            :perPage="5"
            :conditions="{
              loading: {
                show: true,
              },
            }"
          >
            <tr slot="header" v-if="!$store.getters.getIsMobile">
              <th class="dialer-has-sort px-2">
                <span>Holiday</span>
              </th>
              <th class="dialer-has-sort px-2 dialer-col-center">
                <span>Date</span>
              </th>
              <th class="dialer-has-sort px-2 dialer-col-center">
                <span>Unavailable time</span>
              </th>
              <th class="dialer-has-sort px-2 dialer-col-center">
                <span>Announcment</span>
              </th>
              <th class="dialer-has-sort px-2 dialer-col-right w-fit-content">
                <div class="d-flex justify-content-end">
                  <a class="newButton blackBG" @click="$modal.show('AddPublicHolidayModal')">
                    <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                    <span class="newButton-textPart">Add holiday</span>
                  </a>
                </div>
              </th>
            </tr>
            <template #body>
              <tr >
                <td class="dialer-row-title">
                  Independence Day
                </td>
                <td class="dialer-has-sort dialer-col-center">
                  3 Jul 2023
                </td>
                <td class="dialer-has-sort dialer-col-center">
                  <div class="unailableTime">
                    <div class="unailableTime-heading">Unvailable all day</div>
                    <div class="unailableTime-text">Call will be shared to voicemail</div>
                  </div>
                </td>
                <td class="dialer-has-sort dialer-col-center">
                  <b-icon :icon="`${true ? 'play-fill' : 'pause-fill'}`" font-scale="1.5" />
                </td>
                <td class="dialer-has-sort dialer-col-right w-fit-content">
                  <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion userTeamsFilter optionIsdotted filterWithText">
                    <b-dropdown right>
                      <template #button-content> 
                        <div class="d-flex justify-content-end w-100">
                          <b-icon icon="three-dots-vertical" variant="dark" class="fillBlack sm-mar-left" scale="1.5" />
                        </div>
                      </template>
                      <b-dropdown-item>Edit</b-dropdown-item>
                      <b-dropdown-item>Delete</b-dropdown-item>
                    </b-dropdown>
                  </div>
                </td>
              </tr>
            </template>
            <!-- <template #loading>
              <tr v-for="n in 5" :key="n">
                <td>
                  <div class="d-flex align-items-center w-fit-content">
                    <div class="latestShimmerDesign" style="height:21px;width:80px;"></div>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center w-fit-content mx-auto">
                    <div class="md-mar-left latestShimmerDesign" style="height:21px;width:21px;"></div>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center w-fit-content mx-auto">
                    <div class="latestShimmerDesign md-mar-left" style="height:21px;width:21px;"></div>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center w-fit-content mx-auto">
                    <div class="latestShimmerDesign md-mar-left" style="height:21px;width:50px;"></div>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center justify-sontent-end w-fit-content ml-auto">
                    <div class="latestShimmerDesign" style="height:16px;width:70px;"></div>
                  </div>
                </td>
              </tr>
            </template> -->
          </vb-table>
          <div class="d-flex align-items-center justify-content-between">
            <div>
              <div class="latestGreyBox-heading-main">Telephone numbers</div>
              <div class="latestGreyBox-descriptionText">These days are automatically blocked from having Meetings scheduled.</div>
            </div>
            <div>
              <a class="newButton blackBG" @click="$modal.show('PublicHolidayModal')">
                <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                <span class="newButton-textPart">Add Number</span>
              </a>
            </div>
          </div>


          
          <vb-table class="latestTableDesign-withBlackBorders-again mt-0"
            :isListEmpty="false"
            :listLength="10"
            :perPage="5"
            :conditions="{
              loading: {
                show: true,
              },
            }"
          >
            <tr slot="header" v-if="!$store.getters.getIsMobile">
              <th class="dialer-has-sort px-2 py-1"></th>
              <th class="dialer-has-sort px-2 py-1"></th>
              <th class="dialer-has-sort px-2 py-1 dialer-col-right w-fit-content"></th>
            </tr>
            
            <template #body>
              <tr v-for="number in testDATA" :key="number.id">
                <td class="dialer-row-title one">
                  <div class="d-flex justify-content-between dialer-numberWithCountry">
                    <div class="d-flex align-items-center">
                      <span class="country-img-container p-0">
                        <vb-icon v-if="number.object === 'TelephoneNumbers'" icon="numbersSettings-table-tdIcon" width="35px" height="35px" />
                        <vb-icon v-else-if="number.object === 'CallerId'" icon="numbersSettings-table-tdIcon-another" width="35px" height="35px" />
                        <vb-icon v-else icon="numbersSettings-table-tdIcon" width="35px" height="35px" />
                      </span>
                      <span class="ml-2 d-flex flex-column dialer-numberInfo justify-content-center" v-if="number.queue_service">
                        <span class="m-0 didNumber">{{ number.name | number_formater }}</span>
                      </span>
                      <span class="ml-2 d-flex flex-column dialer-numberInfo justify-content-center" v-else-if="number.object === 'TelephoneNumbers'">
                        <span class="m-0 didNumber" v-b-tooltip :title="number.is_trial==1?'Default':''">{{ number.number | number_formater }}</span>
                        <div class="d-flex align-items-center">
                          <small class="didNumber-text mr-2 w-auto h-fit-content addedLabelInsideEditNumbersModal" v-if="number.label">({{ number.label }})</small>
                          <div v-if="number.status==2" class="suspendedTag">Suspended</div>
                        </div>
                      </span>
                      <span class="d-flex flex-column ml-1 dialer-numberInfo justify-content-center" v-else>
                        <span class="m-0 didNumber">{{ number.number | number_formater }}</span>
                        <div v-if="number.status==2" class="suspendedTag">Suspended</div>
                      </span>
                    </div>
                    <span class="d-flex align-items-center">
                      <img v-if="number.queue_service" class="country-img" style="border-radius:8px;" width="45px" :src="number.name | number_formater | flag_icon"/>
                      <img v-else class="country-img" style="border-radius:8px;" width="45px" :src="number.number | number_formater | flag_icon"/>
                    </span>
                  </div>
                </td>
                <td class="dialer-row-title two">
                  <div v-if="number.object === 'TelephoneNumbers'" class="w-fit-content">
                    <template v-if="number.ringgroupId==number.assigned">
                      <div v-if="number.ringgroup_members[0]" class="d-flex flex-column" >
                        <div class="d-flex align-items-center">
                          <Info 
                            :id="number.ringgroup_members[0]"  
                            :is_blf="false"
                          />
                          <!-- <div class="w-auto dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight dialer-dropDown-p-1 ml-2" v-if="number.ringgroup_members.length>1" @click.stop="conditions.more=conditions.more==number.id?'':number.id">
                            <div class="px-2 rounded more-numbers-tag">
                              <small>{{conditions.more==number.id ? '-' : '+'}} {{ number.ringgroup_members.length-1 }} more</small>
                            </div>
                          </div> -->
                        </div>
                        <!-- <template v-if="conditions.more==number.id">
                          <template v-for="(assigned, index) in number.ringgroup_members.filter((item,index)=>index>0)" >
                            <div class="mt-2" :key="assigned" v-show="index >= start && index <= end" >
                              <Info 
                                :id="assigned"  
                                :is_blf="false"
                              />
                            </div>
                          </template>
                        </template> -->
                      </div>
                    </template>
                    <template v-else-if="number.assigned">
                      <div>
                        <Info 
                          :id="number.assigned"  
                          :is_blf="false"
                        />
                      </div>
                    </template>
                  </div>
                </td>
                <td class="dialer-has-sort dialer-col-right w-fit-content">
                  <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion userTeamsFilter optionIsdotted filterWithText">
                    <b-dropdown right>
                      <template #button-content> 
                        <div class="d-flex justify-content-end w-100">
                          <b-icon icon="three-dots-vertical" variant="dark" class="fillBlack sm-mar-left" scale="1.5" />
                        </div>
                      </template>
                      <b-dropdown-item>Edit</b-dropdown-item>
                      <b-dropdown-item>Delete</b-dropdown-item>
                    </b-dropdown>
                  </div>
                </td>
              </tr>
            </template>
            <!-- <template #loading>
              <tr v-for="n in 5" :key="n">
                <td>
                  <div class="d-flex align-items-center w-fit-content">
                    <div class="latestShimmerDesign" style="height:21px;width:80px;"></div>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center w-fit-content mx-auto">
                    <div class="md-mar-left latestShimmerDesign" style="height:21px;width:21px;"></div>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center w-fit-content mx-auto">
                    <div class="latestShimmerDesign md-mar-left" style="height:21px;width:21px;"></div>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center w-fit-content mx-auto">
                    <div class="latestShimmerDesign md-mar-left" style="height:21px;width:50px;"></div>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center justify-sontent-end w-fit-content ml-auto">
                    <div class="latestShimmerDesign" style="height:16px;width:70px;"></div>
                  </div>
                </td>
              </tr>
            </template> -->
          </vb-table>
        </div>
      </modal>
      <modal name="AddPublicHolidayModal" class="dialer_animation right_side_popup m-w-800 EditUserModal bigSquareCheckbox vm--container scrollable">
        <div class="dialer-edit-header pb-0">
          <div>
            <h2 class="dialer-settings-title newer mb-0">Add public holiday</h2>
          </div>
          <div class="dialer-edit-actions">
            <a class="newCloseButton" @click="$modal.hide('AddPublicHolidayModal')">
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </a>
          </div>
        </div>
        <div class="vm--modal-text">This days are automatically blocked from having Meetings scheduled.</div>
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <div class="whiteBGinputWithGreyRoundedBorder w-100">
            <label>Holiday name</label>
            <input class="w-100 bg-white" type="text" />
            <!-- <p v-if="$v.forms.update_label.label.$error" class="text text-danger animated bounceIntop">
              <span v-if="!$v.forms.update_label.label.required">IVR name is required</span>
              <span v-else-if="!$v.forms.update_label.label.minLength">IVR name should be minimum {{ $v.forms.update_label.label.$params.minLength.min }} character</span>
              <span v-else-if="!$v.forms.update_label.label.maxLength">IVR name can be maximum {{ $v.forms.update_label.label.$params.maxLength.max }} character</span>
              <span v-else-if="!$v.forms.update_label.label.valid">IVR name can be number and alphabet</span>
            </p> -->
          </div>
          <div class="whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionLeftAligned w-100 mt-20px">
            <label>From:</label>
            <vue-ctk-date-time-picker only-date v-bind="ctkDateTimePickerOptions" class="date-time-input" />
          </div>
          <div class="latestGreyBox-heading-main-16px mt-20px">What happens to calls..</div>
          <div class="newBusinessHours mt-8px">
            <div class="newBusinessHours-row">
              <div class="newBusinessHours-row-left">
                <div class="newBusinessHours-row-headingContainer">
                  <div class="newBusinessHours-row-heading">Monday</div>
                  <vb-icon icon="businessHours-addSchedule-icon" width="20.76px" height="20.76px" />
                </div>
                <!-- <div class="newBusinessHours-row-customText" v-if="days[day.value][0] && days[day.value][0].type=='custom'">Custom</div> -->
              </div>
              <div class="newBusinessHours-row-right">
                <div class="newBusinessHours-row-right-row" v-for="n in 3" :key="n.id" >
                  <div class="newBusinessHours-row-right-row-left">
                    <div class="newBusinessHours-row-right-row-left-button">
                      <vb-icon icon="businessHours-editSchedulePencil-icon" width="8.762px" height="8.761px"/>
                      <span>Edit</span>
                    </div>
                  </div>
                  <div class="newBusinessHours-row-right-row-right">
                    <div :class="[`red newBusinessHours-row-right-row-right-heading`]">
                      00:00:00 - 00:00:00
                    </div>
                    <template >
                      <div class="newBusinessHours-row-right-row-right-text">Unavailable all day</div>
                    </template>
                  </div>
                </div>
                <!-- <div class="newBusinessHours-row-right-row" v-if="!days[day.value] || days[day.value].length==0" @click="!getUserPermissions.out_of_hours ? '' : $modal.show(`${randomId}-EditOutOfHoursDayModal`,{ day: day.value })">
                  <div class="newBusinessHours-row-right-row-left">
                    <div class="newBusinessHours-row-right-row-left-button">
                      <vb-icon icon="businessHours-editSchedulePencil-icon" width="8.762px" height="8.761px"/>
                      <span>Edit</span>
                    </div>
                  </div>
                  <div class="newBusinessHours-row-right-row-right">
                    <div class="newBusinessHours-row-right-row-right-heading" :class="`${day.value}`">available all day</div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
              <div class="w-100 d-flex align-items-center justify-content-between">
                <div class="d-flex flex-column">
                  <div class="latestGreyBox-heading-main">Holiday Announcement</div>
                  <div class="latestGreyBox-descriptionText">This sound file will play when a call will come on this holiday.</div>
                </div>
                <a class="newButton sm-mar-left"  >
                  <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                  <span class="newButton-textPart">Add</span>
                </a>
              </div>
              <div class="w-50 mt-12px">
                <vb-audio-player :design="`${$store.getters.getIsMobile ? 'minimum' : ''}`" class="whiteBGaudioWithGreyRoundedBorder-again w-100" />
              </div>
            </div>
          </div>
        </div>
      </modal>
    </section>
    <ProfileImageUploader :modalName="`GlobalSettingProfileImageUploader`" @image-uploaded="''" />
    <PickupGroupsModal />
    <NumberBlockingModal />
    <CallRecordingModal />
    <SoundLibraryModal />
    <AllExtensionsModal />
  </div>
</template>

<script>
import StatusesModal from "../Modals/settings/StatusesModal.vue";
import NumberBlockingModal from "../Modals/NumberBlockingModal.vue";
import CallRecordingModal from "../Modals/CallRecordingModal.vue";
import SoundLibraryModal from "../Modals/SoundLibraryModal.vue";
import TagsModal from "../Modals/settings/TagsModal.vue";
import PowerDialerTagsModal from "../PowerDialer/TagsModal.vue";
import { VOIP_API } from "../../utils";
import { required } from "vuelidate/lib/validators";
import ProfileImageUploader from '../Modals/ProfileImageUploader.vue'
import PickupGroupsModal from '../Modals/PickupGroupsModal.vue'
import AllExtensionsModal from "../Modals/AllExtensionsModal.vue";
import PublicHolidayGroupsModal from "../Modals/public-holidays/PublicHolidayGroupsModal.vue";
import { mapGetters } from 'vuex';
import { CountryName2, flagsCountryCode } from '@/utils/flags';
import Info from '../Lists/Info.vue';
import { ctkDateTimePicker } from '../../mixin';
import _ from 'lodash'
// import Location from './Location.vue';
import UserRecordedVoicemail from '../Voicemail/UserRecordedVoicemailCopy.vue';
var moment = require("moment-timezone");
var interval_id;
export default {
  name: "GlobalSettings",
  components: {
    ProfileImageUploader,
    PickupGroupsModal,
    StatusesModal,
    TagsModal,
    NumberBlockingModal,
    CallRecordingModal,
    SoundLibraryModal,
    PowerDialerTagsModal,
    // Location,
    AllExtensionsModal,
    UserRecordedVoicemail,
    Info,
    PublicHolidayGroupsModal
  },
  inject:[
    'local_filess','appNotify'
  ],
  data() {
    return {
      api: {
        update_blf: this.$helperFunction.apiInstance(),
        fetch_account_detail: this.$helperFunction.apiInstance(),
        update_time_zone: this.$helperFunction.apiInstance(),
      },
      forms: {
        update_time_zone: this.$helperFunction.formInstance({
          data: {
            country: '',
            time_zone: '',
          }
        }),
        update_blf: this.$helperFunction.formInstance({
          data: {
            blf: false,
          }
        }),
      },
      current_date: {
        date: "",
        time: "",
      },
      testDATA: [
        {
          "object": "TelephoneNumbers",
          "id": "k2pdLE19Z9jr9zKn",
          "label": "this is a very long label to check design is fixed or not",
          "extensions": ["<span class=\"label label-primary\">8001</span> Frank Fahad", "<span class=\"label label-primary\">8005</span> user five"],
          "extensionArr": ["8001", "8005"],
          "didWithCode": "",
          "number": "44-844-3585437",
          "did": "44-844-3585437",
          "assigned": "700094010089",
          "ringgroupId": "700094010099",
          "extnType": "BASICIVR",
          "html": ["<span class=\"label label-primary\">8001</span> Frank Fahad", "<span class=\"label label-primary\">8005</span> user five"],
          "created_at": "2024-12-26T05:52:39.000000Z",
          "status": 1,
          "assignedToUsers": "<span class=\"label label-primary\">2502</span> IVR MENU 2",
          "assignedToUsersArr": {
              "extn": "2502",
              "label": "IVR MENU 2"
          },
          "real_id": 997,
          "location": null,
          "ringgroup_members": [],
          "user_mode": 0,
          "actionable_date": "0000-00-00",
          "is_trial": 0
        }, 
        {
          "object": "TelephoneNumbers",
          "id": "w3ryQV1gyAYD5pRm",
          "label": null,
          "extensions": ["<span class=\"label label-primary\">8001</span> Frank Fahad", "<span class=\"label label-primary\">8002</span> user two", "<span class=\"label label-primary\">8003</span> user three", "<span class=\"label label-primary\">8004</span> user four", "<span class=\"label label-primary\">8005</span> user five", "<span class=\"label label-primary\">8006</span> user six", "<span class=\"label label-primary\">8007</span> user seven", "<span class=\"label label-primary\">8008</span> user eight", "<span class=\"label label-primary\">8009</span> user nine", "<span class=\"label label-primary\">8010</span> user ten", "<span class=\"label label-primary\">8011</span> user eleven"],
          "extensionArr": ["8001", "8002", "8003", "8004", "8005", "8006", "8007", "8008", "8009", "8010", "8011"],
          "didWithCode": "",
          "number": "5509110750110",
          "did": "5509110750110",
          "assigned": "700094010011",
          "ringgroupId": "700094010011",
          "extnType": "RINGGROUP",
          "html": ["<span class=\"label label-primary\">8001</span> Frank Fahad", "<span class=\"label label-primary\">8002</span> user two", "<span class=\"label label-primary\">8003</span> user three", "<span class=\"label label-primary\">8004</span> user four", "<span class=\"label label-primary\">8005</span> user five", "<span class=\"label label-primary\">8006</span> user six", "<span class=\"label label-primary\">8007</span> user seven", "<span class=\"label label-primary\">8008</span> user eight", "<span class=\"label label-primary\">8009</span> user nine", "<span class=\"label label-primary\">8010</span> user ten", "<span class=\"label label-primary\">8011</span> user eleven"],
          "created_at": "2024-09-11T07:52:07.000000Z",
          "status": 1,
          "assignedToUsers": "<span class=\"label label-primary\">2002</span> 5509110750110",
          "assignedToUsersArr": {
              "extn": "2002",
              "label": "5509110750110"
          },
          "real_id": 711,
          "location": null,
          "ringgroup_members": ["700094010001", "700094010012", "700094010018", "700094010024", "700094010036", "700094010042", "700094010048", "700094010054", "700094010066", "700094010072"],
          "user_mode": 0,
          "actionable_date": "0000-00-00",
          "is_trial": 1
        }
      ]
    };
  },
  mixins: [ctkDateTimePicker],
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getCurrentUser',
      'getIsMobile'
    ]),
    countries(){ 
      return _.orderBy(moment.tz.countries().map((i)=>({
        value: i,
        text: CountryName2[i],
        flag: flagsCountryCode[i],
      })) , ['text'], ['asc']);
    },
    timezones(){
      return moment.tz.zonesForCountry(this.forms.update_time_zone.country) ?? []
    },
  },
  validations: {
    forms: {
      update_time_zone: {
        country: {
          required,
        },
        time_zone: {
          required,
        },
      },
    },
  },
  methods: {
    async fetchAccountDetail() {
      if (this.api.fetch_account_detail.send) return;
      try {
        this.api.fetch_account_detail.send = true;
        const { data } = await VOIP_API.endpoints.users.accountdetail()
        const tz = data?.user_detail?.timezone ?? moment.tz.guess();
        if(tz) {
          this.forms.update_time_zone.country = data?.user_detail?.country ?? moment.tz(tz)._z.countries()?.[0]
          this.forms.update_time_zone.time_zone = tz
          moment.tz.setDefault(this.forms.update_time_zone.time_zone);
          this.$store.state.common.user.country = this.forms.update_time_zone.country
        }
        this.forms.update_blf.blf=data?.user_detail?.blf=='enable'
      } finally {
        this.api.fetch_account_detail.send = false;
      }
    },
    setDate() {
      let vm = this;
      interval_id = setInterval(() => {
        vm.current_date.date = moment().format("D/M/YYYY");
        vm.current_date.time = moment().format("h:mm:ss A");
      }, 1 * 1000);
    },
    async updateBLF(){
      if(this.api.update_blf.send) return;
      try {
        this.api.update_blf.send=true
        await VOIP_API.endpoints.users.updateblf({
          field: 'blf',
          uid: this.getCurrentUser?.uid,
          value: `${this.forms.update_blf.blf ? 'disable' : 'enable'}`
        })
        this.$store.state.common.user.blf = !this.forms.update_blf.blf;
        this.forms.update_blf.blf=!this.forms.update_blf.blf
        this.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        if(this.$refs['blf_switch']) this.$refs['blf_switch'].localChecked = this.forms.update_blf.blf;
        this.api.update_blf.send=false
      }
    },
    async updateTimeZone(){
      this.forms.update_time_zone.submitted=true
      this.$v.forms.update_time_zone.$touch()
      if(this.api.update_time_zone.send || this.api.fetch_account_detail.send || this.$v.forms.update_time_zone.$invalid) return;
      try {
        this.api.update_time_zone.send=true
        await VOIP_API.endpoints.users.updateblf({
          field: 'timezone',
          uid: this.getCurrentUser?.uid,
          value: this.forms.update_time_zone.time_zone,
          country: this.forms.update_time_zone.country,
        })
        moment.tz.setDefault(this.forms.update_time_zone.time_zone);
        this.$store.state.common.user.country = this.forms.update_time_zone.country ?? moment.tz(moment().tz())._z.countries()?.[0]
        this.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.update_time_zone.send=false
      }
    },
  },
  activated() {
    this.fetchAccountDetail();
    this.setDate();
  },
  beforeDestroy() {
    clearInterval(interval_id);
  },
};
</script>

<style lang="scss" scoped>
.profile-image-section {
  position: relative;
  width: 180px;
  height: 180px;
  display: flex;
  overflow: hidden;
  @include border-radius(50%);
  .loader-icon {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .profile-img-upload-btn {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    align-items: center;
    display: none;
    justify-content: center;
    .upload-icon {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.6);
    }
    input {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 2;
      opacity: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .profile-img {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include border-radius(50%);
    }
  }
  &:hover {
    .profile-img-upload-btn {
      display: block;
      cursor: pointer;
    }
  }
}
</style>