<template>
  <div class=" dialer-userInfoMenu v2 mobile text-center w-100 position-absolute py-3">
    <b-dropdown v-if="isDormant || isFreePlanDisabled" id="dropdown-1" varient="link" no-caret class="user-thumbnail desktopVersion side_bar_profile_dropdown">
      <template v-slot:button-content>
        <vb-avatar :is_blf="false" :loading="$store.state.logout_running" :image="$store.getters.getCurrentUser.profileImg" :status_id="$store.getters.getCurrentUser.status_id" :name="$store.getters.getCurrentUser.display_name" />
      </template>
      <b-dropdown-item class="border-bottom">
        <div class="dialer_contact">
          <div class="user-profile">
            <vb-avatar :is_blf="false" :image="$store.getters.getCurrentUser.profileImg" :status_id="$store.getters.getCurrentUser.status_id" :name="$store.getters.getCurrentUser.display_name" />
          </div>
          <div class="user-info" @click="onClickAvatar()">
            <span class="user-name" @click="onClickAvatar()">{{ $store.getters.getCurrentUser.display_name }}</span>
            <span class="user-number" @click="onClickAvatar()">{{ $store.getters.getCurrentUser.extn }}</span>
          </div>
        </div>
      </b-dropdown-item>
      <b-dropdown-item @click="logout({is_user: true})">
        <b-icon icon="box-arrow-right" class="mr-2" scale="0.8" />
        <span class="text-capitalize">Logout</span>
      </b-dropdown-item>
    </b-dropdown>
    <template v-else-if="$store.getters.getIsMobile">
      <div @click="$store.state.global_conditions.tab='user_profile'" class="">
        <vb-avatar :is_blf="false" :image="$store.getters.getCurrentUser.profileImg" :status_id="$store.getters.getCurrentUser.status_id" :name="$store.getters.getCurrentUser.display_name" />
        <span class="with-border" v-if="snooze_label!='off'">
          <vb-icon icon="dialer-snoozeIcon-light" width="14" height="14" />
        </span>
      </div>
    </template>
    <b-dropdown v-else-if="$store.getters.isIntegration" id="dropdown-1" varient="link" no-caret class="user-thumbnail side_bar_profile_dropdown">
      <template v-slot:button-content>
        <vb-avatar :is_blf="false" :image="$store.getters.getCurrentUser.profileImg" :status_id="$store.getters.getCurrentUser.status_id" :name="$store.getters.getCurrentUser.display_name" />
        <template v-if="$store.getters.getCurrentUser.status_id && snooze_label">
          <span v-if="snooze_label!='off' && snooze_label!='unlimited'" style="color: #ffffff;">{{ $store.state.sip.snooze_time | time_audio }}</span>
          <span class="with-border" v-if="snooze_label!='off'">
            <vb-icon icon="dialer-snoozeIcon-light" width="14" height="14" />
          </span>
        </template>
        <span class="sr-only">User Menu</span>
      </template>
      <b-dropdown-item class="border-bottom">
        <div class="dialer_contact">
          <div class="user-profile">
            <vb-avatar :is_blf="false" :image="$store.getters.getCurrentUser.profileImg" :status_id="$store.getters.getCurrentUser.status_id" :name="$store.getters.getCurrentUser.display_name" />
          </div>
          <div class="user-info">
            <span class="user-name">{{ $store.getters.getCurrentUser.display_name }}</span>
            <span class="user-number">{{ $store.getters.getCurrentUser.extn }}</span>
          </div>
        </div>
      </b-dropdown-item>
      <b-dropdown-item @click="logout({is_user: true})">
        <b-icon v-if="$store.state.logout_running" icon="arrow-clockwise" animation="spin-pulse" variant="danger" />
        <template v-else>
          <b-icon icon="box-arrow-right" class="mr-2" scale="0.8" />
          <span class="text-capitalize">Logout</span>
        </template>
      </b-dropdown-item>
    </b-dropdown>
    <b-dropdown v-else id="dropdown-1" varient="link" no-caret class="user-thumbnail desktopVersion side_bar_profile_dropdown">
      <template v-slot:button-content>
        <vb-avatar :is_blf="false" :loading="$store.state.logout_running" :image="$store.getters.getCurrentUser.profileImg" :status_id="$store.getters.getCurrentUser.status_id" :name="$store.getters.getCurrentUser.display_name" />
        <template v-if="$store.getters.getCurrentUser.status_id && snooze_label">
          <span v-if="snooze_label!='off' && snooze_label!='unlimited'" style="color: #ffffff;font-size:11px;">{{ $store.state.sip.snooze_time | time_audio }}</span>
          <span class="with-border" v-if="snooze_label!='off'">
            <vb-icon icon="dialer-snoozeIcon-light" width="14" height="14" />
          </span>
        </template>
        <span class="sr-only">User Menu</span>
      </template>
      <b-dropdown-item class="border-bottom">
        <div class="dialer_contact">
          <div class="user-profile">
            <vb-avatar @click="onClickAvatar()" :is_blf="false" :image="$store.getters.getCurrentUser.profileImg" :status_id="$store.getters.getCurrentUser.status_id" :name="$store.getters.getCurrentUser.display_name" />
          </div>
          <div class="user-info" @click="onClickAvatar()">
            <span class="user-name" @click="onClickAvatar()">{{ $store.getters.getCurrentUser.display_name }}</span>
            <span class="user-number" @click="onClickAvatar()">{{ $store.getters.getCurrentUser.extn }}</span>
            <template v-if="!$store.getters.isIntegration">
              <b-dropdown-item class="position-relative dialer-organization opacity-1 dd-opacity-1" @click="$modal.show(`${_uid}-userProfileStatusModal`)">
                <vb-status :statusId="$store.getters.getCurrentUser.status_id" class="position-relative top-0 left-0 bottom-0" />
                <span class="ml-1">{{ $store.getters.getCurrentUser.status }}</span>
                <!-- <b-icon class="arrow_icon" icon="chevron-down" scale="0.7" />
                <transition name="slide-in">
                  <ul class="organization-list">
                    <li v-for="status in $store.state.common.statuses" :key="status.id" @click="$store.dispatch('setStatus',status.id)">
                      <a>{{ status.status }}</a>
                      <b-icon v-if="status.id === $store.getters.getCurrentUser.status_id" icon="check-circle-fill" scale="0.8" :style="`color:${status.colour};`" />
                    </li>
                  </ul>
                </transition> -->
              </b-dropdown-item>
            </template>
          </div>
        </div>
      </b-dropdown-item>
      <b-dropdown-item @click="$modal.show('KbInfoModal')">
        <vb-icon icon="userProfileInfoIcon" class="mr-2" scale="1" />
        <span class="text-capitalize">Help and support</span>
      </b-dropdown-item>
      <b-dropdown-item @click="$emit('change-active-tab','settings')">
        <vb-icon icon="userProfileSettingIcon" class="mr-2" scale="1" />
        <span class="text-capitalize">Settings</span>
      </b-dropdown-item>
      <b-dropdown-item v-if="!$store.getters.isIntegration && getProperty($store.getters.getCurrentUser,'organizations.length',0)>1" class="position-relative dialer-organization">
        <b-icon icon="building" class="mr-2" scale="0.8" />
        <span class="text-capitalize">Select Organization</span>
        <b-icon class="arrow_icon" icon="chevron-down" scale="0.7" />
        <transition name="slide-in">
          <ul class="organization-list forStatusList">
            <li v-for="(organization, index) in $store.getters.getCurrentUser.organizations" :key="index" @click="organization.id === $store.getters.getCurrentUser.selected_organization_id ? '' : switchOrganization(organization)">
              <a>{{ organization.label }}</a>
              <b-icon v-if="$store.getters.getCurrentUser.selected_organization_id === organization.id" icon="check-circle-fill" scale="0.8" class="status-1" />
            </li>
          </ul>
        </transition>
      </b-dropdown-item>
      <b-dropdown-item @click="$modal.show(`${_uid}-snoozeModal`)" class="position-relative dialer-organization second opacity-1">
        <vb-icon icon="dialer-snoozeIcon" width="20" height="20" class="mr-2 dialer-snoozeIcon" scale="0.8" />
        <span class="text-capitalize">Away</span>
        <!-- <b-icon class="arrow_icon" icon="chevron-down" scale="0.7" /> -->
        <!-- <transition name="slide-in">
          <ul class="organization-list forSnoozeTimeList">
            <li @click="snooze('off')">
              <a>Turn Off</a>
              <b-icon v-if="snooze_label=='off'" icon="check-circle-fill" scale="0.8" class="status-1" />
            </li>
            <li @click="snooze('unlimited')">
              <a>Turn On</a>
              <b-icon v-if="snooze_label=='unlimited'" icon="check-circle-fill" scale="0.8" class="status-1" />
            </li>
            <li @click="snooze('5 min')">
              <a>Turn on for 5 Minutes</a>
              <b-icon v-if="snooze_label=='5 min'" icon="check-circle-fill" scale="0.8" class="status-1" />
            </li>
            <li @click="snooze('10 min')">
              <a>Turn on for 10 Minutes</a>
              <b-icon v-if="snooze_label=='10 min'" icon="check-circle-fill" scale="0.8" class="status-1" />
            </li>
            <li @click="snooze('15 min')">
              <a>Turn on for 15 Minutes</a>
              <b-icon v-if="snooze_label=='15 min'" icon="check-circle-fill" scale="0.8" class="status-1" />
            </li>
            <li @click="snooze('20 min')">
              <a>Turn on for 20 Minutes</a>
              <b-icon v-if="snooze_label=='20 min'" icon="check-circle-fill" scale="0.8" class="status-1" />
            </li>
            <li @click="snooze('30 min')">
              <a>Turn on for 30 Minutes</a>
              <b-icon v-if="snooze_label=='30 min'" icon="check-circle-fill" scale="0.8" class="status-1" />
            </li>
            <li @click="snooze('45 min')">
              <a>Turn on for 45 Minutes</a>
              <b-icon v-if="snooze_label=='45 min'" icon="check-circle-fill" scale="0.8" class="status-1" />
            </li>
            <li @click="snooze('60 min')">
              <a>Turn on for 1 Hour</a>
              <b-icon v-if="snooze_label=='60 min'" icon="check-circle-fill" scale="0.8" class="status-1" />
            </li>
            <li @click="$modal.show('CustomeSnoozeModal')">
              <a>Set Custom away time</a>
              <b-icon v-if="snooze_label=='custom'" icon="check-circle-fill" scale="0.8" class="status-1" />
            </li>
          </ul>
        </transition> -->
      </b-dropdown-item>
      <b-dropdown-item @click="logout({is_user: true})">
        <b-icon icon="box-arrow-right" class="mr-2" scale="0.8" />
        <span class="text-capitalize">Logout</span>
      </b-dropdown-item>
      <!-- <b-dropdown-item @click="logout({ is_user: true, hard: true })">
        <b-icon icon="box-arrow-right" class="mr-2" scale="0.8" />
        <span class="text-capitalize">Hard Logout</span>
      </b-dropdown-item> -->
    </b-dropdown>
    
    <modal class="modalForDropdown" width="600px" height="auto" :scrollable="true" :name="`${_uid}-snoozeModal`">
      <section class="ct-user-new dialer-edit">
        <div class="dialer-edit-header d-flex w-100 align-items-start justify-content-between pt-0">
          <div class="text-left w-75">
            <h2 class="dialer-settings-title newer"> Away</h2>
            <div class="dialer-settings-intro newerTextVersion w-75 mb-0">
              Choose your preferred away time here
            </div>
          </div>
          <div class="dialer-edit-actions">
            <button class="newDoneButton" @click="$modal.hide(`${_uid}-snoozeModal`)">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023 hasScrollBarInsideIt mt-20px" style="--total: 90vh; --minusValue: 75px;">
          <div class="whiteBoxWithBorderInsideGreyBox greenBox">
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-none">
              <div class="d-flex align-items-center">
                <div :class="`actLikeSquareRadio mr-20px ${snooze_label=='off' ? 'checked' : '' }`" @click="snooze('off')"></div>
                <div class="text-left">
                  <div class="latestGreyBox-heading-main">Turn off away </div>
                  <div class="latestGreyBox-description-main">I'm available to receive calls</div>
                </div>
              </div>
               <span class="newBlueColorTagLike">Default</span>
            </div>
          </div>
          <div class="whiteBoxWithBorderInsideGreyBox redBox mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none" >
              <div :class="`actLikeSquareRadio mr-20px ${snooze_label=='unlimited' ? 'checked' : '' }`" @click="snooze('unlimited')"></div>
              <div class="text-left">
                <div class="latestGreyBox-heading-main">Turn on away </div>
                <div class="latestGreyBox-description-main">I'm unavailable to receive calls</div>
              </div>
            </div>
          </div>
          <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none" >
              <div :class="`actLikeSquareRadio mr-20px ${snooze_label=='5 min' ? 'checked' : '' }`" @click="snooze('5 min')"></div>
              <div class="text-left">
                <div class="latestGreyBox-heading-main">Turn on away for 5 minutes </div>
                <div class="latestGreyBox-description-main">This will turn off away in 5 minutes</div>
              </div>
            </div>
            <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none">
              <div :class="`actLikeSquareRadio mr-20px ${snooze_label=='10 min' ? 'checked' : '' }`" @click="snooze('10 min')"></div>
              <div class="text-left">
                <div class="latestGreyBox-heading-main">Turn on for 10 minutes</div>
                <div class="latestGreyBox-description-main">This will turn off away in 10 minutes</div>
              </div>
            </div>
            <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none">
              <div :class="`actLikeSquareRadio mr-20px ${snooze_label=='15 min' ? 'checked' : '' }`" @click="snooze('15 min')"></div>
              <div class="text-left">
                <div class="latestGreyBox-heading-main">Turn on for 15 minutes</div>
                <div class="latestGreyBox-description-main">This will turn off away in 15 minutes</div>
              </div>
            </div>
            <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none">
              <div :class="`actLikeSquareRadio mr-20px ${snooze_label=='20 min' ? 'checked' : '' }`" @click="snooze('20 min')"></div>
              <div class="text-left">
                <div class="latestGreyBox-heading-main">Turn on for 20 minutes</div>
                <div class="latestGreyBox-description-main">This will turn off away in 20 minutes</div>
              </div>
            </div>
            <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none">
              <div :class="`actLikeSquareRadio mr-20px ${snooze_label=='30 min' ? 'checked' : '' }`" @click="snooze('30 min')"></div>
              <div class="text-left">
                <div class="latestGreyBox-heading-main">Turn on for 30 minutes</div>
                <div class="latestGreyBox-description-main">This will turn off away in 30 minutes</div>
              </div>
            </div>
            <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none">
              <div :class="`actLikeSquareRadio mr-20px ${snooze_label=='45 min' ? 'checked' : '' }`" @click="snooze('45 min')"></div>
              <div class="text-left">
                <div class="latestGreyBox-heading-main">Turn on for 45 minutes</div>
                <div class="latestGreyBox-description-main">This will turn off away in 45 minutes</div>
              </div>
            </div>
            <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none">
              <div :class="`actLikeSquareRadio mr-20px ${snooze_label=='60 min' ? 'checked' : '' }`" @click="snooze('60 min')"></div>
              <div class="text-left">
                <div class="latestGreyBox-heading-main">Turn on for 1 hour</div>
                <div class="latestGreyBox-description-main">This will turn off away in 1 hour</div>
              </div>
            </div>
            <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none">
              <div :class="`actLikeSquareRadio mr-20px ${snooze_label=='custom' ? 'checked' : '' }`" @click="$modal.show('CustomeSnoozeModal')"></div>
              <div class="text-left">
                <div class="latestGreyBox-heading-main">Set Custom away time</div>
                <div class="latestGreyBox-description-main">This will turn off away after the selected time</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </modal>
    <modal class="modalForDropdown" width="600px" height="auto" :scrollable="true" :name="`${_uid}-userProfileStatusModal`">
      <section class="ct-user-new dialer-edit">
        <div class="dialer-edit-header d-flex w-100 align-items-start justify-content-between pt-0">
          <div class="text-left w-75">
            <h2 class="dialer-settings-title newer">Status</h2>
            <div class="dialer-settings-intro newerTextVersion w-75 mb-0">
              Choose your preferred status here
            </div>
          </div>
          <div class="dialer-edit-actions">
            <button class="newDoneButton" @click="$modal.hide(`${_uid}-userProfileStatusModal`)">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023 hasScrollBarInsideIt mt-20px" style="--total: 90vh; --minusValue: 75px;">
          <div class="whiteBoxWithBorderInsideGreyBox">
            <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none" v-for="status in $store.state.common.statuses" :key="status.id" >
              <div :style="`border-color: ${status.colour}`" 
                :class="`actLikeSquareRadio mr-20px withoutAfter ${status.id === $store.getters.getCurrentUser.status_id ? 'checked' : '' }`" 
                @click="
                  $store.dispatch('setStatus',status.id);
                  showNotificationForStatusCahnge(status.id)
                "
              >
                <div class="insteadOfAfter" :style="`background-color: ${status.colour}`"></div>
              </div>
              <div class="latestGreyBox-heading-main">{{ status.status }}</div>
            </div>
          </div>
        </div>
      </section>
    </modal>
    
    <CustomeSnoozeModal @time="snooze({ label: 'custom', sec: $event.time_seconds, })" />


  </div>
</template>

<script>
import { events } from '../utils';
import CustomeSnoozeModal from "./Modals/CustomeSnoozeModal.vue";
import { mapGetters, mapMutations, mapState } from 'vuex';
import { SET_LOCAL_SETTING_SETTINGS_TAB } from '@/store/helper/mutations';
export default {
  name: 'UserProfile',
  inject: [
    'local_filess', 
    'logout', 
    'getProperty',
    'snooze',
    'appNotify',
  ],
  components: {
    CustomeSnoozeModal,
  },
  data() {
    return {
      isDropdown2Visible: false,
    };
  },
  computed: {
    ...mapState({
      snooze_label: (state)=>state.sip.snooze
    }),
    ...mapGetters([
      'isDormant',
      'isFreePlanDisabled',
    ]),
    sip(){ return this.$store.state.sip.state },
    currentUserStatusIdAndStatuses() {
      return {
        status_id: this.$store.getters.getCurrentUser?.status_id,//this.current_user.status_id,
        statuses: this.$store.state.common.statuses,
      };
    },
  },
  watch: {
    currentUserStatusIdAndStatuses(obj) {
      const { status_id, statuses } = obj;
      const status = statuses.find((status) => status.id == status_id);
      if (!status) return;
      if (!status.allow_call && this.snooze_label!='off') return;
      if (status.allow_call && this.snooze_label=='off') return;
      this.snooze({
        label: !status.allow_call?'unlimited':'off',
        status_change: false
      })
    },
  },
  methods: {
    ...mapMutations([
      SET_LOCAL_SETTING_SETTINGS_TAB
    ]),
    //--other--
    onClickAvatar(){
      this.SET_LOCAL_SETTING_SETTINGS_TAB('Profile')
      this.$store.state.global_conditions.tab='settings'
      this.$root.$emit(events.setting_tab,{ tab: 'Profile' })
    },
    //--events--
    async switchOrganization(organization) {
      this.$root.$emit(events.switch_organization, {
        organization,
      });
    },
    bvDropdownHide(bvEvent) {
      if (bvEvent.componentId === "dropdown-2") {
        this.isDropdown2Visible = false;
      }
      if (this.isDropdown2Visible) {
        bvEvent.preventDefault();
      }
    },
    bvDropdownShow(bvEvent) {
      if (bvEvent.componentId === "dropdown-2") {
        this.isDropdown2Visible = true;
      }
    },
    showNotificationForStatusCahnge(value){
      setTimeout(()=>{
        if(value === this.$store.getters.getCurrentUser.status_id){
          this.appNotify({
            message:'Status changed successfully',
            type:'success'
          })
        }
        // console.log("value",value)
        // console.log("this.$store.getters.getCurrentUser.status_id",this.$store.getters.getCurrentUser.status_id)
      },2 * 1000)
    }
  },
  created() {
    this.$root.$on("bv::dropdown::show", this.bvDropdownShow);
    this.$root.$on("bv::dropdown::hide", this.bvDropdownHide);
  },
  beforeDestroy() {
    this.$root.$off("bv::dropdown::show", this.bvDropdownShow);
    this.$root.$off("bv::dropdown::hide", this.bvDropdownHide);
  },
}
</script>

<style>

</style>