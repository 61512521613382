var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup",
    attrs: {
      "width": "40%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header w-100 pb-0"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0 d-flex align-items-center"
  }, [_c('a', {
    staticClass: "newBackButton mr-16px",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "arrow-left-circle"
    }
  }), _c('span', {
    staticClass: "newBackButton-textpart"
  }, [_vm._v("Back")])], 1), _vm._v(" Public Holidays ")])]), _vm.data.group_id ? _c('UpdatePublicHolidayGroup', {
    attrs: {
      "groupId": _vm.data.group_id
    },
    on: {
      "removed": function ($event) {
        _vm.$modal.hide(_vm.modalName);
        _vm.$emit('removed');
      },
      "updated": function ($event) {
        return _vm.$emit('updated');
      }
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }