<template>
  <div class="dialer-analytics-main dialer-analytics-billing">
    <div class="dialer-box">
      <header class="dialer-analytics-titleBar">
        <template v-if="getIsMobile">
          <div class="dialer-analytics-title calc-title-width-analytics">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" @click="$emit('back')" />
            <h1 class="">Billed calls</h1>
          </div>
        </template>
        <div v-else class="dialer-analytics-title">
          <b-icon icon="wallet" variant="primary" />
          <h1 class="md-mar-left">Billed calls</h1>
        </div>
      </header>
      <div class="dialer-analytics-reporting-filter mt-4">
        <div class="dialer-analyticsReportingFilter-inner dialer-flex align-items-end">
          <div class="dialer-analyticsReportingFilter-inner-leftSide">
            <div class="mb-0 column col2 whiteBGinputWithGreyRoundedBorder">
              <!-- <label class="dialer-input-label pl-2">Call number</label> -->
              <label class="dialer-input-label pl-2">From number</label>
              <b-form-input
                type="text"
                v-vb-input-number-formate
                v-model="filter.billing.callerid"
                placeholder="From number"
              />
              <p v-if="$v.filter.billing.callerid.$invalid" class="text text-danger animated bounceIntop">
                <span v-if="!$v.filter.billing.callerid.validNumber">* Number is invalid</span>
              </p>
            </div>
            <div class="mb-0 column col1 whiteBGinputWithGreyRoundedBorder">
              <!-- <label class="pl-2">Number called</label> -->
              <label class="pl-2">To number</label>
              <div class="whiteBGinputWithGreyRoundedBorder">
                <b-form-input 
                  type="text"
                  v-vb-input-number-formate
                  v-model="filter.billing.callednumber"
                  placeholder="To number"
                />
                <p v-if="$v.filter.billing.callednumber.$invalid" class="text text-danger animated bounceIntop">
                  <span v-if="!$v.filter.billing.callednumber.validNumber">* Number is invalid</span>
                </p>
              </div>
            </div>
            <div class="mb-0 column col3 whiteBGinputWithGreyRoundedBorder dropdownVersion">
              <label class="dialer-input-label pl-2">Select billed</label>
              <b-select v-model="filter.billing.billed" :options="billedOptions">Select billed</b-select>
            </div>
          </div>
          <b-button class="newButton ml-auto" @click="fetchBilling()">
            <vb-icon icon="squared-browse-icon" height="38px" width="38px" />
            <span class="newButton-textPart">Fetch</span>
          </b-button>
        </div>
      </div>
      <!-- <div class="d-flex w-100 justify-content-end mb-4">
        <b-button class="newButton ml-auto" @click="fetchBilling()">
          <vb-icon icon="squared-browse-icon" height="38px" width="38px" />
          <span class="newButton-textPart">Fetch</span>
        </b-button>
      </div> -->
      <vb-table class="latestTableDesign-withBlackBorders-again pt-3"
        :noRecordClass="`noDataInTable`"
        textNoRecord=" "
        :isListEmpty="response.billing.length==0"
        :loading="api.fetch_billing.send" 
        :listLength="pagination.billing.total" 
        :perPage="pagination.billing.per_page" 
        @page-changed="pagination.billing.page=$event;fetchBilling();"
        :conditions="{ 
          pagination: { 
            per_page: false 
          } 
        }"
        :page="pagination.billing.page"
      >
        <tr slot="header">
          <!-- <th class="dialer-has-sort">
            <span>id</span>
          </th> -->
          <th class="dialer-has-sort">
            <!-- <span>Caller Number</span> -->
            <span>From</span>
          </th>
          <th class="dialer-has-sort">
            <!-- <span>Called Number</span> -->
            <span>To</span>
          </th>
          <th class="dialer-has-sort">
            <span>Time</span>
          </th>
          <th class="dialer-has-sort">
            <span>Duration</span>
          </th>
          <th class="dialer-has-sort">
            <span>Cost</span>
          </th>
        </tr>
        <template #body>
          <tr class="dialer-row-select" v-for="data in response.billing" :key="data.id">
            <!-- <td class="dialer-row-title">{{ data.id }}</td> -->
            <td class="dialer-row-title">{{ data.callerid | number_formater }}</td>
            <td class="dialer-row-title">{{ data.called_number | number_formater }}</td>
            <td class="dialer-row-title">{{ data.call_start }}</td>
            <td class="dialer-row-title">{{ data.duration | duration_format }}</td>
            <td class="dialer-row-title">{{ data.client_cost }}</td>
          </tr>
        </template>
        <template #loading>
          <tr v-for="n in 5" :key="n">
            <!-- <td>
              <div class="latestShimmerDesign" style="height:20px;width:-webkit-fill-available;"></div>
            </td> -->
            <td>
              <div class="latestShimmerDesign" style="height:20px;width:-webkit-fill-available;"></div>
            </td>
            <td>
              <div class="latestShimmerDesign" style="height:20px;width:-webkit-fill-available;"></div>
            </td>
            <td>
              <div class="latestShimmerDesign" style="height:20px;width:-webkit-fill-available;"></div>
            </td>
            <td>
              <div class="latestShimmerDesign" style="height:20px;width:-webkit-fill-available;"></div>
            </td>
            <td class="dialer-col-right">
              <div class="latestShimmerDesign" style="height:20px;width:-webkit-fill-available;"></div>
            </td>
          </tr>
        </template>
      </vb-table>
    </div>
  </div>
</template>

<script>
import { 
  $fn, 
  VOIP_API 
} from "@/utils";
import { number_formater } from '@/filter';
import { mapGetters, mapState } from 'vuex';
let billed_options = [
  {
    text: 'All',
    value: 'all',
  },
  {
    text: 'Billed',
    value: 'billed_call',
  }
]
export default {
  name: "Billing",
  data() {
    return {
      api: {
        fetch_billing: this.$helperFunction.apiInstance({  }),
      },
      response: {
        billing: [],
      },
      filter: {
        billing: {
          callednumber: '',
          callerid: '',
          account: '',
          billed: 'all',
        },
      },
      pagination: {
        billing: {
          per_page: 10,
          page: 1,
          total: 0,
        },
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getIsMobile'
    ]),
    ...mapState({
      usersAccountcode: state=>Object.keys(state.common.voipusers),
    }),
    billedOptions(){ return billed_options },
  },
  validations: {
    filter: {
      billing: {
        callednumber: {
          validNumber(value){ return !value || $fn.validPhoneNumber(value) },
        },
        callerid: {
          validNumber(value){ return !value || $fn.validPhoneNumber(value) },
        },
      },
    },
  },
  methods: {
    async fetchBilling() {
      this.$v.filter.billing.$touch();
      if (this.$v.filter.billing.$invalid || this.api.fetch_billing.send) return;
      this.api.fetch_billing.send = true;
      try {
        const { data } = await VOIP_API.endpoints.analytics.billingList({
          page: this.pagination.billing.page,
          callednumber: number_formater(this.filter.billing.callednumber,'',true)?.replaceAll?.(' ','')?.replaceAll?.('+','') ?? '',
          callerid: number_formater(this.filter.billing.callerid,'',true)?.replaceAll?.(' ','')?.replaceAll?.('+','') ?? '',
          account: this.filter.billing.account,
          billed: this.filter.billing.billed,
        })
        this.response.billing=data?.data ?? []
        this.pagination.billing.total=data?.totalCount ?? 0
      } catch(ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_billing.send = false;
      }
    },
  },
  mounted() {
    this.pagination.billing.page=1
    this.fetchBilling();
  },
};
</script>

<style>
</style>