var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "RecordingModalLikeSomething",
    attrs: {
      "width": "50%",
      "height": "auto",
      "name": _vm.modalName,
      "scrollable": true
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit"
  }, [_c('form', {
    staticClass: "dialer-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.addSound();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header pt-0 d-block"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Upload your custom sound file ")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('a', {
    staticClass: "newCloseButton",
    attrs: {
      "id": "CloseModalButton"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)], 1)]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v(" Enter the name of the music to be uploaded below, and select the MP3 audio file. ")])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.add_sound.name,
      expression: "forms.add_sound.name"
    }],
    staticClass: "w-100 mt-8px",
    attrs: {
      "type": "text",
      "disabled": _vm.api.add_sound.send,
      "maxlength": _vm.$v.forms.add_sound.name.$params.maxLength.max
    },
    domProps: {
      "value": _vm.forms.add_sound.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.add_sound, "name", $event.target.value);
      }
    }
  }), _vm.forms.add_sound.submitted && _vm.$v.forms.add_sound.name.$invalid || _vm.api.add_sound.validation_errors.name ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.add_sound.name.required ? _c('span', [_vm._v("* Name is required")]) : !_vm.$v.forms.add_sound.name.alphaNum ? _c('span', [_vm._v("* Name can be only alphabet, number and space")]) : !_vm.$v.forms.add_sound.name.minLength ? _c('span', [_vm._v("* Name should be minimum " + _vm._s(_vm.$v.forms.add_sound.name.$params.minLength.min) + " character")]) : !_vm.$v.forms.add_sound.name.maxLength ? _c('span', [_vm._v("* Name can be maximum " + _vm._s(_vm.$v.forms.add_sound.name.$params.maxLength.max) + " character")]) : _vm._e(), _vm._l(_vm.api.add_sound.validation_errors.name, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()]), _vm.conditions.select_category ? _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion mt-20px"
  }, [_c('label', [_vm._v("Category")]), _c('b-dropdown', {
    attrs: {
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', [_vm._v(_vm._s(_vm._f("get_property")(_vm.selectedSoundCategory, 'text', 'Select category')))]), _c('b-icon', {
          attrs: {
            "icon": "chevron-down"
          }
        })];
      },
      proxy: true
    }], null, false, 4059484375)
  }, _vm._l(_vm.soundCategory, function (category) {
    return _c('b-dropdown-item', {
      key: category.value,
      on: {
        "click": function ($event) {
          _vm.forms.add_sound.category = category.value;
        }
      }
    }, [_c('span', {
      staticClass: "sm-mar-left"
    }, [_vm._v(_vm._s(category.text))])]);
  }), 1), _vm.forms.add_sound.submitted && _vm.$v.forms.add_sound.category.$invalid || _vm.api.add_sound.validation_errors.group ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.add_sound.category.required ? _c('span', [_vm._v("* Category is required")]) : _vm._e(), _vm._l(_vm.api.add_sound.validation_errors.group, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()], 1) : _vm._e(), _c('div', {
    staticClass: "latestGreyBox-heading-main mt-32px"
  }, [_vm._v("Your recording")]), _c('div', {
    staticClass: "latestUploadAndRecord-container mt-20px"
  }, [_c('AudioFileInput', {
    attrs: {
      "disabled": _vm.api.add_sound.send,
      "options": Object.assign({}, _vm.localOptions, {
        recording: _vm.localOptions.recording && _vm.forms.add_sound.category != _vm.soundCategory.moh.value,
        text_to_speech: _vm.localOptions.text_to_speech && _vm.forms.add_sound.category != _vm.soundCategory.moh.value
      })
    },
    on: {
      "change": function ($event) {
        return _vm.addSound($event);
      }
    }
  }), _vm.forms.add_sound.submitted && _vm.$v.forms.add_sound.file.$invalid || _vm.api.add_sound.validation_errors.file ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.add_sound.file.required ? _c('span', [_vm._v("* File is required")]) : _vm._e(), _vm._l(_vm.api.add_sound.validation_errors.file, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()], 1)])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }